import { lsToPx } from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import RoundedButton from "../ui/roundedButton";
import CircleCanvas from "@/components/animation/circle-canvas";

export default function SchedulerVisual(props) {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  return (
    <section className="sc-visual" css={style.visual}>
      <CircleCanvas
        bgColor="transparent"
        color="#fff"
        isMask="false"
        id="circleCanvas"
        scale="2"
        // maskProgress={listProgress}
      />
      <div className="visual-wrap" css={style.title}>
        <h2 className="page-title">
          {!isMobile ? (
            <img src="/images/image/scheduler/img-title.svg" alt="scheduler" />
          ) : (
            <img src="/images/image/scheduler/img-title-mo.svg" alt="scheduler" />
          )}
        </h2>
        <p className="page-desc">
          <span>Check the schedule of</span>
          <span>
            Bauer Lab’s <em>global branches.</em>
          </span>
        </p>
        <div className="btn-box">
          <RoundedButton type="scroll" />
        </div>
      </div>
    </section>
  );
}

const style = {
  visual: css`
    background-color: ${theme.palette.black};
    .circle-canvas {
      opacity: 0.7;
    }
  `,
  title: css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* height: 100vh; */
    height: calc(100vh - 70px);
    padding: ${theme.typography.pxToRem(180)} 0 ${theme.typography.pxToRem(64)};
    /* margin-bottom: ${theme.typography.pxToRem(200)}; */

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      height: calc(var(--vh) * 100);
      padding: calc(168 / 768 * 100vh) calc(20 / 375 * 100vw) 1.875rem;
    }
    .page-title {
      width: calc(1473 / 1920 * 100%);
      aspect-ratio: 1473 / 260;
      margin-bottom: ${theme.typography.pxToRem(50)};
      img {
        width: 100%;
        height: 100%;
      }

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: 100%;
        aspect-ratio: 335 / 58;
        margin-bottom: ${theme.typography.pxToRem(60)};
      }
    }

    .page-desc {
      display: flex;
      flex-direction: column;
      transform: translateX(70px);
      span {
        ${globalCSS.en};
        font-weight: 400;
        font-size: ${theme.typography.pxToRem(30)};
        line-height: calc(40 / 30);
        ${lsToPx(30, -2)};
        color: ${theme.palette.white};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: calc(16 / 375 * 100vw);
          line-height: calc(20 / 16);
          letter-spacing: 0;
        }
        em {
          color: ${theme.palette.yTxt};
        }
        &:nth-of-type(2) {
          transform: translateX(50px);
        }
      }

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        transform: none;
      }
    }

    .btn-box {
      margin-top: auto;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        position: absolute;
        bottom: ${theme.typography.pxToRem(30)};
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .current-year {
      position: absolute;
      bottom: ${theme.typography.pxToRem(40)};
      left: ${theme.typography.pxToRem(40)};
      ${globalCSS.en};
      color: ${theme.palette.white};
      font-weight: 700;
      font-size: ${theme.typography.pxToRem(30)};
      line-height: ${theme.typography.pxToRem(40)};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        display: none;
      }
    }
  `,
};
