import { formatYmd } from "@/assets/ts/format";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Item } from "@/components/posts/post";
import { AnimateSharedLayout } from "framer-motion";
import theme from "@/assets/ts/theme";
import ViewMore from "../ui/viewMore";
import { getImagePath } from "@/assets/ts/image";
import { useRecoilState } from "recoil";
import { pageYState } from "@/state";
import { useRef } from "react";
import { lockDom, unlockDom } from "@/assets/ts/common";

type ItemDataType = {
  image: object;
  title: string;
  cont: string;
  date: string;
  prvncDtl: string;
  seq: number;
  category: string;
  idx: number;
  tpDtl: number;
};

export default function PostItem({
  data,
  lenis,
  schTxt,
  handleItemHover,
}: {
  data: ItemDataType;
  lenis: any;
  schTxt: string;
  handleItemHover: () => void;
}) {
  const navigator = useNavigate();
  const location = useLocation();

  const handleItemClick = (seq: string | number) => {
    const hasSearch = location.search ? location.search : `?schTpDtl=${data.tpDtl}`;
    navigator(`/${location.pathname?.split("/")?.[1]}/${seq}${hasSearch}`);
  };

  const highlightedText = (text: any, query: any) => {
    if (query !== "" && text.includes(query)) {
      const parts = text.split(new RegExp(`(${query})`, "gi"));

      return (
        <>
          {parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
              <span
                key={index}
                style={{ color: theme.palette.yTxt }}>
                {part}
              </span>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </>
      );
    }

    return <span>{text}</span>;
  };

  const [pageY, setPageY] = useRecoilState(pageYState);
  // const testRef = useRef(null);

  function scrollLock() {
    setPageY(window.scrollY);
    const main = document.querySelector("main");
    main.style.top = `-${window.scrollY}px`;
    lockDom("html");
    lockDom("body");
  }

  return (
    <>
      <AnimateSharedLayout>
        <Item
          onMouseEnter={handleItemHover}
          initial="normal"
          transition={{ type: "tween" }}
          onClick={() => {
            handleItemClick(data.seq);
            scrollLock();
          }}
          layoutId={String(data.seq)}
          className={location.pathname.includes("news") ? "item-news" : ""}>
          <Link
            to="#"
            onClick={(event) => event.preventDefault()}>
            {!location.pathname.includes("news") && (
              <div className="img-box">
                <img
                  src={getImagePath(data?.image)}
                  alt={data.title}
                />
                <ViewMore />
              </div>
            )}
            <div className="text-box">
              <em className="title">{highlightedText(data.title, schTxt)}</em>
              <div className="desc-box">
                {!location.pathname.includes("news") && <span>{data?.category}</span>}
                {data.tpDtl === 2 ? null : <span> {data.prvncDtl}</span>}
                <span>{formatYmd(data.date)}</span>
              </div>
            </div>
          </Link>
        </Item>
      </AnimateSharedLayout>
    </>
  );
}
