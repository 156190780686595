import { RouteType } from "@/types/route";
import Main from "@/pages";
import ContactUs from "@/pages/contact";
import About from "@/pages/about";
import Creation from "@/pages/creation";
import CreationDetail from "@/pages/creation/detail";
import NewsList from "@/pages/news";
import NoticeList from "@/pages/notice";
import International from "@/pages/international";
import Guide from "@/pages/guide";
import GuideFont from "@/pages/guide/font";
import GuidePalette from "@/pages/guide/palette";
import { Error404, Error500 } from "@/components/error/error";
import Scheduler from "@/pages/scheduler";

const RoutesData: RouteType[] = [
  { component: Main, path: "" },
  { component: ContactUs, path: "/contact" },
  { component: About, path: "/about" },
  { component: Creation, path: "/creation" },
  { component: CreationDetail, path: "/creation/:seq" },
  { component: NewsList, path: "/news" },
  { component: NewsList, path: "/news/:seq" },
  { component: NoticeList, path: "/notice" },
  { component: NoticeList, path: "/notice/:seq" },
  { component: International, path: "/international" },
  { component: Scheduler, path: "/scheduler" },
  { component: Scheduler, path: "/scheduler/:seq" },
  { component: Guide, path: "/guide" },
  { component: GuideFont, path: "/guide/font" },
  { component: GuidePalette, path: "/guide/palette" },
  { component: Error404, path: "/*" },
];
export default RoutesData;
