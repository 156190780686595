import globalCSS from "@/assets/ts/global-css";
import guideCSS from "@/assets/ts/guide-css";
import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";

export default function GuideFont() {
  return (
    <div className="guide-container" css={[guideCSS.guideContainer, style.font]}>
      <div className="guide-content">
        <div className="title-box">
          <h2 className="guide-section-title">Font</h2>
        </div>
        <div className="content-box">
          <div className="font-box">
            <h3>
              roman
              <br />
              handing
            </h3>
            <span css={globalCSS.romanH1}>globalCSS.roman1</span>
            <span css={globalCSS.romanH2}>globalCSS.roman2</span>
            <span css={globalCSS.romanH3}>globalCSS.roman3</span>
            <span css={globalCSS.romanH4}>globalCSS.roman4</span>
            <span css={globalCSS.romanH5}>globalCSS.roman5</span>
            <span css={globalCSS.romanH6}>globalCSS.roman6</span>
            <span css={globalCSS.romanH7}>globalCSS.roman7</span>
          </div>
          <div className="font-box">
            <h3>
              roman
              <br />
              contents
            </h3>
            <span css={globalCSS.romanCont1}>globalCSS.romanCont1</span>
            <span css={globalCSS.romanCont2}>globalCSS.romanCont2</span>
            <span css={globalCSS.romanCont3}>globalCSS.romanCont3</span>
            <span css={globalCSS.romanCont4}>globalCSS.romanCont4</span>
          </div>
          <div className="font-box">
            <h3>
              hangul
              <br />
              heading
            </h3>
            <span css={globalCSS.hangulH1}>globalCSS.hangulH1</span>
          </div>
          <div className="font-box">
            <h3>
              hangul
              <br />
              contents
            </h3>
            <span css={globalCSS.hangulCont1}>globalCSS.hangulCont1</span>
            <span css={globalCSS.hangulCont2}>globalCSS.hangulCont2</span>
            <span css={globalCSS.hangulCont3}>globalCSS.hangulCont3</span>
            <span css={globalCSS.hangulCont4}>globalCSS.hangulCont4</span>
          </div>
        </div>
      </div>
    </div>
  );
}

const style = {
  font: css`
    .content-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .font-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: ${theme.typography.pxToRem(150)};

        h3 {
          text-align: center;
          text-transform: uppercase !important;
          ${globalCSS.romanH7}
          margin-bottom: ${theme.typography.pxToRem(70)};
        }

        span {
          + span {
            margin-top: ${theme.typography.pxToRem(30)};
          }
        }
      }
    }
  `,
};
