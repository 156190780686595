import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import { getRandomImage } from "@/assets/ts/bauerlab/util/utils";
import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import React, { useEffect, useRef } from "react";

export default function RollingImgs({ lenis, progress, data }) {
  function innerText(category: string, number: string) {
    return (
      <div className="text-box">
        <em className="category">{category}</em>
        <span className="number">{number}</span>
      </div>
    );
  }
  const container = useRef(null);
  const left = useRef(null || []);
  const right = useRef(null);

  function movingImgWrap(dom: HTMLElement, scroll: number) {
    const direction = dom.getAttribute("data-direction");
    if (direction === "left") {
      let left = `20% + (-5% * (${scroll} - ${container.current.offsetTop}) / ${container.current.clientHeight} * 15)`;

      dom.style.transform = `translateX(calc(${left}))`;
    } else {
      let right = `-20% + (5% * (${scroll} - ${container.current.offsetTop}) / ${container.current.clientHeight} * 15)`;
      dom.style.transform = `translateX(calc(${right}))`;
    }
  }

  return (
    <div className="imgs-list-wrap" css={style.imgs} ref={container}>
      <ul
        className="intro-img-list"
        data-direction={"left"}
        ref={left}
        style={{
          transform: `translate3d(${100 - 200 * progress}%,0,0)`,
        }}
      >
        {/* {Array.isArray(data) &&
          data?.map((item, index) => {
            return (
              <li className="intro-img-item">
                <img src={getRandomImage(index)} alt={`${item.nm}이미지`} />
                {innerText(item.nm, item.count)}
              </li>
            );
          })} */}
        <li className="intro-img-item">
          <img
            src="/images/image/creation/img-creation-rolling1.png"
            alt="bauerlab creation"
          />
          {innerText(data?.[0]?.nm, data?.[0]?.count)}
        </li>
        <li className="intro-img-item">
          <img
            src="/images/image/creation/img-creation-rolling2.png"
            alt="bauerlab creation"
          />
        </li>
        <li className="intro-img-item">
          <img
            src="/images/image/creation/img-creation-rolling3.png"
            alt="bauerlab creation"
          />
          {innerText(data?.[1]?.nm, data?.[1]?.count)}
        </li>
        <li className="intro-img-item">
          <img
            src="/images/image/creation/img-creation-rolling4.png"
            alt="bauerlab creation"
          />
        </li>
        <li className="intro-img-item">
          <img
            src="/images/image/creation/img-creation-rolling5.png"
            alt="bauerlab creation"
          />
          {innerText(data?.[2]?.nm, data?.[2]?.count)}
        </li>
      </ul>
      <ul
        className="intro-img-list"
        data-direction={"right"}
        ref={right}
        style={{
          transform: `translate3d(${-100 + 200 * progress}%,0,0)`,
        }}
      >
        <li className="intro-img-item">
          <img
            src="/images/image/creation/img-creation-rolling7.png"
            alt="bauerlab creation"
          />
        </li>
        <li className="intro-img-item">
          <img
            src="/images/image/creation/img-creation-rolling6.png"
            alt="bauerlab creation"
          />
          {innerText(data?.[3]?.nm, data?.[3]?.count)}
        </li>
        <li className="intro-img-item">
          <img
            src="/images/image/creation/img-creation-rolling8.png"
            alt="bauerlab creation"
          />
        </li>
        <li className="intro-img-item">
          <img
            src="/images/image/creation/img-creation-rolling9.png"
            alt="bauerlab creation"
          />
          {innerText(data?.[4]?.nm, data?.[4]?.count)}
        </li>
        <li className="intro-img-item">
          <img
            src="/images/image/creation/img-creation-rolling10.png"
            alt="bauerlab creation"
          />
        </li>
      </ul>
    </div>
  );
}

const style = {
  imgs: css`
    position: relative;
    top: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* height: 100vh; */
    gap: ${theme.typography.pxToRem(42)};
    transform: translate3d(0, -50%, 0) rotate(-3.07deg);

    .intro-img-list {
      display: flex;
      gap: ${theme.typography.pxToRem(25)};
      align-items: center;

      &[data-direction="left"] {
        transform: translateX(70%);
      }

      &[data-direction="right"] {
        transform: translateX(-70%);
      }

      .intro-img-item {
        position: relative;
        width: ${theme.typography.pxToRem(336)};
        height: ${theme.typography.pxToRem(336)};
        border-radius: 42px;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: ${theme.typography.pxToRem(170)};
          height: ${theme.typography.pxToRem(170)};
        }

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: -1;
          object-fit: cover;
          border-radius: 42px;
        }

        .text-box {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          & > * {
            text-align: center;
            font-weight: 400;
            font-size: ${theme.typography.pxToRem(38)};
            line-height: calc(38 / 48);
            color: ${theme.palette.white};
            letter-spacing: 0.76px;
            text-transform: uppercase !important;

            @media (max-width: ${theme.breakpoints.values.lg}px) {
              font-size: ${theme.typography.pxToRem(20)};
              line-height: ${theme.typography.pxToRem(24)};
            }
          }

          span {
            margin-top: ${theme.typography.pxToRem(10)};
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              margin-top: ${theme.typography.pxToRem(2)};
            }
          }
        }
      }
    }
  `,
};
