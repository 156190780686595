import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import { headerMenuProgressState, lenisScrollState, pageYState, prevPathState } from "@/state";
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Partners from "@/components/main/partners";
import WeAre from "@/components/main/weare";
import Circle from "@/assets/ts/bauerlab/Circle";
import Creation from "@/components/main/creation";
import { useMainManager } from "@/hooks/use-main";
import { useMediaQuery } from "@mui/material";
import theme from "@/assets/ts/theme";
import globalCSS from "@/assets/ts/global-css";
import RoundedButton from "@/components/ui/roundedButton";
import { Player } from "@lottiefiles/react-lottie-player";
import { lockDom, unlockDom } from "@/assets/ts/common";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";
// import LogoSvg from "@/assets/svg/logo-title.svg";

export default function Main({ lenis, webGL }) {
  const { list, fetchFinishAll, creationType } = useMainManager();

  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const interactionItem = useRef({
    observer: null,
    scroll: null,
  });

  const [menuProgressState, setMenuProgressState] = useRecoilState(headerMenuProgressState);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);

  const webGLRef = useRef(null);
  const [init, setInit] = useState(false);
  const [introEnd, setIntroEnd] = useState(false);
  const topEl = useRef(null);
  const scrollObjects = useRef({});
  const creation = useRef(null);
  const circleAnimation = useRef(null);
  const circleCanvas = useRef(null);
  const identity = useRef(null);
  const identityText = useRef(null);

  useEffect(() => {
    if (isMobile) return;

    if (!webGLRef.current) {
      webGLRef.current = webGL;
    } else {
      if (!isMobile && fetchFinishAll && Object.keys(list).length === 4) {
        webGLRef.current.renderStart();
        webGLRef.current.addSyncImgMesh();
      }
    }
  }, [webGLRef.current, webGL, fetchFinishAll, isMobile, list]);

  useEffect(() => {
    let creationItem = [];
    let observer = new Observer();
    document.querySelectorAll(".creation-item").forEach((el: HTMLElement) => {
      el.setAttribute("data-fade-anim-mask", "");
      el.style.transitionDelay = Math.random() * 0.4 + "s";
      observer.add(el);
      creationItem.push(el);
    });
    return () => {
      creationItem.forEach((el) => {
        observer.remove(el);
      });
      observer.kill();
    };
  }, [webGL, fetchFinishAll, isMobile, list]);

  // useEffect(() => {
  //   if (webGLRef.current && webGL && fetchFinishAll) {
  //     if (isMobile) {
  //       webGLRef.current.removeSyncImgMesh();
  //     } else {
  //       webGLRef.current.addSyncImgMesh();
  //     }
  //   }
  // }, [isMobile]);

  // useEffect(() => {
  //   return () => {
  //     webGLRef.current.removeSyncImgMesh();
  //     setTimeout(() => {
  //       webGLRef.current.renderStop();
  //     }, 100);
  //   };
  // }, [webGLRef]);

  useEffect(() => {
    if (!circleAnimation.current) {
      circleAnimation.current = new Circle(circleCanvas.current);
      circleAnimation.current.isMask = true;
    }
  }, [circleCanvas]);

  useEffect(() => {
    if (init) return;
    setInit(true);

    let scrollObjs = scrollObjects.current;

    creation.current = document.getElementById("creation");
    identity.current = document.getElementById("identity");
    identityText.current = document.getElementById("identityText");

    /*
      header menu progress
    */
    let menuProgressValue = { ...menuProgressState };
    scrollObjs["weare"] = new ScrollObject(document.getElementById("weare"));
    scrollObjs["creation"] = new ScrollObject(creation.current);
    scrollObjs["partners"] = new ScrollObject(document.getElementById("partners"));
    scrollObjs["top"] = new ScrollObject(topEl.current);
    scrollObjs["identity"] = new ScrollObject(identity.current);
    scrollObjs["identityText"] = new ScrollObject(identityText.current);

    scrollObjs["weare"].on(ScrollObject.EVENT_INTERSECTION, ({ inview, rect }) => {
      if (webGLRef.current) {
        if (inview && rect.top > window.innerHeight * 0.5) {
          webGLRef.current.particleOnToggle(inview);
        }
      }
    });
    scrollObjs["identityText"].on(ScrollObject.EVENT_INTERSECTION, ({ inview, rect }) => {
      if (webGLRef.current && !inview && rect.top < window.innerHeight * 0.5) {
        webGLRef.current.particleOnToggle(false);
      } else if (webGLRef.current && inview) {
        webGLRef.current.particleOnToggle(true);
      }
    });

    setTimeout(() => {
      for (let key in scrollObjects.current) {
        scrollObjects.current[key].resize();
      }
    }, 1000);

    return () => {
      for (let key in scrollObjects.current) {
        scrollObjects.current[key].kill();
      }

      webGLRef.current.particleOnToggle(false);
      // webGLRef.current.removeSyncImgMesh();
    };
  }, []);

  useEffect(() => {
    let scroll = lenisState.scroll;
    let progress = lenisState.progress;
    let scrollObjs = scrollObjects.current;
    let menuProgressValue = { ...menuProgressState };
    for (let key in scrollObjs) {
      let scrollObj: ScrollObject = scrollObjs[key];
      scrollObj.update(scroll);

      if (key == "partners") {
        // console.log(scrollObj.ratio.inBottom);
        // scrollObj.resize();
      }

      if (menuProgressValue.hasOwnProperty(key)) {
        menuProgressValue[key] = clamp(scrollObj.ratio.inBottom) * 100;
      }
    }

    setMenuProgressState({
      ...menuProgressValue,
    });

    if (webGL) webGL.syncScroll(scroll, 0);

    /*
      weare progress
    */
    let weareP = 0;
    if (scrollObjs["weare"]) {
      weareP = scrollObjs["weare"].ratio.y;
      weareP = clamp(weareP);
    }

    /*
      identity
    */

    let identityP = clamp(scrollObjs["identity"].ratio.y - 1);

    /*
      circle
    */
    let circleP = scroll / window.innerHeight;
    let circleY = window.innerHeight * 0.5 - scroll;
    let circleTop = scroll;
    let circleTopLimit = window.innerHeight * 2;
    if (circleY < 0) {
      circleY = 0;
    }
    if (circleTop > circleTopLimit) {
      circleTop = circleTopLimit;
    }

    circleAnimation.current.radiusScale = 1.5 + circleP;
    // circleAnimation.current.zoomSpeed = 0.6 + circleP * 0.2;
    if (introEnd) {
      circleAnimation.current.opacity = 0.6 - clamp(circleP * 0.8) * 0.6;
    } else {
      circleAnimation.current.opacity = 0;
    }
    circleAnimation.current.maskProgress = weareP;
    circleAnimation.current.translate(0, circleY);
    if (weareP >= 1) {
      circleAnimation.current.stop();
    } else {
      circleAnimation.current.start();
    }
    // circleCanvas.current.style.transform = `translate3d(0,${circleTop}px,0)`;

    if (webGL) {
      webGL.particleZ(-1000 + weareP * 1000 - identityP * 3000);
    }
  }, [lenisState]);

  /* 인트로 때 헤더, 스크롤 버튼 숨기기 및 인트로 스크롤 고정 ///// 사이트 첫 진입 시에만 인트로 노출(로컬스토리지) */
  const [pageY, setPageY] = useRecoilState(pageYState);

  const scrollArticleRef = useRef(null);
  const introLottieRef = useRef(null);
  const [totalFrame, setTotalFrame] = useState(0);
  const prevPath = useRecoilValue(prevPathState);
  const location = useLocation();

  const [showIntro, setShowIntro] = useState(true);

  function scrollLock() {
    setPageY(window.scrollY);
    const main = document.querySelector("main");
    main.style.top = `-${window.scrollY}px`;
    lockDom("html");
    lockDom("body");
  }
  function scrollUnlock() {
    const main = document.querySelector("main");

    unlockDom("html");
    unlockDom("body");
    main.style.top = `0px`;
    window.scrollTo(0, pageY);
    window.setTimeout(() => {
      setPageY(null);
    });
  }

  useEffect(() => {
    if (prevPath !== "/") {
      setShowIntro(false);
    }
  }, [location, prevPath]);

  useEffect(() => {
    if (introEnd) setIntroEnd(true);

    const header = document.querySelector("header");
    scrollArticleRef.current.style.transition = "all 0.3s";
    header.style.transition = "all 0.4s";

    header.style.transform = "translateY(-200%)";
    scrollArticleRef.current.style.opacity = "0";
    scrollArticleRef.current.style.transform = "translateY(30px)";
    scrollLock();

    if (introEnd) {
      scrollUnlock();
      header.style.transform = "translateY(0%)";
      scrollArticleRef.current.style.opacity = "1";
      scrollArticleRef.current.style.transform = "translateY(0px)";
    }

    if (introEnd) {
      if (circleAnimation.current) {
        circleAnimation.current.opacity = 0.6;
      }
    }
  }, [introEnd]);

  const logoMoVideoRef = useRef(null);
  const logoMoImgRef = useRef(null);

  useEffect(() => {
    if (!isMobile) return;
    if (introEnd) {
      logoMoVideoRef.current.classList.add("hidden");
      logoMoImgRef.current.classList.add("active");
    }
  }, [isMobile, introEnd]);

  // useEffect(() => {
  //   sessionStorage.setItem("welcome baurlab", "true");
  // }, []);

  return (
    <div css={style.main}>
      <div css={style.circle}>
        <canvas ref={circleCanvas}></canvas>
      </div>
      <section
        id="top"
        css={style.top}
        ref={topEl}
        className={introEnd ? "" : "isIntro"}>
        <div className="top-content">
          <h1 className={!introEnd ? "isIntro" : ""}>
            {isMobile ? (
              <>
                <video
                  ref={logoMoVideoRef}
                  autoPlay
                  playsInline
                  muted
                  style={{
                    width: "100%",
                    transition: `transform 0.5s ${theme.transitions.easing.inout3}`,
                    transform: `translate(0,${introEnd ? 0 : 25}%)`,
                    mixBlendMode: "lighten",
                  }}
                  onLoadedMetadata={(e) => {
                    if (!showIntro) {
                      setIntroEnd(true);
                      let video = e.target as HTMLVideoElement;
                      console.log("stop");
                      video.currentTime = video.duration - 0.1;
                    }
                  }}
                  onEnded={() => {
                    console.log("end");
                    if (showIntro) {
                      setIntroEnd(true);
                    }
                  }}>
                  <source
                    src="/video/logo-mobile.mp4"
                    type="video/mp4"
                  />
                </video>
                <div
                  className="logo-img-box"
                  ref={logoMoImgRef}
                  css={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    aspectRatio: "1 / 1",

                    transition: `transform 0.5s ${theme.transitions.easing.inout3}`,
                    transform: `translate(0,${introEnd ? 0 : 25}%)`,
                    mixBlendMode: "lighten",
                  }}>
                  <img
                    src="/images/image/logo-mo.png"
                    alt="bauer-lab-logo"
                  />
                </div>
              </>
            ) : (
              <video
                autoPlay
                playsInline
                muted
                style={{
                  width: "100%",
                  transition: `transform 0.5s ${theme.transitions.easing.inout3}`,
                  transform: `translate(0,${introEnd ? -45 : -10}%)`,
                  mixBlendMode: "lighten",
                }}
                onLoadedMetadata={(e) => {
                  if (!showIntro) {
                    setIntroEnd(true);
                    let video = e.target as HTMLVideoElement;
                    video.currentTime = video.duration - 0.1;
                  }
                }}
                onEnded={() => {
                  if (showIntro) {
                    setIntroEnd(true);
                  }
                }}>
                <source
                  src="/video/logo-pc.mp4"
                  type="video/mp4"
                />
              </video>
            )}
          </h1>
          <div className="lead">
            {isMobile ? (
              <>
                <p
                  style={{ transitionDelay: "0s" }}
                  className={introEnd ? "active" : ""}>
                  We are a <span className="-yellow">pioneering company,</span>
                  {/* We are an <span className="-yellow">immersive company,</span> */}
                </p>
                <p
                  style={{ transitionDelay: "0.05s" }}
                  className={introEnd ? "active" : ""}>
                  crafting an alternate realm
                </p>
                <p
                  style={{ transitionDelay: "0.1s" }}
                  className={introEnd ? "active" : ""}>
                  of innovation by intertwining technology
                </p>
                <p
                  style={{ transitionDelay: "0.15s" }}
                  className={introEnd ? "active" : ""}>
                  and life experiences through
                </p>
                <p
                  style={{ transitionDelay: "0.2s" }}
                  className={introEnd ? "active" : ""}>
                  a convergence of narrative-driven content
                </p>
                <p
                  style={{ transitionDelay: "0.25s" }}
                  className={introEnd ? "active" : ""}>
                  with diverse forms of artistic expression.
                </p>
              </>
            ) : (
              <>
                <p
                  style={{ transitionDelay: "0s" }}
                  className={introEnd ? "active" : ""}>
                  We are a <span className="-yellow">pioneering company,</span>
                  {/* We are an <span className="-yellow">immersive company,</span> */}
                </p>
                <p
                  style={{ transitionDelay: "0.05s" }}
                  className={introEnd ? "active" : ""}>
                  crafting an alternate realm of innovation by intertwining technology
                </p>
                <p
                  style={{ transitionDelay: "0.1s" }}
                  className={introEnd ? "active" : ""}>
                  and life experiences through a convergence of narrative-driven content
                </p>
                <p
                  style={{ transitionDelay: "0.15s" }}
                  className={introEnd ? "active" : ""}>
                  with diverse forms of artistic expression.
                </p>
              </>
            )}
          </div>
          <div
            className="btn-scroll-box"
            ref={scrollArticleRef}>
            <RoundedButton type={"scroll"} />
          </div>
        </div>
      </section>
      <div className="top-sticky-space"></div>
      <WeAre id="weare" />
      <Creation
        id="creation"
        lenis={lenis}
        ref={creation}
        list={list}
        fetchFinishAll={fetchFinishAll}
        creationType={creationType}
      />
      <Partners lenis={lenis}></Partners>
    </div>
  );
}

const style = {
  main: css`
    position: relative;
    .more-button-wrap {
      padding: 0 40px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    overflow: clip;
    .top-sticky-space {
      position: relative;
      width: 100px;
      height: 100vh;
    }
  `,
  top: css`
    position: absolute;
    top: 0;
    width: 100%;
    height: 200vh;

    /* background-color: transparent; */
    /* transition: background-color 0.3s; */
    &.isIntro {
      /* background-color: ${theme.palette.blue}; */
    }

    .top-content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* height: 100vh; */
      min-height: calc(var(--vh) * 100);
      h1 {
        position: relative;
        width: calc(1700 / 1920 * 100%);
        height: 120px;
        margin: 0 auto;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          position: relative;
          width: calc(100% - 40px);
          height: auto;
          margin: 0 auto;
          margin-top: -80px;
          aspect-ratio: 1/1;
          video {
            position: absolute;
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;

            &.hidden {
              visibility: hidden;
            }
          }

          .logo-img-box {
            position: absolute;
            visibility: hidden;

            &.active {
              visibility: visible;
            }
          }
        }
        img {
          width: 100%;
          height: auto;
        }

        .lf-player-container {
          position: absolute;
          width: 100%;
          height: min-content;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          transition: transform 0.6s ${theme.transitions.easing.inout3};
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            top: 0;
          }
        }

        &.isIntro {
          .lf-player-container {
            transform: translate(0, calc(120px + -50%));
          }
        }
      }

      .lead {
        color: white;
        text-align: center;
        margin-top: 60px;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin-top: -20px;
        }

        @media (min-width: 1921px) {
          transform: translateY(32%);
        }

        @media (min-width: 3500px) {
          transform: translateY(70%);
        }

        @media (min-width: 4500px) {
          transform: translateY(150%);
        }

        p {
          opacity: 0;
          transform: translate(0, 20px);
          &.active {
            opacity: 1;
            transform: translate(0, 0);
            transition: opacity 0.7s 0.5s ${theme.transitions.easing.inout1},
              transform 0.6s 0.5s ${theme.transitions.easing.inout3};
          }

          font-size: ${theme.typography.pxToRem(30)};
          line-height: ${theme.typography.pxToRem(40)};
          letter-spacing: 0.02em;
          ${globalCSS.en};

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            font-size: ${theme.typography.pxToRem(16)};
            line-height: ${theme.typography.pxToRem(20)};
          }
        }
      }

      .-yellow {
        color: ${theme.palette.yellow};
      }
    }

    .btn-scroll-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: absolute;
      bottom: ${theme.typography.pxToRem(40)};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        bottom: ${theme.typography.pxToRem(30)};
      }
    }
  `,
  circle: css`
    position: sticky;
    top: 0;
    width: 100% !important;
    height: 100vh;
    pointer-events: none;
    transition: opacity 0.3s ${theme.transitions.easing.inout1};

    canvas {
      width: 100% !important;
    }
  `,
};
