import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { styled } from "@mui/material/styles";

export const XTRadioGroupForm = styled(RadioGroup)(({ theme }) => ({}));

export const XTMonthRadioForm = styled(FormControlLabel)(({ theme }) => ({
  margin: "0",
  display: "inline-flex",
  flexDirection: "row-reverse",
  maxWidth: "fit-content",

  "+.MuiFormControlLabel-root": {
    marginTop: `${theme.typography.pxToRem(2)}`,
  },

  ".MuiRadio-root": {
    padding: 0,
    borderRadius: 0,
    color: `${theme.palette.transparent}`,

    input: {},

    span: { display: "none" },

    "&.Mui-checked": {
      width: `${theme.typography.pxToRem(10)}`,
      height: `${theme.typography.pxToRem(10)}`,
      borderRadius: "50%",
      backgroundColor: `${theme.palette.blue}`,
      marginLeft: `${theme.typography.pxToRem(10)};`,
      filter: `blur(1px)`,

      "+ .MuiTypography-root": {
        fontWeight: 700,
        color: `${theme.palette.white};`,
      },
    },
  },
  ".MuiTypography-root": {
    fontSize: `${theme.typography.pxToRem(18)}`,
    lineHeight: `${theme.typography.pxToRem(34)}`,
    letterSpacing: "0.36px",
    color: `${theme.palette.gray}`,
    fontWeight: 400,
    fontFamily: `"Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif`,
    transition: "all 0.4s",
  },

  "&:hover": {
    ".MuiTypography-root": {
      // fontWeight: 700,
      color: `${theme.palette.white};`,
    },
  },
}));

export function XTMonthRadio({ value, label }: { value: string; label: string }) {
  return <XTMonthRadioForm value={value} control={<Radio />} label={label} />;
}
