import axios from "axios";
import { getPaginatedPostListParamsType, getPostDetailParamsType } from "@/types/post";

/**
 * 게시물 페이징 목록 조회
 */
export const getPaginatedPostListAPI = async (params: getPaginatedPostListParamsType | any) => {
  console.log("[API] 게시물 페이징 목록 조회: ", params);
  return await axios.get("/api/cs/board/page-list", { params });
};

/**
 * 게시물 상세 조회
 */
export const getPostDetailAPI = async (seq: number, params: getPostDetailParamsType) => {
  const test: getPostDetailParamsType = {
    seq: 1,
    schFld: "",
    schTxt: "",
    schTp: "",
    schTpDtl: "",
    schLangTp: "1",
  };
  return await axios.get(`/api/cs/board/get/${seq}`, { params });
};

/**
 * 게시물 타입 조회
 */
export const getPostTypeAPI = async () => {
  return await axios.get("/api/cs/board/get-type");
};
