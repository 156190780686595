class CommonIneraction {
  constructor() {}

  buttonRolling(selector: HTMLElement) {
    const rolling = selector.querySelector(".item-wrap");
    rolling.classList.add("rolling1");

    const clone = rolling.cloneNode(true) as HTMLElement;
    selector.appendChild(clone);
    clone.classList.add("rolling2");
    clone.classList.remove("rolling1");
  }

  hover(index: number, parent: HTMLElement) {
    if (!parent) return;
    const items = parent.querySelectorAll("li");
    if (!items) return;

    items.forEach((item: HTMLLIElement, i: number) => {
      if (index === i) {
        item.style.opacity = "1";
      } else if (index === -1) {
        item.style.opacity = "1";
      } else {
        item.style.opacity = "0.5";
      }
    });
  }
}

const commonMotion = new CommonIneraction();
export default commonMotion;
