import UA from "@/assets/ts/bauerlab/ua";
import { gui } from "./GUI";

export default class Circle {
  container: HTMLElement;
  canvasTarget: HTMLCanvasElement;
  cvs: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;

  canvasScale: number = 2;

  mouse: any = { x: 0, y: 0, cx: 0, cy: 0, r_cx: 0, r_cy: 0 };
  mouseFollowSpeed: any = 0.0;
  mouseFollowRange: any = 0.0;
  stageSize: any = { width: 0, height: 0, cx: 0, cy: 0, diagonal: 0 };
  circleSize: number = 0;
  center: any = { x: 0, y: 0 };
  scale: any = { x: 1, y: 1 };
  radius: number = 0;
  maskProgress: number = 0;
  radiusScale: number = 1.5;
  speed: number = 0.2;
  speedReverse: boolean = false;
  rotation: number = 30;
  zoomSpeed: number = 0.6;
  opacity: number = 0.5;
  x: number = 0;
  y: number = 0;
  position: any = {
    x: 0,
    y: 0,
  };
  thickness: number = 1;

  rad: number = Math.PI / 180;

  nums: number = UA.isPC ? 30 : 15;
  margin: number = 10;
  color: string = "#ffffff";
  fillColor: string = "#131CDE";

  isRender: Boolean = false;
  circles: Array<any> = [];
  time: number = 0;
  makeTimer = null;
  isMask = false;
  isLineMaking = true;
  followMosue = true;
  isFitContasiner = false;

  constructor(canvas, fillColor = "#131CDE") {
    this.container = canvas.parentNode;

    this.cvs = canvas; //document.createElement("canvas");
    this.ctx = this.cvs.getContext("2d");
    this.container.append(this.cvs);

    this.mouse.x = this.container.clientWidth * 0.5;
    this.mouse.y = this.container.clientHeight * 0.5;

    this.defineSize();
    this.makeCircles();
    this.draw();

    this.setGui();

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onResize = this.onResize.bind(this);
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("resize", this.onResize);

    this.resize();

    this.start();
    this.fillColor = fillColor;
  }

  start() {
    if (this.isRender) return;
    this.isRender = true;
    this.render();
  }
  stop() {
    this.isRender = false;
  }

  setGui() {
    let g = gui.addFolder("circle");
    g.close();
    g.add(this, "nums", 0, 100, 1).onChange(() => {
      this.makeCircles();
    });

    g.add(this, "followMosue").onChange((value) => {
      if (!value) {
        this.x = 0;
        this.y = 0;
      }
    });
    g.add(this, "speedReverse");
    g.add(this, "x", -3, 3, 0.01).listen();
    g.add(this, "y", -3, 3, 0.01).listen();
    g.add(this, "opacity", 0, 1, 0.01).listen();
    g.add(this, "maskProgress", 0, 1, 0.01);
    g.add(this, "speed", 0, 1, 0.01);
    g.add(this, "rotation", 0, 360, 0.01);
    g.add(this, "zoomSpeed", 0, 2, 0.01);
    g.add(this, "mouseFollowSpeed", 0, 0.3, 0.01);
    g.add(this, "mouseFollowRange", 0, 1, 0.01);
    g.add(this, "radiusScale", 0.5, 3, 0.01).onChange(() => {
      this.defineSize();
      this.makeCircles();
    });
    g.add({ scale: () => {} }, "scale");
    g.add(this.scale, "x", 0, 2, 0.01);
    g.add(this.scale, "y", 0, 2, 0.01);

    g.addColor(this, "color");
    g.add(this, "thickness", 0.5, 10, 0.1);
  }

  defineSize() {
    let containerRect = this.container.getBoundingClientRect();
    this.stageSize.width = this.container.clientWidth;
    this.stageSize.height = this.container.clientHeight;

    this.stageSize.marginHeight =
      this.stageSize.height - document.documentElement.clientHeight;

    if (this.isFitContasiner) {
      this.stageSize.marginHeight = 0;
    }

    this.stageSize.cx = this.stageSize.width * 0.5;
    this.stageSize.cy = this.stageSize.height * 0.5;
    this.stageSize.diagonal = Math.sqrt(
      Math.pow(this.stageSize.width, 2) + Math.pow(this.stageSize.height, 2)
    );
  }

  onResize() {
    if (!UA.isPC && !this.isFitContasiner) {
      if (this.stageSize.width == window.innerWidth) {
        return;
      }
    }

    this.resize();
    // this.draw();
  }

  resize() {
    this.defineSize();
    this.circleSize =
      this.stageSize.width < this.stageSize.height
        ? this.stageSize.width
        : this.stageSize.height;

    this.cvs.width = this.stageSize.width * this.canvasScale;
    this.cvs.height = this.stageSize.height * this.canvasScale;

    this.cvs.style.width = this.stageSize.width + "px";
    this.cvs.style.height = this.stageSize.height + "px";

    this.ctx.scale(this.canvasScale, this.canvasScale);

    this.center.x = this.stageSize.width * 0.5;
    this.center.y = this.stageSize.height * 0.5;
    this.radius = this.circleSize * 0.5 - 1;
    // this.radius *= this.radiusScale;

    this.margin = this.radius / this.nums;

    if (this.isFitContasiner) {
      this.radius = this.stageSize.width * 0.5;
    }

    this.makeCircles();
  }

  kill() {
    this.isRender = false;
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("resize", this.onResize);
  }

  render() {
    this.draw();
    if (this.isRender) requestAnimationFrame(this.render.bind(this));
  }

  onMouseMove(e) {
    this.mouse.x = e.clientX;
    this.mouse.y = e.clientY;

    let hw = this.stageSize.width * 0.5;
    let hh = this.stageSize.height * 0.5;
    this.mouse.cx = this.mouse.x - hw;
    this.mouse.cy = this.mouse.y - hh;
    this.mouse.r_cx = this.mouse.cx / hw;
    this.mouse.r_cy = this.mouse.cy / hh;
  }

  translate(x = this.position.x, y = this.position.y) {
    this.position.x = x;
    this.position.y = y;
  }

  makeCircles() {
    this.isLineMaking = true;
    this.circles = [];
    if (this.makeTimer) clearTimeout(this.makeTimer);

    this.makeTimer = setTimeout(() => {
      for (let i = 0; i < this.nums; i++) {
        this.circles.push({
          x: this.center.x,
          y: this.center.y,
          progress: 0,
          radius: this.radius - this.margin * i,
          alpha: 1,
          reset: false,
        });

        if (i == this.nums - 1) {
          this.isLineMaking = false;
        }
      }
    }, 10);
  }

  draw() {
    if (this.isLineMaking) return;

    this.margin = this.radius / this.nums;
    // this.margin *= this.radiusScale;

    this.ctx.strokeStyle = this.color;
    this.ctx.clearRect(0, 0, this.cvs.width, this.cvs.height);

    if (this.fillColor == "transparent") {
      this.ctx.fillStyle = "rgba(255,255,255,0)";
    } else {
      this.ctx.fillStyle = this.fillColor;
      this.ctx.fillRect(0, 0, this.cvs.width, this.cvs.height);
    }

    let hw = this.stageSize.width * 0.5;
    let hh = this.stageSize.height * 0.5;
    let tx = this.mouse.r_cx * this.mouseFollowRange * hw + hw;
    let ty = this.mouse.r_cy * this.mouseFollowRange * hh + hh;

    this.center.x += (tx - this.center.x) * this.mouseFollowSpeed;
    this.center.y += (ty - this.center.y) * this.mouseFollowSpeed;
    // this.center.x += this.position.x;
    // this.center.y += this.position.y;

    if (this.followMosue) {
      this.x += (this.mouse.r_cx - this.x) * 0.2;
      this.y += (this.mouse.r_cy - this.y) * 0.2;
    }

    for (let i = 0; i < this.circles.length; i++) {
      this.ctx.save();
      this.ctx.beginPath();

      let progress = this.circles[i].radius / this.radius;
      let speed = 0.3 + progress * 0.7;
      if (this.speedReverse) {
        speed = 0.3 + (1 - progress) * 0.7;
      }

      let tg = this.center;
      if (i > 0) {
        // tg = this.circles[i - 1];
      }

      let offset = this.circles[i];

      offset.x += (tg.x - offset.x) * speed * this.speed;
      offset.y += (tg.y - offset.y) * speed * this.speed;

      offset.radius += -this.zoomSpeed;

      if (offset.radius < 0) {
        offset.radius += this.radius;
        offset.x = tg.x;
        offset.y = tg.y;
        offset.alpha = 0;
        offset.reset = true;
      }

      if (offset.reset) {
        offset.alpha += 0.01;
        if (offset.alpha > 1) {
          offset.alpha = 1;
          offset.reset = false;
        }
      } else {
        // offset.alpha = progress;
      }

      this.ctx.globalAlpha = offset.alpha * this.opacity;
      this.ctx.lineWidth = this.thickness * progress;
      // this.ctx.scale(1,progress);
      let radius = offset.radius;
      let x = offset.x;
      let y = offset.y;

      x += this.position.x;
      y += this.position.y;

      // this.ctx.arc(x,y,radius,0,Math.PI*2);
      let rx = radius - radius * (1 - progress) * (1 - this.scale.x);
      let ry = radius - radius * (1 - progress) * (1 - this.scale.y);

      rx *= this.radiusScale;
      ry *= this.radiusScale;

      let ellipseX = x + (1 - progress) * this.x * radius;
      let ellipseY = y + (1 - progress) * this.y * radius;
      let ellipseR = progress + this.rotation * this.rad;
      // ellipseX = Math.max(0, ellipseX);
      // ellipseY = Math.max(0, ellipseX);
      ellipseR = Math.max(0, ellipseR);
      this.ctx.ellipse(ellipseX, ellipseY, rx, ry, ellipseR, 0, Math.PI * 2);
      this.ctx.closePath();
      this.ctx.stroke();
      this.ctx.restore();
    }

    if (!this.isMask) return;
    this.ctx.save();
    this.ctx.globalCompositeOperation = "destination-out";
    this.ctx.beginPath();
    this.ctx.arc(
      this.stageSize.cx,
      this.stageSize.cy,
      this.stageSize.diagonal * 0.5 * this.maskProgress,
      0,
      Math.PI * 2
    );
    this.ctx.fill();
    this.ctx.restore();
    // this.ctx.arc(this.center.x, this.center.y, this.radius, 0, Math.PI * 2);
    // this.ctx.fill();

    // this.time += 0.1;
  }
}
