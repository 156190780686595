import { lsToPx } from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { forwardRef, useEffect, useRef } from "react";
import RoundedButton from "../ui/roundedButton";
import CircleCanvas from "@/components/animation/circle-canvas";

const ContactVisual = forwardRef<HTMLElement, {}>((props, ref) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  return (
    <section className="sc-visual" css={style.visual} ref={ref}>
      <CircleCanvas bgColor="transparent" color="#fff" isMask="false" id="circleCanvas" scale="2" />
      <h2 className="sc-title">
        {!isMobile ? (
          <img src="/images/image/contact/img-title.svg" alt="contact us" />
        ) : (
          <img src="/images/image/contact/img-title-sm.svg" alt="contact us" />
        )}
      </h2>
      <div className="sc-desc">
        <span>
          <em>Connect with us</em> - your gateway
        </span>
        <span>to exceptional experiences.</span>
      </div>
      <div className="btn-box">
        <RoundedButton type="scroll" />
      </div>
    </section>
  );
});

const style = {
  visual: css`
    width: 100%;
    /* height: 100vh; */
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.typography.pxToRem(180)} 0 ${theme.typography.pxToRem(64)};
    position: relative;
    z-index: 2;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding: calc(168 / 768 * 100vh) calc(20 / 375 * 100vw) 1.875rem;
    }

    .circle-canvas {
      opacity: 0.7;
    }

    .sc-title {
      position: relative;
      width: calc(1597 / 1920 * 100%);
      aspect-ratio: 1597 / 260;
      margin-bottom: ${theme.typography.pxToRem(50)};

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: 100%;
        aspect-ratio: 332 / 50;
        margin-bottom: ${theme.typography.pxToRem(60)};
      }
      img {
        width: 100%;
        height: 100%;
      }
    }

    .sc-desc {
      display: flex;
      flex-direction: column;
      ${globalCSS.en};
      font-weight: 400;
      font-size: ${theme.typography.pxToRem(30)};
      line-height: calc(40 / 30);
      ${lsToPx(30, -2)};
      color: ${theme.palette.white};

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        font-size: ${theme.typography.pxToRem(16)};
        line-height: calc(22 / 16);
        letter-spacing: 0;
      }

      span {
        &:nth-of-type(1) {
          transform: translateX(-200px);

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            transform: translateX(-40px);
          }
        }

        &:nth-of-type(2) {
          transform: translateX(-50px);
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            transform: translateX(40px);
          }
        }
      }

      em {
        color: ${theme.palette.yTxt};
      }
    }

    .btn-box {
      margin-top: auto;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        position: absolute;
        bottom: ${theme.typography.pxToRem(30)};
        left: 50%;
        transform: translateX(-50%);
      }
    }
  `,
};

export default ContactVisual;
