import { BoxGeometry, Group, PlaneGeometry, ShapeGeometry } from "three";
import { Mesh } from "three";
import { MeshBasicMaterial } from "three";
import { Vector3 } from "three";
import { CatmullRomCurve3 } from "three";
import { SVGLoader } from "./SVGLoader";

function makeCurvePoint(num = 500, positions = []) {
  let curve = new CatmullRomCurve3(positions);
  let points = [];

  curve.getPoints(num).forEach(({ x, y, z }) => {
    points.push(x, y, z);
  });

  return { curve, points };
}

function pointToPosition(points) {
  const positions = [];
  for (let i = 0; i < points.length; i += 3) {
    positions.push(new Vector3(points[i + 0], points[i + 1], points[i + 2]));
  }

  return positions;
}

function positionToPoint(positions) {
  let points = [];

  positions.forEach(({ x, y, z }) => {
    points.push(x, y, z);
  });

  return points;
}

function makeSimpleBox(
  vec3 = new Vector3(),
  w = 100,
  h = 100,
  d = 100,
  color = 0xff0000,
  params = {}
) {
  let geo = new BoxGeometry(w, h, d);
  let mat = new MeshBasicMaterial(Object.assign({ color: color }, params));
  let mesh = new Mesh(geo, mat);
  mesh.position.copy(vec3);
  return mesh;
}

function makeSimplePlane(
  vec3 = new Vector3(),
  w = 10,
  h = 10,
  color = 0xff0000,
  params = {}
) {
  let geo = new PlaneGeometry(w, h, 1, 1);
  let mat = new MeshBasicMaterial(Object.assign({ color: color }, params));
  let mesh = new Mesh(geo, mat);
  mesh.position.copy(vec3);
  return mesh;
}

function changeDecimal(value, decimal = 1000) {
  return Math.round(value * decimal) / decimal;
}

function clipBoardCopy(data) {
  const handler = (e) => {
    document.removeEventListener("copy", handler);
    e.clipboardData.setData("text/plain", data);
    e.preventDefault();
  };
  document.addEventListener("copy", handler);
  document.execCommand("copy");
}

async function loadCSV() {
  try {
    const target = `/assets/data.csv`; //file
    const res = await fetch(target, {
      method: "get",
      headers: {
        "content-type": "text/csv; charset=UTF-8",
        //'Authorization': //in case you need authorisation
      },
    });

    if (res.status === 200) {
      let data = await res.text();
      data = data.replaceAll("\r", "");
      data = data.replaceAll(",,,", "");

      const table = data.split(/\n/).slice(1);

      const filtered = table.filter((value) => {
        if (value != ",,,") return value;
      });

      // create empty array
      const csvData = [];

      // // this will return each line as an individual String
      const lines = data.split("\n");

      // loop through the lines and return an array of individual
      // Strings within the line that are separated by a comma
      for (let i = 0; i < lines.length; i++) {
        let value = lines[i].split(",");
        if (value[0] == "") {
        } else {
          csvData.push(value);
        }
      }

      const group = [
        { name: "news", id: null, list: [] },
        { name: "facilities[main]", id: null, list: [] },
        { name: "facilities[small]", id: null, list: [] },
        { name: "caution", id: null, list: [] },
      ];

      // define number of array
      csvData.forEach((dataArr, i) => {
        group.forEach((groupData) => {
          if (groupData.name == dataArr[0]) {
            groupData.id = i;
          }
        });
      });

      // data parser
      group.forEach((now, i) => {
        if (i > 0) {
          let before = group[i - 1];
          before.list = csvData.slice(before.id + 1, now.id);
        }
        if (i == group.length - 1) {
          let before = group[i - 1];
          now.list = csvData.slice(now.id + 1);
        }
      });

      const result = {};

      group.forEach((data) => {
        result[data.name] = data.list;
      });

      return result;
    } else {
      return dummyData;
    }
  } catch (err) {
    console.log(`err ${err}`);
    return dummyData;
  }
}

function svgShapeToMesh(url) {
  return new Promise<Group>((resolve) => {
    const svgLoader = new SVGLoader();
    const group = new Group();
    svgLoader.load(url, (data) => {
      let svgSize = data.xml.viewBox.animVal;
      data.paths.forEach((path: any) => {
        // let fillColor = data.path.userData.node.style.fill;
        const shapes = SVGLoader.createShapes(path);
        let material = new MeshBasicMaterial({ color: 0x000000 });
        material.transparent = true;
        material.depthTest = true;
        material.depthWrite = true;

        // material.uniforms.uColor.value = new Color().setStyle(fillColor);
        // .convertSRGBToLinear();

        for (let i = 0; i < shapes.length; i++) {
          const shape = shapes[i];
          const geometry = new ShapeGeometry(shape);

          // @ts-ignore
          geometry.index?.array.reverse();

          // geometry.attributes.position.array
          for (
            let i = 0;
            i < geometry.attributes.position.array.length;
            i += 3
          ) {
            let arr = geometry.attributes.position.array as Array<number>;
            arr[i + 0] += -svgSize.width * 0.5;
            arr[i + 1] += -svgSize.height * 0.5;
            arr[i + 1] *= -1;
          }

          const mesh = new Mesh(geometry, material);
          group.add(mesh);
          // this.leaves.push({ mesh: mesh, opacity: 0 });
        }
      });

      resolve(group);
    });
  });
}

function delayPromise(delay = 1000) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
}

function getIOSWindowHeight() {
  // Get zoom level of mobile Safari
  // Note, that such zoom detection might not work correctly in other browsers
  // We use width, instead of height, because there are no vertical toolbars :)
  var zoomLevel = document.documentElement.clientWidth / window.innerWidth;

  // window.innerHeight returns height of the visible area.
  // We multiply it by zoom and get out real height.
  return window.innerHeight * zoomLevel;
}

function clamp(value, min = 0, max = 1) {
  return Math.min(Math.max(value, min), max);
}

function translate(dom, x, y, z = 0) {
  dom.style.transform = `translate3d(${x}px,${y}px,${z}px)`;
}

function checkWebPSupport(src) {
  return new Promise<boolean>((resolve, reject) => {
    var img = new Image();
    img.onload = function () {
      resolve(true);
    };
    img.onerror = function () {
      reject(false);
    };
    img.src = src;
  });
}

function getStyleValue(element: any, key: string, isNumber: boolean = true) {
  let style = element.currentStyle || window.getComputedStyle(element);
  if (isNumber) {
    try {
      return Number(style[key].match(/-?[\d|,|.|e|E|\+]+/g)[0]);
    } catch {}
  } else {
    return style[key];
  }
}

function getRandomImage(id = undefined) {
  let keyword = [
    "computer",
    "police",
    "school",
    "santa",
    "war",
    "amazon",
    "india",
    "gold",
    "earth",
    "snow",
    "halloween",
    "nature",
    "background",
    "christmas",
    "tree",
    "holiday",
    "christmas",
    "video",
    "christmas",
    "time",
    "water",
    "fashion",
    "girl",
    "winter",
    "dark",
    "navidad",
    "wallapaper",
    "movies",
    "gradient",
    "nature",
    "galaxy",
  ];
  if (id == undefined) {
    id = Math.floor(Math.random() * keyword.length);
  } else {
    id %= keyword.length;
  }
  return `https://source.unsplash.com/random?${keyword[id]}`;
}

export {
  makeCurvePoint,
  pointToPosition,
  positionToPoint,
  makeSimpleBox,
  makeSimplePlane,
  changeDecimal,
  clipBoardCopy,
  loadCSV,
  svgShapeToMesh,
  delayPromise,
  clamp,
  translate,
  checkWebPSupport,
  getStyleValue,
  getRandomImage,
};
