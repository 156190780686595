import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import commonMotion from "@/utils/ineraction/common";
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";

export default function ViewMore() {
  const buttonRef = useRef(null);

  useEffect(() => {
    commonMotion.buttonRolling(buttonRef.current);
  }, []);
  return (
    <div css={style.viewMore} ref={buttonRef} className="article-view-more">
      <div className="item-wrap">
        <p>
          <span>VIEW MORE</span>
          <i css={globalCSS.icoViewMore}></i>
        </p>
        <p>
          <span>VIEW MORE</span>
          <i css={globalCSS.icoViewMore}></i>
        </p>
        <p>
          <span>VIEW MORE</span>
          <i css={globalCSS.icoViewMore}></i>
        </p>
      </div>
    </div>
  );
}

const style = {
  viewMore: css`
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: ${theme.typography.pxToRem(40)};
    flex-wrap: nowrap;
    overflow: hidden;
    background-color: rgba(16, 17, 221, 0.8);
    /* display: none; */
    transform: translateY(100%);
    transition: transform 0.4s;

    p,
    .item-wrap {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      flex: 1 0 100%;
      max-width: fit-content;
    }

    .item-wrap {
      gap: ${theme.typography.pxToRem(18)};
      /* border: 1px solid gold; */
      min-width: max-content;
      padding: 0 ${theme.typography.pxToRem(9)};
    }

    span {
      font-weight: 400;
      font-size: ${theme.typography.pxToRem(17)};
      line-height: 1;
      letter-spacing: 0.32px;
      color: ${theme.palette.yTxt};
      ${globalCSS.en};
      min-width: fit-content;
    }

    i {
      margin-left: 2px;
      transform: translateY(-1px);
      flex: 1 0 ${theme.typography.pxToRem(22)};
      max-width: ${theme.typography.pxToRem(22)};
    }

    .rolling1 {
      animation: rollingleft1 30s linear infinite;
    }
    .rolling2 {
      animation: rollingleft2 30s linear infinite;
    }

    @keyframes rollingleft1 {
      0% {
        transform: translateX(0);
      }
      50% {
        transform: translateX(-100%);
      }
      50.01% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0);
      }
    }

    @keyframes rollingleft2 {
      0% {
        transition: translateX(0);
      }
      100% {
        transform: translateX(-200%);
      }
    }

    @keyframes rolling {
      0% {
        transform: translateX(0);
      }
      50% {
        transform: translateX(-100%);
      }
      50.01% {
        transform: translateX(100%);
        border: 1px solid red;
      }
      100% {
        transform: translateX(0);
      }
    }
  `,
};
