import {
  getCreationTypeAPI,
  getPaginatedCreationListAPI,
} from "@/api/creation";

import { useEffect, useState } from "react";
import { useQueries, useQuery } from "react-query";
import { useLanguage } from "./use-language";

export function useMainManager() {
  const [list, setList] = useState({});
  const [fetchFinishAll, setFetchFinishAll] = useState(false);
  const langTp = useLanguage();
  // GET Creation Type
  const { data: creationType, isSuccess } = useQuery<any>(
    // AxiosResponse<WarehousingListType>,
    // AxiosError,
    // WarehousingListType["data"]
    "getCreationType",
    getCreationTypeAPI,
    {
      select: (data) => {
        const typeList = Object.entries(data.data.data.ArticleType);
        const result = typeList.map((el) => {
          return {
            nm: el[1],
            cd: Number(el[0]) - 1,
          };
        });

        return result;
      },
      onError: (error) => {
        console.log("getCreationType - error", error);
      },
      refetchOnWindowFocus: false,
    }
  );

  /**
   * main page - creation 4가지의 카테고리를 한 번에 합쳐서 호출하는 쿼리
   */
  const mainCreation = useQueries(
    creationType?.map((t) => ({
      queryKey: ["mainCreation", t.nm, langTp],
      queryFn: () =>
        getPaginatedCreationListAPI({
          pg: 1,
          pgSz: 3,
          sordFld: "rgstYmd",
          sordMetd: "DESC",
          schTp: t.cd + 1,
          schLangTp: langTp,
        }),
      select: (data) => {
        const name = t.nm.replace(/ /g, "_");

        return { [name]: data.data.data.articleList.list };
        // setList((prev) => {        //   const name = t.nm.replace(/ /g, "_");
        //   // 여기서 prev는 배열이어야 합니다.
        //   const name = t.nm.replace(/ /g, "_");
        //   const newItem = { [name]: data.data.data.articleList.list };
        //   // prev가 배열이라고 가정하고, 새로운 항목을 추가
        //   return [...prev, newItem];
        // });
      },
      // keepPreviousData: true,

      refetchOnWindowFocus: false,
      enabled: !!langTp && !!isSuccess,
    })) || []
  );

  useEffect(() => {
    let result =
      mainCreation.every((array) => array.status === "success") &&
      Object.keys(mainCreation)?.length == 4
        ? true
        : false;
    if (result) return setFetchFinishAll(true);
  }, [mainCreation]);

  useEffect(() => {
    if (fetchFinishAll) {
      const result = mainCreation.reduce((acc, item) => {
        const dataKey = Object.keys(item.data); // 첫 번째 키 추출 (예: '0', '1')
        const data = item.data[dataKey]; // 해당 키의 data 객체 추출

        acc[dataKey] = data;

        return acc;
      }, {});

      setList(result);
    }
  }, [fetchFinishAll]);

  /**
   * 데이터 호출 부분의 상태가 모두 success이고, 불러온 데이터의 리스트의 길이가 4개면 true
   */

  // const fetchFinishAll = mainCreation.every(
  //   (array) => array.isLoading === false
  // );

  return {
    list,
    creationType,
    fetchFinishAll,
  };
}
