import { atom } from "recoil";

export const headerMenuProgressState = atom({
  key: "headerMenuProgressState",
  default: {
    weare: 0,
    creation: 0,
    partners: 0,
  },
});

export const contactHeaderMenuProgressState = atom({
  key: "contactHeaderMenuProgressState",
  default: {
    enquiry: 0,
    officeSpace: 0,
  },
});

export const lenisScrollState = atom({
  key: "lenisScrollState",
  default: {
    lenis: null,
    scroll: 0,
    limit: 0,
    velocity: 0,
    direction: 0,
    progress: 0,
  },
});

export const isDetailPageState = atom({
  key: "isDetailPage",
  default: false,
});

export const isMenuOpenState = atom({
  key: "isMenuOpenState",
  default: false,
});

export const pointerState = atom({
  key: "pointerState",
  default: {
    x: 0,
    y: 0,
  },
});

export const videoState = atom({
  key: "videoState",
  default: {
    playing: false, // 재생 유무
    muted: false, // 음소거
    controls: false, // 컨트롤바 (기본적으로 제공하는 걸 막기 위한, 커스텀을 하기 위해 false 처리)
    volume: 0.5, // 기본 볼륨
    playbackRate: 1, // 재생 속도
    played: 0, // 재생의 정도(value, 얼마큼 재생되었는지)
    seeking: false, // 재생바를 움직이고 있는지
    duration: 0, // 전체 시간
  },
});

export const cursorState = atom({
  key: "cursorState",
  default: false,
});

export const isScrollHiddenState = atom({
  key: "isScrollHidden",
  default: false,
});

export const pageYState = atom({
  key: "pageY",
  default: 0,
});

export const prevPathState = atom({
  key: "prevPathState",
  default: "/",
});
