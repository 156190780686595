import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { videoState } from "@/state";
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useRecoilState } from "recoil";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { getVideoPath } from "@/assets/ts/image";

const VideoTabButton = ({ children, onClick }) => {
  return (
    <button className="btn-video-tab" onClick={onClick}>
      {children}
    </button>
  );
};

export default function XTVideo({ video, title, onClose }) {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const [pauseAppear, setPauseAppear] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);

  const [options, setOptions] = useState(
    video.map(() => ({
      playing: false,
      muted: false,
      volume: 1.0,
      playbackRate: 1.0,
      played: 0,
      seeking: false,
      progress: 0,
    }))
  );

  const videoRefs = useRef(video.map(() => null));

  const handlePlayPause = (index, playing) => {
    const update = options.map((option, i) => (i === index ? { ...option, playing: playing } : option));
    setOptions(update);

    if (playing) {
      videoRefs.current[index].play();
    } else {
      videoRefs.current[index].pause();
    }
  };

  const handleMuted = (index, isMuted) => {
    const update = options.map((option, i) => (i === index ? { ...option, muted: isMuted } : option));
    setOptions(update);

    if (isMuted) {
      videoRefs.current[index].muted = true;
    } else {
      videoRefs.current[index].muted = false;
    }
  };

  const handleProgress = (index, event) => {
    const update = options.map((option, i) =>
      i === index
        ? {
            ...option,
            played: event.target.currentTime,
            progress: (event.target.currentTime / event.target.duration) * 100,
          }
        : option
    );
    setOptions(update);
  };

  const handleVideoChange = (index) => {
    setVideoIndex(index);
    setOptions(options.map((option) => ({ ...option, playing: false, played: 0 })));
    videoRefs.current.forEach((ref, i) => {
      if (i !== index) ref.pause();
    });
  };

  const controlsRef = useRef(null);

  return (
    <div className="video-modal-inner" css={style.video}>
      <div className="modal-header" css={style.videoHeader}>
        <Link to="/" className="logo">
          <img src="/images/icon/logo.svg" alt="바우어랩" />
        </Link>
        <button className="btn-close" css={globalCSS.menuBtn} onClick={onClose}>
          <span>Close</span>
        </button>
      </div>
      <div className="video-wrap">
        {video.map((item, i) => (
          <div
            className="video-wrap-inner"
            key={item.seq}
            style={{ visibility: videoIndex === i ? "visible" : "hidden" }}
            onClick={() => {
              if (!isMobile && videoIndex !== i) return;
              if (options[i].playing) {
                videoRefs.current[i].pause();
                const update = options.map((option, idx) => (idx === i ? { ...option, playing: false } : option));
                setOptions(update);
              } else {
                videoRefs.current[i].play();
                const update = options.map((option, idx) => (idx === i ? { ...option, playing: true } : option));
                setOptions(update);
              }
            }}>
            <div className="video-box">
              <video
                ref={(el) => (videoRefs.current[i] = el)}
                muted={false}
                autoPlay={false}
                playsInline
                loop={false}
                width="100%"
                onTimeUpdate={(event) => handleProgress(i, event)}
                height="100%">
                <source src={getVideoPath(item)} type="video/mp4" />
              </video>
            </div>
            <div className="video-control-box" ref={controlsRef}>
              {!options[i].playing && (
                <button className="btn-play" onClick={() => handlePlayPause(i, true)}>
                  <i css={globalCSS.icoPlay}></i>
                </button>
              )}

              {options[i].playing && isMobile && pauseAppear && (
                <button
                  className="btn-play"
                  onPointerEnter={(event) => {
                    handlePlayPause(i, false);
                  }}>
                  <i css={globalCSS.icoPauseMo}></i>
                </button>
              )}
              <div className="bottom-inner">
                {options[i].playing && !isMobile && (
                  <button onClick={() => handlePlayPause(i, false)}>
                    <i css={globalCSS.icoPause}></i>
                  </button>
                )}
                {options[i].muted ? (
                  <button
                    onClick={(e) => {
                      handleMuted(i, false);
                      e.stopPropagation();
                    }}>
                    <i css={globalCSS.icoMuted}></i>
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      handleMuted(i, true);
                      e.stopPropagation();
                    }}>
                    <i css={globalCSS.icoUnmuted}></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="video-progress-tabs" css={style.videoTab}>
        {video.map((item, i) => (
          <VideoTabButton onClick={() => handleVideoChange(i)}>
            <span className="text">
              <span className="title">{title}</span>
              {video.length === 1 ? "" : i + 1}
            </span>
            <span className="over-text" style={{ "--progress": options[i].progress + "%" } as any}>
              <span className="title">{title}</span> {video.length === 1 ? "" : i + 1}
            </span>
          </VideoTabButton>
        ))}
      </div>
    </div>
  );
}

const style = {
  videoHeader: css`
    display: flex;

    align-items: center;
    width: 100%;
    position: fixed;
    top: ${theme.typography.pxToRem(40)};
    padding: 0 ${theme.typography.pxToRem(40)};
    left: 0;

    z-index: 5;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding: 0 ${theme.typography.pxToRem(20)};
      top: ${theme.typography.pxToRem(20)};
    }

    .logo {
      display: block;
      width: ${theme.typography.pxToRem(50)};
      height: ${theme.typography.pxToRem(50)};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .btn-close {
      width: ${theme.typography.pxToRem(90)};
      margin-left: auto;

      span {
        color: ${theme.palette.white};
        opacity: 1;
      }
    }
  `,

  video: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* background-color: ${theme.palette.black}; */
    height: 100%;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      justify-content: flex-start;
    }

    .video-wrap {
      position: relative;
      width: 100%;
      max-width: calc(1400 / 1920 * 100%);
      margin: 0 auto;
      height: ${theme.typography.pxToRem(787.5)};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        max-width: 100%;
        margin: 0;
      }

      .video-wrap-inner {
        /* position: relative; */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: flex;
          justify-content: center;
          align-items: center;
          /* pointer-events: none; */
        }
      }

      .video-box {
        width: 100%;
        height: 100%;
        /* height: ${theme.typography.pxToRem(787.5)}; */
        background-color: ${theme.palette.black};

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          height: auto;
          aspect-ratio: 375 / 210.94;
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .video-control-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;

        .btn-play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.8;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: 100%;
            height: auto;
            aspect-ratio: 375/210.94;

            i {
              margin: 0 auto;
            }
          }
        }

        .bottom-inner {
          position: absolute;
          bottom: ${theme.typography.pxToRem(10.5)};
          right: ${theme.typography.pxToRem(10)};
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            right: ${theme.typography.pxToRem(20)};
          }
        }

        button {
          cursor: pointer;
        }
      }
    }
  `,

  videoTab: css`
    display: flex;
    max-width: calc(1400 / 1920 * 100%);
    width: 100%;
    margin: 0 auto;
    margin-top: ${theme.typography.pxToRem(40)};
    justify-content: space-between;
    gap: ${theme.typography.pxToRem(20)};
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      max-width: 100%;
      left: 0;
      gap: ${theme.typography.pxToRem(15)};
      margin-top: ${theme.typography.pxToRem(30)};
      margin-bottom: ${theme.typography.pxToRem(20)};
      padding: 0 ${theme.typography.pxToRem(20)};
    }

    button {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      position: relative;
      flex: 1;
      width: 100%;
      border-radius: 100px;
      border: 1px solid ${theme.palette.white};
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${theme.palette.white};
      height: ${theme.typography.pxToRem(44)};
      font-weight: 400;
      ${globalCSS.en};
      font-size: ${theme.typography.pxToRem(27)};
      line-height: calc(32 / 27);
      overflow: hidden;
      cursor: pointer;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        font-size: ${theme.typography.pxToRem(16)};
        line-height: calc(16 / 16);
        height: ${theme.typography.pxToRem(32)};
      }

      .text,
      .over-text {
        width: 100%;
        .title {
          max-width: 90%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &::after {
        content: "";
        width: 0px;
        aspect-ratio: 1 / 1;
        background-color: ${theme.palette.white};
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: width 0.3s;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: none;
        }
      }

      .text {
        z-index: 2;
        transition: color 0.3s;
      }

      &:hover {
        &::after {
          width: 110%;
        }

        .text {
          color: ${theme.palette.blue};
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            color: ${theme.palette.white};
          }
        }

        .over-text {
          color: ${theme.palette.blue};
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            color: ${theme.palette.white};
          }
        }
      }

      .over-text {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        border: 1px solid ${theme.palette.white};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${theme.palette.black};
        background-color: ${theme.palette.white};

        clip-path: polygon(0% 0, var(--progress) 0%, var(--progress) 100%, 0 100%);
        transition: all 0.2s;
        z-index: 3;
      }

      .text,
      .over-text {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  `,
};
