import { DetailDesc } from "./../../components/posts/post";
import moment from "moment";

export function formatYmd(ymd: string): string {
  return moment(ymd).format("YYYY. MM. DD");
}

export function formatHyphenYmd(ymd: string): string {
  return moment(ymd).format("YYYY-MM-DD");
}

export function formatYmdHis(ymd: string, his: string): string {
  if (!ymd || !his) {
    return "-";
  }
  return moment(ymd + " " + his).format("YYYY-MM-DD HH:mm:ss");
}

export function formatRangeYmd(strtYmd: string, endYmd: string) {
  if (!strtYmd || !endYmd) {
    return "-";
  }

  return `${moment(strtYmd).format("YYYY-MM-DD")} ~ ${moment(endYmd).format("YYYY-MM-DD")}`;
}

export function formatRangeHyphenYmd(startYmd: string, endYmd: string) {
  if (!startYmd || !endYmd) {
    return "-";
  }

  return `${moment(startYmd).format("YYYY. MM. DD")} - ${moment(endYmd).format("YYYY. MM. DD")}`;
}

export function formatKComma(value) {
  return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function convertSecondsToMinutes(seconds) {
  const hours = Math.floor(seconds / 3600) == 0 ? "" : `${Math.floor(seconds / 3600)}h`;
  const minutes = Math.floor((seconds % 3600) / 60) == 0 ? "" : `${Math.floor((seconds % 3600) / 60)}min`; // 분 계산

  const sec = seconds > 59 ? "" : `${seconds}sec`;

  const time = hours + minutes + sec;
  return time;
}

export function formatEnYmd(ymd: string) {
  if (!ymd) {
    return "-";
  }
  moment.locale("en"); // 영어로 설정
  return moment(ymd, "YYYYMMDD").format("YYYY.MMM.DD.ddd");
}

// 20231121
