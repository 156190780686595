import { type } from "os";
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import theme from "./theme";
import { lsToPx } from "./common";

const globalCSS = {
  webglContainer: css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  `,

  mainContainer: css`
    background-color: ${theme.palette.blue};
  `,
  subHeader: css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(45)} 0 ${theme.typography.pxToRem(40)};
    display: flex;
    justify-content: space-between;
    z-index: 999;
    pointer-events: none;
    transition: transform 0.4s;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding: ${theme.typography.pxToRem(20)};
    }

    a {
      /* width: 100%; */

      display: flex;
      justify-content: space-between;
    }

    .logo {
      margin-right: auto;

      h1 {
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          transition: transform 0.4s;
        }
      }
    }

    .sub-logo {
      width: ${theme.typography.pxToRem(120)};
      height: ${theme.typography.pxToRem(120)};
      transition: all 0.3s;
      pointer-events: all;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: ${theme.typography.pxToRem(30)};
        height: ${theme.typography.pxToRem(30)};
        transition: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.sm {
        width: ${theme.typography.pxToRem(50)} !important;
        height: ${theme.typography.pxToRem(50)} !important;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: ${theme.typography.pxToRem(30)} !important;
          height: ${theme.typography.pxToRem(30)} !important;
        }
      }
    }

    .sub-logo-sm {
      width: ${theme.typography.pxToRem(50)} !important;
      height: ${theme.typography.pxToRem(50)} !important;
      pointer-events: all;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: ${theme.typography.pxToRem(30)} !important;
        height: ${theme.typography.pxToRem(30)} !important;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .btn-menu {
      width: ${theme.typography.pxToRem(32)};
      height: ${theme.typography.pxToRem(32)};
      border: 1px solid gold;
      pointer-events: auto;
    }

    .anchor-section {
      margin-right: ${theme.typography.pxToRem(10)};
      cursor: pointer;
      pointer-events: all;
      a {
        cursor: pointer;
      }
    }

    &.blue {
      > button {
        border-color: ${theme.palette.blue};
        span {
          color: ${theme.palette.blue};
        }

        &::after {
          background-color: ${theme.palette.blue};
        }
      }
    }
  `,

  footer: css`
    position: fixed;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -2;
    width: 100vw;
    background-color: ${theme.palette.blue};

    padding-top: ${theme.typography.pxToRem(130)};
    padding-right: ${theme.typography.pxToRem(40)};
    padding-bottom: ${theme.typography.pxToRem(338)};
    padding-left: calc(100 / 1920 * 100vw);
    min-height: 100vh;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding-top: ${theme.typography.pxToRem(91)};
      padding-left: calc(20 / 375 * 100vw);
      padding-right: calc(20 / 375 * 100vw);
      /* padding-bottom: ${theme.typography.pxToRem(220.68)}; */
      padding-bottom: calc(180 / 812 * 100vh);
    }
    .inner {
      /* position: relative; */

      .content-title {
        display: block;
        font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
        font-weight: 700;
        font-size: ${theme.typography.pxToRem(24)};
        line-height: 1;
        color: ${theme.palette.white};
        ${lsToPx(24, -2)};
        text-transform: uppercase;
        margin-bottom: ${theme.typography.pxToRem(18)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(20)};
          margin-bottom: ${theme.typography.pxToRem(15.16)};
        }
      }

      .sns-list {
        display: flex;
        align-items: center;

        .sns-item {
          width: ${theme.typography.pxToRem(40)};
          height: ${theme.typography.pxToRem(40)};
          a {
            width: 100%;
            height: 100%;
            display: block;

            img {
              width: 100%;
              height: 100%;
            }
          }

          + .sns-item {
            margin-left: ${theme.typography.pxToRem(15)};
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              margin-left: ${theme.typography.pxToRem(20)};
            }
          }
        }
      }

      .address-box {
        p {
          font-weight: 400;
          font-size: ${theme.typography.pxToRem(16)};
          line-height: calc(24 / 16);
          color: ${theme.palette.white};
          font-style: normal;
          font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            font-size: ${theme.typography.pxToRem(14)};
            line-height: calc(20 / 14);
          }
        }
      }

      .menu-box {
        ul {
          display: flex;
          align-items: center;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            flex-direction: column;
            align-items: flex-start;
          }

          li {
            a {
              position: relative;
              font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
              font-size: ${theme.typography.pxToRem(18)};
              line-height: 1;
              color: ${theme.palette.white};
              text-transform: capitalize;
              @media (max-width: ${theme.breakpoints.values.lg}px) {
                font-size: ${theme.typography.pxToRem(15.07)};
              }

              &::before {
                content: "";
                position: absolute;
                bottom: -3px;
                left: 0;
                width: 0;
                height: ${theme.typography.pxToRem(2)};
                background-color: ${theme.palette.white};
                transition: all 0.3s;
                @media (max-width: ${theme.breakpoints.values.lg}px) {
                  display: none;
                }
              }

              &::after {
                content: "";
                display: block;
                width: ${theme.typography.pxToRem(16)};
                height: ${theme.typography.pxToRem(16)};
                background: url("/images/icon/ico-arrow-menu.svg") no-repeat center / cover;
                position: absolute;
                bottom: ${theme.typography.pxToRem(2)};
                right: ${theme.typography.pxToRem(-20)};
                transform: scale(0);
                transform-origin: left bottom;
                transition: transform 0.3s 0.2s;
                @media (max-width: ${theme.breakpoints.values.lg}px) {
                  display: none;
                }
              }

              &:hover {
                @media (max-width: ${theme.breakpoints.values.lg}px) {
                  &::before {
                    display: none;
                  }

                  &::after {
                    display: none;
                  }
                }
                &::before {
                  width: 100%;
                }

                &::after {
                  transform: scale(1);
                }
              }
            }
            + li {
              margin-left: ${theme.typography.pxToRem(50)};
              @media (max-width: ${theme.breakpoints.values.lg}px) {
                margin-left: 0;
                margin-top: ${theme.typography.pxToRem(25)};
              }
            }
          }
        }
      }

      .btn-lang-box {
        button {
          font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
          font-weight: 700;
          font-size: ${theme.typography.pxToRem(24)};
          line-height: 1;
          color: rgba(255, 255, 255, 0.5);
          ${lsToPx(24, -2)};
          transition: color 0.3s;
          text-transform: uppercase;
          cursor: pointer;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            font-size: ${theme.typography.pxToRem(20)};
            letter-spacing: 0;
            transition: none;
          }

          &.active {
            color: ${theme.palette.white};
          }

          &:hover {
            color: ${theme.palette.white};
          }

          + button {
            margin-left: ${theme.typography.pxToRem(20)};
          }
        }
      }

      .bottom-box {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          flex-direction: column;
          align-items: center;
          bottom: ${theme.typography.pxToRem(20.12)};
        }

        img {
          width: calc(1500 / 1920 * 100vw);
          aspect-ratio: 1500 / 263.61;
          @media (max-width: ${1625}px) {
            width: calc(1300 / 1920 * 100vw);
          }

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: calc(335 / 375 * 100vw);
            aspect-ratio: 335 / 58.88;
          }
        }

        span {
          padding-left: calc(19 / 1920 * 100vw);
          padding-bottom: ${theme.typography.pxToRem(10)};
          font-weight: 400;
          font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
          font-size: ${theme.typography.pxToRem(14)};
          line-height: calc(16 / 14);
          ${lsToPx(14, -2)};
          color: ${theme.palette.white};

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            padding: 0;
            margin-top: ${theme.typography.pxToRem(19)};
            font-size: ${theme.typography.pxToRem(12)};
            line-height: 1;
            ${lsToPx(12, -2)};
          }
        }
      }
    }

    .from-bottom {
      + .from-bottom {
        margin-top: ${theme.typography.pxToRem(60)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin-top: ${theme.typography.pxToRem(50)};
          margin-top: calc(50 / 812 * 100vh);
        }
      }
    }
  `,

  mainWrapper: css`
    position: relative;
    min-height: calc(var(--vh) * 100);
    margin-bottom: calc(100vh + 100px);

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      margin-bottom: calc(100vh + 40px);
      width: 100%;
      /* overflow-x: hidden; */
    }

    &.hidden-footer {
      &::before,
      &::after {
        display: none;
      }
      margin-bottom: 0;
    }
    &::before,
    &::after {
      content: "";
      width: ${theme.typography.pxToRem(100)};
      height: ${theme.typography.pxToRem(100)};
      display: block;
      position: absolute;
      bottom: ${theme.typography.pxToRem(-99)};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: ${theme.typography.pxToRem(40)};
        height: ${theme.typography.pxToRem(40)};
        bottom: ${theme.typography.pxToRem(-39)};
      }
    }

    &::before {
      left: 0;
      background-image: url("/images/image/footer-article-left.png");
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        background-image: url("/images/image/footer-mobile-article-left.png");
      }
    }

    &::after {
      right: 0;
      background-image: url("/images/image/footer-article-right.png");
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        background-image: url("/images/image/footer-mobile-article-right.png");
      }
    }

    &.y {
      &::before {
        left: 0;
        background-image: url("/images/image/footer-article-left-y.png");
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          background-image: url("/images/image/footer-mobile-article-left-y.png");
        }
      }

      &::after {
        right: 0;
        background-image: url("/images/image/footer-article-right-y.png");
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          background-image: url("/images/image/footer-mobile-article-right-y.png");
        }
      }
    }

    &.w {
      &::before {
        left: 0;
        background-image: url("/images/image/footer-article-left-w.png");
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          background-image: url("/images/image/footer-mobile-article-left-w.png");
        }
      }

      &::after {
        right: 0;
        background-image: url("/images/image/footer-article-right-w.png");
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          background-image: url("/images/image/footer-mobile-article-right-w.png");
        }
      }
    }
  `,

  menu: css`
    width: 100%;
    height: 100%;
    position: relative;

    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    padding: 0 ${theme.typography.pxToRem(20)};
    /* background: url("/images/image/img-menu-bg.png") no-repeat center / cover; */
    pointer-events: all;
    clip-path: circle(0% at 50% 50%);
    /* transition: clip-path 1s cubic-bezier(0.43, 0.05, 0.17, 1);
     */
    /* transition: clip-path 1s cubic-bezier(0.43, 0.05, 0.17, 1), visibility 0s linear 1s; */
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding: 0 ${theme.typography.pxToRem(30)};
    }

    .menu-header {
      position: fixed;
      padding: 0 ${theme.typography.pxToRem(40)};
      top: ${theme.typography.pxToRem(40)};
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        padding: 0 ${theme.typography.pxToRem(20)};
        top: ${theme.typography.pxToRem(20)};
      }

      .sub-logo-sm {
        position: relative;
        width: ${theme.typography.pxToRem(50)};
        height: ${theme.typography.pxToRem(50)};
        cursor: pointer;

        [data-tooltip] {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          &::before,
          &::after {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            transition: all 0.2s ease;
            font-size: ${theme.typography.pxToRem(11)};
            letter-spacing: -0.14px;
            color: ${theme.palette.blue};
            font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
          }

          &::before {
            content: attr(data-tooltip);
            height: ${theme.typography.pxToRem(15)};
            bottom: ${theme.typography.pxToRem(-60)};
            padding: ${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(10)};
            border-radius: 100px;
            background-color: ${theme.palette.white};
            color: ${theme.palette.blue};
            box-shadow: 0 3px 8px rgba(165, 165, 165, 0.5);
            text-transform: uppercase;
          }

          &::after {
            content: "";
            border-left: 5px solid transparent;
            bottom: ${theme.typography.pxToRem(-35)};
            border-right: 5px solid transparent;
            border-bottom: 5px solid ${theme.palette.white};
          }
        }

        &:hover {
          [data-tooltip] {
            &::before {
              visibility: visible;
              bottom: ${theme.typography.pxToRem(-40)};
              opacity: 1;
            }

            &::after {
              visibility: visible;
              bottom: ${theme.typography.pxToRem(-15)};
              opacity: 1;
            }
          }
        }

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: ${theme.typography.pxToRem(30)};
          height: ${theme.typography.pxToRem(30)};

          [data-tooltip] {
            display: none;
          }

          &:hover {
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }

      .btn-close {
        width: ${theme.typography.pxToRem(90)};
        span {
          opacity: 1;
        }
        button {
          /* width: 100%; */
        }
      }
    }

    .menu-list {
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: calc(50 / 812 * 100vh);
      }
    }

    .menu-depth-1 {
      position: relative;
      font-weight: 400;
      /* font-size: ${theme.typography.pxToRem(90)}; */
      font-size: calc(90 / 1920 * 100vw);

      line-height: 1;
      color: ${theme.palette.white};
      font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
      text-transform: uppercase !important;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        /* font-size: ${theme.typography.pxToRem(38)}; */
        /* font-size: min(calc(34 / 375 * 100vw), ${theme.typography.pxToRem(34)}); */
        font-size: ${theme.typography.pxToRem(34)};
        font-weight: 700;
      }

      + .menu-depth-1 {
        margin-left: ${theme.typography.pxToRem(150)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin-left: 0;
          margin-top: ${theme.typography.pxToRem(25)};
          /* margin-top: calc(40 / 812 * 100%); */
        }
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: ${theme.typography.pxToRem(4)};
        background-color: ${theme.palette.white};
        transition: all 0.3s;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: none;
        }
      }

      &::after {
        content: "";
        display: block;
        width: ${theme.typography.pxToRem(24)};
        height: ${theme.typography.pxToRem(24)};
        background: url("/images/icon/ico-arrow-menu.svg") no-repeat center / cover;
        position: absolute;
        bottom: ${theme.typography.pxToRem(20)};
        right: ${theme.typography.pxToRem(-30)};
        transform: scale(0);
        transform-origin: left bottom;
        transition: transform 0.3s 0.2s;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: none;
        }
      }

      &:not(.not-hover):hover {
        &::before {
          width: 100%;
        }

        &::after {
          transform: scale(1);
        }

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          &::before,
          &::after {
            display: none;
          }
        }
      }
    }

    .menu-depth-2 {
      position: relative;
      font-weight: 700;
      /* font-size: ${theme.typography.pxToRem(24)}; */
      /* font-size: calc(24 / 1920 * 100vw); */
      font-size: max(calc(24 / 1920 * 100vw), ${theme.typography.pxToRem(18)});
      line-height: 1;
      color: ${theme.palette.white};
      font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
      letter-spacing: 0.48px;
      text-transform: uppercase !important;
      max-width: fit-content;
      display: inline-flex;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-top: ${theme.typography.pxToRem(22)};

        font-weight: 700;
        font-size: ${theme.typography.pxToRem(20)};
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: ${theme.typography.pxToRem(1)};
        background-color: ${theme.palette.white};
        transition: all 0.3s;
      }

      &::after {
        content: "";
        display: block;
        width: ${theme.typography.pxToRem(20)};
        height: ${theme.typography.pxToRem(20)};
        background: url("/images/icon/ico-arrow-menu.svg") no-repeat center / cover;
        position: absolute;
        bottom: 0;
        right: ${theme.typography.pxToRem(-30)};
        transform: scale(0);
        transform-origin: left bottom;
        transition: transform 0.3s 0.2s;
      }

      &.active {
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          &::before,
          &::after {
            display: none;
          }
        }
        &::before {
          width: 100%;
        }

        &::after {
          transform: scale(1);
        }
      }
    }

    .flex {
      display: flex;
      align-items: center;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        flex-direction: column;
        align-items: flex-start;
      }

      + .flex {
        margin-top: ${theme.typography.pxToRem(60)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin-top: ${theme.typography.pxToRem(30)};
        }
      }

      &:nth-of-type(2) {
        padding-left: ${theme.typography.pxToRem(60)};
        @media (max-width: 1110px) {
          padding-left: 0;
        }
      }

      &:nth-of-type(3) {
        justify-content: flex-end;
      }
    }

    .sub-menu-list {
      display: flex;
      flex-direction: column;
      margin-left: ${theme.typography.pxToRem(40)};

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-left: 0;
        width: 100%;
      }

      a {
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: 100%;
        }
        + a {
          margin-top: ${theme.typography.pxToRem(2)};
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            margin-top: ${theme.typography.pxToRem(20)};
            /* margin-top: calc(20 / 812 * 100%); */
          }
        }
        &:nth-of-type(1) {
          margin-left: ${theme.typography.pxToRem(39)};
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            text-align: center;
            margin: 0 auto;
            margin-top: ${theme.typography.pxToRem(22)};
            /* margin-top: calc(22 / 812 * 100%); */
          }
        }

        &:nth-of-type(3) {
          margin-left: ${theme.typography.pxToRem(83)};
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            margin-left: calc(89 / 315 * 100%);
          }
        }
      }
    }

    a {
      position: relative;
    }

    .sns-list {
      position: fixed;
      bottom: ${theme.typography.pxToRem(40)};
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      gap: ${theme.typography.pxToRem(15)};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        bottom: ${theme.typography.pxToRem(20)};
      }

      .sns-item {
        position: relative;
        width: ${theme.typography.pxToRem(40)};
        height: ${theme.typography.pxToRem(40)};
        background-color: ${theme.palette.white};
        border-radius: 50%;
        a {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }

          [data-tooltip] {
            &::before,
            &::after {
              visibility: hidden;
              opacity: 0;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              white-space: nowrap;
              transition: all 0.2s ease;
              font-size: ${theme.typography.pxToRem(11)};
              letter-spacing: -0.14px;
              color: ${theme.palette.blue};
              font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
            }

            &::before {
              content: attr(data-tooltip);
              height: ${theme.typography.pxToRem(15)};
              top: ${theme.typography.pxToRem(-40)};
              padding: ${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(10)};
              border-radius: 100px;
              background-color: ${theme.palette.white};
              color: ${theme.palette.blue};
              box-shadow: 0 3px 8px rgba(165, 165, 165, 0.5);
              text-transform: uppercase;
            }

            &::after {
              content: "";
              border-left: 5px solid transparent;
              top: ${theme.typography.pxToRem(-15)};
              border-right: 5px solid transparent;
              border-top: 5px solid ${theme.palette.white};
            }
          }
        }

        &:hover {
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            &::before,
            &::after {
              display: none;
            }
          }
          [data-tooltip] {
            &::before {
              visibility: visible;
              top: ${theme.typography.pxToRem(-60)};
              opacity: 1;
            }

            &::after {
              visibility: visible;
              top: ${theme.typography.pxToRem(-35)};
              opacity: 1;
            }
          }
        }
      }
    }

    .lang-toggle-box {
      position: absolute;
      /* top: ${theme.typography.pxToRem(40)}; */
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      align-items: center;
      gap: ${theme.typography.pxToRem(20)};

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        position: fixed;
        top: auto;
        /* bottom: ${theme.typography.pxToRem(80)}; */
        bottom: calc(80 / 812 * 100vh);
        transform: translate(-50%, 0);
      }

      button {
        font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
        font-weight: 700;
        font-size: ${theme.typography.pxToRem(24)};
        line-height: 1;
        ${lsToPx(24, -2)};
        color: rgba(255, 255, 255, 0.5);
        transition: all 0.3s;
        text-transform: uppercase;
        cursor: pointer;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(20)};
          line-height: 1;
        }

        &.active {
          color: ${theme.palette.white};
        }

        &:hover {
          color: ${theme.palette.white};
        }
      }
    }
  `,

  menuMaskCircle: css`
    width: 100px;
    height: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: red;
    display: block;
  `,

  roundButton: css`
    display: flex;
    align-items: center;
    width: ${theme.typography.pxToRem(60)};
    height: ${theme.typography.pxToRem(60)};
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid ${theme.palette.white};
    background-color: transparent;
    cursor: auto;

    &.scroll,
    &.more {
      transform: rotate(90deg);

      i {
        transform: rotate(270deg);
      }

      .rolling1 {
        animation: rollingleft1 7s linear infinite reverse;
      }
      .rolling2 {
        animation: rollingleft2 7s linear infinite reverse;
      }
    }

    &.more,
    &.list,
    &.top {
      cursor: pointer;
    }

    &.list {
      .rolling1 {
        animation: rollingleft1 7s linear infinite;
      }
      .rolling2 {
        animation: rollingleft2 7s linear infinite;
      }
    }

    &.list {
      transition: transform 0.3s;
      @media (hover: hover) {
        &:hover {
          background-color: ${theme.palette.white};
          transform: scale(1.2);
          span {
            color: ${theme.palette.blue} !important;
          }

          i {
            filter: invert(89%) sepia(99%) saturate(7355%) hue-rotate(249deg) brightness(98%) contrast(144%);
          }
        }
      }
    }

    &.more {
      transition: transform 0.3s;
      @media (hover: hover) {
        &:hover {
          background-color: ${theme.palette.white};
          transform: scale(1.2) rotate(90deg);
          span {
            color: ${theme.palette.blue} !important;
          }

          i {
            filter: invert(89%) sepia(99%) saturate(7355%) hue-rotate(249deg) brightness(98%) contrast(144%);
          }
        }
      }
    }

    &.top {
      position: fixed;
      /* bottom: ${theme.typography.pxToRem(40)}; */
      top: 50%;
      right: ${theme.typography.pxToRem(40)};
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
      z-index: 999;
      transition: all 0.4s;
      pointer-events: all;
      transform: rotate(90deg) translateX(10px) translateY(-50%);

      &.active {
        visibility: visible;
        opacity: 1;
        transform: rotate(90deg) translateX(0px);
      }

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: ${theme.typography.pxToRem(50)};
        height: ${theme.typography.pxToRem(50)};
      }

      @media (hover: hover) {
        &:hover {
          background-color: ${theme.palette.white};
          transform: rotate(90deg) translateX(0px) scale(1.2);
          span {
            color: ${theme.palette.blue} !important;
          }

          i {
            filter: invert(89%) sepia(99%) saturate(7355%) hue-rotate(249deg) brightness(98%) contrast(144%);
          }
        }
      }

      i {
        transform: rotate(270deg);
      }
      .rolling1 {
        animation: rollingleft1 7s linear infinite;
      }
      .rolling2 {
        animation: rollingleft2 7s linear infinite;
      }
    }

    span,
    i {
      transition: transform 0.3s;
    }

    .item-wrap {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        span {
          color: ${theme.palette.white};
          font-weight: 700;
          font-size: ${theme.typography.pxToRem(12)};
          line-height: 1;
          letter-spacing: -0.24px;
          text-transform: uppercase !important;
        }
      }

      @keyframes rollingleft1 {
        0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(-100%);
        }
        50.01% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(0);
        }
      }

      @keyframes rollingleft2 {
        0% {
          transition: translateX(0);
        }
        100% {
          transform: translateX(-200%);
        }
      }
    }
  `,

  cursor: css`
    width: ${theme.typography.pxToRem(12)};
    height: ${theme.typography.pxToRem(12)};
    position: fixed;
    display: block;
    background-color: ${theme.palette.white};
    transition: transform 0s;
    top: -6px;
    left: -6px;
    border-radius: 50%;
    pointer-events: none;
    z-index: 1004;
    transition: width 0.3s, border-radius 0.3s, height 0.3s, background-color 0.3s;

    &.over {
      width: ${theme.typography.pxToRem(200)};
      border-radius: 100px;
      height: ${theme.typography.pxToRem(44)};
      background-color: ${theme.palette.blue};
      top: -36px;
      left: -160px;
      display: flex;
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;

      .rolling1,
      .rolling2 {
        display: flex;
        align-items: center;
        padding: 0 ${theme.typography.pxToRem(5)};
      }
      .rolling1 {
        animation: rollingleft1 10s linear infinite;
      }

      .rolling2 {
        animation: rollingleft2 10s linear infinite;
      }

      .rolling2 {
      }

      .item {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        flex: 1;
        width: max-content;

        + .item {
          margin-left: ${theme.typography.pxToRem(20)};
        }
        span {
          font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
          font-weight: 400;
          font-size: ${theme.typography.pxToRem(18)};
          line-height: ${theme.typography.pxToRem(22)};
          letter-spacing: 0.36px;
          color: ${theme.palette.yTxt};
        }
        i {
          display: block;
          width: ${theme.typography.pxToRem(22)};
          height: ${theme.typography.pxToRem(22)};
        }
      }

      @keyframes rollingleft1 {
        0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(-100%);
        }
        50.01% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(0);
        }
      }

      @keyframes rollingleft2 {
        0% {
          transition: translateX(0);
        }
        100% {
          transform: translateX(-200%);
        }
      }

      &.creation {
        .item i {
          background: url("/images/icon/ico-view-more.svg") no-repeat center / cover;
          transform: translateY(-0.5px);
        }
      }
      &.video {
        .item i {
          background: url("/images/icon/ico-video.svg") no-repeat center / cover;
        }
      }
    }
  `,

  modalWrapper: css`
    padding: ${theme.typography.pxToRem(60)};
    border: 1px solid ${theme.palette.white};
    max-width: ${theme.typography.pxToRem(800)};
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding: 0 ${theme.typography.pxToRem(37.5)};
      padding-top: ${theme.typography.pxToRem(40)};
      padding-bottom: ${theme.typography.pxToRem(54)};
      width: 100%;
      max-width: 100%;
      border: none;
      position: absolute;
      bottom: 0;
      height: calc(100% - ${theme.typography.pxToRem(52)});
      overflow-y: auto;
    }
    h2 {
      font-weight: 700;
      font-size: ${theme.typography.pxToRem(26)};
      line-height: 1;
      color: ${theme.palette.white};
      margin-bottom: ${theme.typography.pxToRem(30)};

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        font-size: ${theme.typography.pxToRem(28)};
        line-height: ${theme.typography.pxToRem(32)};
        margin-bottom: ${theme.typography.pxToRem(40)};
      }
    }

    p {
      display: block;
      max-width: ${theme.typography.pxToRem(584)};
      font-weight: 400;
      font-size: ${theme.typography.pxToRem(18)};
      line-height: ${theme.typography.pxToRem(28)};
      color: ${theme.palette.white};
      /* margin-bottom: ${theme.typography.pxToRem(30)}; */
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        max-width: 100%;
        width: 100%;
        font-size: ${theme.typography.pxToRem(16)};
        line-height: ${theme.typography.pxToRem(24)};
        margin-bottom: ${theme.typography.pxToRem(40)};
      }
    }

    dl {
      margin-top: ${theme.typography.pxToRem(30)};
      display: flex;
      align-items: center;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-top: ${theme.typography.pxToRem(15)};
        flex-direction: column;
        justify-content: center;
      }

      dt {
        display: flex;
        justify-content: center;
        align-items: center;
        /* width: ${theme.typography.pxToRem(130)}; */
        height: ${theme.typography.pxToRem(30)};
        border: 1px solid ${theme.palette.white};
        border-radius: 100px;
        color: ${theme.palette.white};
        font-size: ${theme.typography.pxToRem(14)};
        font-weight: 400;
        line-height: ${theme.typography.pxToRem(14)};
        ${lsToPx(14, -2)};
        min-width: ${theme.typography.pxToRem(130)};
        padding: 0 ${theme.typography.pxToRem(18)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          /* flex: 1 0 ${theme.typography.pxToRem(120)}; */
          max-width: ${theme.typography.pxToRem(120)};
          height: ${theme.typography.pxToRem(30)};
          padding: 0;
          margin-bottom: ${theme.typography.pxToRem(10)};
        }
      }

      dd {
        margin-left: ${theme.typography.pxToRem(20)};
        font-weight: 400;
        font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
        font-size: ${theme.typography.pxToRem(18)};
        line-height: ${theme.typography.pxToRem(22)};
        ${lsToPx(18, -2)};
        color: ${theme.palette.white};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(16)};
          line-height: ${theme.typography.pxToRem(24)};
          letter-spacing: 0;
          font-weight: 600;
          /* margin-left: ${theme.typography.pxToRem(15)}; */
          margin-left: 0;
          text-align: center;
        }
      }
    }
  `,

  anim: css`
    background-color: ${theme.palette.black};

    /* 
    &::after {
      content: "";
      position: absolute;
      bottom: -100px;
      left: 0;
      right: 0;
      height: 100px;
      mask-image: url("/images/image/footer-mask.svg");
      mask-repeat: no-repeat;
      mask-position: bottom;
      border: 2px solid red;
    } */

    [data-fade-anim-mask] {
      clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
      transition-property: clip-path;

      &.in-view-from-bottom {
        transition-duration: 0.8s;
        transition-timing-function: cubic-bezier(0.47, 0.16, 0.24, 1);
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      }
    }
    [data-fade-anim] {
      opacity: 0;
      /* opacity: 1; */
      transform: translate(0, 50px);

      &.in-view-from-bottom {
        opacity: 1;
        transform: translate(0, 0);
        transition: opacity 0.7s 0s ${theme.transitions.easing.inout1},
          transform 0.5s ${theme.transitions.easing.inout3};
      }
    }

    [data-fade-text-anim] {
      opacity: 0;
      transform: translate(0, 20px);
      &.in-view-from-bottom {
        opacity: 1;
        transform: translate(0, 0);
        transition: opacity 0.7s 0s ${theme.transitions.easing.inout1},
          transform 0.5s ${theme.transitions.easing.inout3};
      }
    }
  `,

  formErrorMsg: css`
    margin-top: ${theme.typography.pxToRem(10)};
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(14)};
    line-height: 1;
    ${lsToPx(14, -2)};
    color: ${theme.palette.yTxt};
  `,

  flexibleLink: css`
    position: relative;
    display: flex;
    min-width: 80px;
    height: 80px;

    justify-content: center;
    color: white;
    letter-spacing: -0.02em;

    border: solid 2px;
    border-radius: 40px;

    font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-size: ${theme.typography.pxToRem(30)};
    line-height: 1;
    font-weight: 700;
    overflow: hidden;
    transition: width 0.5s ${theme.transitions.easing.inout3};

    width: 50%;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      height: ${theme.typography.pxToRem(48)};
      font-size: ${theme.typography.pxToRem(16)};
    }

    .background {
      width: 0px;
      aspect-ratio: 1 / 1;
      background-color: ${theme.palette.white};
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: width 0.4s ${theme.transitions.easing.inout4};
      z-index: 1;
    }

    /* &::before {
      content: "";
      width: 0px;
      aspect-ratio: 1 / 1;
      background-color: ${theme.palette.white};
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: width 0.4s ${theme.transitions.easing.inout4};
      z-index: 1;
    } */

    span {
      position: relative;
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 0 10px;
      font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
      opacity: 0;
      color: ${theme.palette.white};
      z-index: 2;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        gap: 0 5px;
      }
      transition: color 0.4s ${theme.transitions.easing.inout1};
    }

    i {
      transition: background 0.4s ${theme.transitions.easing.inout1};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: ${theme.typography.pxToRem(20)};
        height: ${theme.typography.pxToRem(20)};
      }
    }

    &.in-view-from-bottom {
      width: 100%;
    }

    &:hover {
      .background {
        width: 110% !important;
        transition: width 0.6s ${theme.transitions.easing.inout4};
      }
      /* &::before {
        width: 110%;
        transition: width 0.6s ${theme.transitions.easing.inout4};
      } */

      span {
        color: ${theme.palette.blue} !important;
      }

      i {
        background: url("/images/icon/ico-arrow-right-blue.svg") no-repeat center / cover;
      }
    }
  `,

  /* font */

  en: css`
    font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
  `,
  ko: css`
    font-family: "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
  `,

  /* heading */

  romanH1: css`
    font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(260)};
    line-height: 1;
    letter-spacing: ${lsToPx(260, 4)};
  `,
  romanH2: css`
    font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(220)};
    line-height: 1;
    letter-spacing: ${lsToPx(220, 4)};
  `,
  romanH3: css`
    font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 700;
    font-size: ${theme.typography.pxToRem(140)};
    line-height: 1;
  `,
  romanH4: css`
    font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 700;
    font-size: ${theme.typography.pxToRem(100)};
    line-height: 1;
  `,
  romanH5: css`
    font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(90)};
    line-height: 1;
  `,
  romanH6: css`
    font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 700;
    font-size: ${theme.typography.pxToRem(30)};
    line-height: 1;
  `,

  romanH7: css`
    font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 700;
    font-size: ${theme.typography.pxToRem(24)};
    line-height: 1;
    letter-spacing: ${lsToPx(24, 2)};
  `,

  hangulH1: css`
    font-family: "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 600;
    font-size: ${theme.typography.pxToRem(140)};
    line-height: calc(150 / 140);
  `,

  /* content */

  romanCont1: css`
    font-family: "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(46)};
    line-height: calc(56 / 46);
    letter-spacing: ${lsToPx(46, -2)};
  `,
  romanCont2: css`
    font-family: "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(30)};
    line-height: calc(40 / 30);
    letter-spacing: ${lsToPx(30, -2)};
  `,
  romanCont3: css`
    font-family: "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(27)};
    line-height: calc(32 / 27);
  `,
  romanCont4: css`
    font-family: "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(18)};
    line-height: calc(22 / 18);
    letter-spacing: ${lsToPx(18, -2)};
  `,

  hangulCont1: css`
    font-family: "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 600;
    font-size: ${theme.typography.pxToRem(24)};
    line-height: calc(38 / 24);
  `,
  hangulCont2: css`
    font-family: "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 600;
    font-size: ${theme.typography.pxToRem(20)};
    line-height: calc(30 / 20);
  `,
  hangulCont3: css`
    font-family: "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(20)};
    line-height: calc(30 / 20);
  `,
  hangulCont4: css`
    font-family: "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(18)};
    line-height: calc(28 / 18);
  `,

  /* icon */

  icoArrowUp: css`
    display: block;
    width: ${theme.typography.pxToRem(12)};
    height: ${theme.typography.pxToRem(12)};
    background: url("/images/icon/ico-arrow-up.svg") no-repeat center / cover;
  `,

  icoArrowDown: css`
    display: block;
    width: ${theme.typography.pxToRem(12)};
    height: ${theme.typography.pxToRem(12)};
    background: url("/images/icon/ico-arrow-down.svg") no-repeat center / cover;
  `,

  icoArrowLeft: css`
    display: block;
    width: ${theme.typography.pxToRem(12)};
    height: ${theme.typography.pxToRem(12)};
    background: url("/images/icon/ico-arrow-left.svg") no-repeat center / cover;
  `,

  icoSearch: css`
    display: block;
    width: ${theme.typography.pxToRem(28)};
    height: ${theme.typography.pxToRem(28)};
    background: url("/images/icon/ico-search.svg") no-repeat center / cover;
  `,

  icoDelete: css`
    display: block;
    width: ${theme.typography.pxToRem(28)};
    height: ${theme.typography.pxToRem(28)};
    background: url("/images/icon/ico-delete.svg") no-repeat center / cover;
  `,

  icoDownload: css`
    display: block;
    width: ${theme.typography.pxToRem(28)};
    height: ${theme.typography.pxToRem(28)};
    background: url("/images/icon/ico-download.svg") no-repeat center / cover;
  `,

  icoDatePrev: css`
    display: block;
    width: ${theme.typography.pxToRem(28)};
    height: ${theme.typography.pxToRem(28)};
    background: url("/images/icon/ico-date-prev.svg") no-repeat center / cover;
  `,

  icoDateNext: css`
    display: block;
    width: ${theme.typography.pxToRem(28)};
    height: ${theme.typography.pxToRem(28)};
    background: url("/images/icon/ico-date-next.svg") no-repeat center / cover;
  `,

  icoSubmit: css`
    display: block;
    width: ${theme.typography.pxToRem(32)};
    height: ${theme.typography.pxToRem(32)};
    background: url("/images/icon/ico-submit.svg") no-repeat center / cover;
  `,

  icoMenuArrow: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/ico-arrow-menu.svg") no-repeat center / cover;
  `,

  icoViewMore: css`
    display: block;
    width: ${theme.typography.pxToRem(22)};
    height: ${theme.typography.pxToRem(22)};
    background: url("/images/icon/ico-view-more.svg") no-repeat center / cover;
  `,

  icoArrowRight: css`
    display: block;
    width: ${theme.typography.pxToRem(24)};
    height: ${theme.typography.pxToRem(24)};
    background: url("/images/icon/ico-arrow-right.svg") no-repeat center / cover;

    &.blue {
      background: url("/images/icon/ico-arrow-right-blue.svg") no-repeat center / cover;
    }
  `,

  icoPlay: css`
    display: block;
    width: ${theme.typography.pxToRem(80)};
    height: ${theme.typography.pxToRem(80)};
    background: url("/images/icon/ico-play.svg") no-repeat center / cover;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      width: ${theme.typography.pxToRem(60)};
      height: ${theme.typography.pxToRem(60)};
    }
  `,

  icoPause: css`
    display: block;
    width: ${theme.typography.pxToRem(48)};
    height: ${theme.typography.pxToRem(48)};
    background: url("/images/icon/ico-pause.svg") no-repeat center / cover;
  `,

  icoUnmuted: css`
    display: block;
    width: ${theme.typography.pxToRem(48)};
    height: ${theme.typography.pxToRem(48)};
    background: url("/images/icon/ico-unmuted.svg") no-repeat center / cover;
  `,

  icoMuted: css`
    display: block;
    width: ${theme.typography.pxToRem(48)};
    height: ${theme.typography.pxToRem(48)};
    background: url("/images/icon/ico-muted.svg") no-repeat center / cover;
  `,

  icoPauseMo: css`
    display: block;
    width: ${theme.typography.pxToRem(80)};
    height: ${theme.typography.pxToRem(80)};
    background: url("/images/icon/ico-pause-mo.svg") no-repeat center / cover;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      width: ${theme.typography.pxToRem(60)};
      height: ${theme.typography.pxToRem(60)};
    }
  `,

  /******** Tab layout ********/

  circleCanvas: css`
    position: fixed;
    /* position: absolute; */
    top: 0;
    left: 0;
    transform: scale(1);
    mix-blend-mode: screen;
    opacity: 0.5;
    /* opacity: 1; */
    pointer-events: none;
    /* z-index: -1; */
  `,

  circleSm: css`
    position: absolute;
    /* position: absolute; */
    top: 0;
    left: 0;
    transform: scale(1.3);
    mix-blend-mode: screen;
    /* opacity: 0.5; */
    opacity: 0.7;
    pointer-events: none;
    /* z-index: -1; */
  `,

  postContainer: css`
    position: relative;
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
    padding: 0 ${theme.typography.pxToRem(40)};
    z-index: 2;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding: 0 ${theme.typography.pxToRem(20)};
      padding-top: ${theme.typography.pxToRem(116)};
    }

    .sub-page-title {
      position: absolute;
      top: ${theme.typography.pxToRem(40)};
      left: 50%;
      transform: translateX(-50%);
      font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
      font-weight: 700;
      font-size: ${theme.typography.pxToRem(26)};
      line-height: 1;
      color: ${theme.palette.gray};
      mix-blend-mode: difference;
      text-transform: uppercase !important;
    }

    .tabs-area {
      margin-left: ${theme.typography.pxToRem(20)};
      /* flex: 1 0 100%; */
      width: 100%;
      max-width: ${theme.typography.pxToRem(290)};
      min-width: ${theme.typography.pxToRem(140)};
      margin-right: ${theme.typography.pxToRem(40)};
      /* padding-top: ${theme.typography.pxToRem(70)}; */
      /* margin-bottom: ${theme.typography.pxToRem(154)}; */
      position: sticky;
      top: ${theme.typography.pxToRem(250)};
      left: 0;
      /* width: 1px; */
      /* height: ${theme.typography.pxToRem(500)}; */
      height: fit-content;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        display: none;
      }

      + .contents-area {
        width: 100%;
        display: block !important;
        max-width: ${theme.typography.pxToRem(1480)};
      }
    }

    .contents-area {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      width: 100%;

      .inner {
        max-width: ${theme.typography.pxToRem(1480)};
        width: 100%;
        padding-top: ${theme.typography.pxToRem(138)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          padding-top: 0;
        }
      }
    }
  `,

  paginationWrap: css`
    padding: ${theme.typography.pxToRem(149.7)} 0 ${theme.typography.pxToRem(150)};
  `,

  /******** Menu ********/
  menuBtn: css`
    position: relative;
    box-sizing: border-box;
    width: ${theme.typography.pxToRem(32)};
    height: ${theme.typography.pxToRem(32)};
    border-radius: 100px;
    border: 1px solid ${theme.palette.white};
    pointer-events: all;
    cursor: pointer;
    transition: all 0.4s;
    padding: 0;
    max-width: ${theme.typography.pxToRem(90)};
    overflow: hidden;

    &::after {
      content: "";
      width: ${theme.typography.pxToRem(10)};
      height: ${theme.typography.pxToRem(10)};
      background-color: ${theme.palette.yellow};
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: ${theme.typography.pxToRem(10)};
      border-radius: 50%;
      filter: blur(2px);
    }

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: ${theme.typography.pxToRem(14)};
      font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
      font-weight: 400;
      font-size: ${theme.typography.pxToRem(17)};
      line-height: 1;
      color: ${theme.palette.white};
      letter-spacing: -0.32px;
      opacity: 0;
      transition: opacity 0.3s ${theme.transitions.easing.inout3};
    }

    &.appear {
      width: ${theme.typography.pxToRem(99)};
      /* flex: 1 0 ${theme.typography.pxToRem(90)}; */
      span {
        opacity: 1;
      }
    }

    &[data-isdetail="true"] {
      width: ${theme.typography.pxToRem(90)};
      flex: 1 0 ${theme.typography.pxToRem(90)};
      span {
        opacity: 1;
      }
    }
    &[data-isdetail="fasle"] {
      &:hover {
        width: ${theme.typography.pxToRem(90)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: ${theme.typography.pxToRem(32)};
        }
        /* flex: 1 0 ${theme.typography.pxToRem(90)}; */

        span {
          opacity: 1;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            opacity: 0;
          }
        }
      }
    }
  `,

  closeBtn: css`
    position: relative;

    width: ${theme.typography.pxToRem(32)};
    height: ${theme.typography.pxToRem(32)};
    border-radius: 50%;
    border: 1px solid ${theme.palette.white};
    pointer-events: all;
    cursor: pointer;
    transition: all 0.3s;

    &::after {
      content: "";
      width: ${theme.typography.pxToRem(10)};
      height: ${theme.typography.pxToRem(10)};
      background-color: ${theme.palette.yellow};
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: ${theme.typography.pxToRem(10)};
      border-radius: 50%;
      filter: blur(2px);
    }
  `,

  formBox: css`
    display: flex;
    gap: ${theme.typography.pxToRem(20)};
    width: 100%;
    align-items: center;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: column;
    }

    + .form-area {
      margin-top: ${theme.typography.pxToRem(60)};
    }

    .form-box {
      flex: 1;
      display: flex;
      flex-direction: column;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: 100%;
        + .form-box {
          margin-top: ${theme.typography.pxToRem(60)};
        }
      }

      .label {
        font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
        font-weight: 700;
        font-size: ${theme.typography.pxToRem(20)};
        line-height: 1;
        color: ${theme.palette.white};
        ${lsToPx(20, -2)};
        text-transform: uppercase;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(17)};
        }
      }
    }
  `,

  checkboxBox: css`
    /* width: ${theme.typography.pxToRem(346)};
    height: ${theme.typography.pxToRem(32)}; */

    display: flex;
    flex-direction: column;

    .flex-row-box {
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }

    > p {
      text-align: center;
    }

    .MuiCheckbox-root {
      color: white !important;
    }

    button {
      color: ${theme.palette.white};
      cursor: pointer;
      font-size: ${theme.typography.pxToRem(20)};
      line-height: ${theme.typography.pxToRem(30)};
      padding: 0;
      margin-left: ${theme.typography.pxToRem(5)};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        font-size: ${theme.typography.pxToRem(16)};
        line-height: ${theme.typography.pxToRem(24)};
      }
    }
  `,

  error: css`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    canvas {
      transform: scale(1.4);
    }

    .error {
      position: relative;
      width: 100%;
      max-width: ${theme.typography.pxToRem(800)};
      padding: ${theme.typography.pxToRem(60)};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${theme.palette.white};
      border: 1px solid ${theme.palette.white};

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        max-width: 100%;
        border: none;
        padding: 0 ${theme.typography.pxToRem(37.5)};
        align-items: flex-start;
        height: 100%;
      }

      h2 {
        font-weight: 700;
        font-size: ${theme.typography.pxToRem(26)};
        line-height: 1;
        margin-bottom: ${theme.typography.pxToRem(30)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(28)};
          line-height: ${theme.typography.pxToRem(38)};
          margin-bottom: ${theme.typography.pxToRem(40)};
        }
      }

      strong {
        font-weight: 600;
        font-size: ${theme.typography.pxToRem(56)};
        line-height: ${theme.typography.pxToRem(66)};
        margin-bottom: ${theme.typography.pxToRem(30)};
        text-transform: uppercase;
        font-family: "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(50)};
          line-height: ${theme.typography.pxToRem(50)};
          margin-bottom: ${theme.typography.pxToRem(40)};
        }
      }

      p {
        font-weight: 400;
        font-size: ${theme.typography.pxToRem(18)};
        line-height: ${theme.typography.pxToRem(28)};
        margin-bottom: ${theme.typography.pxToRem(60)};
        text-align: center;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(17)};
          line-height: ${theme.typography.pxToRem(24)};
          margin-bottom: 0;
          text-align: left;
        }
      }

      a {
        width: 100%;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          padding: 0 ${theme.typography.pxToRem(37.5)};
          position: absolute;
          left: 0;
          bottom: ${theme.typography.pxToRem(30)};
        }

        button {
          font-size: ${theme.typography.pxToRem(20)};
          line-height: ${theme.typography.pxToRem(30)};
          height: ${theme.typography.pxToRem(60)};

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            font-size: ${theme.typography.pxToRem(17)};
            line-height: ${theme.typography.pxToRem(17)};
            height: ${theme.typography.pxToRem(48)};
            opacity: 0.8;
          }
        }
      }
    }
  `,
};
export default globalCSS;
