import gsap from "gsap";

class MenuInteraction {
  menu: string;
  isRunning: boolean;
  constructor() {
    this.menu = "#menu";
    this.isRunning = false;
  }

  play(onComplete: () => void) {
    if (this.isRunning) return;
    const tl = gsap.timeline();
    tl.set(this.menu, { display: "flex" }).to(this.menu, {
      clipPath: "circle(100% at 50% 50%)",
      onComplete: onComplete,
    });
  }
  reverse(onComplete: () => void) {
    if (this.isRunning) return;
    const tl = gsap.timeline();
    tl.to(this.menu, { clipPath: "circle(0% at 50% 50%)" }).set(this.menu, {
      display: "none",
      onComplete: onComplete,
    });
  }
}

export const menuMotion = new MenuInteraction();
