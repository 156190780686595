import { css } from "@emotion/react";
import { createRef, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { lenisScrollState } from "@/state";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import Observer from "@/assets/ts/bauerlab/util/Observer";

interface ScrollObjectType {
  observer: Observer | null;
  scrollObj: ScrollObject | null;
}

export default function FadeTextSingleLine({ texts }) {
  const [init, setInit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const paragraphRefs = useRef(texts.map(() => createRef()));

  const iteractionItems = useRef<ScrollObjectType>({
    observer: null,
    scrollObj: null,
  });

  useEffect(() => {
    let observer = new Observer();
    let doms = [];
    paragraphRefs.current.map((data) => {
      observer.add(data.current);
      doms.push(data.current);
    });
    return () => {
      doms.map((dom) => {
        observer.remove(dom);
      });
    };
  }, []);

  return (
    <div>
      {texts.map((text, index) => (
        <p key={index} ref={paragraphRefs.current[index]} data-fade-text-anim>
          {text}
        </p>
      ))}
    </div>
  );
}

const style = {
  main: css`
    border: solid 1px #ffffff;
    width: 100px;
    height: 50px;
  `,
};
