import globalCSS from "@/assets/ts/global-css";
import { Link, useLocation } from "react-router-dom";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import { useRecoilState } from "recoil";
import { useEffect, useRef, useState } from "react";
import { lenisScrollState } from "@/state";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import RoundedButton from "./ui/roundedButton";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { footerMenu, snsData } from "@/assets/ts/mock-data/menu";
import { css, useMediaQuery } from "@mui/material";
import theme from "@/assets/ts/theme";
import { useTranslation } from "react-i18next";
import useSmoothScroll from "@/hooks/use-smooth-scroll";
import { useMoveTop } from "@/hooks/use-moveTop";
interface ScrollObjectType {
  observer: Observer | null;
  scrollObj: ScrollObject | null;
}

const Footer = ({ className }: { className?: string }) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  const changeLang = (lang: string): void => {
    i18n.changeLanguage(lang);
  };

  const footerRef = useRef(null);
  const isMobile = useMediaQuery(
    `(max-width:${theme.breakpoints.values.lg}px)`
  );
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const [isTopBtnActive, setIsTopBtnActive] = useState(false);
  const [progress, setProgress] = useState({
    sns: 0,
    address: 0,
    menu: 0,
    lang: 0,
  });

  const interactionItems = useRef<ScrollObjectType>({
    observer: null,
    scrollObj: null,
  });

  useEffect(() => {
    if (footerRef.current) {
      interactionItems.current.observer = new Observer();
      interactionItems.current.observer.add(footerRef.current);
      interactionItems.current.scrollObj = new ScrollObject(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        interactionItems.current.observer.remove(footerRef.current);
        interactionItems.current.observer.kill();
        interactionItems.current.scrollObj.kill();
      }
    };
  }, [footerRef]);

  useEffect(() => {
    let scroll = lenisState.scroll;
    const main = document.querySelector("#main");
    let start = main.clientHeight - footerRef.current.clientHeight;

    if (scroll > start && scroll <= lenisState.limit) {
      let value =
        60 - ((60 - 0) * (scroll - start)) / (lenisState.limit - start);
      setProgress({
        sns: value * 5,
        address: value * 4,
        menu: value * 3,
        lang: value * 2,
      });
    }
  }, [lenisState]);
  const { moveTop } = useMoveTop();
  // const lenis = useSmoothScroll();
  // function moveLink() {
  //   lenis.scrollTo(0, { immediate: true });
  // }

  const handleChangeLang = (lang: string) => {
    changeLang(lang);
    if (location.pathname == "/") {
      window.location.reload();
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <>
      <footer
        css={globalCSS.footer}
        id="footer"
        className={className}
        ref={footerRef}
      >
        <div className="inner" style={{ opacity: lenisState.progress }}>
          <ul
            className="sns-list from-bottom"
            style={{
              transform: `translateY(${progress.sns}px)`,
            }}
          >
            {snsData.map((sns, i) => (
              <li key={sns.nm} className={`sns-item sns-${sns.nm}`}>
                <a href={sns.url} target="_blank" rel="noreferrer">
                  <span data-tooltip={`${sns.nm}`}></span>
                  <img
                    src={`/images/image/img-sns-${sns.nm}.png`}
                    alt={sns.nm}
                  />
                </a>
              </li>
            ))}
          </ul>

          <address
            className="address-box from-bottom"
            style={{
              transform: `translateY(${progress.address}px)`,
            }}
          >
            <em className="content-title">contact</em>
            {!isMobile ? (
              <p>
                서울특별시 마포구 동교로17길 41
                <br />
                41, Donggyo-ro 17-gil, Mapo-gu, Seoul, Republic of Korea
              </p>
            ) : (
              <p>
                서울특별시 마포구 동교로17길 41
                <br />
                41, Donggyo-ro 17-gil, Mapo-gu, Seoul,
                <br />
                Republic of Korea
              </p>
            )}
          </address>
          <div
            className="menu-box from-bottom"
            style={{
              transform: `translateY(${progress.menu}px)`,
            }}
          >
            <em className="content-title">menu</em>

            <ul>
              {footerMenu.map((menu, i) => (
                <li key={menu.cd}>
                  <Link to={menu.url} onClick={moveTop}>
                    {menu.nm}
                  </Link>
                </li>
              ))}
              {/* {footerMenu.map((menu, i) => (
                <li key={menu.cd}>
                  <Link to={menu.url}>{menu.nm}</Link>
                </li>
              ))} */}
            </ul>
          </div>
          <div
            className="btn-lang-box from-bottom"
            style={{
              transform: `translateY(${progress.lang}px)`,
            }}
          >
            <button
              className={i18n.language == "ko" ? "active" : ""}
              onClick={() => handleChangeLang("ko")}
            >
              kr
            </button>
            <button
              className={i18n.language == "en" ? "active" : ""}
              onClick={() => handleChangeLang("en")}
            >
              en
            </button>
          </div>
          <div className="bottom-box">
            <img src="/images/image/img-footer-logo.svg" alt="bauerlab" />
            <span>Ⓒ2023 BAUER LAB All rights reserved.</span>
          </div>
        </div>
        <div className="btn-top-box" css={style.scrollTop}>
          <RoundedButton type={"top"} />
        </div>
      </footer>
      {/* {isTopBtnActive && } */}
    </>
  );
};

const style = {
  scrollTop: css`
    @media (max-width: ${theme.breakpoints.values.md}px) {
      position: relative;
      .round-wrap {
        position: absolute;
        top: -12px;
        right: 0;
        transform: translate(0, -50%) rotate(90deg) !important;
      }
    }
  `,
};

export default Footer;
