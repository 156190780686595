import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { forwardRef } from "react";
import RoundedButton from "../ui/roundedButton";
import CircleCanvas from "@/components/animation/circle-canvas";

const InternationalVisual = forwardRef<HTMLElement, {}>((props, ref) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  return (
    <>
      <section className="sc-visual" css={style.visual} ref={ref}>
        <CircleCanvas
          bgColor="transparent"
          color="#000000"
          isMask="false"
          id="circleCanvas"
          scale="2"
          // maskProgress={listProgress}
        />
        <h2 className="sc-title">
          <img
            src={
              !isMobile ? "/images/image/international/img-title.svg" : "/images/image/international/img-title-mo.svg"
            }
            alt="hub for global partnership"
          />
          {!isMobile ? (
            <p>
              Bauer Lab has established close collaborative partnerships
              <br />
              with top international studios, acting as a bridge
              <br />
              to address cultural gaps and communication issues
              <br />
              that were previously
              <br />
              absent in the domestic market.
            </p>
          ) : (
            <p>
              Bauer Lab has established close
              <br />
              collaborative partnerships with top
              <br />
              international studios,
              <br />
              acting as a bridge to address cultural gaps
              <br />
              and communication issues that were
              <br />
              previously absent
              <br />
              in the domestic market.
            </p>
          )}
        </h2>
        <div className="btn-wrap">
          <RoundedButton type="scroll" />
        </div>
      </section>
    </>
  );
});
const style = {
  visual: css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding-top: ${theme.typography.pxToRem(171)};
    padding-left: ${theme.typography.pxToRem(200)};

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding: 0 ${theme.typography.pxToRem(13)};
      justify-content: center;
      align-items: center;
    }

    .sc-title {
      position: relative;
      width: calc(1378 / 1920 * 100%);
      aspect-ratio: 1378 / 660;
      margin-bottom: ${theme.typography.pxToRem(39)};

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: 100%;
        aspect-ratio: auto;
      }

      img {
        width: 100%;
        height: 100%;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          height: auto;
        }
      }

      p {
        position: absolute;
        top: calc(240 / 660 * 100%);
        left: calc(866 / 1378 * 100%);
        width: 100%;
        ${globalCSS.en};
        font-weight: 400;
        /* font-size: ${theme.typography.pxToRem(27)}; */
        font-size: calc(27 / 1920 * 100vw);
        line-height: calc(32 / 27);
        color: ${theme.palette.blue};

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          position: relative;
          top: 0;
          left: 0;
          font-size: calc(16 / 375 * 100vw);
          line-height: calc(20 / 16);
          text-align: center;
          margin-top: ${theme.typography.pxToRem(60)};
        }
      }
    }

    .btn-wrap {
      position: absolute;
      bottom: ${theme.typography.pxToRem(40)};
      left: 50%;
      transform: translateX(-50%);

      .round-wrap {
        border-color: ${theme.palette.blue};
      }

      .item {
        span {
          color: ${theme.palette.blue} !important;
          mix-blend-mode: difference;
        }

        i {
          filter: invert(89%) sepia(99%) saturate(7355%) hue-rotate(249deg) brightness(98%) contrast(144%);
        }
      }
    }
  `,
};

export default InternationalVisual;
