import { lsToPx } from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import officeData from "@/assets/ts/mock-data/office";
import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { forwardRef, useState } from "react";
import Clock from "../clock/clock";
import { XTTab, XTTabContent, XTTabs } from "../ui/tabs";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import ContactMap from "./contact-map";

const ContactOffice = forwardRef<HTMLElement, {}>((props, ref) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const [officeTabValue, setOfficeTabValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newVal: number) => {
    setOfficeTabValue(newVal);
  };

  const getOfficeTabLabel = (nation: string, local: string) => {
    if (nation === "korea") {
      return `${nation}`;
    } else {
      return `${nation}. ${local}`;
    }
  };

  const getOfficeTime = (local: string) => {
    switch (local) {
      case "seoul":
        return "ko";
      case "ca":
        return "la";
      case "burbank":
        return "la";
      case "quebec":
        return "qb";
      default:
        return "ko";
    }
  };

  return (
    <section className="sc-office" css={style.office} ref={ref} id="office">
      {!isMobile ? (
        <>
          <div className="tabs-area">
            <XTTabs value={officeTabValue} onChange={handleTabChange}>
              {officeData.map((office, index) => (
                <XTTab label={getOfficeTabLabel(office.nation, office.local)} tabIndex={index} key={index} />
              ))}
            </XTTabs>
          </div>
          <div className="contents-area">
            {officeData.map((office, index) => (
              <XTTabContent value={officeTabValue} index={index} key={index}>
                <div className="title-box">
                  <div className="nation-box">
                    <div className="info-box">
                      <h2>{office.nation}</h2>
                      <span className={office.local === "ca" ? "uppercase" : ""}>{office.local}</span>
                    </div>
                    <div className="clock-box">
                      <Clock local={getOfficeTime(office.local)} />
                    </div>
                  </div>
                  <div className="address-box">
                    <div className="address-info-box">
                      <dl>
                        <dt>e-mail.</dt>
                        <dd>{office.email}</dd>
                      </dl>
                      <dl>
                        <dt>business hours.</dt>
                        <dd>{office["business-hours"]}</dd>
                      </dl>
                      {office.phone && (
                        <dl>
                          <dt>phone.</dt>
                          <dd>{office.phone}</dd>
                        </dl>
                      )}
                    </div>
                    <p className="address-desc">{office.desc}</p>
                  </div>
                </div>
                <div className="map-box">
                  <iframe src={office.map} title={office.local}></iframe>
                </div>
              </XTTabContent>
            ))}
          </div>
        </>
      ) : (
        <>
          <ul className="content-list">
            {officeData.map((office, index) => (
              <li className="content-item" key={index}>
                <div className="title-box">
                  <div className="nation-box">
                    <div>
                      <h2>{office.nation}</h2>
                      <span className={office.local === "ca" ? "uppercase" : ""}>{office.local}</span>
                    </div>
                    <div>
                      <Clock local={getOfficeTime(office.local)} />
                    </div>
                  </div>
                  <div className="address-box">
                    <div className="address-info-box">
                      <dl>
                        <dt>e-mail.</dt>
                        <dd>{office.email}</dd>
                      </dl>
                      <dl>
                        <dt>business hours.</dt>
                        <dd>{office["business-hours"]}</dd>
                      </dl>
                      {office.phone && (
                        <dl>
                          <dt>phone.</dt>
                          <dd>{office.phone}</dd>
                        </dl>
                      )}
                    </div>
                    <p className="address-desc">{office.desc}</p>
                  </div>
                </div>
                <ContactMap map={office.map} local={office.local} />
              </li>
            ))}
          </ul>
        </>
      )}
    </section>
  );
});

const style = {
  office: css`
    display: flex;
    padding: 0 ${theme.typography.pxToRem(60)} ${theme.typography.pxToRem(150)};
    margin-top: ${theme.typography.pxToRem(700)};
    position: relative;
    z-index: 2;
    position: relative;
    min-height: ${theme.typography.pxToRem(1000)};

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding: 0 ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(80)};
      margin-top: ${theme.typography.pxToRem(300)};
      height: auto;
    }

    .tabs-area {
      width: 100%;
      max-width: ${theme.typography.pxToRem(290)};
      margin-left: ${theme.typography.pxToRem(20)};
      margin-right: ${theme.typography.pxToRem(40)};
      .MuiButtonBase-root {
        text-transform: uppercase !important;

        &.Mui-selected {
          color: ${theme.palette.blue} !important;
        }
      }
    }
    .contents-area {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      flex: 1;
      display: flex;
      justify-content: center;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }

      .contents {
        width: 100%;
        /* max-width: ${theme.typography.pxToRem(1300)}; */
        max-width: ${theme.typography.pxToRem(1140)};
        .title-box {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .nation-box {
            display: flex;
            align-items: flex-start;
            margin-right: ${theme.typography.pxToRem(40)};
            .info-box {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              h2 {
                ${globalCSS.en};
                font-weight: 700;
                font-size: ${theme.typography.pxToRem(100)};
                line-height: 1;
                color: ${theme.palette.blue};
                letter-spacing: -2px;
                margin-bottom: ${theme.typography.pxToRem(12)};
                text-transform: uppercase;
              }

              span {
                text-transform: capitalize;
                font-weight: 400;
                ${globalCSS.en};
                color: ${theme.palette.blue};
                font-weight: 400;
                font-size: ${theme.typography.pxToRem(50)};
                line-height: 1;
                letter-spacing: -2px;

                &.uppercase {
                  text-transform: uppercase;
                }
              }
            }
            .clock-box {
              margin-left: ${theme.typography.pxToRem(40)};
            }
          }

          .address-box {
            .address-info-box {
              margin-bottom: ${theme.typography.pxToRem(15)};
              min-width: ${theme.typography.pxToRem(453)};
              dl {
                display: flex;
                /* align-items: center; */
                flex-direction: column;
                ${globalCSS.en};
                font-size: ${theme.typography.pxToRem(26)};
                line-height: ${theme.typography.pxToRem(32)};
                color: ${theme.palette.blue};
                letter-spacing: -0.52px;
                text-transform: uppercase;

                + dl {
                  margin-top: ${theme.typography.pxToRem(2)};
                }
              }
              dt {
                font-weight: 400;
                /* margin-right: ${theme.typography.pxToRem(20)}; */
              }
              dd {
                font-weight: 700;
              }
            }
            .address-desc {
              ${globalCSS.en};
              font-size: ${theme.typography.pxToRem(18)};
              line-height: ${theme.typography.pxToRem(22)};
              color: ${theme.palette.blue};
              font-weight: 400;
              letter-spacing: 0.36px;
            }
          }
        }
        .map-box {
          width: 100%;
          margin-top: ${theme.typography.pxToRem(60)};
          height: ${theme.typography.pxToRem(450)};
          border: 1px solid ${theme.palette.blue};

          iframe {
            width: 100%;
            height: 100%;
            border: none;
            pointer-events: none;
          }
        }
      }
    }

    /* mobile */

    .content-list {
      display: flex;
      flex-direction: column;

      .content-item {
        position: relative;
        display: flex;
        flex-direction: column;

        + .content-item {
          margin-top: ${theme.typography.pxToRem(40)};
          padding-top: ${theme.typography.pxToRem(40)};

          &::before {
            content: "";
            width: calc(100% - 15px);
            height: 1px;
            background-color: ${theme.palette.blue};
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .nation-box {
          display: flex;
          align-items: flex-end;
          gap: ${theme.typography.pxToRem(16)};
          padding: 0 ${theme.typography.pxToRem(8.5)};

          > div {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            text-transform: uppercase;
          }

          h2 {
            ${globalCSS.en};
            color: ${theme.palette.blue};
            font-size: ${theme.typography.pxToRem(40)};
            line-height: 1;
            font-weight: 700;
            margin-bottom: ${theme.typography.pxToRem(7.93)};
          }

          span {
            font-weight: 700;
            font-size: ${theme.typography.pxToRem(20)};
            line-height: 1;
            color: ${theme.palette.blue};
            ${globalCSS.en};
          }
        }

        .address-box {
          margin-bottom: ${theme.typography.pxToRem(40)};
          margin-top: ${theme.typography.pxToRem(21.44)};
          padding: 0 ${theme.typography.pxToRem(8.5)};
          dl {
            display: flex;
            align-items: flex-start;
            ${globalCSS.en};
            font-size: ${theme.typography.pxToRem(16)};
            line-height: ${theme.typography.pxToRem(24)};
            color: ${theme.palette.blue};
            text-transform: uppercase;
            flex-direction: column;

            dt {
              font-weight: 400;
            }

            dd {
              font-weight: 700;
              flex: 1;
              /* margin-left: ${theme.typography.pxToRem(4)}; */
            }
          }

          .address-desc {
            font-weight: 400;
            font-size: ${theme.typography.pxToRem(12)};
            line-height: ${theme.typography.pxToRem(16)};
            ${globalCSS.en};
            color: ${theme.palette.blue};
            margin-top: ${theme.typography.pxToRem(15)};
          }
        }

        .map-wrapper {
          width: 100%;
          /* aspect-ratio: 335 / 300; */
          max-height: ${theme.typography.pxToRem(300)};
        }

        &:nth-of-type(even) {
          dl {
            align-items: flex-end;
          }

          .address-desc {
            text-align: right;
          }
          .nation-box {
            flex-direction: row-reverse;
          }
        }
      }
    }
  `,
};

export default ContactOffice;
