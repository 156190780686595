import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as SvgArrow } from "@/assets/svg/ico-arrow-right.svg";
import { useRecoilState } from "recoil";
import { lenisScrollState } from "@/state";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import Easing from "@/assets/ts/bauerlab/util/Easing";
import globalCSS from "@/assets/ts/global-css";

interface ScrollObjectType {
  observer: Observer | null;
  scrollObj: ScrollObject | null;
}

export default function FlexsibleLink(props) {
  const [init, setInit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const moreButton = useRef(null);
  const interactionItems = useRef<ScrollObjectType>({
    observer: null,
    scrollObj: null,
  });
  useEffect(() => {
    if (props.progress !== undefined) {
      setProgress(props.progress);
    }
  }, [props.progress]);
  useEffect(() => {
    if (moreButton.current) {
      interactionItems.current.observer = new Observer();
      interactionItems.current.observer.add(moreButton.current);
      interactionItems.current.scrollObj = new ScrollObject(moreButton.current);
    }

    return () => {
      if (moreButton.current) {
        interactionItems.current.observer.remove(moreButton.current);
        interactionItems.current.observer.kill();
        interactionItems.current.scrollObj.kill();
      }
    };
  }, [moreButton]);

  useEffect(() => {
    // if (interactionItems.current.scrollObj) {
    // let scrollObj = interactionItems.current.scrollObj;
    // scrollObj.update(lenisState.scroll);
    // let progress = scrollObj.ratio.allY * 3;
    // progress = clamp(progress);
    // setProgress((prev) => {
    //   if (typeof progress !== "undefined") {
    //     return prev + (progress - prev) * 0.3;
    //   }
    //   return prev;
    // });
    // }
  }, [lenisState]);

  return (
    <div className="more-button-wrap">
      <Link
        {...props}
        data-passive={props.passive}
        ref={moreButton}
        css={[globalCSS.flexibleLink, style.main]}
        style={{ width: 60 + progress * 40 + "%", opacity: progress * 2 }}
        to={props.to}
      >
        <div className="background"></div>
        <span style={{ opacity: clamp(progress) }} data-text={props.children}>
          {props.children}
          {/* <SvgArrow /> */}
          <i css={globalCSS.icoArrowRight}></i>
        </span>
      </Link>
    </div>
  );
}

const style = {
  main: css`
    width: 100%;
    transition: none !important;
    &.in-view-from-bottom {
      /* opacity: 1; */
      span {
        /* opacity: 1 !important; */
      }
    }
  `,
};
