import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import { t } from "i18next";

const UndefinedSchResult = (schTxt: any) => {
  return (
    <p css={style.result}>
      <span>
        "<em>{schTxt.schTxt}</em>"
      </span>
      <span
        dangerouslySetInnerHTML={{
          __html: t("post:searchNoResult"),
        }}></span>
    </p>
  );
};

const style = {
  result: css`
    width: 100%;
    padding-top: ${theme.typography.pxToRem(70)};
    display: flex;
    justify-content: center;
    max-width: ${theme.typography.pxToRem(1140)};
    font-weight: 700;
    font-size: ${theme.typography.pxToRem(26)};
    line-height: 1;
    color: ${theme.palette.white};
    display: flex;
    gap: ${theme.typography.pxToRem(5)};
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: ${theme.typography.pxToRem(24)};
      line-height: ${theme.typography.pxToRem(30)};
    }

    span {
      text-align: center;
      em {
        color: ${theme.palette.yTxt};
      }
    }
  `,
};

export default UndefinedSchResult;
