import { handlePageToTop, lsToPx } from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { lenisScrollState, pageYState } from "@/state";
import { css } from "@emotion/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import Lenis from "@studio-freight/lenis";
import axios from "axios";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import { getImagePath } from "@/assets/ts/image";

interface ScrollObjectType {
  observer: Observer | null;
  scrollObj: ScrollObject | null;
}

export default function OtherContent(props: any) {
  const { nextBoard, prevBoard, isFetched, title, otherContentPageToTop } = props;

  const location = useLocation();
  const leftDirectionRef = useRef(null);
  const rightDirectionRef = useRef(null);
  const containerRef = useRef(null);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const contentsRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const interactionItems = useRef<ScrollObjectType>({
    observer: null,
    scrollObj: null,
  });

  useEffect(() => {
    if (contentsRef.current) {
      interactionItems.current.observer = new Observer();
      interactionItems.current.observer.add(contentsRef.current);
      interactionItems.current.scrollObj = new ScrollObject(contentsRef.current);
    }

    return () => {
      if (contentsRef.current) {
        interactionItems.current.observer.remove(contentsRef.current);
        interactionItems.current.observer.kill();
        interactionItems.current.scrollObj.kill();
      }
    };
  }, [contentsRef]);
  const [pageY, setPageY] = useRecoilState(pageYState);

  return (
    <>
      <div className="other-content-box" css={style.content} ref={containerRef}>
        <h3 className="other-title">{title}</h3>
        <ul
          className={
            nextBoard === null || prevBoard === null ? "other-content-list single-content" : "other-content-list"
          }
          ref={contentsRef}>
          {nextBoard && (
            <li className="other-content-item prev" ref={leftDirectionRef} onClick={otherContentPageToTop}>
              <Link to={`/${location.pathname?.split("/")?.[1]}/${nextBoard?.seq}${location.search}`}>
                <img src={getImagePath(nextBoard?.boardImageList?.[0]?.attachImage)} alt={nextBoard?.ttl} />
                <span>{nextBoard?.ttl}</span>
              </Link>
            </li>
          )}
          {prevBoard && (
            <li className="other-content-item next" ref={rightDirectionRef} onClick={otherContentPageToTop}>
              <Link to={`/${location.pathname?.split("/")?.[1]}/${prevBoard?.seq}${location.search}`}>
                <img src={getImagePath(prevBoard?.boardImageList?.[0]?.attachImage)} alt={prevBoard?.ttl} />
                <span>{prevBoard?.ttl}</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

const style = {
  content: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .other-title {
      ${globalCSS.en};
      font-weight: 700;
      font-size: ${theme.typography.pxToRem(24)};
      line-height: 1;
      color: ${theme.palette.white};
      ${lsToPx(24, -2)};
      margin-bottom: ${theme.typography.pxToRem(62)};
      text-transform: uppercase !important;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        font-size: ${theme.typography.pxToRem(16)};
        margin-bottom: ${theme.typography.pxToRem(29.59)};
      }
    }

    .other-content-list {
      position: relative;
      width: ${theme.typography.pxToRem(700)};
      height: ${theme.typography.pxToRem(300)};
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      &.single-content {
        width: ${theme.typography.pxToRem(300)};
        height: ${theme.typography.pxToRem(300)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: ${theme.typography.pxToRem(143.57)};
          height: ${theme.typography.pxToRem(143.57)};
        }

        .other-content-item {
          position: relative;
          left: auto;
          top: auto;
          transform: none;
          opacity: 1;
          transition: none;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: 100%;
            height: 100%;
          }
        }
      }

      &.in-view:not(.single-content) {
        .other-content-item {
          opacity: 1;
          &.prev {
            left: 50%;
            left: 0;
            transform: translate(0, -50%);
          }
          &.next {
            left: 50%;
            left: auto;
            right: 0;
            transform: translate(0, -50%);
          }
        }
      }

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: 100%;
      }

      .other-content-item {
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        transform: translate(-50%, -50%);
        width: ${theme.typography.pxToRem(300)};
        height: ${theme.typography.pxToRem(300)};
        border-radius: 50%;
        overflow: hidden;
        transition: all 0.7s 0.4s, opacity 1s 0.3s;
        opacity: 0;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          /* width: ${theme.typography.pxToRem(143.57)}; */
          width: calc(50% - 12px);
          aspect-ratio: 1 / 1;
          height: auto;
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          &::after {
            content: "";
            width: 100%;
            height: 50%;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
            z-index: 1;
          }
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }

        span {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: ${theme.typography.pxToRem(21.16)};
          font-weight: 400;
          font-size: ${theme.typography.pxToRem(16)};
          line-height: 1;
          color: ${theme.palette.white};
          text-align: center;
          z-index: 2;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            font-size: ${theme.typography.pxToRem(14)};
            line-height: ${theme.typography.pxToRem(24)};
          }
        }
      }
    }
  `,
};
