import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import InternationalInfo from "@/components/international/international-info";
import InternationalParter from "@/components/international/international-partner";
import InternationalVisual from "@/components/international/international-visual";
import FlexsibleLink from "@/components/ui/flexible-link";
import { lenisScrollState } from "@/state";
import { css } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";

export default function International() {
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const circleRef = useRef(null);
  const circleCanvasRef = useRef(null);
  const backgroundRef = useRef(null);
  const visualRef = useRef(null);
  const infoRef = useRef(null);

  const linkBoxRef = useRef(null);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {}, []);

  const colors = [
    {
      r: 217,
      g: 212,
      b: 0,
      a: 1,
    },
    {
      r: 16,
      g: 17,
      b: 221,
      a: 1,
    },

    {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
  ];

  function changeBackground(prev, current, scrollY, start, end) {
    const value = prev - ((prev - current) * (scrollY - start)) / end;
    return Math.round(value);
  }

  useEffect(() => {
    backgroundRef.current.style.backgroundColor = `rgb(217, 212, 0, 1)`;
  }, []);

  useEffect(() => {
    const infoMarginTop = Number(getComputedStyle(infoRef.current).marginTop.replace("px", ""));

    const startBlue = visualRef.current.clientHeight / 2;
    const endBlue = visualRef.current.clientHeight;
    const startWhite = visualRef.current.clientHeight + infoRef.current.clientHeight + infoMarginTop;
    const endWhite = visualRef.current.clientHeight + infoRef.current.clientHeight + infoMarginTop * 1.5;

    if (lenisState.scroll < startBlue) {
      backgroundRef.current.style.backgroundColor = `rgb(${colors[0].r}, ${colors[0].g}, ${colors[0].b})`;
    }

    if (lenisState.scroll >= startBlue - 200 && lenisState.scroll <= endBlue) {
      let r = changeBackground(colors[0].r, colors[1].r, lenisState.scroll, startBlue, endBlue - startBlue);
      let g = changeBackground(colors[0].g, colors[1].g, lenisState.scroll, startBlue, endBlue - startBlue);
      let b = changeBackground(colors[0].b, colors[1].b, lenisState.scroll, startBlue, endBlue - startBlue);
      let circleScale = 1.5 - ((1.5 - 2) * (lenisState.scroll - startBlue)) / endBlue;

      let circleOpacity = 0.25 - ((0.25 - -2) * (lenisState.scroll - startBlue)) / endBlue;

      if (b > 0) {
        backgroundRef.current.style.backgroundColor = `rgb(${r}, ${g}, ${b})`;
        circleCanvasRef.current.style.transform = `scale(${circleScale})`;
        circleCanvasRef.current.style.opacity = `${circleOpacity}`;
      }
    }

    if (lenisState.scroll >= startWhite && lenisState.scroll <= endWhite) {
      let r = changeBackground(colors[1].r, colors[2].r, lenisState.scroll, startWhite, endWhite - startWhite);
      let g = changeBackground(colors[1].g, colors[2].g, lenisState.scroll, startWhite, endWhite - startWhite);
      let b = changeBackground(colors[1].b, colors[2].b, lenisState.scroll, startWhite, endWhite - startWhite);
      backgroundRef.current.style.backgroundColor = `rgb(${r}, ${g}, ${b})`;
    }

    if (lenisState.scroll > endWhite && lenisState.progress !== 0) {
      backgroundRef.current.style.backgroundColor = `rgb(${colors[2].r}, ${colors[2].g}, ${colors[2].b})`;
      linkBoxRef.current.style.opacity = "0";
      linkBoxRef.current.style.visibility = "hidden";
    } else {
      linkBoxRef.current.style.opacity = "1";
      linkBoxRef.current.style.visibility = "visible";
    }
  }, [lenisState]);

  /*  */
  useEffect(() => {
    document.querySelector("main").style.backgroundColor = "rgba(217, 212, 0, 1)";
    backgroundRef.current.style.backgroundColor = "rgba(217, 212, 0, 1)";
    return () => {
      document.querySelector("main").style.backgroundColor = "rgba(0, 0, 0, 1)";
    };
  }, []);

  return (
    <>
      <div className="international-bg" css={[style.international, { backgroundColor: "red" }]} ref={backgroundRef}>
        <canvas ref={circleCanvasRef} css={globalCSS.circleCanvas}></canvas>

        <InternationalVisual ref={visualRef} />
        <div className="link-box" ref={linkBoxRef}>
          <FlexsibleLink to="/scheduler">Scheduler</FlexsibleLink>
          <FlexsibleLink to="/notice">Noitce</FlexsibleLink>
        </div>
        <InternationalInfo ref={infoRef} />
        <InternationalParter />
      </div>
    </>
  );
}

const style = {
  international: css`
    width: 100%;
    background-color: rgba(217, 212, 0, 1);
    .link-box {
      position: sticky;
      left: 0;
      top: calc(100vh - ${theme.typography.pxToRem(120)});
      padding: 0 ${theme.typography.pxToRem(40)};
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: ${theme.typography.pxToRem(40)};
      z-index: 2;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        padding: 0 ${theme.typography.pxToRem(20)};
        gap: ${theme.typography.pxToRem(15)};
        top: calc(100vh - ${theme.typography.pxToRem(60)});
      }

      .more-button-wrap {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }
  `,
};
