import UA from "@/assets/ts/bauerlab/ua";
import { gui } from "./GUI";

export default class CircleMask {
  container: HTMLElement;
  canvasTarget: HTMLCanvasElement;
  cvs: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;

  canvasScale: number = 2;

  stageSize: any = { width: 0, height: 0, cx: 0, cy: 0, diagonal: 0 };
  progress: number = 0;
  rad: number = Math.PI / 180;
  color: string = "#131CDE";
  invert: boolean = false;

  constructor(container, color = "#131CDE") {
    this.container = container;
    this.cvs = document.createElement("canvas");
    this.ctx = this.cvs.getContext("2d");
    this.container.append(this.cvs);
    this.color = color;

    this.setGui();

    this.onResize = this.onResize.bind(this);
    window.addEventListener("resize", this.onResize);

    this.resize();
  }

  kill() {
    window.removeEventListener("resize", this.onResize);
  }

  setGui() {
    let g = gui.addFolder("circleMask");
    g.close();
    g.add(this, "progress", 0, 1, 0.01)
      .listen()
      .onChange(() => {
        this.draw();
      });
    g.add(this, "invert").onChange(() => {
      this.draw();
    });
    g.addColor(this, "color").onChange(() => {
      this.draw();
    });
  }

  onResize() {
    if (!UA.isPC) {
      if (this.stageSize.width == window.innerWidth) {
        return;
      }
    }
    this.resize();
  }

  resize() {
    this.defineSize();
    this.cvs.width = this.stageSize.width * this.canvasScale;
    this.cvs.height = this.stageSize.height * this.canvasScale;

    this.cvs.style.width = this.stageSize.width + "px";
    this.cvs.style.height = this.stageSize.height + "px";

    this.ctx.scale(this.canvasScale, this.canvasScale);
    this.draw();
  }

  defineSize() {
    let containerRect = this.container.getBoundingClientRect();
    this.stageSize.width = this.container.clientWidth;
    this.stageSize.height = this.container.clientHeight;

    this.stageSize.cx = this.stageSize.width * 0.5;
    this.stageSize.cy = this.stageSize.height * 0.5;
    this.stageSize.diagonal = Math.sqrt(
      Math.pow(this.stageSize.width, 2) + Math.pow(this.stageSize.height, 2)
    );
    // this.stageSize.width = containerRect.width;
    // this.stageSize.height = containerRect.height;
  }

  draw(progress = this.progress) {
    this.progress = progress;
    this.ctx.clearRect(0, 0, this.cvs.width, this.cvs.height);

    this.ctx.fillStyle = this.color;
    if (this.invert) {
      this.ctx.fillRect(0, 0, this.cvs.width, this.cvs.height);
      this.ctx.save();
      this.ctx.globalCompositeOperation = "destination-out";
      this.ctx.beginPath();
      this.ctx.arc(
        this.stageSize.cx,
        this.stageSize.cy,
        this.stageSize.diagonal * 0.5 * this.progress,
        0,
        Math.PI * 2
      );
      this.ctx.fill();
      this.ctx.restore();
    } else {
      this.ctx.save();
      // this.ctx.globalCompositeOperation = "destination-out";
      this.ctx.beginPath();
      this.ctx.arc(
        this.stageSize.cx,
        this.stageSize.cy,
        this.stageSize.diagonal * 0.5 * this.progress,
        0,
        Math.PI * 2
      );
      this.ctx.fill();
      this.ctx.restore();
    }
  }
}
