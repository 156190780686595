import {
  Clock,
  Color,
  OrthographicCamera,
  PerspectiveCamera,
  Scene,
  Vector2,
  VideoTexture,
  WebGLRenderer,
} from "three";
import UA from "./ua";

import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { gui } from "./GUI";
import { LastScreenShader } from "./shaders/lastScreenShader";
import { OrbitControls } from "./util/OrbitControls";

export type stageSizeType = {
  width: number;
  height: number;
  aspect: number;
  marginHeight: number;
};

let stageSize: stageSizeType = {
  width: 0,
  height: 0,
  aspect: 0,
  marginHeight: 0,
};

let mouse = {
  x: 0,
  y: 0,
  cx: 0,
  cy: 0,
  _cx: 0,
  _cy: 0,
  rx: 0,
  ry: 0,
  _rx: 0,
  _ry: 0,
};

let screenFitZ: number = 0;

let clock: Clock = new Clock();
let renderer: WebGLRenderer;
let camera: PerspectiveCamera;
let orthoCamera;

let renderCamera: PerspectiveCamera | OrthographicCamera;
let renderScene: Scene;

let changeSceneCamera = function (
  scene: Scene,
  camera: PerspectiveCamera | OrthographicCamera
) {
  renderScene = scene;
  renderCamera = camera;
};

let orbitCtrl: OrbitControls;
function setOrbitControl(camera, renderer) {
  if (orbitCtrl) orbitCtrl.dispose();
  orbitCtrl = new OrbitControls(camera, renderer.domElement);
  // orbitCtrl = new OrbitControls(camera, document.body);
  orbitCtrl.enabled = true;
  orbitCtrl.zoomSpeed = 0.3;
  orbitCtrl.enableDamping = true;
  orbitCtrl.target.set(0, 0.35, 0);
  orbitCtrl.update();
}
export {
  stageSize,
  clock,
  renderer,
  orthoCamera,
  screenFitZ,
  camera,
  renderCamera,
  renderScene,
  orbitCtrl,
  mouse,
  setOrbitControl,
  changeSceneCamera,
};

export default class BaseWebGL {
  renderList: any;
  resizeList: any;
  containerEl: HTMLElement;
  config: any;
  mouse: any;
  isRender: boolean = false;
  renderId: number;

  // renderScene: Scene;
  // renderCamera: PerspectiveCamera | OrthographicCamera;

  scene: Scene;
  stage: stageSizeType;

  camera: PerspectiveCamera;
  orthoCamera: OrthographicCamera;
  renderer: WebGLRenderer;

  composer: EffectComposer;
  lastScreenShaderPass: ShaderPass;
  fxaaPass: ShaderPass;

  stats: any;
  statusDom: HTMLElement;
  statusContent: string = "";

  crtDrawSceneId: number = 0;
  nextDrawSceneId: number = -1;

  effectParams = {
    uProgress: 0,
    uOpacity: 1.0,
    uScale: 1,
  };

  screenFitZ: number = 0;

  clock: Clock;

  constructor(containerEl, option = {}) {
    this.clock = new Clock();
    this.renderList = {};
    this.resizeList = {};

    this.containerEl = containerEl;

    this.stage = {
      width: 1920,
      height: 1080,
      aspect: 4 / 3,
      marginHeight: 1080,
    };

    this.mouse = {
      x: 0,
      y: 0,
      cx: 0,
      cy: 0,
      _cx: 0,
      _cy: 0,
      rx: 0,
      ry: 0,
      _rx: 0,
      _ry: 0,
    };
    mouse = this.mouse;

    this.defineSize();

    this.config = {
      camera_fov: 45,
      camera_near: 0.1,
      camera_far: 10000,

      render_antialias: false,
      render_alpha: true,
      render_pixelRatio: UA.isMobile ? 1.5 : window.devicePixelRatio,

      render_clearColor: 0x000000,
      render_clearColorAlpha: 0,
      render_autoClearColor: true,
    };

    this.config = Object.assign(this.config, option);

    this.isRender = false;

    /*
			Scene, Camera, Renderer
		*/
    this.scene = new Scene();
    renderScene = this.scene;
    // this.scene.fog = new Fog(new Color(config.colors.gray), 1, 8500);

    this.camera = new PerspectiveCamera(
      this.config.camera_fov,
      this.stage.aspect,
      this.config.camera_near,
      this.config.camera_far
    );
    renderCamera = this.camera;

    this.renderer = new WebGLRenderer({
      antialias: this.config.render_antialias,
      alpha: true,
      // alpha: this.config.render_alpha,
      // stencil: false,
      // depth: false,
    });

    renderer = this.renderer;

    this.renderer.setClearColor(this.config.render_clearColor);
    this.renderer.setClearAlpha(this.config.render_clearColorAlpha);
    this.renderer.setPixelRatio(this.config.render_pixelRatio);
    this.renderer.setSize(this.stage.width, this.stage.height);
    this.render = this.render.bind(this);

    if (this.containerEl) this.containerEl.prepend(this.renderer.domElement);
    // this.setPostProcessing();

    /*
			Event , Stats, Helpers
		*/
    this.resizeHandler = this.resizeHandler.bind(this);
    window.addEventListener("resize", this.resizeHandler);

    // this.stats = statsMin();
    // document.body.append(this.stats.domElement);

    if (UA.isPC) {
      this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
      window.addEventListener("mousemove", this.mouseMoveHandler);
    }
  }

  setPostProcessing() {
    /*
            postprocessing
        */
    let video: HTMLVideoElement = document.getElementById(
      "intro"
    ) as HTMLVideoElement;
    let videoTexture = new VideoTexture(video);
    this.lastScreenShaderPass = new ShaderPass(LastScreenShader);
    this.lastScreenShaderPass.uniforms.tVideo.value = videoTexture;
    this.lastScreenShaderPass.uniforms.uResolution.value = new Vector2(
      this.stage.width,
      this.stage.height
    );

    this.fxaaPass = new ShaderPass(FXAAShader);

    this.fxaaPass.material.uniforms["resolution"].value.x =
      1 / (this.renderer.domElement.offsetWidth * window.devicePixelRatio);
    this.fxaaPass.material.uniforms["resolution"].value.y =
      1 / (this.renderer.domElement.offsetHeight * window.devicePixelRatio);

    this.scene.background = new Color(this.config.render_clearColor);
    const scenePass = new RenderPass(this.scene, this.camera);

    this.composer = new EffectComposer(this.renderer);

    this.composer.addPass(scenePass);
    this.composer.addPass(this.lastScreenShaderPass);
    // this.composer.addPass(this.fxaaPass);
  }

  setEffGui() {
    let g = gui.addFolder("effect").close();

    this.statusDom = document.createElement("div");
    this.statusDom.style.position = "fixed";
    this.statusDom.style.left = "0";
    this.statusDom.style.top = "50px";
    this.statusDom.style.padding = "5px";
    this.statusDom.style.fontSize = "10px";
    this.statusDom.style.backgroundColor = "#ffffff";
    this.statusDom.style.color = "#000";
    this.statusDom.style.zIndex = "900";
    this.statusDom.style.opacity = "0.5";
    // document.body.append(this.statusDom);
  }

  defineSize() {
    this.stage.width = this.containerEl.clientWidth;
    this.stage.height = this.containerEl.clientHeight;
    this.stage.aspect = this.stage.width / this.stage.height;
    this.stage.marginHeight =
      this.stage.height - document.documentElement.clientHeight;
    stageSize = this.stage;
    // if (this.scene) this.scene.position.y = this.stage.marginHeight;
  }

  resizeHandler() {
    this.resize();
  }

  mouseMoveHandler(e) {
    let _x = this.mouse.x;
    let _y = this.mouse.y;

    this.mouse.x = e.clientX;
    this.mouse.y = e.clientY;
    let widthHalf = window.innerWidth * 0.5;
    let heightHalf = window.innerHeight * 0.5;
    this.mouse.cx = this.mouse.x - widthHalf;
    this.mouse.cy = -(this.mouse.y - heightHalf);
    this.mouse.rx = (this.mouse.x - widthHalf) / widthHalf;
    this.mouse.ry = (this.mouse.y - heightHalf) / heightHalf;

    let dx = _x - this.mouse.x;
    let dy = _y - this.mouse.y;
    this.mouse.acc = Math.min(100, Math.sqrt(dx * dx + dy * dy)) / 100;
  }
  resize(force: boolean = false) {
    if (!force) {
      if (UA.isIOS && this.containerEl.clientWidth == this.stage.width) return;
    }

    this.defineSize();
    this.screenFitZ = this.computeZ(0);
    screenFitZ = this.screenFitZ;
    this.updateResizeList(this.stage);

    this.camera.position.z = this.screenFitZ;
    this.camera.aspect = this.stage.aspect;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.stage.width, this.stage.height);

    if (this.lastScreenShaderPass) {
      this.fxaaPass.material.uniforms["resolution"].value.x =
        1 / (this.renderer.domElement.offsetWidth * window.devicePixelRatio);
      this.fxaaPass.material.uniforms["resolution"].value.y =
        1 / (this.renderer.domElement.offsetHeight * window.devicePixelRatio);
      this.composer.setSize(this.stage.width, this.stage.height);

      this.lastScreenShaderPass.uniforms.uResolution.value = new Vector2(
        this.stage.width,
        this.stage.height
      );
    }
  }

  computeZ(
    targetZ,
    targetHeight1 = this.stage.height,
    targetHeight2 = this.stage.height
  ) {
    let vFOV = (this.camera.fov * Math.PI) / 180;
    let vHeightPartial = 2 * Math.tan(vFOV / 2);
    var p1 = targetHeight1 * this.stage.height;
    var p2 = targetZ * vHeightPartial;
    var p3 = targetHeight2 * vHeightPartial;
    var p4 = targetHeight2 * p2;
    var p5 = p1 + p4;
    var z = p5 / p3;
    return z;
  }

  computeByZ(targetZ) {
    let vFOV = (this.camera.fov * Math.PI) / 180;
    let vHeightPartial = 2 * Math.tan(vFOV / 2);
    return targetZ * vHeightPartial;
  }

  /*
		render list, resize list
	*/

  renderStart() {
    if (!this.isRender) {
      this.isRender = true;
      this.renderId = requestAnimationFrame(this.render);
    }
  }

  renderStop() {
    this.isRender = false;
    cancelAnimationFrame(this.renderId);
  }

  addResizeList(key, target) {
    this.resizeList[key] = target;
  }

  removeRenderList(key) {
    delete this.renderList[key];
  }

  removeResizeList(key) {
    delete this.resizeList[key];
  }

  updateResizeList(...arg: any) {
    for (let key in this.resizeList) {
      this.resizeList[key](...arg);
    }
  }

  updateDrawTexture(crtTexture, nextTexture, id = this.crtDrawSceneId) {
    this.lastScreenShaderPass.uniforms.tDiffuse2.value = crtTexture;
    this.lastScreenShaderPass.uniforms.tDiffuse3.value = nextTexture;
    this.crtDrawSceneId = id;
  }

  updateStatusContent(
    content: string = "",
    init: boolean = false,
    output: boolean = false
  ) {
    if (this.statusDom) {
      if (init) {
        this.statusContent = "";
      } else {
        this.statusContent += "<br / >" + content;
      }

      if (output) {
        this.statusDom.innerHTML = this.statusContent;
      }
    }
  }

  render() {
    this.renderer.render(renderScene, renderCamera);

    this.mouse._rx += (this.mouse.rx - this.mouse._rx) * 0.1;
    this.mouse._ry += (this.mouse.ry - this.mouse._ry) * 0.1;
    this.update();

    if (this.isRender) {
      this.renderId = requestAnimationFrame(this.render);
    }
  }

  update() {}

  kill() {
    window.removeEventListener("resize", this.resizeHandler);
    window.removeEventListener("mousemove", this.mouseMoveHandler);
  }
}
