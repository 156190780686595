import guideCSS from "@/assets/ts/guide-css";
import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";

export default function GuidePalette() {
  return (
    <div className="guide-container" css={[guideCSS.guideContainer, style.colors]}>
      <div className="guide-content">
        <div className="content-box">
          <div className="color-wrap">
            <div className="blue-box"></div>
            <div className="white-box"></div>
            <div className="black-box"></div>
            <div className="gray-box"></div>
            <div className="white-box"></div>
            <div className="white-box"></div>
            <div className="white-box"></div>
            <div className="white-box"></div>
            <div className="white-box"></div>
            <div className="yellow-box"></div>
            <div className="yg1-box"></div>
            <div className="yg2-box"></div>
            <div className="yg3-box"></div>
            <div className="ytxt-box"></div>
          </div>

          <div className="color-box">
            <h3 className="guide-content-title">Primary</h3>
            <ul className="color-list">
              <li className="blue">
                <span>bl.blue</span>
                <div></div>
              </li>
              <li className="black">
                <span>bl.blue</span>
                <div></div>
              </li>
            </ul>
          </div>
          <div className="color-box">
            <h3 className="guide-content-title">secondary</h3>
            <ul className="color-list">
              <li className="yellow">
                <span>bl.yellow</span>
                <div></div>
              </li>
              <li className="ytxt">
                <span>bl.y.txt</span>
                <div></div>
              </li>
              <li className="yg1">
                <span>bl.yg.1</span>
                <div></div>
              </li>
              <li className="yg2">
                <span>bl.yg.2</span>
                <div></div>
              </li>
              <li className="yg3">
                <span>bl.yg.3</span>
                <div></div>
              </li>
            </ul>
          </div>
          <div className="color-box">
            <h3 className="guide-content-title">grayscale</h3>
            <ul className="color-list">
              <li className="white">
                <span>bl.white</span>
                <div></div>
              </li>
              <li className="gray">
                <span>bl.gray</span>
                <div></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

const style = {
  colors: css`
    .content-box {
      padding-bottom: ${theme.typography.pxToRem(100)};
      .color-wrap {
        width: 100%;
        height: ${theme.typography.pxToRem(1000)};
        display: grid;
        grid-template-columns: calc(800 / 1920 * 100%) repeat(4, 173px) 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .blue-box {
          grid-row: span 2;
          background-color: ${theme.palette.blue};
        }

        .white-box {
          background-color: ${theme.palette.white};

          &:first-of-type {
            grid-row: span 2;
          }
        }

        .black-box {
          background-color: ${theme.palette.black};
          grid-column: span 2;
        }

        .gray-box {
          background-color: ${theme.palette.gray};
          height: 80%;
          grid-column: span 2;
        }

        .yellow-box {
          background-color: ${theme.palette.yellow};
        }

        .yg1-box {
          background-color: ${theme.palette.yg1};
        }

        .yg2-box {
          background-color: ${theme.palette.yg2};
        }
        .yg3-box {
          background-color: ${theme.palette.yg3};
        }
        .ytxt-box {
          background-color: ${theme.palette.yTxt};
        }
      }

      .color-box {
        margin-top: ${theme.typography.pxToRem(600)};

        h3 {
          font-weight: 400;
          font-size: ${theme.typography.pxToRem(200)};
          letter-spacing: -12px;
          color: ${theme.palette.black};
          text-transform: uppercase !important;
          text-align: center;
        }

        .color-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: ${theme.typography.pxToRem(100)};

          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            span {
              display: block;
              margin-bottom: ${theme.typography.pxToRem(30)};
              font-weight: 400;
              font-size: ${theme.typography.pxToRem(30)};
              letter-spacing: -1.6px;
              text-transform: uppercase !important;
            }

            div {
              width: 200px;
              height: 200px;
            }

            + li {
              margin-top: ${theme.typography.pxToRem(60)};
            }

            &.blue {
              span {
                color: ${theme.palette.blue};
              }

              div {
                background-color: ${theme.palette.blue};
              }
            }

            &.black {
              span {
                color: ${theme.palette.black};
              }

              div {
                background-color: ${theme.palette.black};
              }
            }

            &.yellow {
              span {
                color: ${theme.palette.yellow};
              }

              div {
                background-color: ${theme.palette.yellow};
              }
            }

            &.ytxt {
              span {
                color: ${theme.palette.yTxt};
              }

              div {
                background-color: ${theme.palette.yTxt};
              }
            }

            &.yg1 {
              span {
                color: ${theme.palette.yg1};
              }

              div {
                background-color: ${theme.palette.yg1};
              }
            }

            &.yg2 {
              span {
                color: ${theme.palette.yg2};
              }

              div {
                background-color: ${theme.palette.yg2};
              }
            }

            &.yg3 {
              span {
                color: ${theme.palette.yg3};
              }

              div {
                background-color: ${theme.palette.yg3};
              }
            }

            &.white {
              span {
                color: ${theme.palette.black};
              }

              div {
                background-color: ${theme.palette.white};
                border: 1px solid ${theme.palette.gray};
              }
            }

            &.gray {
              span {
                color: ${theme.palette.gray};
              }

              div {
                background-color: ${theme.palette.gray};
              }
            }
          }
        }
      }
    }
  `,
};
