import axios from "axios";
import { getPaginatedCreationListParamsType } from "@/types/creation";

/**
 * 크리에이션 페이징 목록 조회
 */
export const getPaginatedCreationListAPI = async (
  params: getPaginatedCreationListParamsType | any
) => {
  return await axios.get("/api/cs/article/page-list", { params });
};

/**
 * 크리에이션 상세 조회
 */
export const getCreationDetailAPI = async (seq: number, params: any) => {
  return await axios.get(`/api/cs/article/get/${seq}`, { params });
};

/**
 * 크리에이션 타입 조회
 */
export const getCreationTypeAPI = async () => {
  return await axios.get("/api/cs/article/get-type");
};

/**
 * 크리에이션 카테고리 갯수 조회
 */
export const getCreationCountAPI = async (params: any) => {
  console.log("getCreationCountAPI", params);
  return await axios.get("api/main/main/main", { params });
};
