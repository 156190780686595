import { formatRangeHyphenYmd } from "@/assets/ts/format";
import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import styled from "@emotion/styled";
import { AnimateSharedLayout, motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

const SchedulerList = styled(motion.ul)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: ${theme.typography.pxToRem(100)};
  column-gap: ${theme.typography.pxToRem(20)};
  margin-bottom: ${theme.typography.pxToRem(154)};
`;

export default SchedulerList;
