import { Link } from "react-router-dom";
import { DetailDesc } from "../posts/post";

export function DescFormat({ title, desc }: { title: string; desc: string }) {
  return (
    <DetailDesc>
      <dt>{title}</dt>
      <dd>{desc}</dd>
    </DetailDesc>
  );
}

export function DescFormatLink({ title, data }: { title: string; data: any }) {
  function isValidUrl(string) {
    var RegExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    if (RegExp.test(string)) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <DetailDesc>
      {isValidUrl(data?.prvncUrl) && (
        <>
          <dt>{title}</dt>
          <dd>
            <a
              href={data?.prvncUrl}
              target="_blank">
              {data?.prvncDtl}
            </a>
          </dd>
        </>
      )}
    </DetailDesc>
  );
}
