import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { lenisScrollState } from "@/state";
import { css } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import FadeText from "../animation/fade-texts";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import { clamp } from "@/assets/ts/bauerlab/util/utils";

const partners = [
  {
    img: "/images/image/international/img-partner1.png",
    ttl: "The mill",
    width: 166.73,
    widthMo: 83.37,
    height: 141.82,
    delay: Math.random() * (1 - 0.5) + 0.5,
  },
  {
    img: "/images/image/international/img-partner2.png",
    ttl: "brogent",
    width: 294.54,
    widthMo: 147.27,

    height: 51.39,
    delay: Math.random() * (1 - 0.5) + 0.5,
  },

  {
    img: "/images/image/international/img-partner4.png",
    ttl: "jason bruges stuido",
    width: 294.11,
    widthMo: 147.05,

    height: 120,
    delay: Math.random() * (1 - 0.5) + 0.5,
  },
];

interface ScrollObjectType {
  observer: Observer | null;
  scrollObj: ScrollObject | null;
}

const InternationalParter = () => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const [textProgress, setTextProgress] = useState(0);
  const [progress, setProgress] = useState(0);
  const wrapperRef = useRef(null);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const interactionItems = useRef<any>({
    scrollObjects: {},
  });
  const interactionObserverItems = useRef<ScrollObjectType>({
    observer: null,
    scrollObj: null,
  });

  useEffect(() => {
    interactionItems.current.scrollObjects["title"] = new ScrollObject(wrapperRef.current);
  }, []);

  useEffect(() => {
    for (let key in interactionItems.current.scrollObjects) {
      interactionItems.current.scrollObjects[key].update(lenisState.scroll + 250);
    }
    let titleObj = interactionItems.current.scrollObjects["title"];
    setTextProgress(titleObj.ratio.yClamp);

    if (interactionObserverItems.current.scrollObj) {
      let scrollObj = interactionObserverItems.current.scrollObj;
      scrollObj.update(lenisState.scroll);

      let progress = scrollObj.ratio.y + 0.5;
      if (progress > 1) progress = 1;
      setProgress((prev) => {
        if (typeof progress !== "undefined") {
          return prev + (progress - prev) * 0.2;
        }
        return prev;
      });
    }

    return () => {
      for (let key in interactionItems.current.scrollObjects) {
        interactionItems.current.scrollObjects[key].kill();
      }
    };
  }, [lenisState]);

  useEffect(() => {
    if (wrapperRef.current) {
      interactionObserverItems.current.observer = new Observer();
      interactionObserverItems.current.observer.add(wrapperRef.current);
      interactionObserverItems.current.scrollObj = new ScrollObject(wrapperRef.current);
    }

    return () => {
      if (wrapperRef.current) {
        interactionObserverItems.current.observer.remove(wrapperRef.current);
        interactionObserverItems.current.observer.kill();
        interactionObserverItems.current.scrollObj.kill();
      }
    };
  }, [wrapperRef]);

  return (
    <section className="sc-partenr" css={style.partner} ref={wrapperRef}>
      <div className="text-box">
        <h2 className="sc-title">
          <FadeText texts={["Trusted by the world’s <br/> largest companies"]} progress={textProgress} />
        </h2>

        <p>
          Bauer Lab has cultivated robust collaborative alliances with
          <br />
          prominent international enterprises and design ateliers, acting
          <br />
          as a conduit to mitigate cultural disparities and enhance
          <br />
          communication within the domestic market.
        </p>
      </div>
      <div className="partner-box">
        {partners.map((partner, index) => (
          <div className={`img-box img-${index + 1}`} key={index}>
            <img
              src={partner.img}
              alt={partner.ttl}
              width={!isMobile ? partner.width : partner.widthMo}
              style={{ transitionDelay: `${partner.delay}s` }}
              css={{ aspectRatio: `${partner.width} / ${partner.height}` }}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

const style = {
  partner: css`
    width: 100%;
    padding: 0 ${theme.typography.pxToRem(240)} ${theme.typography.pxToRem(285)} ${theme.typography.pxToRem(100)};
    margin-top: ${theme.typography.pxToRem(800)};
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: column;
      justify-content: center;
      margin-top: ${theme.typography.pxToRem(600)};
      padding: 0 ${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(150)} ${theme.typography.pxToRem(22)};
    }

    .text-box {
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-bottom: ${theme.typography.pxToRem(80)};
      }
      h2 {
        ${globalCSS.en};
        font-weight: 700;
        font-size: ${theme.typography.pxToRem(26)};
        line-height: 1;
        color: ${theme.palette.blue};
        margin-bottom: ${theme.typography.pxToRem(48)};
        text-transform: uppercase;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          text-align: center;
          font-size: calc(22 / 375 * 100vw);
          line-height: calc(26 / 24);
          margin-bottom: ${theme.typography.pxToRem(20)};
        }
      }

      p {
        ${globalCSS.en};
        font-weight: 400;
        font-size: ${theme.typography.pxToRem(27)};
        line-height: ${theme.typography.pxToRem(32)};
        color: ${theme.palette.blue};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          text-align: center;
          font-size: calc(16 / 375 * 100vw);
          line-height: calc(20 / 16);
        }
      }
    }

    .partner-box {
      display: flex;
      align-items: center;
      row-gap: ${theme.typography.pxToRem(100)};
      width: ${theme.typography.pxToRem(600)};
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: 100%;
        row-gap: ${theme.typography.pxToRem(47.45)};
      }
      .img-box {
        position: relative;
        flex: 1 0 50%;

        img {
          opacity: 0;
          transform: translateY(15px);
          transition: all;
          transition-duration: 0.3s;
          transition-timing-function: cubic-bezier(0.43, 0.05, 0.17, 1);
        }

        &:last-of-type {
          flex: 1 0 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: flex;
          justify-content: center;
          img {
            text-align: center;
          }
        }
      }
    }

    &.in-view {
      .partner-box {
        img {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  `,
};

export default InternationalParter;
