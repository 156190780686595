import deepdash from "deepdash";
import lodash from "lodash";
import { creation } from "@/utils/ineraction/creation";
import { useRecoilState } from "recoil";
import {
  getCreationTypeAPI,
  getPaginatedCreationListAPI,
  getCreationDetailAPI,
  getCreationCountAPI,
} from "@/api/creation";
import { useLocation, useSearchParams, useParams } from "react-router-dom";
import {
  getCreationDetailParamsType,
  getPaginatedCreationListParamsType,
} from "@/types/creation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useQueries, useQuery } from "react-query";
import { useLanguage } from "./use-language";
import { creationListParamState } from "@/state/creation";

const _ = deepdash(lodash);

export function useCreationManager() {
  const [creationTypeList, setCreationTypeList] = useState({});

  const [creationList, setCreationList] = useState();
  const [currentPage, setCurrentPage] = useState(-1);
  const languageValue = useLanguage();

  const [searchParams, setSearchParams] = useSearchParams();

  const [creationListParamsForm, setCreationListParamsForm] =
    useState<getPaginatedCreationListParamsType>({
      pg: 1,
      pgSz: 15,
      sordFld: "rgstYmd",
      sordMetd: "DESC",
      schTp: searchParams.get("schTp") || "",
      schLangTp: languageValue,
    });

  const creationDetailParams = {
    schTp: creationListParamsForm.schTp,
    schLangTp: creationListParamsForm.schLangTp,
  } as getCreationDetailParamsType;

  const {
    data: creationData,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ["creationListQuery", creationListParamsForm, languageValue, searchParams],
    ({ pageParam = 1 }) =>
      getPaginatedCreationListAPI({
        ...creationListParamsForm,
        pg: pageParam,
        schTp: searchParams.get("schTp") || "",
      }),

    {
      getNextPageParam: (_lastPage, pages) => {
        const totalSize = _lastPage.data.data.articleList.totalSize;
        const TotalPage = _lastPage.data.data.articleList.totalPageSize;

        // console.log("_lastPage", _lastPage.data.data.articleList);
        if (TotalPage > _lastPage.data.data.articleList.page) {
          return pages.length + 1;
        } else if (_lastPage.data.data.articleList.page == TotalPage) {
          if (_lastPage.data.data.articleList.page !== 1) {
            if (_lastPage.data.data.articleList.list?.length > 10) {
              return pages.length + 1;
            }
          }
        } else if (TotalPage == pages.length) {
          return undefined;
        } else if (16 > totalSize) {
          return undefined;
        } else return undefined;
      },
      refetchOnWindowFocus: false,
      enabled: !!creationListParamsForm || !!searchParams,
    }
  );

  const [remainingItems, setRemainingItems] = useState([]);

  useEffect(() => {
    const creationDataList = creationData?.pages.flatMap((group, i) => {
      const list = group?.data?.data?.articleList?.list || [];

      if (i === 0) {
        // 첫 번째 호출: 모든 데이터를 표시
        return [{ type: "firstList", items: list }];
      } else {
        // 이후 호출: 새로운 데이터 중 10개를 표시하고 나머지를 저장
        const newItemsToShow = remainingItems.concat(
          list.slice(0, 10 - remainingItems.length)
        );
        const newRemainingItems = list.slice(10 - remainingItems.length);

        setRemainingItems(newRemainingItems);
        return [{ type: "regularList", items: newItemsToShow }];
      }
    });

    setCreationList(creationDataList);
  }, [creationData]);

  useEffect(() => {
    setCurrentPage(-1);
    setCreationListParamsForm((prev) => {
      return {
        ...prev,
        pg: 1,
        schLangTp: languageValue,
      };
    });
    refetch();
  }, [creationListParamsForm.schTp, languageValue, searchParams]);
  const CreationTypedata = useQueries([
    {
      queryKey: ["getCreationType", languageValue],
      queryFn: getCreationTypeAPI,
      select: (data) => {
        const typeList = Object.entries(data.data.data.ArticleType);
        const result = typeList.map((el) => {
          return {
            nm: el[1],
            cd: el[0],
          };
        });
        result.unshift({ nm: "ALL", cd: "" });

        return result;
      },
      onError: (error) => {
        console.log("getCreationType - error", error);
      },
      refetchOnWindowFocus: false,
      enabled: !!languageValue,
    },
    {
      queryKey: ["getCreationCount", languageValue],
      queryFn: () => getCreationCountAPI({ schLangTp: languageValue }),
      select: (data) => {
        const typeList = Object.entries(data.data.data);
        const result = typeList.map((el) => {
          return {
            nm: el[0],
            count: el[1],
          };
        });
        // console.log("getCreationCount", result);
        return result;
      },
      onError: (error) => {
        // console.log("getCreationCount", error);
      },
      refetchOnWindowFocus: false,
      enabled: !!languageValue,
    },
  ]);
  const fetchFinishAll = CreationTypedata.some((res) => res.isLoading);

  useEffect(() => {
    if (!fetchFinishAll) {
      const mergedData = _.mergeWith(
        _.keyBy(CreationTypedata[0].data, "nm"),
        _.keyBy(CreationTypedata[1].data, "nm"),
        (objValue, srcValue) => {
          // nm이 같은 경우 값을 합치기
          if (_.isEqual(objValue, srcValue)) {
            return objValue;
          } else {
            return _.merge({}, objValue, srcValue);
          }
        }
      );

      const creationMergeData = _.values(mergedData);

      creationMergeData[0].count = _.sumBy(creationMergeData, "count");
      return setCreationTypeList(creationMergeData);
    }
  }, [fetchFinishAll]);

  return {
    creationList,
    creationTypeList,
    fetchFinishAll,
    fetchNextPage,
    creationDetailParams,
    creationListParamsForm,
    setCreationListParamsForm,
    hasNextPage,
    currentPage,
    setCurrentPage,
    setSearchParams,
    searchParams,
  };
}

//////////////////////////////////////////////////////////////////////////////////////////////

export function useCreationDetailManager() {
  const location = useLocation();
  const { seq } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [creationDetailData, setCreationDetailData] = useState();
  const [creationDetailParamsForm, setCreationDetailParamsForm] =
    useState<getCreationDetailParamsType>({
      seq: seq,
      schTp: searchParams.get("schTp"),
      schLangTp: "1",
    });
  const langTp = useLanguage();

  // 크리에이션 상세 조회
  const { data: creationDetail, isFetched } = useQuery<any>(
    ["creationDetail", creationDetailParamsForm, seq, useLanguage()],
    () =>
      getCreationDetailAPI(seq, {
        schTp: searchParams.get("schTp"),
        schLangTp: langTp,
      }),
    {
      onSuccess: (data) => {
        console.log("creationDetailData - data", data);
        try {
          setCreationDetailData(data.data.data);
        } catch {
          console.warn("data does not exist");
        }
      },
      onError: (error) => {
        console.log("creationDetailData - error", error);
      },
      enabled: !!creationDetailParamsForm.seq,
      refetchOnWindowFocus: false,
    }
  );

  return {
    creationDetailData,
    setCreationDetailParamsForm,
    isFetched,
  };
}
