import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import { createRef, forwardRef, useEffect, useRef } from "react";
import globalCSS from "@/assets/ts/global-css";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import { lenisScrollState } from "@/state";
import { useRecoilState } from "recoil";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import FadeTextSingleLine from "@/components/fadeTextSingleLine";
import { useLanguage } from "@/hooks/use-language";
import { useMediaQuery } from "@mui/material";
import RollingText from "@/components/rolling-text";

const AboutBusiness = forwardRef<HTMLElement, {}>((props, ref) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const langTp = useLanguage();
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const scrollObjs = useRef({});
  const itemData = [
    {
      ttl: "Theatrical<br>Work",
      desc: {
        en: [
          "As one of the top Korean studios with expertise",
          "in both set and video design, we participate in",
          "more than 10 theatrical productions each year,",
          "ranging from musicals, plays, dance, opera, and",
          "Gugak(traditional Korean music). Our understanding",
          "of storytelling and emotional connection",
          "allows us to create new and innovative content",
          "for all areas of the performing arts.",
        ],
        kr: [
          "바우어랩은 국내 최고의 공연 무대,",
          "영상디자인 스튜디오입니다.",
          "연간 10편 이상의 프로덕션에 참여하고 있으며",
          "공연예술,연극, 뮤지컬, 무용, 오페라, 국악 등. ",
          "전 분야에 걸쳐  작품의 주제에 대한 깊이 있는",
          "해도를 바탕으로 다양하고 새로운 형식의",
          "디자인을 제안합니다.",
        ],
      },
    },
    {
      ttl: "Mega<br>Events",
      desc: {
        en: [
          "Bauer Lab excels in generating a myriad",
          "of creative outcomes through its extensive",
          "experience in mega events, festivals, and fairs.",
          "We design fresh and dynamic content",
          "by tailoring solutions specifically to the nature of",
          "each hosted event. Our commitment",
          "to consistently provide event-specific solutions",
          "and unveil attention-grabbing content plans",
          "has garnered us recognition time and time again.",
        ],
        kr: [
          "바우어랩은 다양한 형태의 이벤트, 페스티벌,",
          "페어 등의 메가 이벤트 경험을 통해",
          "다채로운 결과물들을 창출하고 있습니다.",
          "주최되는 행사의 성격에 최적화된 솔루션을 바탕으로",
          "신선하고 새로운 콘텐츠를 기획하며 행사 성격에",
          "최적화된 솔루션을 제공하며",
          "매번 새로운 콘텐츠 기획으로 주목받고 있습니다.",
        ],
      },
    },
    {
      ttl: "Immersive<br>Experience",
      desc: {
        en: [
          "We're currently in the process of developing an",
          "experiential business model that allows the audience",
          "to actively make choices, interact, and immerse",
          "themselves in the narrative. Our goal is to achieve",
          "optimal synergy by seamlessly blending media and",
          "physical spaces for a truly lifelike and engaging experience.",
        ],
        kr: [
          "스토리의 경험을 기반으로 한",
          "몰입형 체험 공간을 구축하여 관객이 직접 선택하고",
          "움직이고 경험해 보면서 구매할 수 있는",
          "체험형 비즈니스 모델을 개발 중에 있습니다.",
          "미디어와 공간이 만나",
          "더욱 실감나는 스토리를 체험하게 하여",
          "최상의 시너지를 발휘하고자 합니다.",
        ],
      },
    },
    {
      ttl: "Metaverse<br>Contents",
      desc: {
        en: [
          "Bauer Lab is on a path of steady growth fueled by",
          "ongoing research and development endeavors.",
          "With an eye on the future, we aspire to present new challenges",
          "in the realm of culture and arts.",
          "Our collective mission is to construct a platform tailored to the demands",
          "of the metaverse era, as we prepare for the opportunities that lie ahead.",
        ],
        kr: [
          "바우어랩은 꾸준한 연구와 개발을",
          "시도하여 성장하고 있습니다.",
          "다가올 미래를 준비하여",
          "문화 예술 분야에 새로운 도전과제를 제시하고",
          "함께 연대하여 메타버스 시대에 맞는",
          "플랫폼 구축을 목표로 하고 있습니다.",
        ],
      },
    },
  ];
  const itemListRef = useRef(null);
  const items = useRef(Array.from({ length: 4 }, () => createRef()));
  const itemsBorder = useRef(Array.from({ length: 4 }, () => createRef()));
  const itemsBlueLayer = useRef(Array.from({ length: 4 }, () => createRef()));
  const itemsText = useRef(Array.from({ length: 4 }, () => createRef()));
  const itemsDesc = useRef(Array.from({ length: 4 }, () => createRef()));
  const businessStickySpace = useRef(null);
  useEffect(() => {
    scrollObjs.current["sticky"] = new ScrollObject(businessStickySpace.current);
  }, []);

  useEffect(() => {
    let scroll = lenisState.scroll;
    let scrollObjects = scrollObjs.current;
    for (let key in scrollObjects) {
      scrollObjs.current[key].update(scroll);
    }

    let stickyScroll = scrollObjects["sticky"].ratio.inBottom;
    let p = clamp(stickyScroll);

    items.current.forEach((item, index) => {
      let el = item.current as HTMLElement;
      let x = el.offsetWidth * 0.5;
      let moveX = window.innerWidth;
      let progress = p;
      let rect = el.getBoundingClientRect();
      let itemP = (rect.left + rect.width) / (window.innerWidth + rect.width);
      itemP = (itemP - 0.5) / 0.5;
      let y = itemP * 100;

      x = (itemListRef.current.offsetWidth - window.innerWidth) * -progress;

      let scaleOffset = isMobile ? 0.2 : 0.4;
      el.style.transform = `translate3d(${x}px,${-y}px,0) scale(${1 - Math.abs(itemP) * scaleOffset})`;

      let borderEl = itemsBorder.current[index].current as HTMLElement;
      let borderP = Math.abs(itemP) / 0.5;
      borderEl.style.transform = `rotate(${itemP * 90}deg) scale(${1 + Math.abs(itemP) * 0.2})`;
      borderEl.style.opacity = `${1 - Math.abs(borderP)}`;

      let textEl = itemsText.current[index].current as HTMLElement;
      textEl.style.opacity = `${1 - Math.abs(borderP)}`;

      let blueLayer = itemsBlueLayer.current[index].current as HTMLElement;
      blueLayer.style.opacity = `${Math.abs(borderP)}`;

      let desc = itemsDesc.current[index].current as HTMLElement;
      desc.style.opacity = `${1 - Math.abs(borderP)}`;
    });
  }, [lenisState]);
  return (
    <section className="sc-business" id="business" ref={ref} css={style.business}>
      <div className="business-title">
        <RollingText align="center" title="BUSINESS AREA" />
      </div>
      <div css={style.businessContent}>
        <ul ref={itemListRef} className="img-list">
          {itemData.map((item, index) => (
            //@ts-ignore
            <li key={index} ref={items.current[index]}>
              <div className="img-wrap">
                <img src={`/images/image/about/business${index + 1}.png`} alt="item.ttl" />
                <div className="blue-layer" ref={itemsBlueLayer.current[index]}></div>
              </div>
              <img
                src="/images/image/main/circle-border.svg"
                className="border"
                alt=""
                ref={itemsBorder.current[index]}
              />
              <p ref={itemsText.current[index]} dangerouslySetInnerHTML={{ __html: item.ttl }}></p>
            </li>
          ))}
        </ul>
        <div className="text">
          <ul className="text-list">
            {itemData.map((item, index) => (
              <li key={index} ref={itemsDesc.current[index]}>
                {item.desc[langTp === "1" ? "kr" : "en"].map((desc, j) => (
                  <p key={j} lang={langTp === "1" ? "kr" : "en"}>
                    {desc}
                  </p>
                ))}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="businessStickySpace" ref={businessStickySpace}></div>
    </section>
  );
});

const style = {
  business: css`
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: 100vw;

    .businessStickySpace {
      height: 200vh;
    }

    .business-title {
      padding-top: 50vh;
    }

    h2 {
      font-size: ${theme.typography.pxToRem(120)};
      text-align: center;
      /* white-space: nowrap; */
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        font-size: calc(40 / 375 * 100vw) !important;
      }
    }
  `,

  businessContent: css`
    position: sticky;
    height: 100vh;
    width: 100%;
    top: 0;
    color: #fff;
    display: flex;
    margin-top: -25vh;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .img-list {
      display: inline-flex;
      gap: 5vw;
      width: min-content;
      padding: 5vh 100vw;
      /* transform: rotate(-10deg); */
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        gap: 0vw;
      }
      li {
        position: relative;
        aspect-ratio: 1/1;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: 60vh;
        }

        .img-wrap {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          aspect-ratio: 1/1;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(0.99);
          }
        }
        .blue-layer {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: red;
          border-radius: 50%;
          mix-blend-mode: lighten;
          z-index: 1;
          background: ${theme.palette.blue};
        }

        .border {
          position: absolute;
          left: -3%;
          top: -3%;
          width: 106%;
          height: 106%;
        }

        p {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          ${globalCSS.en};
          font-size: ${theme.typography.pxToRem(70)};
          font-size: calc(70 / 1920 * 100vw);
          letter-spacing: 0.02em;
          line-height: 1em;
          font-weight: bold;

          @media (max-width: ${theme.breakpoints.values.md}px) {
            /* font-size: calc(30 / 375 * 100vw); */
            font-size: ${theme.typography.pxToRem(30)};
          }
        }
      }
    }

    .text {
      margin: 0 auto;
      padding-top: 40px;
      display: flex;
      justify-content: center;
      width: 100%;

      .text-list {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          padding: 0 46px;
          height: 200px;
        }
        li {
          position: absolute;
          left: 50%;
          top: 0;
          width: max-content;
          opacity: 0;
          transform: translate(-50%, 0);
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            max-width: 100vw;
            padding: 0 20px;
            max-width: 560px;
            width: 100%;
          }
          p {
            font-size: ${theme.typography.pxToRem(20)};
            line-height: ${theme.typography.pxToRem(30)};
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              font-size: ${theme.typography.pxToRem(16)};
              line-height: ${theme.typography.pxToRem(20)};
            }
            @media (max-width: ${theme.breakpoints.values.md}px) {
              &:lang(en) {
                font-size: ${theme.typography.pxToRem(14)};
                line-height: ${theme.typography.pxToRem(20)};
              }
            }
          }
        }
      }
    }

    .img-list {
      height: 60vh;
      margin-top: 5vh;
      li,
      .img-wrap {
        width: 50vh;
        height: 50vh;
        aspect-ratio: 1/1;
      }

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        height: 40vh;
        margin-top: 10vh;
        li,
        img {
          width: 30vh;
          height: 30vh;
        }
      }
    }
    .text {
      height: 40vh;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        height: 50vh;
      }
    }
  `,
};

export default AboutBusiness;
