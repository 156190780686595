import Circle from "@/assets/ts/bauerlab/Circle";
import CircleSm from "@/assets/ts/bauerlab/CircleSm";
import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { useMediaQuery } from "@mui/material";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { XTSubmitButton } from "../ui/button";

export const Error404 = () => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const circleRef = useRef(null);
  const circleCanvasRef = useRef(null);
  useEffect(() => {
    if (!circleRef.current) {
      circleRef.current = new CircleSm(circleCanvasRef.current, "transparent");
    }
  }, [circleCanvasRef]);
  return (
    <>
      <main css={[globalCSS.error]}>
        <div className="error">
          <canvas ref={circleCanvasRef} css={globalCSS.circleCanvas}></canvas>

          {isMobile ? (
            <h2>
              페이지를
              <br />
              찾을 수 없습니다.
            </h2>
          ) : (
            <h2>페이지를 찾을 수 없습니다.</h2>
          )}
          <strong>404 error</strong>
          {isMobile ? (
            <p>
              페이지 주소가 변경되었거나,
              <br />
              사라진 페이지입니다.
              <br />
              주소를 다시 한번 확인해 주세요.
            </p>
          ) : (
            <p>
              페이지 주소가 변경되었거나, 사라진 페이지입니다.
              <br />
              주소를 다시 한번 확인해 주세요.
            </p>
          )}

          <Link to="/">
            <XTSubmitButton>바우어랩 메인으로</XTSubmitButton>
          </Link>
        </div>
      </main>
    </>
  );
};

export const Error500 = () => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const circleRef = useRef(null);
  const circleCanvasRef = useRef(null);
  useEffect(() => {
    if (!circleRef.current) {
      circleRef.current = new Circle(circleCanvasRef.current, "transparent");
    }
  }, [circleCanvasRef]);
  return (
    <>
      <main css={globalCSS.error}>
        <div className="error">
          <canvas ref={circleCanvasRef} css={globalCSS.circleCanvas}></canvas>
          {isMobile ? (
            <h2>
              서비스 접속이
              <br />
              원활하지 않습니다.
            </h2>
          ) : (
            <h2>서비스 접속이 원활하지 않습니다.</h2>
          )}

          <strong>500 error</strong>
          {isMobile ? (
            <p>
              일시적으로 서버에 이상이 생겨 서비스 사용이 <br />
              제한되었습니다. <br />
              이용에 불편을 드려 죄송합니다
              <br />
              잠시 후 다시 이용해 주세요.
            </p>
          ) : (
            <p>
              일시적으로 서버에 이상이 생겨 서비스 사용이 제한되었습니다.
              <br />
              이용에 불편을 드려 죄송합니다
              <br />
              잠시 후 다시 이용해 주세요.
            </p>
          )}

          <Link to="/">
            <XTSubmitButton>바우어랩 메인으로</XTSubmitButton>
          </Link>
        </div>
      </main>
    </>
  );
};
