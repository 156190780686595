import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { isDetailPageState, lenisScrollState } from "@/state";
import { postItemOpenState } from "@/state/post";
import { useMediaQuery } from "@mui/material";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { MenuButton } from "./menuButton";

export default function SubHeader({ lenis }) {
  // console.log(`🚀 ~ lenis:`, lenis);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const headerRef = useRef(null);

  useEffect(() => {
    let scroll = lenisState.scroll;
    let direction = lenisState.direction;

    if (lenisState.scroll >= 100) {
      transformSmallLogo();
    } else {
      transformBigLogo();
    }

    // if (lenisState.scroll < 100) {
    //   transformBigLogo();
    // } else {
    //   transformSmallLogo();
    // }

    // if (scroll > 100 && direction === 1) {
    //   transformSmallLogo();
    // } else if (scroll < 30) {
    //   transformBigLogo();
    // }
  }, [lenisState, isMobile]);

  useEffect(() => {
    if (!isMobile) return;
    if (lenisState.scroll > 100) {
      logoRef.current.style.transform = "translateY(-200%)";
      return;
    } else {
      logoRef.current.style.transform = "translateY(0%)";
    }
  }, [lenisState, isMobile]);

  useEffect(() => {
    let scroll = lenisState.scroll;
    let direction = lenisState.direction;
    let limit = lenisState.limit;
    const mainMarginBottom = Number(getComputedStyle(document.querySelector("main")).marginBottom.replace("px", ""));

    if (mainMarginBottom * 0.5 > limit - scroll) {
      if (limit === 0) {
        headerRef.current.style.transform = "translateY(0%)";
      } else {
        headerRef.current.style.transform = "translateY(-100%)";
      }
    } else {
      headerRef.current.style.transform = "translateY(0%)";
    }
  }, [lenisState]);

  const location = useLocation();
  const pathname = location.pathname;

  const logoRef = useRef(null);

  function transformSmallLogo() {
    if (pathname.includes("/notice") || pathname.includes("/news")) return;
    if (!logoRef.current) return;
    if (!isMobile) {
      logoRef.current.style.width = "3.125rem";
      logoRef.current.style.height = "3.125rem";
    } else {
      logoRef.current.style.width = "1.875rem";
      logoRef.current.style.height = "1.875rem";
    }
  }

  function transformBigLogo() {
    if (pathname.includes("/notice") || pathname.includes("/news")) return;
    if (!logoRef.current) return;
    if (!isMobile) {
      logoRef.current.style.width = "7.5rem";
      logoRef.current.style.height = "7.5rem";
    } else {
      logoRef.current.style.width = "1.875rem";
      logoRef.current.style.height = "1.875rem";
    }
  }

  return (
    <header
      id="sub-header"
      css={globalCSS.subHeader}
      className={pathname.includes("international") ? "blue" : ""}
      ref={headerRef}>
      <Link
        className="logo"
        to="/"
        onClick={() => {
          window.dispatchEvent(new Event("lenisTop"));
          window.dispatchEvent(new Event("lenisStart"));
          // window.location.href = "/";
        }}>
        <h1
          className={pathname.includes("/notice") || pathname.includes("/news") ? "sub-logo sm" : "sub-logo"}
          ref={logoRef}>
          <img
            src={pathname.includes("international") ? "/images/icon/logo-blue.svg" : "/images/icon/logo.svg"}
            alt="바우어랩"
          />
        </h1>
        {/* {pathname.includes("notice") || pathname.includes("news") ? (
          <h1
            className={pathname.includes("notice") || pathname.includes("news") ? "sub-logo sm" : "sub-logo"}
            ref={logoRef}>
            <img
              src={pathname.includes("international") ? "/images/icon/logo-blue.svg" : "/images/icon/logo.svg"}
              alt="바우어랩"
            />
          </h1>
        ) : (
          <h1 className="sub-logo" ref={logoRef}>
            <img
              src={pathname.includes("international") ? "/images/icon/logo-blue.svg" : "/images/icon/logo.svg"}
              alt="바우어랩"
            />
          </h1>
        )} */}
      </Link>

      <MenuButton />
    </header>
  );
}
