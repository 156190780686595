import BouerlabWebGL from "@/assets/ts/bauerlab/BouerlabWebGL";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

const useWebGL = () => {
  const [init, setInit] = useState(false);
  const reqIdRef = useRef<number | null>(null);
  const webGL = useRef(null);

  // useEffect(() => {
  // if (init) return;
  // setInit(true);
  // const animate = (time: DOMHighResTimeStamp) => {
  //   reqIdRef.current = requestAnimationFrame(animate);
  //   if (webGL.current && webGL.current.isRender) {
  //     webGL.current.render(time);
  //   }
  // };
  // reqIdRef.current = requestAnimationFrame(animate);
  // return () => {
  //   if (reqIdRef.current !== null) {
  //     cancelAnimationFrame(reqIdRef.current);
  //   }
  // };
  // }, []);

  useLayoutEffect(() => {
    let webglContainer = document.getElementById("webglContainer");
    let webgl = new BouerlabWebGL(webglContainer);
    webGL.current = webgl;
    return () => {
      webgl.kill();
      webGL.current = null;
    };
  }, []);

  // Ensure to return lenis for use outside the hook if needed
  return webGL.current;
};

export default useWebGL;
