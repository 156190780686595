import { lsToPx } from "./common";
import globalCSS from "@/assets/ts/global-css";
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import theme from "./theme";

const CreationDetailCSS = {
  _container: css`
    .creation-top-ttl {
      font-weight: 700;
      font-size: ${theme.typography.pxToRem(26)};
      color: ${theme.palette.gray};
      position: fixed;
      top: ${theme.typography.pxToRem(40)};
      left: 50%;
      transform: translateX(-50%) translateY(-200%);
      z-index: 2;
      mix-blend-mode: difference;
      transition: all 0.3s;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        top: ${theme.typography.pxToRem(20)};
        font-size: ${theme.typography.pxToRem(16)};
        transform: translateX(-50%) translateY(-200%);
      }
    }

    .sc-visual {
      position: relative;
      width: 100%;

      .visual-img-list {
        position: static;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          height: 100%;
        }

        .swiper-slide {
          @media (max-width: ${theme.breakpoints.values.sm}px) {
            overflow: hidden;
          }
        }
      }

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        display: flex;
        flex-direction: column-reverse;
      }
      .visual-heading {
        position: relative;
        display: flex;
        height: 100vh;
        z-index: 1;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: none;
          flex-direction: column;
          padding: 0 30px;
        }
      }

      .visual-heading__title {
        display: flex;
        align-items: flex-end;
        flex: 1;
        width: 100%;
        height: 100%;
        padding: ${theme.typography.pxToRem(40)};
        color: ${theme.palette.white};

        font-family: "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;
        font-weight: 600;
        font-size: calc((140 / 1920) * 100vw);
        line-height: calc(150 / 140);
      }

      .visual-heading__detail {
        /* flex: 1; */
        width: calc(1000 / 1920 * 100%);
        padding-top: ${theme.typography.pxToRem(186)};
        /* padding-right: ${theme.typography.pxToRem(184)}; */
        padding-right: calc(184 / 1920 * 100%);

        .detail-desc {
          color: ${theme.palette.white};
          margin-bottom: ${theme.typography.pxToRem(40)};
        }

        .detail-info {
          display: flex;
          flex-direction: column;

          .info-box {
            display: flex;

            + .info-box {
              margin-top: ${theme.typography.pxToRem(15)};
            }
            dl {
              display: flex;
              align-items: center;
              flex: 1;

              &:first-of-type {
                dt {
                  width: ${theme.typography.pxToRem(90)};
                  height: ${theme.typography.pxToRem(30)};
                }
              }

              dt {
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: capitalize !important;
                ${globalCSS.en};
                color: ${theme.palette.white};
                font-size: ${theme.typography.pxToRem(14)};
                line-height: 1;
                ${lsToPx(14, 2)};
                border: 1px solid ${theme.palette.white};
                border-radius: 100px;
                width: ${theme.typography.pxToRem(120)};
                height: ${theme.typography.pxToRem(30)};
              }

              dd {
                font-weight: 400;
                font-size: ${theme.typography.pxToRem(18)};
                line-height: 1;
                color: ${theme.palette.white};
                ${lsToPx(18, 2)};
                margin-left: ${theme.typography.pxToRem(15)};
              }
            }
          }
        }

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: 100%;
        }
      }

      .visual-img {
        position: sticky;
        top: 0;
        width: 100vw;
        height: 100vh;
        transform-origin: 50% 0;
        transform: translate3d(
            calc((1920 - 1000) / 1920 * 50vw - var(--y) * (1920 - 1000) / 1920 * 50vw),
            calc(-40vh + var(--y) * 40vh),
            0
          )
          scale(calc((1000 + var(--scale) * 920) / 1920));

        img {
          width: 100vw;
          /* height: 100vh; */
          height: 100%;
          object-fit: cover;
        }

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          position: relative;
          transform: translate3d(0, 0, 0) !important;
          height: auto;
          aspect-ratio: 375 / 500;
        }
      }

      .visual-sticky-space {
        width: 100%;
        height: 100vh;
        margin-top: -100vh;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: none;
        }
      }
    }

    .sc-detail {
      max-width: ${theme.typography.pxToRem(640)};
      margin: ${theme.typography.pxToRem(215)} auto ${theme.typography.pxToRem(200)};
      display: flex;
      flex-direction: column;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin: ${theme.typography.pxToRem(150)} auto 0;
        padding: 0 30px;
      }

      em {
        ${globalCSS.romanCont1};
        color: ${theme.palette.white};
        text-align: left;
        ${globalCSS.en};
        ${lsToPx(46, -2)};
        margin-bottom: ${theme.typography.pxToRem(40)};

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(24)};
        }
      }

      p {
        font-weight: 600;
        font-size: ${theme.typography.pxToRem(20)};
        line-height: calc(30 / 20);
        color: ${theme.palette.white};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(14)};
        }
      }
    }

    .sc-video {
      cursor: pointer;
      position: relative;
      width: 100%;
      max-width: calc(1360 / 1920 * 100%);
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        max-width: 100%;
        margin-top: ${theme.typography.pxToRem(159)};
      }
      .creation-video-item {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        position: static;
      }

      // pagination (swiper)
    }
    .sc-video + .sc-imgs {
      margin-top: 50vh;
    }

    .img-list-wrapper {
      /* overflow: hidden; */
    }

    .sc-imgs {
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;

      .img-list-wrapper {
        position: sticky;
        top: 0;
        height: 100vh;
        width: calc(900 / 1920 * 100%);
        /* width: 100%; */
        display: flex;
        align-items: center;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          /* width: calc(100% - 40px); */
          width: 100%;
          padding: 0 20px;
          margin-top: calc(250px - (100vh - 188 / 335 * 100vw) * 0.5);
        }
      }

      .img-list {
        display: inline-flex;
        gap: 40px;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          gap: 10px;
        }

        .img-list__item {
          position: relative;
          /* width: ${theme.typography.pxToRem(900)}; */
          width: max-content;
          width: calc(900 / 1920 * 100vw);
          height: 50vh;

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: calc(100vw - 40px);
            height: auto;
            aspect-ratio: 335/188;
            border: solid 1px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .sc-imgs__sticky-space {
        width: 100%;
        height: 100vh;
      }
    }

    .sc-related {
      margin-top: ${theme.typography.pxToRem(300)};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-top: calc(250px - (100vh - 188 / 335 * 100vw) * 0.5);
      }

      .sc-title {
        text-transform: uppercase !important;
        ${globalCSS.romanH7};
        font-size: ${theme.typography.pxToRem(16)};
        color: ${theme.palette.white};
      }

      .related-list {
        display: flex;
        align-items: center;
        gap: ${theme.typography.pxToRem(100)};
        margin-top: ${theme.typography.pxToRem(62)};

        &.single-related-list {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          gap: ${theme.typography.pxToRem(24)};
          margin-top: ${theme.typography.pxToRem(30)};
          width: calc(100% - 32px);
        }
        .related-item {
          position: relative;
          border-radius: 50%;
          width: ${theme.typography.pxToRem(300)};
          height: ${theme.typography.pxToRem(300)};
          aspect-ratio: 1/1;
          overflow: hidden;

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: 50%;
            height: auto;
          }

          a {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;

            &::after {
              content: "";
              width: 100%;
              height: 50%;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
              position: absolute;
              bottom: 0;
              right: 0;
            }

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              object-fit: cover;
            }

            span {
              z-index: 2;
              position: absolute;
              bottom: ${theme.typography.pxToRem(40)};
              left: 50%;
              transform: translateX(-50%);
              font-size: ${theme.typography.pxToRem(16)};
              line-height: 1;
              letter-spacing: 0.32px;
              font-weight: 400;
              color: ${theme.palette.white};
              text-align: center;
            }
          }
        }
      }
    }

    .sc-visual,
    .sc-video {
      .swiper-pagination {
        bottom: 0;
        right: auto;
        left: auto;
        top: auto;
        padding: 0 calc(180 / 1360 * 100%);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          padding: 0;
          justify-content: center;
        }
      }
      .custom-pagination-button {
        width: ${theme.typography.pxToRem(31.82)};
        height: ${theme.typography.pxToRem(10.61)};
        border-radius: 10.32px;
        border: 1px solid ${theme.palette.white};
        background: ${theme.palette.transparent};
        margin: 0;
        opacity: 1;
        transform: rotate(-45deg);
        transition: all 0.3s;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: ${theme.typography.pxToRem(21.21)};
          height: ${theme.typography.pxToRem(7.07)};
          transform: translate(0, -50%) rotate(-45deg);
        }

        &.swiper-pagination-bullet-active {
          background-color: ${theme.palette.white};
        }
      }
    }

    .sc-visual {
      .swiper-pagination {
        bottom: -10px;
      }
    }

    .list-btn-box {
      padding: ${theme.typography.pxToRem(150)} 0 ${theme.typography.pxToRem(149.5)};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `,
  get container() {
    return this._container;
  },
  set container(value) {
    this._container = value;
  },
};

export default CreationDetailCSS;
