import { getRandomImage } from "@/assets/ts/bauerlab/util/utils";
import theme from "@/assets/ts/theme";
import { useLanguage } from "@/hooks/use-language";
import { css } from "@emotion/react";
import { type } from "os";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { handleMouseLeave, handleMovePageTop } from "@/assets/ts/common";
import { cursorState } from "@/state";
import { imageTpType } from "@/types/image";
import { useMoveTop } from "@/hooks/use-moveTop";
import creationMotion from "@/utils/ineraction/creation";
import { getImagePath } from "@/assets/ts/image";
import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import globalCSS from "@/assets/ts/global-css";

type image = {
  aiSeq: number;
  tp: number;
  seq: number;
  attachImage: {
    seq: number;
  };
};
type detail = {
  ttl: string;
};

type creationItemType = {
  seq: number;
  articleDetailList: detail[];
  articleImageList: image[];
};

const getImageUrl = (item: creationItemType, tp: 2 | 3) => {
  return item?.articleImageList?.filter((el) => el.tp == tp)?.[0];
};

/**
 * image tp = 2
 */
export function CreationItemHorizontal({ item }: creationItemType) {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSchTpQuery = () => {
    return searchParams.get("schTp") ? `?schTp=${item?.tp}` : "";
  };
  const langTp = useLanguage();

  const detailData = item?.articleDetailList?.filter((el) => el.langTp == langTp)?.[0];

  const imageData = item?.articleImageList?.filter((el) => el.tp == imageTpType.HORIZONTAL_IMAGE_SEQ);

  // console.log("imageData", imageData?.[0]?.seq);

  return (
    <li
      className="creation-item horizontal"
      css={[style.item, style.horizontal]}
      data-fade-anim-mask
      onMouseEnter={(event) => {
        creationMotion.handleMouseEnter(event.currentTarget);
      }}
      onMouseLeave={() => creationMotion.handleMouseLeave()}>
      <Link to={`/creation/${item?.seq}${handleSchTpQuery()}`} onClick={() => creationMotion.handleMouseLeave()}>
        <figure>
          <div className="img-box">
            <img
              src={getImagePath(imageData?.[0]?.attachImage)}
              // src={`${axios.defaults.baseURL}/api/at/attach-image/get/${imageData?.[0]?.attachImage?.seq}`}
              alt={detailData?.ttl}
            />
          </div>
          <div className="ttl-box">
            <figcaption>{detailData?.ttl}</figcaption>
          </div>
        </figure>
      </Link>
    </li>
  );
}

/**
 * image tp = 3
 */
export function CreationItemVertical({ item }: creationItemType) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const langTp = useLanguage();

  const detailData = item?.articleDetailList?.filter((el) => el.langTp == langTp)?.[0];

  const imageData = item?.articleImageList?.filter((el) => el.tp == imageTpType.VERTICAL_IMAGE_SEQ);

  const handleSchTpQuery = () => {
    return searchParams.get("schTp") ? `?schTp=${item?.tp}` : "";
  };

  return (
    <li
      className="creation-item vertical"
      css={style.item}
      data-fade-anim-mask
      onMouseEnter={(event) => {
        creationMotion.handleMouseEnter(event.currentTarget);
      }}
      onMouseLeave={() => creationMotion.handleMouseLeave()}>
      <Link to={`/creation/${item?.seq}${handleSchTpQuery()}`} onClick={() => creationMotion.handleMouseLeave()}>
        <figure>
          <div className="img-box">
            <img src={getImagePath(imageData?.[0]?.attachImage)} alt={detailData?.ttl} />
          </div>
          <div className="ttl-box">
            <figcaption>{detailData?.ttl}</figcaption>
          </div>
        </figure>
      </Link>
    </li>
  );
}

export function CreationCardItem({ item }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSchTpQuery = () => {
    return searchParams.get("schTp") ? `?schTp=${item?.tp}` : "";
  };

  const langTp = useLanguage();

  const detailData = item?.articleDetailList?.filter((el) => el.langTp == langTp)?.[0];
  const imageData = item?.articleImageList?.filter((el) => el.tp == imageTpType.VERTICAL_IMAGE_SEQ);
  return (
    <div className="creation-card-item" css={style.cardItem}>
      <div className="creation-card-item__inner">
        <div className="text-box" data-fade-anim>
          <p>
            Immerse yourself in the borderless universe
            <br />
            where art and technology gives birth
            <br />
            to extraordinary creations.
          </p>
        </div>
        <div
          className="img-box creation-item"
          data-fade-anim-mask
          onMouseEnter={(event) => {
            creationMotion.handleMouseEnter(event.currentTarget);
          }}
          onMouseLeave={() => creationMotion.handleMouseLeave()}>
          <Link to={`/creation/${item?.seq}${handleSchTpQuery()}`} onClick={() => creationMotion.handleMouseLeave()}>
            <figure>
              <img src={getImagePath(imageData?.[0]?.attachImage)} alt={detailData?.ttl} />
              <figcaption>{detailData?.ttl}</figcaption>
            </figure>
          </Link>
        </div>
      </div>
    </div>
  );
}

export function CreationItemDummy() {
  <li></li>;
}

const style = {
  cardItem: css`
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: column;
      gap: 60px;
      height: auto;
      margin: 250px 0;
    }

    .creation-card-item__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(1440 / 1920 * 100%);
      gap: calc(385 / 1440 * 100%);
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: 100%;
        flex-direction: column;
      }
    }

    .text-box {
      color: #fff;
      width: calc(675 / 1440 * 100%);
      ${globalCSS.en};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: 100%;
        padding: 0 22px;
        z-index: 1;
        left: 0;
        top: 2.5em;
        height: max-content;
        mix-blend-mode: difference;
      }
    }

    .img-box {
      position: relative;
      width: calc(380 / 1440 * 100%);
      margin-top: -2.5em;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: calc(280 / 375 * 100vw);
        /* margin: 0; */
        margin-top: ${theme.typography.pxToRem(60)};
      }
      img {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: contain;
        aspect-ratio: 380 / 506;
      }

      figcaption {
        color: #fff;
        margin-top: ${theme.typography.pxToRem(20)};
        font-size: ${theme.typography.pxToRem(16)};
        line-height: ${theme.typography.pxToRem(16)};
        letter-spacing: 0.02em;
        padding-left: ${theme.typography.pxToRem(30)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          padding: 0;
          position: absolute;
          width: 100%;
          bottom: 20px;
          text-align: center;
        }
      }
    }
  `,
  item: css`
    position: relative;

    a {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    figure {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    .img-box {
      position: relative;
      width: 100%;
      height: 100%;
      /* height: auto; */

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .ttl-box {
      margin-top: ${theme.typography.pxToRem(22.5)};
      padding-left: ${theme.typography.pxToRem(30)};
      font-weight: 400;
      font-size: ${theme.typography.pxToRem(16)};
      line-height: 1;
      color: ${theme.palette.white};
      letter-spacing: 0.32px;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 20px;
        font-size: ${theme.typography.pxToRem(14)};
        line-height: 1rem;
        text-align: center;
        padding: 0;
      }
    }

    clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
    transition-property: clip-path;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.47, 0.16, 0.24, 1);
    &.in-view {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      .img-box {
      }
    }
  `,

  dummy: css``,
  horizontal: css`
    a {
      .img-box {
        aspect-ratio: 380 / 213.75;
        overflow: hidden;
      }
    }
  `,
  vertical: css``,
};
