import { css } from "@emotion/react";
import { useEffect, useRef, useState, useMemo } from "react";
import { useRecoilState } from "recoil";
import { lenisScrollState } from "@/state";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import theme from "@/assets/ts/theme";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import globalCSS from "@/assets/ts/global-css";

interface ScrollObjectType {
  observer: Observer | null;
  scrollObj: ScrollObject | null;
}

const splitText = (text: string) => {
  const words = text.split(" ");
  let count = -1;

  return words.map((item, index) => {
    if (item === "<br/>") {
      return <br key={index} />;
    } else {
      let word = item.split("");
      const n = word.length;

      return word.map((letter, index) => {
        return (
          <span
            key={index}
            className="splited-letter"
            dangerouslySetInnerHTML={{
              __html: n - 1 === index ? letter + "&nbsp;" : letter,
            }}
          ></span>
        );
      });
    }
  });
};

export default function FadeText({ texts, progress }) {
  const [init, setInit] = useState(false);
  // const [progress, setProgress] = useState(0);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const containerRef = useRef(null);
  const splitedLetters = useRef([]);

  const iteractionItems = useRef<ScrollObjectType>({
    observer: null,
    scrollObj: null,
  });
  const scrollObjects = useRef({});

  const memoizedSplitText = useMemo(() => {
    return (text) => splitText(text);
  }, []);

  useEffect(() => {
    if (init) return;
    setInit(true);

    scrollObjects.current["container"] = new ScrollObject(containerRef.current);
    setTimeout(() => {
      containerRef.current
        ?.querySelectorAll(".splited-letter")
        .forEach((element) => {
          splitedLetters.current.push({
            el: element,
            progress: 0,
            progressOld: 0,
          });
        });
      splitedLetters.current.unshift({});
    }, 500);

    return () => {
      scrollObjects.current["container"].kill();
    };
  }, []);

  useEffect(() => {
    let scrollObjs = scrollObjects.current;
    for (let key in scrollObjs) {
      scrollObjs[key].update(lenisState.scroll);
    }

    let n = splitedLetters.current.length;
    // let progress = clamp(scrollObjs["container"].ratio.y - 0.5);
    let currentId = progress * n;

    splitedLetters.current.forEach((data: any, i: number) => {
      if (i == 0) return;
      let progress = i - currentId;
      progress = clamp(progress);
      progress = Math.round(progress * 100) / 100;
      data.progress += (progress - data.progress) * 0.5;
      if (Math.abs(data.progress - progress) < 0.01) {
        data.progress = progress;
      }
      if (data.progressOld != data.progress) {
      }
      data.el.style.opacity = `${0 + (1 - data.progress) * 1}`;
      data.el.style.transform = `perspective(1000px) translate3d(${
        data.progress * 0
      }px,0,0) scale(${1 - data.progress * 0.2})`;

      data.progressOld = data.progress;
    });
  }, [lenisState]);

  return (
    <div className="splite-text" css={style.main} ref={containerRef}>
      {texts.map((words, index) => memoizedSplitText(words))}
    </div>
  );
}

const style = {
  main: css`
    span {
      opacity: 0;
      display: inline-flex;
      ${globalCSS.en};
      /* transition-duration: 0.1s; */
      /* transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); */
      /* transform: translate3d(0, 0, 0) scale(0.5); */
    }
    &:not(&.in-view-from-bottom) {
      /* span {
        transition-delay: 0s !important;
      } */
    }

    &.in-view-from-bottom {
      /* span {
        opacity: 1;
        transition: opacity 2s ${theme.transitions.easing.inout1},
          transform 0.25s ${theme.transitions.easing.inout3};
      } */
    }
  `,
};
