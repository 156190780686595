import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import mypageEn from "./language/en/mypage.json";
import mypageKo from "./language/ko/mypage.json";
import newsEn from "./language/en/news.json";
import newsKo from "./language/ko/news.json";
import aboutEn from "./language/en/about.json";
import aboutKo from "./language/ko/about.json";
import contactEn from "./language/en/contact.json";
import contactKo from "./language/ko/contact.json";
import mainEn from "./language/en/main.json";
import mainKo from "./language/ko/main.json";
import creationEn from "./language/en/creation.json";
import creationKo from "./language/ko/creation.json";
import postEn from "./language/en/post.json";
import postKo from "./language/ko/post.json";

const resource = {
    en: {
        mypage: mypageEn,
        news: newsEn,
        about: aboutEn,
        contact: contactEn,
        main: mainEn,
        creation: creationEn,
        post: postEn

    },

    ko: {
        mypage: mypageKo,
        news: newsKo,
        about: aboutKo,
        contact: contactKo,
        main: mainKo,
        creation: creationKo,
        post: postKo

    },

};

const options = {
  order: ["cookie"],
  lookupCookie: "i18nextLng",

  caches: ["cookie"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: options,
    resources: resource,
    whitelist: ["ko", "en"],
    fallbackLng: ["ko", "en"],
    debug: true,
    // keySeparator: true, // we do not use keys in form messages.welcome
    react: {
      useSuspense: false, //   <---- this will do the magic
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
