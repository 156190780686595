import axios from "axios";
import { contactParamsType } from "@/types/contact";

/**
 * 연락처 타입 조회
 */
export const getContactTypeAPI = async () => {
  return await axios.get("/api/in/contact/get-type");
};

/**
 * 연락처 등록
 */
export const registContactAPI = async (params: contactParamsType) => {
  return await axios.post("/api/in/contact/regist", { ...params });
};
