import { mouse } from "@/assets/ts/bauerlab/BaseWebGL";
import { gui, gui_vec3 } from "@/assets/ts/bauerlab/GUI";
import UA from "@/assets/ts/bauerlab/ua";
import {
  BufferGeometry,
  Clock,
  Color,
  Float32BufferAttribute,
  Object3D,
  Points,
  ShaderMaterial,
  Vector3,
} from "three";

export default class Particle {
  container: Object3D;
  num: number = UA.isPC ? 80 : 25;
  particles: Points;
  color: Color = new Color(0x1011de);
  clock: Clock;
  positions = [];
  scale = UA.isPC ? 50 : 15;
  addRot = new Vector3();
  constructor() {
    this.clock = new Clock();

    const w = window.innerWidth * 1.25;
    const h = window.innerHeight * 1.25;
    let count = 0;

    const positions = [];
    const scales = [];
    const shapes = [];
    const flickers = [];
    const times = [];
    const vf = [];

    const diagonel = Math.sqrt(
      Math.pow(window.innerWidth, 2) + Math.pow(window.innerHeight, 2)
    );

    for (let i = 0; i < this.num; i++) {
      const x = diagonel * Math.random() - diagonel * 0.5;
      const y = diagonel * Math.random() - diagonel * 0.5;
      let z = 1500 * Math.random() - 1000;
      let scale = this.scale;

      let random = Math.random();
      if (random < 0.3) {
        z = -random * diagonel;
      } else if (random > 0.4 && random < 0.7) {
        z = random * diagonel * 0.5 - diagonel * 0.25;
      } else {
        scale *= 1.5;
        z = random * 200 + 300;
      }

      scales[i] = scale; //Math.random() * this.scale * 0.25 + this.scale;
      times[i] = Math.PI * 2;
      shapes[i] = Math.floor(Math.random() > 0.2 ? 0 : 1);
      flickers[i] = Math.random() < 0.5 ? 1 : 0;
      positions.push(x, y, z);
      this.positions.push(x, y, z);
      vf.push(Math.random() * 1, Math.random() * 1, Math.random() * 1);
    }

    const geometry = new BufferGeometry();
    geometry.setAttribute("position", new Float32BufferAttribute(positions, 3));
    geometry.setAttribute("velocity", new Float32BufferAttribute(vf, 3));
    geometry.setAttribute("scale", new Float32BufferAttribute(scales, 1));
    geometry.setAttribute("time", new Float32BufferAttribute(times, 1));
    geometry.setAttribute("shape", new Float32BufferAttribute(shapes, 1));
    geometry.setAttribute("flicker", new Float32BufferAttribute(flickers, 1));

    let material = new ShaderMaterial({
      uniforms: {
        color: { value: new Color(this.color) },
      },
      vertexShader: `
        varying float vShape;
        varying float vTime;
        varying float vFlicker;
        varying vec2 vUv;
        attribute float scale;
        attribute float shape;
        attribute float time;
        attribute float flicker;

        void main() {
          vUv = uv;
          vTime = time;
          vShape = shape;
          vFlicker = flicker;
          vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    		  gl_PointSize = scale * ( -1000. / mvPosition.z );
          // gl_PointSize = scale;
          gl_Position = projectionMatrix * mvPosition;
          
        }
      `,
      fragmentShader: `
        #define PI 3.1415926535897932384626433832795
        uniform vec3 color;
        varying float vShape;
        varying float vTime;
        varying vec2 vUv;
        varying float vFlicker;
        

        void main() {
          float alpha = 0.0;
          if(vShape == 0.0) {
            if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;
          }
          
          
          if(vShape == 1.0) {
            vec2 bl = step(vec2(0.15),gl_PointCoord);
            alpha = bl.x * bl.y;
  
            vec2 tr = step(vec2(0.15),1.0-gl_PointCoord);
            alpha *= tr.x * tr.y;
          }


          alpha = (1.0-alpha) * 0.6;
          if(vShape == 0.0 && vFlicker == 1.0) {
            alpha *= fract(vTime * 250.);
          }


    		  gl_FragColor = vec4( color, alpha );
        }
        `,
      transparent: true,
    });

    this.container = new Object3D();
    this.particles = new Points(geometry, material);
    this.container.add(this.particles);

    this.setGui();
  }

  setGui() {
    let g = gui.addFolder("partcle");
    g.close();
    gui_vec3(g, this.container.position, {});
  }

  update() {
    this.container.rotation.x +=
      (mouse.ry * 0.5 + this.addRot.x - this.container.rotation.x) * 0.02;
    this.container.rotation.y +=
      (mouse.rx * 0.5 + this.addRot.y - this.container.rotation.y) * 0.02;

    const positions = this.particles.geometry.attributes.position.array;
    const times = this.particles.geometry.attributes.time.array;
    const velocities = this.particles.geometry.attributes.velocity.array;
    const scales = this.particles.geometry.attributes.scale.array;

    const w = window.innerWidth * 0.5;
    const h = window.innerHeight * 0;
    const delta = this.clock.getDelta();
    let count = 0;

    for (let i = 0; i < this.num * 3; i += 3) {
      times[count] += delta * velocities[count] * 0.5;
      let t = times[count];
      let r = (((i * 360) / this.num) * Math.PI) / 180;
      positions[i] = this.positions[i] + Math.cos(t) * w * 0.2;
      positions[i + 1] = this.positions[i + 1] + Math.sin(t + i) * h * 0.2;
      // positions[i + 2] = this.positions[i + 2] + Math.sin(t + i) * 100;
      // scales[count] = this.scale - Math.sin(t) * this.scale;
      count++;
    }

    this.particles.geometry.attributes.position.needsUpdate = true;
    this.particles.geometry.attributes.time.needsUpdate = true;
    this.particles.geometry.attributes.scale.needsUpdate = true;
  }
}
