import globalCSS from "@/assets/ts/global-css";
import { menu, snsData } from "@/assets/ts/mock-data/menu";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SubHeader from "@/components/sub-header";
import styled from "@emotion/styled";
import { AnimateSharedLayout, motion } from "framer-motion";
import {
  isMenuOpenState,
  isScrollHiddenState,
  pageYState,
  pointerState,
} from "@/state";
import { useRecoilState } from "recoil";
import gsap, { random } from "gsap";
import CustomEase from "gsap/dist/CustomEase";
import { menuMotion } from "@/utils/ineraction/menu";
import { useMediaQuery } from "@mui/material";
import theme from "@/assets/ts/theme";
import useSmoothScroll from "@/hooks/use-smooth-scroll";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/react";
import { handlePageToTop, unlockDom } from "@/assets/ts/common";

const Menu = () => {
  const [subMenu, setSubMenu] = useState([]);
  const depth2Ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(isMenuOpenState);
  const [isMenuMotionRunning, setIsMenuMotionRunning] = useState(false);
  const [pointer, setPointer] = useRecoilState(pointerState);
  const isMobile = useMediaQuery(
    `(max-width:${theme.breakpoints.values.lg}px)`
  );
  const { i18n } = useTranslation();
  const menuRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const hasSeq = location.pathname?.split("/").length > 2 ? true : false;
  const changeLang = (lang: string): void => {
    i18n.changeLanguage(lang);
  };
  const [isScrollHidden, setIsScrollHidden] =
    useRecoilState(isScrollHiddenState);

  let vh = 0;

  useEffect(() => {
    const menus = [];
    menu.forEach((item, index) => {
      if (!item.childMenus) return;
      menus.push(...item.childMenus);
    });

    setSubMenu(menus);

    /*  모바일 100vh 설정 */

    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  const backgroundImages = [
    "/images/image/img-menu-bg1.png",
    "/images/image/img-menu-bg2.png",
    "/images/image/img-menu-bg3.png",
  ];

  useEffect(() => {
    const body = document.querySelector("body");
    if (isScrollHidden) {
      body.classList.add("scroll-hidden");
    } else {
      body.classList.remove("scroll-hidden");
    }
  }, [isScrollHidden]);

  const [currentBackgroundImg, setCurrentBackgroundImg] = useState("");

  const getRandomBackgroundImg = () => {
    const randomIndex = Math.floor(Math.random() * backgroundImages.length);
    setCurrentBackgroundImg(backgroundImages[randomIndex]);
  };

  useEffect(() => {
    getRandomBackgroundImg();
  }, []);

  const handleDepth2Hover = (index: number) => {
    const depth2 = depth2Ref.current.querySelectorAll(".menu-depth-2");

    depth2.forEach((d2: HTMLElement, i: number) => {
      if (index === i) {
        d2.classList.add("active");
      } else if (index === -1) {
        d2.classList.remove("active");
        d2.style.opacity = "1";
      } else {
        d2.style.opacity = "0.5";
      }
    });
  };

  const [pageY, setPageY] = useRecoilState(pageYState);

  function scrollUnlock() {
    const main = document.querySelector("main");

    unlockDom("html");
    unlockDom("body");
    main.style.top = `0px`;
    window.scrollTo(0, pageY);
    window.setTimeout(() => {
      setPageY(null);
    });
  }

  const handleCloseMenu = () => {
    // scrollUnlock();
    window.dispatchEvent(new Event("lenisStart"));
    const isCreationPage = location.pathname.includes("creation");
    setIsMenuOpen(false);
    const hasSearch = location.search ? location.search : "";

    if (isCreationPage) {
      // 크리에이션 페이지에서
      navigate(`${location.pathname}${location.search}`);
      setIsScrollHidden(false);
    } else {
      if (location.hash !== "") {
        navigate(`/${location.pathname?.split("/")?.[1]}${hasSearch}`);
        setIsScrollHidden(false);
      } else {
      }
    }

    // 크리에이션페이지는 다른 서브랑 다르게 동작함
    // 서브에서 창을 닫지 않고 메뉴로 이동함
    // if (isCreationPage) {
    //   if (location.hash) {
    //     setIsScrollHidden(true);

    //     navigate(`${location.pathname}${location.search}`);
    //   } else {
    //     navigate(`${location.pathname}${location.search}#menu`);
    //     return;
    //   }
    // }
    // // 일반 서브페이지

    // if (location.hash) {
    //   navigate(`/${location.pathname?.split("/")?.[1]}${hasSearch}`);
    //   setIsScrollHidden(false);
    //   return;
    // } else {
    //   navigate(`/${location.pathname?.split("/")?.[1]}${hasSearch}#menu`);
    // }
  };
  // const lenis = useSmoothScroll();
  const handlePageMove = () => {
    window.dispatchEvent(new Event("lenisStart"));
  };

  const animationComplete = () => {
    setIsMenuMotionRunning(false);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(CustomEase);
    }
    if (isMenuMotionRunning) return;
    setIsMenuMotionRunning(true);

    if (location.hash == "#menu") {
      menuMotion.play(animationComplete);
    } else {
      menuMotion.reverse(animationComplete);
    }
  }, [isMenuOpen, isMenuMotionRunning]);

  function handleToggleLang(lang: string) {
    changeLang(lang);
    handleCloseMenu();
    if (location.pathname == "/") {
      window.location.reload();
    }
  }

  return (
    <>
      <div
        id="menu"
        className="menu"
        css={globalCSS.menu}
        ref={menuRef}
        onPointerMove={(event) => {
          !isMobile && setPointer({ x: event.clientX, y: event.clientY });
        }}
        style={{
          // background: "red",
          background: `url(${currentBackgroundImg}) no-repeat center / cover`,
        }}
      >
        <div className="menu-header">
          <em
            className="sub-logo-sm"
            onClick={() => {
              window.location.href = "/"; // scrollUnlock();
              // lenis.scrollTo(0, { immediate: true });
              window.dispatchEvent(new Event("lenisStart"));
            }}
          >
            <span data-tooltip={`Home`}></span>
            <img src="/images/icon/logo.svg" alt="바우어랩" />
          </em>
          {!isMobile && (
            <div className="lang-toggle-box">
              <button
                className={i18n.language == "ko" ? "active" : ""}
                onClick={() => handleToggleLang("ko")}
              >
                kr
              </button>
              <button
                className={i18n.language == "en" ? "active" : ""}
                onClick={() => handleToggleLang("en")}
              >
                en
              </button>
            </div>
          )}
          <button
            css={globalCSS.menuBtn}
            onClick={handleCloseMenu}
            className="btn-close"
          >
            <span>Close</span>
          </button>
        </div>
        <div className="menu-list">
          <div className="flex">
            {menu.map(
              (item) =>
                item.order === 0 && (
                  <Link
                    to={item.url}
                    className={`menu-depth-${item.depth}`}
                    key={item.cd}
                    onClick={handlePageMove}
                  >
                    {item.nm}
                  </Link>
                )
            )}
          </div>
          <div className="flex">
            {menu.map(
              (d1) =>
                d1.order === 1 && (
                  <>
                    <span
                      className={`menu-depth-${d1.depth} not-hover`}
                      key={d1.cd}
                      style={{ cursor: "default" }}
                    >
                      {d1.nm}
                    </span>
                    <div className="sub-menu-list" ref={depth2Ref}>
                      {subMenu.map((sub, i) => (
                        <Link
                          to={sub.url}
                          key={sub.cd}
                          className={`menu-depth-${sub.depth}`}
                          onMouseEnter={() => handleDepth2Hover(i)}
                          onMouseLeave={() => handleDepth2Hover(-1)}
                          onClick={handlePageMove}
                          // onMouseEnter={handleDepth2Hover}
                        >
                          {sub.nm}
                        </Link>
                      ))}
                    </div>
                  </>
                )
            )}
          </div>
          <div className="flex">
            {menu.map(
              (d1) =>
                d1.order === 2 && (
                  <Link
                    to={d1.url}
                    className={`menu-depth-${d1.depth}`}
                    key={d1.cd}
                    onClick={handlePageMove}
                  >
                    {d1.nm}
                  </Link>
                )
            )}
          </div>
        </div>
        {isMobile && (
          <div className="lang-toggle-box">
            <button
              className={i18n.language == "ko" ? "active" : ""}
              onClick={() => handleToggleLang("ko")}
            >
              kr
            </button>
            <button
              className={i18n.language == "en" ? "active" : ""}
              onClick={() => handleToggleLang("en")}
            >
              en
            </button>
          </div>
        )}
        <ul className="sns-list">
          {snsData.map((sns, i) => (
            <li className={`sns-item sns-${sns.nm}`} key={sns.nm}>
              <a href={sns.url} target="_blank">
                <span data-tooltip={`${sns.nm}`}></span>
                <img src={`/images/image/img-sns-${sns.nm}.png`} alt={sns.nm} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Menu;
