import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import styled from "@emotion/styled";

export const MobileTabTitle = styled.button`
  display: flex;
  align-items: center;
  position: fixed;
  top: ${theme.typography.pxToRem(30)};
  left: 50%;
  transform: translateX(-50%);
  ${globalCSS.en}
  font-weight: 700;
  font-size: ${theme.typography.pxToRem(20)};
  line-height: 1;
  color: ${theme.palette.gray};
  text-transform: uppercase;
  cursor: pointer;
  z-index: 10;
  transition: transform 0.5s;

  &::after {
    content: "";
    display: block;
    width: ${theme.typography.pxToRem(20)};
    height: ${theme.typography.pxToRem(20)};
    background: url("/images/icon/ico-select-down.svg") no-repeat center / cover;
  }
`;

export const MobileSchedulerTabTitle = styled.button`
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  transform: none;
  top: ${theme.typography.pxToRem(23)};
  ${globalCSS.en}
  font-weight: 700;
  font-size: ${theme.typography.pxToRem(20)};
  line-height: 1;
  color: ${theme.palette.gray};
  text-transform: uppercase;
  cursor: pointer;
  z-index: 10;
  padding-left: ${theme.typography.pxToRem(20)};

  &::after {
    content: "";
    display: block;
    width: ${theme.typography.pxToRem(20)};
    height: ${theme.typography.pxToRem(20)};
    background: url("/images/icon/ico-select-down.svg") no-repeat center / cover;
  }
`;

export const MobileTabArea = styled.div`
  padding-top: ${theme.typography.pxToRem(30)};
  padding-bottom: calc(100vh - 100%);
  width: 100%;

  .MuiFormControl-root {
    width: 100%;
    .MuiFormGroup-root {
      width: 100%;

      justify-content: center;
      align-items: center;
    }
  }
`;

export const MobileSchedulerTabArea = styled.div`
  width: 100%;
  /* height: 100vh; */
  height: calc(var(--vh) * 100);
  overflow: hidden;
  /* padding: ${theme.typography.pxToRem(90)} 0 ${theme.typography.pxToRem(49)}; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${theme.typography.pxToRem(30)};
`;

export const MobileSchedulerYear = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.typography.pxToRem(40)};
  mix-blend-mode: difference;

  span {
    font-weight: 700;
    ${globalCSS.en};
    font-size: ${theme.typography.pxToRem(40)};
    /* font-size: calc(40 / 375 * 100vw); */
    line-height: 1;
    color: ${theme.palette.white};
  }

  button {
    &.disabled {
      opacity: 0.3;
      cursor: auto;
    }
  }
`;

export const MobileSchedulerMonth = styled.div`
  padding-top: ${theme.typography.pxToRem(30)};
  .MuiFormGroup-root {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
