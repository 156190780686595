import axios from "axios";

type imageType = {
  capa: string;
  ext: string;
  formatModYmd: string;
  formatModYmdHis: string;
  formatRgstYmd: string;
  formatRgstYmdHis: string;
  modHis: string;
  modYmd: string;
  modrSeq: string;
  orgNm: string;
  path: string;
  rgstHis: string;
  rgstYmd: string;
  rgstrIp: string;
  rgstrSeq: string;
  saveNm: string;
  seq: number;
  url: string;
};

export const getImagePath = (imageData: imageType) => {
  // console.log("imageData", imageData);

  if (!imageData) return;
  // if (process.env.REACT_APP_DEPLOY_ENV === "prd") {
  //   return `${imageData?.url}/${imageData?.saveNm}.${imageData?.ext}`;
  // } else {

  return `${axios.defaults.baseURL}/api/at/attach-image/get/${imageData?.seq}`;
};

export const getVideoPath = (videoData: any) => {
  if (!videoData) return;

  // if (process.env.REACT_APP_DEPLOY_ENV === "prd") {
  //   return `${videoData?.attachVideo?.url}/${videoData?.attachVideo?.saveNm}.${videoData?.attachVideo?.ext}`;
  // } else {
  // }
  return `${axios.defaults.baseURL}/api/at/attach-video/download/${videoData?.attachVideo?.seq}`;
};
