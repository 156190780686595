import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import { useEffect, useRef, useState } from "react";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import { useRecoilState } from "recoil";
import { lenisScrollState } from "@/state";
import { css } from "@emotion/react";
import theme from "@/assets/ts/theme";
import { gui } from "@/assets/ts/bauerlab/GUI";
import { useMediaQuery } from "@mui/material";
import { useInView } from "framer-motion";

export default function Partners({ lenis }) {
  const items = useRef({
    observer: new Observer(),
    scroll: null,
  });

  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  interface ScrollObjectType {
    observer: Observer | null;
    scrollObjects: any;
  }

  const iteractionItems = useRef<ScrollObjectType>({
    observer: null,
    scrollObjects: {},
  });

  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const [iconView, setIconView] = useState(false);
  const [clipOffset, setClipOffset] = useState({
    ty: 50,
    by: 50,
  });

  const [isInit, setIsInit] = useState(false);
  const [isIn, setIsIn] = useState(false);
  const [activeId, setActiveId] = useState(-1);
  const scrollObjects = useRef({});
  const partnerListItems = useRef([]);
  const partnersEl = useRef(null);
  const circleRef = useRef(null);
  const circleText = useRef(null);
  const partnerListElWrap = useRef(null);
  const partnerListEl = useRef(null);
  const logoEl = useRef(null);
  const [logoView, setLogoView] = useState(false);
  const logoPosition = useRef({
    x: 0,
    y: 0,
  });

  const introStickySpaceEl = useRef(null);
  const rollingOffset = useRef({
    rotation: isMobile ? 120 : 80,
    rotY: isMobile ? 120 : 80,
    rotZ: isMobile ? -20 : -10,
    distY: 100,
    distZ: isMobile ? 1000 : 1000,
    iconView: false,
  });

  const partnerList = useRef([
    { name: "Disney", logo: "logo1.svg" },
    // { name: "MOMENT FACTORY", logo: "logo2.svg" },
    { name: "INNOCEAN", logo: "logo3.svg" },
    { name: "Hyundai Motor Group", logo: "logo4.svg" },
    { name: "SM Entertainment", logo: "logo5.svg" },
    { name: "스마일게이트", logo: "logo6.svg" },
    { name: "K옥션", logo: "logo7.svg" },
    { name: "The Mill", logo: "logo14.svg" },
    // { name: "JTBC", logo: "logo8.svg" },
    { name: "BROGENT", logo: "logo15.svg" },

    { name: "한국전파진흥협회", logo: "logo9.svg" },
    { name: "KOCCA 한국콘텐츠진흥원", logo: "logo10.svg" },
    { name: "문화체육관광부", logo: "logo11.svg" },
    { name: "과학기술정보통신부", logo: "logo12.svg" },
    { name: "국립중앙박물관", logo: "logo13.svg" },
  ]);

  useEffect(() => {
    if (isInit) return;
    setIsInit(true);
    let scrollObjs = iteractionItems.current.scrollObjects;
    scrollObjs["section"] = new ScrollObject(partnersEl.current);
    scrollObjs["circleText"] = new ScrollObject(circleText.current);
    scrollObjs["partnerListElWrap"] = new ScrollObject(partnerListElWrap.current);
    scrollObjs["introStickySpaceEl"] = new ScrollObject(introStickySpaceEl.current);

    // scrollObjs["stickySpace"] = new ScrollObject(partnerStickySpaceEl.current);

    partnerListEl.current.querySelectorAll("li").forEach((item, i) => {
      partnerListItems.current.push({
        el: item,
        kill: () => {},
      });
    });

    if (1) {
      let g = gui.addFolder("partnerRolling");
      g.close();
      g.add(rollingOffset.current, "rotation", -180, 180, 1).onChange(() => {
        window.dispatchEvent(new Event("scroll"));
      });
      g.add(rollingOffset.current, "rotY", -360, 360, 1).onChange(() => {
        window.dispatchEvent(new Event("scroll"));
      });
      g.add(rollingOffset.current, "rotZ", -360, 360, 1).onChange(() => {
        window.dispatchEvent(new Event("scroll"));
      });
      g.add(rollingOffset.current, "distY", -3000, 3000, 1).onChange(() => {
        window.dispatchEvent(new Event("scroll"));
      });
      g.add(rollingOffset.current, "distZ", -3000, 3000, 1).onChange(() => {
        window.dispatchEvent(new Event("scroll"));
      });
      g.add(rollingOffset.current, "iconView").onChange((value) => {
        setIconView(value);
      });
    }

    return () => {
      for (let key in scrollObjects.current) {
        scrollObjects.current[key].kill();
      }

      partnerListItems.current.forEach((item) => {
        item.kill();
      });

      if (items.current.observer) {
        items.current.observer.kill();
      }
    };
  }, []);

  useEffect(() => {
    let scrollObjs = iteractionItems.current.scrollObjects;
    for (let key in scrollObjs) {
      scrollObjs[key].update(lenisState.scroll);
    }

    let sectionScroll = iteractionItems.current.scrollObjects["section"];
    if (sectionScroll.ratio.allY < 0 || sectionScroll.ratio.allY > 1) {
      return;
    }
    /*
      intro 
    */
    let introScrollObj = scrollObjs["introStickySpaceEl"];
    let introP = introScrollObj.ratio.allYClamp;
    let ty = -introP * 50 + 50;
    let by = introP * 50 + 50;
    setClipOffset({ ...clipOffset, ty: ty, by: by });

    let scale = 1.8;
    scale -= introP * introP * 1.8;

    circleText.current.style.transform = `translate3d(0,0,0) rotate(${introP * introP * 270}deg) scale(${scale})`;

    let circleOpacity = 1;
    if (introScrollObj.ratio.allY > 0.5) {
      circleOpacity = 1 - (introScrollObj.ratio.allY - 0.5) / 0.5;
    }
    circleText.current.style.opacity = clamp(circleOpacity);

    /*
      partner rolling 
    */
    let partnerScrollObj = scrollObjs["partnerListElWrap"];
    let progress = partnerScrollObj.scroll.y / partnerScrollObj.rect.height;

    let halfScreenH = window.innerHeight * 0.5;
    let PI = Math.PI / 180;
    let offset = rollingOffset.current;

    partnerListItems.current.forEach((item, i) => {
      let itemScrollY = -partnerScrollObj.scroll.y + item.el.offsetTop;
      let itemP = 0;
      let opacity = 1;
      if (itemScrollY < halfScreenH) {
        itemP = -(itemScrollY - halfScreenH) / halfScreenH;
        opacity = 1 - itemP;
      }

      let activeY = itemScrollY - halfScreenH * 0.5;
      if (activeY > 200 && activeY < 240) {
        setActiveId(i);
      }
      let r = itemP * offset.rotation;
      let x = 0;
      let y = -Math.cos(offset.rotY * itemP * PI) * offset.distY;
      let z = Math.sin(offset.rotZ * itemP * PI) * offset.distZ;
      let rx = offset.rotZ * itemP;

      if (isMobile) {
        r = 0;
        rx = 0;
        z = 0;
        y = -progress * 0; //itemP * -offset.distY;
        opacity = 1;
      }
      item.el.style.transform = `perspective(1000px) translate3d(${x}px,${y}px,${z}px) rotateX(${r}deg)`;
      item.el.style.transformOrigin = `center ${itemP * 100}%`;
      item.el.style.opacity = `${opacity}`;
    });

    if (!isMobile) {
      if (progress >= 0.8 || partnerScrollObj.ratio.y < 0) {
        setLogoView(false);
      } else {
        setLogoView(true);
      }

      let logoX = 0;
      let logoY = 0;
      if (activeId > -1) {
        let rect = partnerListItems.current[activeId].el.getBoundingClientRect();
        logoX = rect.left + rect.width * 0.3;
        logoY = rect.top + rect.height * 0.5;
        // logoY = window.innerHeight * 0.5;
        if (progress > 0.9) {
          logoY -= 100;
        }
      }

      logoPosition.current.x += (logoX - logoPosition.current.x) * 0.05;
      logoPosition.current.y += (logoY - logoPosition.current.y) * 0.05;
    }
  }, [lenisState]);

  return (
    <section id="partners" css={style.main} ref={partnersEl}>
      <div className="partners-intro">
        <div
          className="circle"
          ref={circleRef}
          style={{
            clipPath: `polygon(0 ${clipOffset.ty}vh, 100% ${clipOffset.ty}vh, 100% ${clipOffset.by}vh, 0 ${clipOffset.by}vh)`,
          }}>
          <div className="circle__img" ref={circleText}>
            <img src="/images/image/main/circle-text.svg" alt="" />
          </div>
        </div>
        <div className="circle-sticky-space" id="partnerContent" ref={introStickySpaceEl}></div>
        <div className="partner-list-wrap" ref={partnerListElWrap}>
          <div className="partner-list-outer">
            <ul className="partner-list" ref={partnerListEl}>
              {partnerList.current.map((partner, index) => (
                <li key={index} className={activeId == index ? "-active" : ""}>
                  <p>{partner.name}</p>
                </li>
              ))}
            </ul>
            <div
              className="logo"
              ref={logoEl}
              style={{
                display: isMobile ? "none" : "block",
                opacity: logoView ? 1 : 0,
                transform: `translate3d(${logoPosition.current.x}px,${logoPosition.current.y}px,0)`,
              }}>
              {partnerList.current.map((partner, index) => (
                <img
                  src={"/images/image/main/partner/" + partner.logo}
                  key={index}
                  // style={{ transform: `rotate(${(activeId % 2) * 360}deg)` }}
                  className={activeId == index ? "-active" : ""}
                  alt=""
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const style = {
  main: css`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      margin-top: -50vh;
    }

    .partners-intro {
      width: 100%;
      min-height: 100vh;
    }

    .circle {
      position: sticky;
      width: 100%;
      height: 100vh;
      top: 0;
      background-color: ${theme.palette.yellow};
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .circle__img {
        min-width: 426px;
        width: calc(790 / 1920 * 100vw);
        height: auto;
        aspect-ratio: 426 / 431;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .logo {
      position: fixed;
      width: 1px;
      height: 1px;
      left: 0;
      top: 0;
      pointer-events: none;
      img {
        position: absolute;
        left: 0;
        top: 0%;
        width: 138px;
        height: 138px;
        aspect-ratio: 1/1;
        transform: translate(-100%, -100%);
        transition: opacity 0.3s 0s ease-in-out;
        opacity: 0;
        &.-active {
          opacity: 1;
          transition: opacity 0.5s ease-out;
        }
      }
      transition: opacity 0.3s 0s ease-in-out;
    }

    .circle-sticky-space {
      position: relative;
      width: 100%;
      height: 100vh;
    }

    .partner-list-wrap {
      position: relative;
      margin-top: 100vh;
    }

    .partner-list-outer {
      width: 100%;
    }

    .partner-list {
      top: 0;
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translate(0, 100px);
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        transform: translate(0, 0);
      }

      li {
        transition: color 0.2s ease-in-out;
        height: max-content;
        font-size: ${theme.typography.pxToRem(120)};
        line-height: 1em;
        backface-visibility: hidden;
        transform-origin: center 100%;
        width: min-content;
        display: inline-block;

        @media (max-width: ${theme.breakpoints.values.xl}px) {
          font-size: ${theme.typography.pxToRem(100)};
        }
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(80)};
        }

        @media (max-width: ${theme.breakpoints.values.md}px) {
          font-size: ${theme.typography.pxToRem(50)};
        }
        @media (max-width: ${theme.breakpoints.values.sm}px) {
          font-size: ${theme.typography.pxToRem(33)};
        }

        p {
          left: 0;
          top: 0;
          white-space: nowrap;
          opacity: 0.5;
          transition: opacity 0.3s ${theme.transitions.easing.inout1};
        }

        .dummy-icon {
          position: absolute;
          top: 50%;
          margin-top: calc(-1.5em / 2);
          border-radius: 50%;
          width: 1.5em;
          height: 1.5em;
          background-color: blue;
          /* z-index: 1; */
          opacity: 0;
          transition: transform 0.3s ${theme.transitions.easing.inout3}, opacity 0.3s ${theme.transitions.easing.inout1};
          transform: perspective(1000px) translate3d(0, 0, 0px);
          /* backface-visibility: hidden; */

          &.-active {
            opacity: 1;
          }
        }

        &.-active {
          color: blue;
          p {
            opacity: 1;
          }
          .dummy-icon {
            opacity: 1;
            transition: transform 0.6s 0.2s ${theme.transitions.easing.inout3},
              opacity 0.6s 0.2s ${theme.transitions.easing.inout1};
            transform: perspective(1000px) translate3d(0, 0, 0px);
          }
        }
      }
    }

    .partner-sticky-space {
      height: 1000px;
      width: 100%;
    }
  `,
};
