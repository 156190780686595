import axios from "axios";
import deepdash from "deepdash";
import lodash from "lodash";
import moment, { Moment } from "moment";
import "moment/locale/ko";

type fileType = {
  file_name: string;
  file_path: string;
  file_type: string;
  reg_date: string;
  size: string;
};
const _ = deepdash(lodash);

export const getTp = (types: object, tp: string) => {
  let text = "";
  _.mapKeys(types, (v, k) => {
    if (k === tp) text = v;
  });

  return text;
};

export const getCate = (categoryList: [], cd: string) => {
  const findObj = _.find(categoryList, (item) => item.cd === cd);

  if (!findObj) return "-";

  return findObj.nm;
};

export const ConvertToGB = (value) => {
  return Math.round(value / 1024);
};
export const ConvertToGBFixed1 = (value) => {
  return (value / 1024).toFixed(1);
};

export const RoundNumber = (value) => {
  return Math.round(value);
};

export function formatNumber(num, precision = 0) {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
}

// export function fileDownload(file: fileType, userId: string) {
//   const { file_name, file_path, file_type, reg_date, size } = file;

//   axios.get(file_path, { responseType: "blob" }).then((res) => {
//     try {
//       const url = window.URL.createObjectURL(new Blob([res.data]));
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", file_name + file_type);
//       document.body.appendChild(link);
//       link.click();

//       window.URL.revokeObjectURL(url);

//       axios.post("/api/account/ins-file-mng-hist", {
//         reqr_id: userId,
//         org_file_nm: file_name,
//         file_cl: file_type.substring(1),
//         file_path: file_path,
//       });
//     } catch (e) {
//       console.log("Error", e);
//     }
//   });
// }
export function fileDownload(aiSeq: number | string, filePath: string) {
  axios
    .get(`/api/at/attach-file/download/${aiSeq}`, {
      responseType: "blob",
    })
    .then((res) => {
      console.log("@@ res", res);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filePath);
      document.body.appendChild(link);
      link.click();
    });
}

export const changeDatepickerDate = (date: Moment | string | null) => {
  // console.log("date", date);
  // YYYY-MM-DD
  if (!date) return "";
  let mnt = moment(date).format("YYYY-MM-DD");
  let d = mnt.split("-");
  return {
    year: Number(d[0]),
    month: Number(d[1]),
    day: Number(d[2]),
  };
};

export const changeSubmitTime = (time: string) => {
  return time.replace(":", "");
};

export const regDeleteSpacing = (value: string) => {
  return value.replace(/\s/g, "");
};

export const regOnlyEnglish = (value: string) => {
  return value.replace(/[^\a-zA-Z ]/g, "");
};

export const regEnglishNum = (value: string) => {
  return value.replace(/[^\a-zA-Z0-9]/g, "");
};

export const regOnlyKor = (value: string) => {
  return value.replace(/[^ㄱ-ㅎ|ㅏ-ㅣ|가-힣| ]/g, "");
};

export const regKorEtc = (value: string) => {
  return value.replace(/[a-zA-Z|0-9]/g, "");
};

export const regEnglishEtc = (value: string) => {
  return value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|0-9]/g, "");
};

export const regOnlyNumber = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export const regNumberDot = (value: string) => {
  return value.replace(/[^0-9.]$/g, "");
};

export const regUrl = (value: string) => {
  return value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣 ]/g, "");
};

export const regPhoneNumber = (value: string) => {
  return value
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};

export const regPwContinuousCheck = (value: string) => {
  // 키보드 배열 조건
  const keyboard = ["1234567890", "qwertyuiop", "asdfghjkl", "zxcvbnm"];

  for (let i = 0; i < value.length - 2; i++) {
    const sliceValue = value.substring(i, i + 3);

    // 모든 조건을 한번씩 순회
    if (keyboard.some((code) => code.includes(sliceValue))) {
      return true;
    }
  }

  // 모든 조건을 넘겼을 때
  return false;
};

export const changeTimePickerTime = (timeNumber: string | any[]) => {
  return `${timeNumber.slice(0, 2)}:${timeNumber.slice(2, 4)}`;
};

export const displayYmd = (y: string | null | undefined) => {
  return y ? moment(y).format("YYYY.MM.DD") : "-";
};

export const displayYmdHis = (
  y: string | null | undefined,
  h: string | null | undefined
) => {
  return y && h ? moment(`${y} ${h}`).format("YYYY.MM.DD HH:mm:ss") : "-";
};

export const zeroFill = (number: number) => {
  if (number < 10) {
    return `0${number}`;
  } else {
    return `${number}`;
  }
};

export const showOrdinal = (index) => {
  return index % 10 === 1
    ? index + "st"
    : index % 10 === 2
    ? index + "nd"
    : index % 10 === 3
    ? index + "rd"
    : index + "th";
};

export const handleChangeSort = (
  schForm: { [key: string]: any },
  fld: string | null = null
) => {
  let form = JSON.parse(JSON.stringify(schForm));

  if (fld) {
    form.sordFld = fld;
  }

  if (form.sordMetd === "DESC") {
    form.sordMetd = "ASC";
  } else {
    form.sordMetd = "DESC";
  }

  return form;
};

export const rem = (size: number) => {
  let defaultSize = 16;
  const rem = size / defaultSize;
  return rem + "rem";
};

export const registVisitLog = (
  path: string,
  menus: Array<any> | null,
  mclSeq: number | null
) => {
  if (path && menus && menus.length > 0) {
    let filters = _.filterDeep(
      menus,
      (item, key, parentValue) => {
        return path.includes(item.url);
      },
      {
        childrenPath: "childMenus" as any,
      }
    );

    if (filters && filters.length > 0) {
      let textArr: string[] = [];

      _.eachDeep(
        filters,
        (item, key, parentValue) => {
          textArr.push(item.nm);
        },
        {
          childrenPath: "childMenus" as any,
        }
      );

      axios
        .post("/api/st/manager-connect-log/detail/regist", {
          // mclSeq,
          mclSeq: "1",
          cont: `${textArr.join(" > ")} > 상세`,
        })
        .then(() => {})
        .catch((err) => {
          console.log(err.response.data);
        });
    }
  }
};

export const trimSpacing = (obj: any) => {
  if (obj) {
    switch (typeof obj) {
      case "string":
        obj.trim();
        break;
      case "object":
        Object.keys(obj).map((key) => {
          obj[key] =
            typeof obj[key] === "string"
              ? obj[key].trim()
              : trimSpacing(obj[key]);
        });
        break;
      default:
        break;
    }
  }

  return obj;
};

export const lsToPx = (fontSize: number, percent: number) => {
  const value = fontSize * percent * -0.01;
  return `${value}px`;
};

export const filterYmd = (date: string | null | undefined) => {
  return date ? moment(date).format("YYYY. MM. DD") : "-";
};

export const handleItemHover = (index: number, parent: HTMLElement) => {
  if (!parent) return;
  const items = parent.querySelectorAll("li");
  if (!items) return;

  items.forEach((item: HTMLLIElement, i: number) => {
    if (index === i) {
      item.style.opacity = "1";
    } else if (index === -1) {
      item.style.opacity = "1";
    } else {
      item.style.opacity = "0.5";
    }
  });
};

export const handleMovePageTop = () => {
  // window.scrollTo({ top: 0, behavior: "smooth" });

  window.scrollTo(0, 0);
};

export const handleMouseLeave = () => {
  const cursor = document.getElementById("cursor");
  cursor.innerHTML = "";
  cursor.classList.remove("over");
  cursor.classList.remove("creation");
};

export const cursorInnerHTML = (title: string) => {
  return `<div class="rolling1"><div class="item"><span>${title}</span><i></i></div><div class="item"><span>${title}</span><i></i></div></div><div class="rolling2"><div class="item"><span>${title}</span><i></i></div><div class="item"><span>${title}</span><i></i></div></div>`;
};

export function lockDom(dom) {
  document.querySelector(dom).style.height = "100%";
  document.querySelector(dom).style.overflow = "hidden";
}

export function unlockDom(dom) {
  document.querySelector(dom).style.height = "";
  document.querySelector(dom).style.overflow = "";
}

export function handlePageToTop() {
  window.dispatchEvent(new Event("lenisTop"));
  // window.scrollTo({ top: 0, behavior: "smooth" });
}
