import ContactOffice from "@/components/contact/contact-office";
import ContactInquiry from "@/components/contact/contact-inquiry";
import ContactVisual from "@/components/contact/contact-visual";
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { contactHeaderMenuProgressState, lenisScrollState } from "@/state";
import theme from "@/assets/ts/theme";
import { useMediaQuery } from "@mui/material";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import { openModalState } from "@/state/contact";

export default function ContactUs() {
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const [menuProgressState, setMenuProgressState] = useRecoilState(contactHeaderMenuProgressState);

  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const visualRef = useRef<HTMLElement>(null);
  const inquiryRef = useRef<HTMLElement>(null);
  const officeRef = useRef<HTMLElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef(null);
  const scrollObjects = useRef({});
  // const submitBtnRef = useRef<HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useRecoilState(openModalState);

  useEffect(() => {
    document.querySelector("html").classList.add("b");

    return () => {
      document.querySelector("html").classList.remove("b");
    };
  }, []);

  useEffect(() => {
    let scroll = lenisState.scroll;
    let progress = lenisState.progress;
    if (!!visualRef.current && !inquiryRef.current && !officeRef.current) return;

    const officeSectionMargin = getComputedStyle(officeRef.current).marginTop;
    const marginTop = Number(officeSectionMargin.replace("px", ""));
    backgroundRef.current.style.height = `${document.querySelector("main").clientHeight}px`;

    if (!isMobile) {
      let backgroundChangePoint = visualRef.current.clientHeight + inquiryRef.current.clientHeight - marginTop;
      let backgroundStyleValue =
        (lenisState.scroll - backgroundChangePoint) /
        (document.querySelector("main").clientHeight - 700 - lenisState.scroll);

      if (lenisState.scroll < 100) {
        backgroundRef.current.style.filter = `invert(0)`;
      }

      if (lenisState.scroll < backgroundChangePoint + marginTop && lenisState.scroll > 100) {
        backgroundRef.current.style.filter = `invert(${backgroundStyleValue.toFixed(3)})`;
        setMenuProgressState((prev) => ({
          ...prev,
          enquiry: progress * 100 * 2,
        }));
      } else {
        backgroundRef.current.style.filter = `invert(${backgroundStyleValue.toFixed(3)})`;
        // let test = progress.toFixed(1);
        setMenuProgressState((prev) => ({
          ...prev,
          officeSpace: clamp(Math.floor(Number(progress.toFixed(1)))),
        }));
      }
    } else {
      // mobile version

      const backgroundChangePoint = visualRef.current.clientHeight + inquiryRef.current.clientHeight - marginTop * 1.5;

      let backgroundStyleValue =
        (lenisState.scroll - backgroundChangePoint) /
        (officeRef.current.querySelectorAll(".content-item")[0].clientHeight + 300 * 5 - lenisState.scroll);

      if (lenisState.scroll > backgroundChangePoint) {
        backgroundRef.current.style.filter = `invert(${backgroundStyleValue.toFixed(3)})`;
      }
    }
  }, [lenisState, isMobile]);

  return (
    <div ref={contactRef}>
      <div className="scroll-bg" css={style.bg} ref={backgroundRef}></div>
      <ContactVisual ref={visualRef} />
      <ContactInquiry ref={inquiryRef} />
      <ContactOffice ref={officeRef} />
    </div>
  );
}

const style = {
  bg: css`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: ${theme.palette.black};
  `,
};
