import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import { XTRowTab, XTRowTabs } from "@/components/ui/tabs";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import globalCSS from "@/assets/ts/global-css";
import { XTCheckBox, XTInput, XTTextarea } from "../ui/input";
import { Checkbox, useMediaQuery } from "@mui/material";
import { lenisScrollState, pageYState } from "@/state";
import { useRecoilState } from "recoil";
import { useContactManager } from "@/hooks/use-contact";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { XTPrivacyModal } from "../ui/modal";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import gsap from "gsap";
import { t } from "i18next";
import Lenis from "@studio-freight/lenis";
import { openModalState } from "@/state/contact";
import { lockDom } from "@/assets/ts/common";
import i18n from "@/i18n";
import { Box } from "@mui/system";

interface ScrollObjectType {
  observer: Observer | null;
  scrollObj: ScrollObject | null;
}

//ContactInquiry
const ContactInquiry = forwardRef<HTMLElement, {}>((props, ref) => {
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const { registContactForm } = useContactManager();

  type RegisterSchemaType = {
    tp: 1 | 2;
    corpNm: string;
    nm: string;
    cph: string;
    email: string;
    memo: string;
    agree: boolean;
  };

  const contactRegistFormSchema = yup.object().shape({
    tp: yup.number().oneOf([1, 2]),
    corpNm: yup.string().required(t("contact:validation.company")),
    nm: yup.string().required(t("contact:validation.name")),
    cph: yup
      .string()
      .required(t("contact:validation.phone"))
      .test(t("contact:validation.hyphen"), (value) => !value.includes("-")),
    email: yup.string().email(t("contact:validation.emailFormat")).required(t("contact:validation.email")),
    memo: yup
      .string()
      .required(t("contact:validation.inquiry"))
      .when("tp", {
        is: 1,
        then: yup.string().max(2000, t("contact:validation.generalInquiry")),
        otherwise: yup.string().max(5000, t("contact:validation.scheduleInquiry")),
      }),
    agree: yup
      .boolean()
      .required(t("contact:validation.personalInfoConsent"))
      .oneOf([true], t("contact:validation.personalInfoConsent")),
  });

  const {
    reset,
    setValue,
    getValues,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterSchemaType>({
    defaultValues: {
      tp: 1,
      corpNm: "",
      nm: "",
      cph: "",
      email: "",
      memo: "",
      agree: false,
    },
    resolver: yupResolver(contactRegistFormSchema),
  });

  const submitBtnRef = useRef(null);

  const interactionItems = useRef<ScrollObjectType>({
    observer: null,
    scrollObj: null,
  });

  useEffect(() => {
    if (submitBtnRef.current) {
      interactionItems.current.observer = new Observer();
      interactionItems.current.observer.add(submitBtnRef.current);
      interactionItems.current.scrollObj = new ScrollObject(submitBtnRef.current);
    }

    return () => {
      if (submitBtnRef.current) {
        interactionItems.current.observer.remove(submitBtnRef.current);
        interactionItems.current.observer.kill();
        interactionItems.current.scrollObj.kill();
      }
    };
  }, [submitBtnRef]);

  const [submitAnimTl, setSubmitAnimTl] = useState(gsap.timeline({ paused: true }));
  const [isSubmiting, setSubmiting] = useState(false);

  useEffect(() => {
    submitAnimTl
      .addLabel("m1")
      .to(
        ".btn-submit .background",
        {
          width: "120%",
        },
        "m1"
      )
      .to(
        ".submit-state-0",
        {
          top: "-20%",
          duration: 0.7,
        },
        "m1"
      )
      .to(".submit-state-1", {
        top: "50%",
        duration: 0.7,
      })
      .to(".submit-state-1", {
        top: "-20%",
        duration: 0.7,
        delay: 1,
      })
      .to(".submit-state-2", {
        top: "50%",
        duration: 0.7,
      })
      .to(".submit-state-2", {
        top: "-20%",
        duration: 0.7,
        delay: 1,
      })
      .set(".submit-state-0", {
        top: "120%",
        color: "#1011DD",
      })

      .addLabel("m2")
      .to(
        ".submit-state-0",
        {
          top: "50%",
          duration: 0.7,
          // onComplete: () => setSubmiting(false),
        },
        "m2"
      )
      .to(
        ".btn-submit .background",
        {
          width: "0px",
          onComplete: () => {
            reset();
            setSubmiting(false);
          },
          onStart: () =>
            gsap.to(".submit-state-0", {
              color: "white",
              delay: 0.2,
            }),
        },
        "m2"
      );
  }, []);

  const [XTTabValue, setXTTabValue] = useState(1);
  const handleTabChange = (event: React.SyntheticEvent, newVal: 1 | 2) => {
    setXTTabValue(newVal);
    setValue("tp", newVal);
  };

  const [disabledBtn, setDisabledBtn] = useState(true);

  const onSubmit = (e) => {
    // e.preventDefault();
    setDisabledBtn(false);

    setTimeout(() => {
      setDisabledBtn(true);
    }, 6500);

    const form = getValues();

    if (form.agree) {
      delete form.agree;
    }

    if (disabledBtn) {
      registContactForm.mutate(form, {
        onSuccess: (res) => {
          setSubmiting(true);
          submitAnimTl.play();
        },
      });
    }
  };

  const onError = (error) => {
    console.log("er", error);
  };

  const [pageY, setPageY] = useRecoilState(pageYState);

  function scrollLock() {
    setPageY(window.scrollY);
    const main = document.querySelector("main");
    main.style.top = `-${window.scrollY}px`;
    lockDom("html");
    lockDom("body");
  }

  const [isModalOpen, setIsModalOpen] = useRecoilState(openModalState);
  const lenis = new Lenis();

  const handleOpenAgree = () => {
    setIsModalOpen(true);
    if (isMobile && i18n.language === "en") {
      window.dispatchEvent(new Event("lenisStart"));
    } else {
      window.dispatchEvent(new Event("lenisStop"));
    }
  };

  const handleCloseAgree = () => {
    setIsModalOpen(false);
  };

  const handleResetTp = () => {
    reset({
      tp: watch("tp"),
      corpNm: "",
      nm: "",
      cph: "",
      email: "",
      memo: "",
      agree: false,
    });
  };

  useEffect(() => {
    handleResetTp();
  }, [watch("tp")]);

  return (
    <>
      <section className="sc-form" css={style.form} ref={ref} id="enquiry">
        <div className="tab-box">
          <XTRowTabs value={XTTabValue} onChange={handleTabChange}>
            <XTRowTab value={1} index={1} label="enquiry" />
            <XTRowTab value={2} index={2} label="Schedule" />
          </XTRowTabs>
        </div>
        <div className="content-box">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="form-area" css={globalCSS.formBox}>
              <Controller
                name="corpNm"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { ...field } }) => (
                  <div className="form-box">
                    <span className="label">company</span>
                    <XTInput
                      {...field}
                      placeholder={t("contact:placeholder.companyName")}
                      required
                      type="text"
                      endAdornment={
                        <div className="input-btn-wrap">
                          {watch("corpNm").length > 0 && (
                            <button
                              onClick={() => {
                                setValue("corpNm", "", {
                                  shouldValidate: true,
                                });
                              }}>
                              <i css={globalCSS.icoDelete}></i>
                            </button>
                          )}
                        </div>
                      }
                    />
                    {errors?.corpNm?.message && <p css={globalCSS.formErrorMsg}>{errors?.corpNm?.message}</p>}
                  </div>
                )}
              />
              <Controller
                name="nm"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { ...field } }) => (
                  <div className="form-box">
                    <span className="label">name</span>
                    <XTInput
                      {...field}
                      placeholder={t("contact:placeholder.name")}
                      required
                      type="text"
                      endAdornment={
                        <div className="input-btn-wrap">
                          {watch("nm").length > 0 && (
                            <button
                              onClick={() => {
                                setValue("nm", "", { shouldValidate: true });
                              }}>
                              <i css={globalCSS.icoDelete}></i>
                            </button>
                          )}
                        </div>
                      }
                    />
                    {errors?.nm?.message && <p css={globalCSS.formErrorMsg}>{errors?.nm?.message}</p>}
                  </div>
                )}
              />
            </div>
            <div className="form-area" css={globalCSS.formBox}>
              <Controller
                name="cph"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { ...field } }) => (
                  <div className="form-box">
                    <span className="label">phone number</span>
                    <XTInput
                      {...field}
                      placeholder={t("contact:placeholder.phoneNumber")}
                      required
                      type="text"
                      endAdornment={
                        <div className="input-btn-wrap">
                          {watch("cph").length > 0 && (
                            <button
                              onClick={() => {
                                setValue("cph", "", { shouldValidate: true });
                              }}>
                              <i css={globalCSS.icoDelete}></i>
                            </button>
                          )}
                        </div>
                      }
                    />
                    {errors?.cph?.message && <p css={globalCSS.formErrorMsg}>{errors?.cph?.message}</p>}
                  </div>
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { ...field } }) => (
                  <div className="form-box">
                    <span className="label">email</span>
                    <XTInput
                      {...field}
                      placeholder={t("contact:placeholder.email")}
                      required
                      type="text"
                      endAdornment={
                        <div className="input-btn-wrap">
                          {watch("email").length > 0 && (
                            <button
                              onClick={() => {
                                setValue("email", "", { shouldValidate: true });
                              }}>
                              <i css={globalCSS.icoDelete}></i>
                            </button>
                          )}
                        </div>
                      }
                    />
                    {errors?.email?.message && <p css={globalCSS.formErrorMsg}>{errors?.email?.message}</p>}
                  </div>
                )}
              />
            </div>
            <div className="form-area" css={globalCSS.formBox}>
              <Controller
                name="memo"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { ...field } }) => (
                  <div className="form-box">
                    <span className="label">message</span>
                    <XTTextarea {...field} placeholder={t("contact:placeholder.message")} required multiline />
                    {errors?.memo?.message && <p css={globalCSS.formErrorMsg}>{errors?.memo?.message}</p>}
                  </div>
                )}
              />
            </div>

            <div className="checkbox-box" css={globalCSS.checkboxBox}>
              <div className="flex-row-box">
                <Controller
                  name="agree"
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <XTCheckBox
                      {...field}
                      value={watch("agree")}
                      onChange={(e: Object, checked: boolean) => {
                        onChange(checked ? true : false);
                      }}
                      label={t("contact:placeholder.personalInfoConsent")}
                      control={<Checkbox checked={watch("agree") === true} />}
                    />
                  )}
                />
                <button
                  type="button"
                  onClick={handleOpenAgree}
                  dangerouslySetInnerHTML={{ __html: t("contact:modalOpen") }}></button>
              </div>

              {errors?.agree?.message && <p css={globalCSS.formErrorMsg}>{errors?.agree?.message}</p>}
            </div>

            <div className="submit-box">
              <button
                className="btn-submit"
                type="submit"
                onClick={handleSubmit(onSubmit, onError)}
                ref={submitBtnRef}
                css={[globalCSS.flexibleLink, style.submitBtn]}>
                <div className="background"></div>
                <span className="submit-inner submit-state-0">
                  Submit
                  <i css={globalCSS.icoArrowRight} className={isSubmiting ? "blue" : ""}></i>
                </span>
                <span className="submit-inner submit-state-1"></span>
                <span className="submit-inner submit-state-2" style={{ color: isSubmiting ? "#1011DD" : "#fff" }}>
                  Success
                  <span className="check">
                    <svg
                      className="check-svg"
                      width="20"
                      height="70"
                      viewBox="0 0 25 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path className="check-path" d="M2.5 7.5L10 15L23 2" stroke="#1011DD" stroke-width="4" />
                    </svg>
                  </span>
                </span>
              </button>
            </div>
          </form>
        </div>
      </section>
      <XTPrivacyModal>
        <Box className="modal-wrap" css={globalCSS.modalWrapper} style={{}}>
          {!isMobile ? (
            <h2
              dangerouslySetInnerHTML={{
                __html: t("contact:privacyPolicy.title"),
              }}></h2>
          ) : (
            <h2
              dangerouslySetInnerHTML={{
                __html: t("contact:privacyPolicy.titleMo"),
              }}></h2>
          )}
          <p
            dangerouslySetInnerHTML={{
              __html: t("contact:privacyPolicy.content"),
            }}></p>
          <dl>
            <dt
              style={{
                maxWidth: i18n.language === "en" ? "fit-content" : "auto",
                padding: i18n.language === "en" ? "0 10px" : "0",
              }}
              dangerouslySetInnerHTML={{
                __html: t("contact:privacyPolicy.purposeOfCollection.title"),
              }}></dt>
            <dd
              dangerouslySetInnerHTML={{
                __html: t("contact:privacyPolicy.purposeOfCollection.content"),
              }}></dd>
          </dl>
          <dl>
            <dt
              style={{
                maxWidth: i18n.language === "en" ? "fit-content" : "auto",
                padding: i18n.language === "en" ? "0 10px" : "0",
              }}
              dangerouslySetInnerHTML={{
                __html: t("contact:privacyPolicy.itemsCollected.title"),
              }}></dt>
            <dd
              dangerouslySetInnerHTML={{
                __html: t("contact:privacyPolicy.itemsCollected.content"),
              }}></dd>
          </dl>
          <dl>
            <dt
              style={{
                maxWidth: i18n.language === "en" ? "fit-content" : "auto",
                padding: i18n.language === "en" ? "0 10px" : "0",
              }}
              dangerouslySetInnerHTML={{
                __html: t("contact:privacyPolicy.retentionPeriod.title"),
              }}></dt>
            <dd
              dangerouslySetInnerHTML={{
                __html: t("contact:privacyPolicy.retentionPeriod.content"),
              }}></dd>
          </dl>
        </Box>
      </XTPrivacyModal>
    </>
  );
});

const style = {
  form: css`
    width: 100%;
    max-width: ${theme.typography.pxToRem(1140)};
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding-bottom: ${theme.typography.pxToRem(100)};

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding: 0 ${theme.typography.pxToRem(20)};
    }

    .tab-box {
      margin-bottom: ${theme.typography.pxToRem(60)};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-bottom: ${theme.typography.pxToRem(80)};
      }
    }

    .checkbox-box {
      margin: ${theme.typography.pxToRem(60)} 0;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin: ${theme.typography.pxToRem(30)} 0 ${theme.typography.pxToRem(80)};
      }
    }

    .submit-box {
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        display: flex;
        justify-content: center;
        /* width: 100%; */

        .more-button-wrap {
          display: flex;
          justify-content: center;
          width: 100%;
        }
      }
    }
  `,

  submitBtn: css`
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .submit-inner {
      position: absolute;
      text-transform: capitalize;
      transition-timing-function: cubic-bezier(0.43, 0.05, 0.17, 1);
      opacity: 1 !important;

      &.submit-state-0 {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
      }

      &.submit-state-1 {
        top: 120%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: ${theme.palette.blue};
        color: ${theme.palette.blue};
        animation: dot 1s infinite linear alternate;
        animation-delay: 0.5s;

        /* display: none; */

        &::before,
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 0;
        }

        &::before {
          left: -15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: ${theme.palette.blue};
          color: ${theme.palette.blue};
          animation: dot 1s infinite alternate;
          animation-delay: 0s;
        }

        &::after {
          left: 15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: ${theme.palette.blue};
          color: ${theme.palette.blue};
          animation: dot 1s infinite alternate;
          animation-delay: 1s;
        }

        @keyframes dot {
          0% {
            background-color: ${theme.palette.blue};
          }

          50%,
          100% {
            background-color: rgba(16, 17, 221, 0.2);
          }
        }
      }

      &.submit-state-2 {
        top: 120%;
        left: 50%;
        transform: translate(-50%, -50%);

        .check {
          opacity: 1 !important;
        }
      }
    }

    &:hover {
      .article-0 {
        background: url("/images/icon/ico-arrow-right-blue.svg") no-repeat center / cover;
      }
    }
  `,
};

export default ContactInquiry;
