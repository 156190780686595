export const MONTH_LIST = [
  { nm: "All", cd: "" },
  { nm: "January", cd: "01" },
  { nm: "February", cd: "02" },
  { nm: "March", cd: "03" },
  { nm: "April", cd: "04" },
  { nm: "May", cd: "05" },
  { nm: "June", cd: "06" },
  { nm: "July", cd: "07" },
  { nm: "August", cd: "08" },
  { nm: "September", cd: "09" },
  { nm: "October", cd: "10" },
  { nm: "November", cd: "11" },
  { nm: "December", cd: "12" },
];
