import { useTranslation } from "react-i18next";

export function useLanguage() {
  const { i18n } = useTranslation();

  const lang = i18n.language;

  if (lang === "ko") {
    // 국문
    return "1";
  } else {
    // 영문
    return "2";
  }
}
