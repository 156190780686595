import { cursorState } from "./../state/index";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { handleMouseLeave } from "@/assets/ts/common";
export function useCursor(parentDom: HTMLElement) {
  const cursorInnerHTML = (title: string) => {
    return `<div class="rolling1"><div class="item"><span>${title}</span><i></i></div><div class="item"><span>${title}</span><i></i></div></div><div class="rolling2"><div class="item"><span>${title}</span><i></i></div><div class="item"><span>${title}</span><i></i></div></div>`;
  };

  const [isCursorHoverActive, setIsCursorHoverActive] = useState(false);

  useEffect(() => {
    if (!parentDom) return;
    const items = parentDom.querySelectorAll(".creation-item");
    const cursor = document.getElementById("cursor");
    items.forEach((item) => {
      const title = item.querySelector("figcaption").innerText;
      if (!title || !cursor) return;

      function handleMouseEnter() {
        setIsCursorHoverActive(true);
        cursor.innerHTML = cursorInnerHTML(title);
        cursor.classList.add("over");
        cursor.classList.add("creation");
      }

      item.addEventListener("mouseenter", handleMouseEnter);
      item.addEventListener("mouseleave", () => {
        setIsCursorHoverActive(false);
        handleMouseLeave();
      });

      return () => {
        item.removeEventListener("mouseenter", handleMouseEnter);
        item.removeEventListener("mouseleave", () => {
          setIsCursorHoverActive(false);
          handleMouseLeave();
        });
      };
    });
  }, [parentDom]);

  return isCursorHoverActive;
}
