export const imageTpType = {
  HORIZONTAL_IMAGE_SEQ: 2,
  VERTICAL_IMAGE_SEQ: 3,
  DETAIL_IMAGE_SEQ: 4,
  CONTENT_IMAGE_SEQ: 5,
  PC_IMAGE_SEQ: 6,
  MO_IMAGE_SEQ: 7,
} as const;

export const videoTpType = {
  CREATION_SHORT_SEQ: 1,
  CREATION_LONG_SEQ: 2,
} as const;
