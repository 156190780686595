import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { lenisScrollState } from "@/state";
import { css } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import FadeText from "../animation/fade-texts";

const InternationalInfo = forwardRef<HTMLElement, {}>((props, ref) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const [textProgress, setTextProgress] = useState({
    empowering: 0,
    artists: 0,
  });
  const textWrapperRef = useRef(null);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const interactionItems = useRef<any>({
    scrollObjects: [],
  });

  useEffect(() => {
    interactionItems.current.scrollObjects["empowering"] = new ScrollObject(textWrapperRef.current);
    interactionItems.current.scrollObjects["artists"] = new ScrollObject(textWrapperRef.current);
  }, []);

  useEffect(() => {
    for (let key in interactionItems.current.scrollObjects) {
      interactionItems.current.scrollObjects[key].update(lenisState.scroll);
    }
    let empoweringScrollObj = interactionItems.current.scrollObjects["empowering"];
    let artistsScrollObj = interactionItems.current.scrollObjects["artists"];
    setTextProgress({
      empowering: empoweringScrollObj.ratio.yClamp + 0.4,
      artists: artistsScrollObj.ratio.yClamp + 0.4,
    });

    return () => {
      for (let key in interactionItems.current.scrollObjects) {
        interactionItems.current.scrollObjects[key].kill();
      }
    };
  }, [lenisState]);
  return (
    <section className="sc-info" css={style.info} ref={ref}>
      <div className="img-box">
        <img src="/images/image/international/img-info.png" alt="bauerlab international" />
      </div>
      <div className="text-box" ref={textWrapperRef}>
        <h2 className="sc-title">
          <FadeText texts={["Empowering"]} progress={textProgress.empowering} />
        </h2>
        <p className="sc-desc">
          {!isMobile ? (
            <>
              <span>
                We help international design studios and artists make a mark in both domestic and global markets.
              </span>
              <span>
                Through diverse collaborations, they're producing top-notch content and sharing technical expertise,
              </span>
              <span>creating a positive influence in the industry.</span>
            </>
          ) : (
            <span>
              We help international design studios and artists make a mark in both domestic and global markets. Through
              diverse collaborations, they're producing top-notch content and sharing technical expertise, creating a
              positive influence in the industry.
            </span>
          )}
        </p>
        <h2 className="sc-title">
          <FadeText texts={[`GLOBAL ARTISTS`]} progress={textProgress.artists} />
        </h2>
      </div>
    </section>
  );
});

const style = {
  info: css`
    /* margin: 100vh 0; */
    margin-top: ${theme.typography.pxToRem(800)};
    position: relative;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      margin-top: ${theme.typography.pxToRem(400)};
    }
    .img-box {
      position: relative;
      width: 100%;
      aspect-ratio: 1920 / 1569;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        aspect-ratio: 375 / 650;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text-box {
      position: absolute;
      top: calc(1100 / 1569 * 100%);
      left: 50%;
      /* width: calc(1300 / 1920 * 100%); */
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translateX(-50%);
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        top: calc(500 / 650 * 100%);
        width: 100%;
        padding: 0 ${theme.typography.pxToRem(22)};
      }

      h2 {
        ${globalCSS.en};
        font-weight: 700;
        /* font-size: ${theme.typography.pxToRem(100)}; */
        font-size: calc(100 / 1920 * 100vw);
        line-height: 1;
        color: ${theme.palette.yTxt};
        text-transform: uppercase;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: calc(30 / 375 * 100vw);
          /* font-size: ${theme.typography.pxToRem(30)}; */
          text-align: center;
        }

        &:nth-of-type(1) {
          transform: translateX(-130px);
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            transform: none;
          }
        }

        &:nth-of-type(2) {
          transform: translateX(calc(300 / 1300 * 100%));
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            transform: none;
          }
        }
      }

      p {
        ${globalCSS.en};
        font-weight: 400;
        /* font-size: ${theme.typography.pxToRem(27)}; */
        font-size: calc(27 / 1920 * 100vw);
        line-height: calc(32 / 27);
        color: ${theme.palette.white};
        /* text-align: center; */

        margin: ${theme.typography.pxToRem(40)} 0;
        display: flex;
        flex-direction: column;

        span {
          max-width: fit-content;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            text-align: center;
          }

          &:nth-of-type(1) {
            transform: translateX(70px);
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              transform: none;
            }
          }
          &:nth-of-type(2) {
          }
          &:nth-of-type(3) {
            transform: translateX(200px);
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              transform: none;
            }
          }
        }
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin: ${theme.typography.pxToRem(20)} 0;
          font-size: calc(16 / 375 * 100vw);
          line-height: calc(20 / 16);
        }
      }
    }
  `,
};

export default InternationalInfo;
