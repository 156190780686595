import Circle from "@/assets/ts/bauerlab/Circle";
import globalCSS from "@/assets/ts/global-css";
import { Modal, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import theme from "@/assets/ts/theme";
import { useRecoilState } from "recoil";
import { openModalState } from "@/state/contact";
import { unlockDom } from "@/assets/ts/common";
import { pageYState } from "@/state";
import i18n from "@/i18n";
// import useSmoothScroll from "@/hooks/use-smooth-scroll";

export const XTModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  ".MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.95)",
  },
}));

export const XTPrivacyModal = ({ children }) => {
  const circleRef = useRef(null);
  const circleCanvasRef = useRef(null);
  useEffect(() => {
    if (!circleRef.current) {
      circleRef.current = new Circle(circleCanvasRef.current, "transparent");
    }
  }, [circleCanvasRef]);

  const [isModalOpen, setIsModalOpen] = useRecoilState(openModalState);
  // const lenis = useSmoothScroll();

  // useEffect(() => {
  // if (isModalOpen) {
  //   lenis.stop();
  // } else {
  //   // lenis.start();
  // }
  // }, [isModalOpen, lenis]);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  return (
    <div
      className="modal-area"
      data-open={isModalOpen ? "open" : "close"}
      css={style.modal}
      onWheel={(e) => {
        if (i18n.language === "en" && isMobile) {
          e.stopPropagation();
        }
      }}>
      <div className="modal-header" css={style.modalHeader}>
        <h1 className="sub-logo-sm">
          <img src="/images/icon/logo.svg" alt="바우어랩" />
        </h1>
        <button
          css={globalCSS.menuBtn}
          onClick={() => {
            setIsModalOpen(false);
            window.dispatchEvent(new Event("lenisStart"));
          }}>
          <span>Close</span>
        </button>
      </div>
      <canvas ref={circleCanvasRef} css={[globalCSS.circleCanvas, style.canvas]}></canvas>
      {children}
    </div>
  );
};

const style = {
  modal: css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      height: 100vh;
    }

    .modal-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(20)} 0 ${theme.typography.pxToRem(20)};
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      h1 {
        width: ${theme.typography.pxToRem(30)};
        height: ${theme.typography.pxToRem(30)};
        margin-top: ${theme.typography.pxToRem(-5)};
        img {
          width: 100%;
          height: 100%;
        }
      }

      button {
        width: ${theme.typography.pxToRem(32)};
        height: ${theme.typography.pxToRem(32)};
        max-width: ${theme.typography.pxToRem(90)};
        transition: all 0.4s 0.3s;

        span {
          left: ${theme.typography.pxToRem(-14)};
          transition: opacity 0.4s 0.3s;
        }
      }
    }

    &[data-open="open"] {
      opacity: 1;
      visibility: visible;

      .modal-header {
        button {
          width: ${theme.typography.pxToRem(90)};
          /* flex: 1 0 ${theme.typography.pxToRem(90)}; */
          span {
            left: ${theme.typography.pxToRem(14)};
            opacity: 1;
          }
        }
      }
    }
    &[data-open="close"] {
      opacity: 0;
      visibility: hidden;
    }
  `,

  modalHeader: css`
    padding: ${theme.typography.pxToRem(20)};
    padding-bottom: 0;
  `,

  canvas: css`
    opacity: 1 !important;
    transform: scale(1.2) !important;
  `,
};
