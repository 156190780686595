import globalCSS from "@/assets/ts/global-css";
import { lenisScrollState } from "@/state";
import commonMotion from "@/utils/ineraction/common";
import { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
type propsTypes = {
  type: string;
  onClick?: () => void;
};

export default function RoundedButton({ type, onClick }: propsTypes) {
  const buttonRef = useRef(null);

  const appearPoint = 300;
  const lenis = useRecoilValue(lenisScrollState);

  useEffect(() => {
    commonMotion.buttonRolling(buttonRef.current);

    const onScroll = () => {
      if (type === "top") {
        if (window.scrollY < appearPoint) {
          hideTopButton();
        }
        if (window.scrollY >= appearPoint) {
          appearTopButton();
        }
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if (type !== "scroll") return;
    let start = window.innerHeight * 0.25;
    let end = window.innerHeight * 0.6;

    if (lenis.scroll > start && lenis.scroll < end && lenis.direction === 1) {
      const opacity = 1 - (1 * (lenis.scroll - start)) / (end - start);
      const y = 0 - ((100 - 0) * (lenis.scroll - start)) / (end - start);
      buttonRef.current.style.opacity = `${opacity}`;
      buttonRef.current.style.transform = `rotate(90deg) translateX(${y}px)`;
    }

    if (lenis.scroll < start && lenis.direction === -1) {
      const opacity = 0 - (0 - (1 * (lenis.scroll - start)) / (0 - start));
      const y = -100 - ((0 - 100) * (lenis.scroll - start)) / (0 - start);
      buttonRef.current.style.opacity = `${opacity}`;
      buttonRef.current.style.transform = `rotate(90deg) translateX(${y}px)`;
    }
  }, [lenis, type]);

  const appearTopButton = () => {
    buttonRef.current.classList.add("active");
  };

  const hideTopButton = () => {
    buttonRef.current.classList.remove("active");
  };

  const handleMoveTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getArrowDirection = (type: string) => {
    switch (type) {
      case "scroll":
        return <i css={globalCSS.icoArrowDown}></i>;
      case "more":
        return <i css={globalCSS.icoArrowDown}></i>;
      case "list":
        return <i css={globalCSS.icoArrowLeft}></i>;
      case "top":
        return <i css={globalCSS.icoArrowUp}></i>;
    }
  };

  const getClassName = (type: string) => {
    switch (type) {
      case "scroll":
        return "round-wrap scroll";
      case "more":
        return "round-wrap more";
      case "list":
        return "round-wrap list";
      case "top":
        return "round-wrap top";
    }
  };

  const handleClickButton = () => {
    if (type === "top") {
      handleMoveTop();
    } else {
      onClick();
    }
  };

  return (
    <button className={getClassName(type)} css={globalCSS.roundButton} ref={buttonRef} onClick={handleClickButton}>
      <ul className="item-wrap">
        <li className="item">
          <span>{type}</span>
          {getArrowDirection(type)}
        </li>
        <li className="item">
          <span>{type}</span>
          {getArrowDirection(type)}
        </li>
      </ul>
    </button>
  );
}
