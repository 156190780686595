import { lsToPx } from "@/assets/ts/common";
import theme from "@/assets/ts/theme";
import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const List = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${theme.typography.pxToRem(60)};
  padding-top: ${theme.typography.pxToRem(70)};
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    gap: ${theme.typography.pxToRem(80)};
  }
`;

export const Item = styled(motion.li)`
  list-style: none;
  width: 100%;
  /* height: ${theme.typography.pxToRem(140.62)}; */
  height: 100%;
  max-width: ${theme.typography.pxToRem(1140)};
  transition: opacity 0.2s;
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    height: auto;
  }

  a {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    color: inherit;
    gap: ${theme.typography.pxToRem(60)};
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: column;
      align-items: flex-start;
      gap: ${theme.typography.pxToRem(20)};
    }

    &:hover {
      .article-view-more {
        /* display: flex; */
        transform: translateY(0);
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: none;
        }
      }
    }
  }

  .img-box {
    position: relative;
    width: ${theme.typography.pxToRem(250)};
    aspect-ratio: 250 / 140.62;
    overflow: hidden;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      width: 100%;
      /* height: ${theme.typography.pxToRem(188.44)}; */
      /* aspect-ratio: auto; */
      aspect-ratio: 355 / 188.44;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text-box {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 700;
      font-size: ${theme.typography.pxToRem(26)};
      line-height: 1.2;
      margin-bottom: ${theme.typography.pxToRem(15)};
      color: ${theme.palette.white};

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        font-size: ${theme.typography.pxToRem(24)};
        line-height: calc(30 / 24);
        margin-bottom: ${theme.typography.pxToRem(8)};
      }
    }

    .desc-box {
      display: flex;
      align-items: center;
      gap: ${theme.typography.pxToRem(60)};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        gap: ${theme.typography.pxToRem(20)};
      }

      span {
        font-weight: 400;
        font-size: ${theme.typography.pxToRem(18)};
        line-height: calc(22 / 18);
        ${lsToPx(18, -2)};
        color: ${theme.palette.white};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(16)};
          line-height: calc(20 / 16);
          letter-spacing: 0;
        }
      }
    }
  }

  &.item-news {
    .text-box {
      .title {
        display: flex;
        align-items: center;
        position: relative;
        /* border: 1px solid red; */
        max-width: fit-content;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: inline;
        }
        &::before {
          content: "";
          position: absolute;
          display: inline-flex;
          width: 0;
          height: 2.23px;
          background-color: ${theme.palette.white};
          left: 0;
          bottom: -3px;
          transition: width 0.3s;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            display: none;
          }
        }

        &::after {
          content: "";
          display: block;
          width: ${theme.typography.pxToRem(24)};
          height: ${theme.typography.pxToRem(24)};
          background: url("/images/icon/ico-arrow-menu.svg") no-repeat center / cover;
          margin-left: 5px;
          transform-origin: bottom left;
          transform: scale(0);
          transition: transform 0.3s 0.1s;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            display: none;
          }
        }
      }
    }
    &:hover {
      .text-box .title {
        &::before {
          width: calc(100% - ${theme.typography.pxToRem(24)} - 5px);
        }

        &::after {
          transform: scale(1);
        }
      }
    }
  }
`;

export const DetailWrap = styled(motion.div)`
  width: 100%;
  height: 100%;
  max-width: ${theme.typography.pxToRem(1140)};
  margin: 0 auto;
`;

export const DetailOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: scroll;
  z-index: 98;
`;

export const DetailDesc = styled.dl`
  display: flex;
  align-items: flex-start;
  gap: ${theme.typography.pxToRem(15)};

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    gap: ${theme.typography.pxToRem(10.5)};
  }

  + dl {
    margin-top: ${theme.typography.pxToRem(20)};
  }

  dt {
    min-width: ${theme.typography.pxToRem(90)};
    height: ${theme.typography.pxToRem(30)};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.palette.white};
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(14)};
    line-height: 1;
    color: ${theme.palette.white};
    ${lsToPx(14, -2)};
    border-radius: 100px;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      min-width: ${theme.typography.pxToRem(60)};
      letter-spacing: 0;
    }
  }

  dd {
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(18)};
    line-height: calc(28 / 18);
    color: ${theme.palette.white};
    width: ${theme.typography.pxToRem(300)};
    word-break: auto-phrase;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      font-weight: 600;
      font-size: ${theme.typography.pxToRem(16)};
      line-height: calc(26 / 16);
      width: auto;
    }

    a {
      cursor: pointer;
      position: relative;
      color: ${theme.palette.white};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        word-break: break-all;
      }
      &::before {
        content: "";
        width: 0;
        height: 1.5px;
        background-color: ${theme.palette.white};
        display: block;
        position: absolute;
        bottom: -3px;
        left: 0;
        transition: all 0.3s;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: none;
        }
      }

      &::after {
        content: "";
        display: block;
        width: ${theme.typography.pxToRem(16)};
        height: ${theme.typography.pxToRem(16)};
        background: url("/images/icon/ico-arrow-menu.svg") no-repeat center / cover;
        position: absolute;
        bottom: ${theme.typography.pxToRem(3)};
        right: ${theme.typography.pxToRem(-20)};
        transform: scale(0);
        transform-origin: left bottom;
        transition: transform 0.3s 0.2s;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: none;
        }
      }
      &:hover {
        &::before {
          width: 100%;
        }

        &::after {
          transform: scale(1);
        }
      }
    }
  }
`;
