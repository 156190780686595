import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

export default function RoundedLink(props) {
  return (
    <Link {...props} css={style.main}>
      <span>{props.children}</span>
    </Link>
  );
}

const style = {
  main: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(22)};
    color: white;
    border-radius: 25px;
    border: solid 1px rgba(255, 255, 255, 0.5);
    font-size: ${theme.typography.pxToRem(17)};
    letter-spacing: -0.02em;
    /* line-height: 1em; */
    line-height: 1;
    height: ${theme.typography.pxToRem(32)};
    ${globalCSS.en};
  `,
};
