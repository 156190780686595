export const menu = [
  {
    cd: "AA",
    depth: "1",
    nm: "about",
    url: "/about",
    order: 0,
  },
  {
    cd: "BB",
    depth: "1",
    nm: "creation",
    url: "/creation",
    order: 0,
  },
  {
    cd: "CC",
    depth: "1",
    nm: "international co.work",
    url: "",
    order: 1,

    childMenus: [
      {
        cd: "CCAA",
        depth: "2",
        nm: "international co.work",
        url: "/international",
      },
      {
        cd: "CCBB",
        depth: "2",
        nm: "scheduler",
        url: "/scheduler",
      },
      {
        cd: "CCCC",
        depth: "2",
        nm: "notice",
        url: "/notice",
      },
    ],
  },
  {
    cd: "DD",
    depth: "1",
    nm: "news",
    url: "/news",
    order: 2,
  },
  {
    cd: "EE",
    depth: "1",
    nm: "contact us",
    url: "/contact",
    order: 2,
  },
];

export const footerMenu = [
  {
    cd: "AA",
    nm: "about",
    url: "/about",
  },
  {
    cd: "BB",
    nm: "creation",
    url: "/creation",
  },
  {
    cd: "CC",
    nm: "international Co. Work",
    url: "/international",
  },
  {
    cd: "DD",
    nm: "news",
    url: "/news",
  },
  {
    cd: "EE",
    nm: "contact us",
    url: "/contact",
  },
];

export const snsData = [
  {
    nm: "linkedin",
    url: "https://www.linkedin.com/company/bauerlab/about",
  },
  {
    nm: "youtube",
    url: "https://www.youtube.com/@BauerLab_Office",
  },

  {
    nm: "instagram",
    url: "https://www.instagram.com/bauer.lab?igshid=NGVhN2U2NjQ0Yg==",
  },
];
