import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { getContactTypeAPI, registContactAPI } from "@/api/contact";
import { contactType, contactParamsType } from "@/types/contact";
import { AxiosError, AxiosResponse } from "axios";

export function useContactManager() {
  const contactTypeInitData = {
    contactTypeData: [
      { nm: "일반", cd: "1" },
      { nm: "스케쥴", cd: "2" },
    ],
  };
  const [contactTypeList, setContactTypeList] = useState(contactTypeInitData);

  const { data: contactTypeData } = useQuery<any>(
    // AxiosResponse<WarehousingListType>,
    // AxiosError,
    // WarehousingListType["data"]
    "getContactType",
    getContactTypeAPI,
    {
      select: (data) => {
        const typeList = Object.entries(data.data.data.ContactType);
        const result = typeList.map((el) => {
          return {
            nm: el[1],
            cd: el[0],
          };
        });

        return result;
      },
      onError: (error) => {
        console.log("contactTypeData - error", error);
      },
      refetchOnWindowFocus: false,
    }
  );

  /**
   * 문의 (tp 1:일반, 2:스케쥴)
   */
  const registContactForm = useMutation(
    async (Form: contactParamsType) => {
      const result = await registContactAPI(Form);
      return result;
    },
    {
      onSuccess: (data) => {
        console.log("registContactForm - data", data);
      },
      onError: (error) => {
        console.log("registContactForm - error", error);
      },
    }
  );

  return {
    contactTypeData,
    registContactForm,
  };
}
