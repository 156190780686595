import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { lenisScrollState } from "@/state";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import FlexsibleLink from "@/components/ui/flexible-link";
import theme from "@/assets/ts/theme";
import RollingText from "@/components/rolling-text";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import FadeText from "@/components/animation/fade-texts";
import CircleMask from "@/assets/ts/bauerlab/CircleMask";
import { useMediaQuery } from "@mui/material";
import FadeTextSingleLine from "@/components/fadeTextSingleLine";
import { useTranslation } from "react-i18next";
import CircleCanvas from "@/components/animation/circle-canvas";
import globalCSS from "@/assets/ts/global-css";
import { XTModal } from "../ui/modal";
import XTVideo from "../ui/video";
import XTMainVideo from "../ui/main-video";

interface ScrollObjectType {
  scrollObjects: any;
}

export default function WeAre(props) {
  const { t } = useTranslation("main");
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    diagonal: Math.sqrt(Math.pow(window.innerWidth, 2) + Math.pow(window.innerHeight, 2)),
  });

  const [init, setInit] = useState(false);
  const [conceptProgress, setConceptProress] = useState(0);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const [videoSectionProgress, setVideoSectionProgress] = useState(0);
  const [textProgress, setTextProgress] = useState(0);
  const [circleZoomSpeed, setCircleZoomSpeed] = useState(0.3);

  const [isMaskState, setIsMaskState] = useState(true);
  const [moreButtonProgress, setMoreButtonProgress] = useState(0);
  const weare = useRef(null);
  const concept = useRef(null);
  const identity = useRef(null);
  const identityTextSpace = useRef(null);
  const identityVideoSection = useRef(null);
  const identityVideoWrap = useRef(null);
  const moreBtnRef = useRef(null);

  const circleMaskContainer = useRef(null);
  const circleMask = useRef(null);
  const circleGraphic = useRef(null);

  const shortVideoRef = useRef(null);

  const interactionItems = useRef<ScrollObjectType>({
    scrollObjects: {},
  });

  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const handleVideoOpen = () => setIsVideoOpen(true);
  const handleVideoClose = () => {
    setIsVideoOpen(false);
  };
  const videoArray = [
    {
      url: "/video/video-main-performance.mp4",
      title: !isMobile ? "PERFORMANCE REEL VIDEO" : "PERFORMANCE",
    },
    {
      url: "/video/video-main-creation.mp4",
      title: !isMobile ? "CREATION REEL VIDEO" : "CREATION",
    },
  ];

  const videoPlay = () => {
    alert("video modal");
  };

  useEffect(() => {
    if (init) return;
    setInit(true);
    interactionItems.current.scrollObjects["weare"] = new ScrollObject(weare.current);
    interactionItems.current.scrollObjects["identity"] = new ScrollObject(identity.current);
    interactionItems.current.scrollObjects["identityTextSpace"] = new ScrollObject(identityTextSpace.current);
    interactionItems.current.scrollObjects["identityVideoSection"] = new ScrollObject(identityVideoSection.current);
    interactionItems.current.scrollObjects["video"] = new ScrollObject(shortVideoRef.current);

    // let stickyScrollObj = new ScrollObject(conceptSticky.current);
    // interactionItems.current.scrollObjects["conceptSticky"] = stickyScrollObj;

    circleMask.current = new CircleMask(circleMaskContainer.current);
    circleMask.current.invert = true;

    let observer = new Observer();
    observer.add(shortVideoRef.current, (isIn) => {
      let video = shortVideoRef.current as HTMLVideoElement;
      if (isIn) {
        if (video.paused) video.play();
      } else {
        if (!video.paused) video.pause();
      }
    });

    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
        diagonal: Math.sqrt(Math.pow(window.innerWidth, 2) + Math.pow(window.innerHeight, 2)),
      });
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      circleMask.current.kill();

      for (let key in interactionItems.current.scrollObjects) {
        interactionItems.current.scrollObjects[key].kill();
      }
    };
  }, []);

  useEffect(() => {
    for (let key in interactionItems.current.scrollObjects) {
      interactionItems.current.scrollObjects[key].update(lenisState.scroll);
    }
    let conceptScrollObj = interactionItems.current.scrollObjects["weare"];
    let identityScrollObj = interactionItems.current.scrollObjects["identity"];
    let identityTextSpaceScrollObj = interactionItems.current.scrollObjects["identityTextSpace"];
    let identityVideoSectionScrollObj = interactionItems.current.scrollObjects["identityVideoSection"];

    let progress = -1 + conceptScrollObj.ratio.y;
    if (progress < 1) {
      setIsMaskState(true);
    } else {
      setIsMaskState(false);
    }
    progress = clamp(progress);

    let videoSectionProgress = identityVideoSectionScrollObj.ratio.y;
    let videoProgress = interactionItems.current.scrollObjects["video"].ratio.yClamp;

    shortVideoRef.current.style.clipPath = `circle(${videoProgress * 100}%)`;

    setTextProgress(identityTextSpaceScrollObj.ratio.yClamp);

    if (circleMask.current) {
      circleMask.current.draw(1 - clamp(identityScrollObj.ratio.y - 1));
    }

    if (circleGraphic.current) {
      circleGraphic.current.style.transform = `rotate(${videoSectionProgress * 90 - 90}deg)`;
    }

    let moreButtonProgress = 0;
    let ratioY = identityVideoSectionScrollObj.ratio.y;
    if (isMobile) {
      moreButtonProgress = clamp(ratioY * 2);
      if (ratioY > 0.7) {
        moreButtonProgress = clamp(1 - (ratioY + 0.3 - 1) / 0.25);
      }
    } else {
      if (ratioY > 1.5) {
        moreButtonProgress = clamp(1 - (identityVideoSectionScrollObj.ratio.y - 1.5) / 0.5);
      } else {
        moreButtonProgress = clamp((ratioY - 0.5) * 3);
      }
    }

    moreBtnRef.current.style.pointerEvents = moreButtonProgress == 0 ? "none" : "auto";

    // moreBtnRef.current.style.opacity = `${moreButtonOpacity}`;
    setMoreButtonProgress(moreButtonProgress);
  }, [lenisState]);

  const splitText = (text: string) => {
    const words = text.split(" ");
    const n = words.length;
    return words.map((item, index) => (
      <span
        key={index}
        className="c-text-anim">
        <span
          dangerouslySetInnerHTML={{
            __html: n - 1 == index ? item : item + " ",
          }}></span>
      </span>
    ));
  };

  return (
    <>
      <section
        {...props}
        css={style.main}
        ref={weare}>
        <div
          css={style.concept}
          ref={concept}
          className={!isMaskState ? "is-no-mask" : ""}
          style={
            {
              "--diagonal": conceptProgress * dimensions.diagonal * 0.5 + "px",
              "--y": 50 + conceptProgress * 100 + "vh",
            } as any
          }>
          <div className="concept__background"></div>
          <div className="circle-mask">
            <div className="concept__content">
              <RollingText
                className="RollingText1 concept__rolling"
                title="IMMERSIVE"
                align="right"
                progress={conceptProgress}>
                <FadeTextSingleLine
                  texts={[
                    t("we-are.immersive.a"),
                    t("we-are.immersive.b"),
                    t("we-are.immersive.c"),
                    t("we-are.immersive.d"),
                  ]}
                />
              </RollingText>
            </div>
            {/* <div className="concept__sticky-space"></div> */}
          </div>
          <div className="concept__content">
            <RollingText
              className="RollingText2 concept__rolling"
              title="STORYTELLER"
              progress={conceptProgress}>
              <FadeTextSingleLine
                texts={[
                  t("we-are.storytellers.a"),
                  t("we-are.storytellers.b"),
                  t("we-are.storytellers.c"),
                  t("we-are.storytellers.d"),
                ]}
              />
            </RollingText>
          </div>

          <div className="concept__sentence">
            <div className="concept__sentence__inner">
              <img
                src="/images/image/main/bouerlab-sentence.svg"
                alt=""
              />
              <div className="circleAnim">
                <div className="circleAnim_circle"></div>
                <div className="circleAnim_circle"></div>
                <div className="circleAnim_circle"></div>
                <div className="circleAnim_circle"></div>
              </div>
              <div className="lineAnim">
                <div className="circleAnim_line"></div>
                <div className="circleAnim_line"></div>
                <div className="circleAnim_line"></div>
                <div className="circleAnim_line"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="identity"
          css={style.identity}
          ref={identity}>
          <div
            className="circleMaskContainer"
            ref={circleMaskContainer}></div>
          <div
            id="identityText"
            className="identity-text">
            <div className="identity-text-content">
              <FadeText
                progress={textProgress}
                texts={[
                  !isMobile
                    ? "Bauer Lab has broadened its influence <br/> from live performance to brand identity, <br/> all through spatial innovation."
                    : "Bauer Lab has broadened <br/> its influence from <br/> live performance <br/> to brand identity, all <br/> through spatial innovation.",
                ]}
              />
            </div>
            <div
              className="identity-text-space"
              ref={identityTextSpace}></div>
          </div>

          <div className="identity-video">
            <div
              className="identity-video__container"
              ref={identityVideoSection}>
              <button
                onClick={() => {
                  handleVideoOpen();
                }}
                onMouseEnter={() => {
                  setCircleZoomSpeed(0.6);
                }}
                onMouseLeave={() => {
                  setCircleZoomSpeed(0.3);
                }}
                className="video-wrap"
                ref={identityVideoWrap}>
                <div className="circleAnim">
                  <CircleCanvas
                    bgColor="transparent"
                    color="#ffffff"
                    isMask="false"
                    scale="2"
                    zoomSpeed={circleZoomSpeed}
                    radiusScale="1"
                    followMosue="false"
                    nums="10"
                    fit_container
                  />
                </div>

                <video
                  ref={shortVideoRef}
                  playsInline
                  muted
                  loop>
                  <source
                    src="/video/main-video-short.mp4"
                    type="video/mp4"
                  />
                </video>

                <div
                  className="video-graphic"
                  ref={circleGraphic}>
                  <img
                    src="/images/image/main/circle-border.svg"
                    alt=""
                  />
                  <p>Bauer Lab Reel Video</p>
                </div>
              </button>
              <div className="bg">
                <img
                  src="/images/image/main/weare-bg.png"
                  alt=""
                />
              </div>
            </div>
            <div
              className="buttun-wrap"
              ref={moreBtnRef}>
              <FlexsibleLink
                to="/about/"
                passive
                progress={moreButtonProgress}>
                About Bauer Lab
              </FlexsibleLink>
              <div className="button-wrap-space"></div>
            </div>
          </div>
        </div>
      </section>
      <XTModal
        open={isVideoOpen}
        className="video-modal"
        onClose={handleVideoClose}
        disableScrollLock={true}>
        <XTMainVideo
          video={videoArray}
          title={""}
          onClose={handleVideoClose}
        />
      </XTModal>
    </>
  );
}

const style = {
  main: css`
    position: relative;
    padding-top: 50vh;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      /* padding-top: 0; */
      margin-top: -50vh;
      z-index: 2;
    }
  `,

  concept: css`
    position: relative;
    z-index: 1;

    &.is-no-mask {
      clip-path: none;
    }

    .concept__background {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .concept__content {
      display: flex;
      flex-direction: column;
      padding-top: 100px;
      padding-bottom: 100px;
      gap: 500px;
      /* height: 100vh; */
    }

    .circle-mask + .concept__content {
      margin-top: 50vh;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-top: 0vh;
      }
    }
    @keyframes cicleAnim {
      0% {
        transform: scale(1.2);
        opacity: 0;
      }
      40% {
        opacity: 0.6;
      }
      100% {
        transform: scale(0);
        opacity: 0;
      }
    }
    @keyframes cicleAnim2 {
      0% {
        transform: scale(1.2);
        opacity: 0;
      }
      20% {
        opacity: 0.6;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 0;
        transform: scale(0);
      }
    }
    @keyframes lineUp {
      0% {
        transform: translate(0, -8px);
        opacity: 0;
      }
      50% {
        opacity: 0.6;
      }
      100% {
        transform: translate(0, 0px);
        opacity: 0;
      }
    }
    @keyframes lineDown {
      0% {
        transform: translate(0, 8px);
        opacity: 0;
      }
      90% {
        opacity: 0.6;
      }
      100% {
        transform: translate(0, 0px);
        opacity: 0;
      }
    }
    .concept__sentence {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-bottom: 57px;
      margin-top: 50vh;
      .concept__sentence__inner {
        position: relative;
        width: calc(665 / 1920 * 100%);

        .lineAnim {
          position: absolute;
          width: 7%;
          aspect-ratio: 1/0.7;
          right: 34%;
          bottom: 2%;

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            aspect-ratio: 1/1.1;
          }

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: calc(50% - 1px);
            width: 100%;
            height: 2px;
            background: ${theme.palette.blue};
          }

          .circleAnim_line {
            position: absolute;
            width: 100%;
            height: 1px;
            background: #fff;
            top: 50%;

            &:nth-of-type(1),
            &:nth-of-type(3) {
              animation: lineUp 2s linear infinite;
            }
            &:nth-of-type(2),
            &:nth-of-type(4) {
              animation: lineDown 2s linear infinite;
            }

            &:nth-of-type(3),
            &:nth-of-type(4) {
              animation-delay: 1s;
            }
          }
        }

        .circleAnim {
          position: absolute;
          width: 12%;
          aspect-ratio: 1/1;
          left: 33.5%;
          bottom: 8%;
          border-radius: 50%;

          &:before {
            content: "";
            position: absolute;
            left: calc(50% - 4px);
            top: calc(50% - 4px);
            width: 8px;
            height: 8px;
            background: #131cde;
            border-radius: 50%;
            filter: blur(1px);
          }

          .circleAnim_circle {
            content: "";
            position: absolute;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            animation: cicleAnim 4s linear infinite;
            opacity: 0;
            border: solid 1px #fff;

            &:nth-of-type(2) {
              animation-delay: 1s;
            }
            &:nth-of-type(3) {
              animation-delay: 2s;
            }
            &:nth-of-type(4) {
              animation-delay: 3s;
            }
          }
        }
      }

      img {
        width: 100%;
        height: auto;
      }

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        .concept__sentence__inner {
          width: calc(277 / 375 * 100%);
        }
      }
    }

    .RollingText1 {
      margin-right: calc(400 / 1920 * 100%);
      margin-left: auto;

      .inner {
        @media (max-width: ${theme.breakpoints.values.lg}px) {
        }
      }

      h2 {
        font-size: ${theme.typography.pxToRem(120)};
        margin-bottom: ${theme.typography.pxToRem(20)};
      }

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-right: auto;
        margin-left: auto;
        width: 100%;

        h2 {
          /* font-size: ${theme.typography.pxToRem(60)}; */
          font-size: calc(55 / 375 * 100vw);
          margin-bottom: ${theme.typography.pxToRem(30)};
          margin-right: auto;
          margin-left: auto;
        }
        p {
          margin-left: calc(86 / 375 * 100%);
          width: 100%;
          font-size: ${theme.typography.pxToRem(14)};
          line-height: ${theme.typography.pxToRem(24)};
          /* margin-top: 30px; */
        }
      }
      /* padding-bottom: 600px; */
    }
    .RollingText2 {
      margin-left: calc(400 / 1920 * 100%);
      margin-right: auto;
      h2 {
        font-size: ${theme.typography.pxToRem(120)};
        margin-bottom: ${theme.typography.pxToRem(20)};
      }
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        h2 {
          font-size: calc(45 / 375 * 100vw);
          margin-bottom: ${theme.typography.pxToRem(30)};
          margin-right: auto;
          margin-left: auto;
        }
        p {
          margin-left: calc(50 / 375 * 100vw);
          font-size: ${theme.typography.pxToRem(14)};
          line-height: ${theme.typography.pxToRem(24)};
        }
      }
    }

    .concept .concept__sticky-space {
      height: 50vh;
      border: solid 1px;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        height: 0vh;
      }
    }
  `,

  identity: css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: solid 10px red; */
    .circleMaskContainer {
      position: sticky;
      top: 0;
      width: 100%;
      height: 100vh;
      z-index: 0;
      overflow: hidden;
    }

    &.is-no-mask {
      clip-path: none;
    }

    .identity-text {
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      width: 100%;
      color: #fff;
    }

    .identity-text-content {
      position: sticky;
      top: 0;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${theme.typography.pxToRem(48)};
      letter-spacing: 0.02em;
      line-height: 1em;

      .splite-text {
        line-height: 1;
      }

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        font-size: calc(22 / 375 * 100vw);
        line-height: ${theme.typography.pxToRem(28)};
        /* justify-content: flex-start; */
        /* padding: 0 40px; */
        /* width: calc(300 / 375 * 100%); */
      }
    }

    .identity-text-space {
      position: relative;
      width: 100%;
      height: 100vh;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        /* height: 50vh; */
      }
    }

    .buttun-wrap {
      position: fixed;
      z-index: 3;
      width: 100%;
      bottom: 40px;
    }

    .more-button-wrap {
      width: 100%;
    }

    .identity-video {
      position: relative;
      width: 100%;
      margin-top: -25vh;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        margin-bottom: 60px;
      }
    }

    .identity-video__container {
      display: flex;
      flex-direction: column;
      top: 0;
      width: 100%;
      z-index: 1;
      padding-top: 500px;
      @media (max-width: ${theme.breakpoints.values.md}px) {
        padding-top: 100px;
      }
    }

    .video-wrap {
      position: relative;
      width: 260px;
      height: 260px;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      cursor: pointer;

      .circleAnim {
        position: absolute;
        width: 150%;
        left: -25%;
        top: -25%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        .circleAnim_circle {
          content: "";
          position: absolute;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          animation: cicleAnim2 5s linear infinite;
          z-index: 1;
          opacity: 0;
        }
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        transition: transform 0.5s ${theme.transitions.easing.inout3};
      }

      @media (max-width: ${theme.breakpoints.values.md}px) {
        width: 157px;
        height: 157px;
      }

      @media (hover: hover) {
        &:hover {
          video {
            transform: scale(1.2);
          }
        }
      }
    }

    .video-graphic {
      position: absolute;
      max-width: 460px;
      width: 170%;
      border-radius: 50%;
      left: -35%;
      top: -35%;
      aspect-ratio: 1/1;
      pointer-events: none;

      ${globalCSS.en};

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        /* transform: translate(-50%, -50%); */
      }
      img {
        width: 100%;
        height: 100%;
        pointer-events: none;
      }

      p {
        position: absolute;
        color: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        font-size: ${theme.typography.pxToRem(20)};
        letter-spacing: 0.02em;
        pointer-events: none;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(14)};
        }
      }
    }

    .bg {
      width: 100%;
      /* margin-top: -230px; */
      mix-blend-mode: lighten;
      display: flex;
      justify-content: center;
      overflow: hidden;

      img {
        width: 100%;
        min-width: 720px;
        height: auto;
      }

      @media (max-width: ${theme.breakpoints.values.md}px) {
        margin-top: -100px;
        img {
          width: 120%;
          margin-left: -10%;
        }
      }

      /* border: dashed 5px rgba(255, 255, 255, 0.5); */
    }
  `,
};
