import { Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";

const XTPagination = styled(Pagination)(({ theme }) => ({
  ".MuiPagination-ul": {
    justifyContent: "center",
  },

  button: {
    position: "relative",
    padding: 0,
    margin: 0,
    zIndex: 1,
    minWidth: "0 !important",

    "&::before": {
      content: '""',
      width: 0,
      height: `${theme.typography.pxToRem(26.7)}`,
      borderRadius: "100px",
      backgroundColor: `${theme.palette.white}`,
      transform: "translate(-50%, -50%) rotate(-45deg)",
      position: "absolute",
      top: "50%",
      left: "50%",
      zIndex: -1,
      transition: "all 0.2s cubic-bezier(0.22, 1, 0.36, 1)",
    },
  },

  ".Mui-disabled": {
    opacity: "1 !important",
  },

  ".MuiPaginationItem-previousNext": {
    width: `${theme.typography.pxToRem(52.27)} !important`,
    height: `${theme.typography.pxToRem(52.27)} !important`,

    svg: {
      display: "none",
    },

    "&:hover": { backgroundColor: "transparent !important" },

    "&::before": {
      display: "none",
    },
  },

  ".MuiPaginationItem-page": {
    width: `${theme.typography.pxToRem(52.27)}`,
    height: `${theme.typography.pxToRem(52.27)}`,
    color: `${theme.palette.gray}`,
    fontFamily: '"Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif',
    fontWeight: 400,
    fontSize: `${theme.typography.pxToRem(18)}`,
    lineHeight: 22 / 18,
    letterSpacing: "0.36px",
    "&:hover": {
      backgroundColor: "transparent !important",
      "&::before": {
        width: `${theme.typography.pxToRem(26.7)}`,
      },
    },
  },

  ".Mui-selected": {
    backgroundColor: "transparent !important",
    color: `${theme.palette.black}`,
    "&::before": {
      width: `${theme.typography.pxToRem(47.2)}`,
    },

    "&:hover": {
      "&::before": {
        width: `${theme.typography.pxToRem(47.2)}`,
      },
    },
  },

  li: {
    position: "relative",

    "&:first-of-type": {
      marginRight: `${theme.typography.pxToRem(30)}`,
      button: {
        "&::after": {
          content: "''",
          width: `${theme.typography.pxToRem(24)} !important`,
          height: `${theme.typography.pxToRem(24)} !important`,
          background: "url('/images/icon/ico-page-prev.svg') no-repeat center / cover",
        },
      },
    },

    "&:last-of-type": {
      marginLeft: `${theme.typography.pxToRem(30)}`,
      button: {
        "&::after": {
          content: "''",
          width: `${theme.typography.pxToRem(24)} !important`,
          height: `${theme.typography.pxToRem(24)} !important`,
          background: "url('/images/icon/ico-page-next.svg') no-repeat center / cover",
        },
      },
    },
  },
}));

export default XTPagination;
