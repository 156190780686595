import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { lenisScrollState } from "@/state";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import Circle from "@/assets/ts/bauerlab/Circle";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import { boolean } from "zod";

interface ScrollObjectType {
  observer: Observer | null;
  scrollObj: ScrollObject | null;
}

export default function CircleCanvas(props) {
  const [init, setInit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const rootRef = useRef(null);
  const circleAnimation = useRef(null);
  const circleCanvas = useRef(null);
  const scale = Number(props.scale) || 1;

  const scrollObjs = useRef([]);
  const observe = useRef(null);
  const iteractionItems = useRef<ScrollObjectType>({
    observer: null,
    scrollObj: null,
  });

  useEffect(() => {
    if (init) return;
    setInit(true);
    circleAnimation.current = new Circle(circleCanvas.current);
    circleAnimation.current.stop();
    if (props.bgColor) {
      circleAnimation.current.fillColor = props.bgColor;
    }

    if (props.color) {
      circleAnimation.current.color = props.color;
    }
    if (props.isMask) {
      circleAnimation.current.isMask = props.isMask;
    }

    if (props.zoomSpeed) {
      circleAnimation.current.zoomSpeed = props.zoomSpeed;
    }

    if (props.followMosue) {
      circleAnimation.current.followMosue = props.followMosue == "true";
    }
    if (props.radiusScale) {
      circleAnimation.current.radiusScale = props.radiusScale;
    }
    if (props.nums) {
      circleAnimation.current.nums = props.nums;
    }

    if (props.fit_container) {
      circleAnimation.current.isFitContasiner = true;
      circleAnimation.current.defineSize();
    }

    observe.current = new Observer();
    observe.current.add(rootRef.current, (isIn) => {
      if (isIn) {
        circleAnimation.current.start();
      } else {
        circleAnimation.current.stop();
      }
    });

    return () => {
      circleAnimation.current.kill();
    };
  }, []);

  useEffect(() => {
    if (props.zoomSpeed) {
      circleAnimation.current.zoomSpeed = props.zoomSpeed;
    }
  }, [props.zoomSpeed]);

  useEffect(() => {
    if (props.fit_container) return;

    /*
      circle
    */
    let scroll = lenisState.scroll;
    let circleP = scroll / window.innerHeight;
    let circleY = window.innerHeight * 0.5 - scroll;
    let circleTop = scroll;
    let circleTopLimit = window.innerHeight * 2;
    if (circleY < 0) {
      circleY = 0;
    }
    if (circleTop > circleTopLimit) {
      circleTop = circleTopLimit;
    }

    circleAnimation.current.radiusScale = 1.5 + circleP * scale;
    // circleAnimation.current.zoomSpeed = 0.6; // + circleP * 0.1;
    circleAnimation.current.opacity = 0.6 - clamp(circleP * 0.8) * 0.6;
    circleAnimation.current.translate(0, circleY);
    // circleCanvas.current.style.transform = `translate3d(0,${circleTop}px,0)`;

    if (props.maskProgress != null) {
      circleAnimation.current.maskProgress = props.maskProgress;
    }
  }, [lenisState]);
  return (
    <div
      className="circle-canvas"
      css={style.main}
      ref={rootRef}
      data-fit-window={props.fit_container ? false : true}
    >
      <canvas ref={circleCanvas}></canvas>{" "}
    </div>
  );
}

const style = {
  main: css`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    pointer-events: none;

    &[data-fit-window="false"] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  `,
};
