import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { lenisScrollState } from "@/state";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import theme from "@/assets/ts/theme";
import globalCSS from "@/assets/ts/global-css";
import Easing from "@/assets/ts/bauerlab/util/Easing";

interface ScrollObjectType {
  observer: Observer | null;
  scrollObj: ScrollObject | null;
}

export default function RollingText(props) {
  const [init, setInit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const targetDom = useRef(null);

  const iteractionItems = useRef<ScrollObjectType>({
    observer: null,
    scrollObj: null,
  });

  useEffect(() => {
    if (targetDom.current) {
      iteractionItems.current.observer = new Observer();
      iteractionItems.current.observer.add(targetDom.current);
      iteractionItems.current.scrollObj = new ScrollObject(targetDom.current);
    }

    return () => {
      if (targetDom.current) {
        iteractionItems.current.observer.remove(targetDom.current);
        iteractionItems.current.observer.kill();
        iteractionItems.current.scrollObj.kill();
      }
    };
  }, [targetDom]);

  useEffect(() => {
    if (iteractionItems.current.scrollObj) {
      let scrollObj = iteractionItems.current.scrollObj;
      scrollObj.update(lenisState.scroll);

      let progress = scrollObj.ratio.y + 0.3;
      if (progress > 1) progress = 1;
      // targetDom.current.style.transform = `rotate(${progress * 360}deg)`;
      setProgress((prev) => {
        if (typeof progress !== "undefined") {
          return prev + (progress - prev) * 0.2;
        }
        return prev;
      });
    }
  }, [lenisState]);
  return (
    <div
      css={style.main}
      ref={targetDom}
      className={props.className}
      data-align={props.align || "left"}
    >
      <div className="inner">
        <h2
          dangerouslySetInnerHTML={{ __html: props.title }}
          style={
            {
              "--progress": progress + "",
            } as any
          }
        ></h2>
        {props.children}
        {/* <p dangerouslySetInnerHTML={{ __html: props.desc }}></p> */}
      </div>
    </div>
  );
}

const style = {
  main: css`
    color: #fff;

    .inner {
      display: flex;
      flex-direction: column;
      transform-style: preserve-3d;
      perspective: 1000px;

      h2 {
        display: inline-block;
        ${globalCSS.en}
        /* font-size: ${theme.typography.pxToRem(120)}; */
        line-height: 1em;
        transform: translate3d(0, 0, 0)
          rotateY(calc(100deg - var(--progress) * 100deg));
      }
      p {
        /* display: inline-block; */
      }
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        /* h2 {
          font-size: ${theme.typography.pxToRem(60)};
        } */
        /* p {
          margin-top: ${theme.typography.pxToRem(10)};
        } */
      }
    }

    &[data-align="right"] {
      .inner {
        align-items: flex-end;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          align-items: flex-start;
        }
      }
    }
  `,
};
