import React, { Component } from "react";
import moment from "moment";
import "moment-timezone";
import { css } from "@emotion/react";
import theme from "@/assets/ts/theme";

interface ClockProps {
  local: string;
}

interface ClockState {
  time: moment.Moment;
}

export default class Clock extends Component<ClockProps, ClockState> {
  private timerId: NodeJS.Timeout | null = null;
  constructor(props: ClockProps) {
    super(props);
    this.state = {
      time: this.getCurrentTime(props.local),
    };
  }

  getCurrentTime(local: string) {
    switch (local) {
      case "ko": // 한국
        return moment().tz("Asia/Seoul");
      case "ny": // 뉴욕
        return moment().tz("America/New_York");
      case "la": // 뉴욕
        return moment().tz("America/Los_Angeles");
      case "bb": // 버뱅크 (la)
        return moment().tz("America/Los_Angeles");
      case "qb": // 캐나다 퀘백
        return moment().tz("America/Montreal");
      default:
        return moment().tz("Asia/Seoul");
    }
  }

  componentDidMount() {
    this.timerId = setInterval(() => {
      this.setState({
        time: this.getCurrentTime(this.props.local),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  render() {
    const hours = this.state.time.hours();
    const minutes = this.state.time.minutes();
    const seconds = this.state.time.seconds();

    return (
      <div className="clock" css={style.clock}>
        <div
          className="hour-hand"
          style={{
            transform: `translateX(-50%) rotateZ(${hours * 30 + minutes * 0.5}deg)`,
          }}
        />
        <div
          className="min-hand"
          style={{
            transform: `translateX(-50%) rotateZ(${minutes * 6}deg)`,
          }}
        />
        <div
          className="sec-hand"
          style={{
            transform: `translateX(-50%) rotateZ(${seconds * 6}deg)`,
          }}
        />
      </div>
    );
  }
}

const style = {
  clock: css`
    position: relative;
    width: ${theme.typography.pxToRem(177)};
    height: ${theme.typography.pxToRem(177)};
    border-radius: 50%;
    border: 2.77px solid #1011dd;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      width: ${theme.typography.pxToRem(90)};
      height: ${theme.typography.pxToRem(90)};
    }

    .hour-hand {
      position: absolute;
      width: 4.15px;
      height: 54px;
      background: #1011dd;
      top: 24.5%;
      left: 50%;
      transform-origin: 50% 90%;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        height: 28px;
        width: 2.5px;
        left: 50%;
        top: 22.5%;
      }
    }

    .min-hand {
      position: absolute;
      width: 2.47px;
      height: 76px;
      background: #1011dd;
      top: 16.5%;
      left: 50%;
      transform-origin: 50% 80%;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        height: 36px;
      }
    }

    .sec-hand {
      position: absolute;
      width: 1.37px;
      height: 88.97px;
      background: #1011dd;
      top: 10.5%;
      left: 50%;
      transform-origin: 50% 80%;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        height: 43px;
      }
    }
  `,
};
