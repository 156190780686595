import {
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  ShaderMaterial,
  Texture,
  TextureLoader,
  Vector2,
  Vector3,
} from "three";
import { stageSize } from "./BaseWebGL";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";

export default class SyncImgMesh {
  texture: Texture;
  mesh: Mesh;
  material: ShaderMaterial;
  geometry: PlaneGeometry;
  syncElement: HTMLImageElement;
  elementRect: DOMRect;
  scrolYOld: number;
  scrollObject: ScrollObject;

  position: Vector3 = new Vector3();
  constructor(imgElement: HTMLImageElement, offsets) {
    // const loadTexture = (source) => {
    //   this.textureLoad(source).catch((error) => {
    //     this.textureLoad("/images/image/main/creation/img1.jpg");
    //   });
    // };

    // if (imgElement.complete) {
    //   src = imgElement.src || "/images/image/main/creation/img1.jpg";
    //   loadTexture(src);
    // } else {
    //   imgElement.addEventListener("load", () => {
    //     src = imgElement.src || "/images/image/main/creation/img1.jpg";
    //     loadTexture(src);
    //   });
    // }
    this.syncElement = imgElement;

    this.scrollObject = new ScrollObject(imgElement);
    this.scrollObject.on(ScrollObject.EVENT_INTERSECTION, (isIn) => {
      if (isIn) {
        this.resize();
      }
    });

    // this.material = new MeshBasicMaterial({});
    this.material = new ShaderMaterial({
      uniforms: {
        uCurve: { value: 10 },
        uCurveY: { value: 0.0 },
        uCurvePower: { value: 0 },
        uCurveStyle: { value: 0 },
        tDiffuse: { value: null },
        uResolution: { value: new Vector2() },
        uScreenResolution: { value: new Vector2() },
      },
      vertexShader: `
				uniform float uCurve;
				uniform float uCurveY;
				uniform float uCurvePower;
				uniform float uCurveStyle;
				uniform vec2 uResolution;
				uniform vec2 uScreenResolution;
				varying vec2 vUv;

				float PI = 3.141592653589793;
				void main() {
					vUv = uv;

					vec4 worldPosition = modelMatrix * vec4(position, 1.0);
					vec4 viewPosition = viewMatrix * worldPosition;

					vec3 newPosition = position;


					float ry = viewPosition.y / uScreenResolution.y;

					float vCurve = 0.0;

					if(uCurveStyle == 0.0) {
						ry = smoothstep(-0.2,-2.0,ry);
						vCurve = sin(-ry * PI * uCurveY * 10.) * uCurve;
					}

					if(uCurveStyle == 1.0) {
						ry = (abs(ry/0.5 - 1.)/2.);
						ry = smoothstep(-0.5,1.5,ry);
						// ry = smoothstep(-1.0,2.0,ry);
						vCurve = sin(ry * PI * uCurveY) * uCurve * uCurvePower;
					}


					newPosition.z = vCurve;



					gl_Position = projectionMatrix * modelViewMatrix * vec4( newPosition, 1.0 );
				}`,
      fragmentShader: `
				uniform sampler2D tDiffuse;
				varying vec2 vUv;
				void main() {
					gl_FragColor = texture2D(tDiffuse, vUv);
				}`,
      transparent: true,
    });

    this.geometry = new PlaneGeometry(1, 1, 10, 200);
    this.mesh = new Mesh(this.geometry, this.material);

    let src = imgElement.src;

    this.textureLoad(src).catch((error) => {
      this.textureLoad("/images/image/main/creation/img1.jpg");
    });

    this.applyUniforms(offsets);

    // console.log("render", src);
  }

  kill() {
    if (this.scrollObject) {
      this.scrollObject.kill();
    }
  }

  async textureLoad(src) {
    return new Promise((resolve, reject) => {
      let textureLoader = new TextureLoader();
      textureLoader.load(
        src,
        (t: Texture) => {
          this.material.uniforms.tDiffuse.value = t;
          this.material.transparent = true;
          this.material.needsUpdate = true;
          resolve(t);
          this.resize();
        },
        () => {},
        (error) => {
          reject(error);
        }
      );
    });
  }

  applyUniforms(items) {
    for (let key in items) {
      if (this.material.uniforms[key]) {
        this.material.uniforms[key].value = items[key];
      }
    }
  }

  updateScroll(scrollY, velocity) {
    if (!this.elementRect) return;
    this.scrollObject.update(scrollY);
    // set position to screen top
    this.position.y = window.innerHeight * 0.5;
    this.position.y -= this.elementRect.height * 0.5;
    // set position to img element top
    // this.position.y -= this.elementRect.top;
    // this.position.y -= this.scrolYOld;
    // this.position.y += scrollY;
    this.position.y += this.scrollObject.scroll.y;
    this.mesh.position.y = this.position.y;
    // this.mesh.position.y += (this.position.y - this.mesh.position.y) * 0.9;

    // set position to screen left
    this.mesh.position.x = -window.innerWidth * 0.5;
    this.mesh.position.x += this.elementRect.left;
    this.mesh.position.x += this.elementRect.width * 0.5;

    this.material.uniforms.uCurvePower.value = velocity;

    // this.mesh.position.x = this.elementRect.width * 0.5;
  }

  resize() {
    this.elementRect = this.syncElement.getBoundingClientRect();
    this.mesh.scale.set(this.elementRect.width, this.elementRect.height, 1);
    this.scrolYOld = window.scrollY;
    this.material.uniforms.uResolution.value.x = this.elementRect.width;
    this.material.uniforms.uResolution.value.y = this.elementRect.height;
    this.material.uniforms.uScreenResolution.value.x = stageSize.width;
    this.material.uniforms.uScreenResolution.value.y = stageSize.height;
    // this.mesh.position.y = this.elementRect.top;
  }
}
