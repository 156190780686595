import Circle from "@/assets/ts/bauerlab/Circle";
import globalCSS from "@/assets/ts/global-css";
import { postItemOpenState, postMobileTabsOpenState } from "@/state/post";
import moment from "moment";
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { MobileSchedulerMonth, MobileSchedulerTabArea, MobileSchedulerYear } from "../ui/mobile-tab";
import { XTMonthRadio, XTRadioGroupForm } from "../ui/radio";

export default function SchedulerMobileTab({
  scheduleParams,
  handleSchTpDtl,
  MONTH_LIST,
  currentYear,
  currentMonth,
  handlePrevYear,
  handleNextYear,
  handleFooterActive,
  handleFooterHidden,
}) {
  const circleRef = useRef(null);
  const circleCanvasRef = useRef(null);
  const [isTabOpen, setIsTabOpen] = useRecoilState(postMobileTabsOpenState);

  useEffect(() => {
    if (!circleRef.current) {
      circleRef.current = new Circle(circleCanvasRef.current, "transparent");
    }
  }, [circleCanvasRef]);

  useEffect(() => {
    handleFooterHidden();
    setIsTabOpen(true);

    return () => {
      handleFooterActive();
      setIsTabOpen(false);
    };
  }, [isTabOpen]);

  return (
    <>
      <canvas
        ref={circleCanvasRef}
        css={[globalCSS.circleCanvas, { transform: isTabOpen ? "scale(1.5)" : "scale(1)" }]}></canvas>
      <MobileSchedulerTabArea>
        <MobileSchedulerYear>
          <button
            onClick={handlePrevYear}
            className={scheduleParams.schYyyy === moment().subtract(1, "y").format("YYYY") ? "disabled" : ""}>
            <i css={globalCSS.icoDatePrev}></i>
          </button>
          <span>{currentYear}</span>
          <button
            onClick={handleNextYear}
            className={scheduleParams.schYyyy === moment().add(1, "y").format("YYYY") ? "disabled" : ""}>
            <i css={globalCSS.icoDateNext}></i>
          </button>
        </MobileSchedulerYear>
        <MobileSchedulerMonth>
          <XTRadioGroupForm defaultValue={scheduleParams.schMm} onChange={handleSchTpDtl} value={scheduleParams.schMm}>
            {MONTH_LIST.map((month, index) => {
              return <XTMonthRadio key={index} value={month.cd} label={`${month.nm}`} />;
            })}
          </XTRadioGroupForm>
        </MobileSchedulerMonth>
      </MobileSchedulerTabArea>
    </>
  );
}
