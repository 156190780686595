import { Button, Tab, Tabs } from "@mui/material";
import { css, styled } from "@mui/material/styles";
import { useState } from "react";

interface XTTabContentProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

export const XTTabs = styled(Tabs)(({ theme }) => ({
  // border: "1px solid red",
  minHeight: "0",

  ".MuiTabs-flexContainer": {
    flexDirection: "column",
    overflow: "auto !important",

    button: {
      position: "relative",
      flexDirection: "row",
      padding: 0,
      maxWidth: "fit-content !important",
      minWidth: "0 !important",
      minHeight: "0 !important",
      fontFamily: '"Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif',
      fontWeight: 400,
      fontSize: `${theme.typography.pxToRem(18)}`,
      lineHeight: 22 / 18,
      letterSpacing: "0.36px",
      overflow: "inherit",
      textTransform: "inherit !important",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-start",
      color: `${theme.palette.gray}`,

      "+ button": {
        marginTop: `${theme.typography.pxToRem(18)}`,
      },

      "&.Mui-selected": {
        color: `${theme.palette.white}`,
        fontWeight: 700,

        "&::after": {
          content: "''",
          transform: "translateY(-50%)",
          width: `${theme.typography.pxToRem(10)}`,
          height: `${theme.typography.pxToRem(10)}`,
          backgroundColor: `${theme.palette.blue}`,
          borderRadius: "50%",
          marginLeft: `${theme.typography.pxToRem(10)}`,
          marginRight: `${theme.typography.pxToRem(3)}`,
          filter: "blur(1px)",
        },
      },
    },
  },

  ".MuiTabs-indicator": {
    display: "none",
    visibility: "hidden",
  },
}));

export const XTTab = styled(Tab)(({ theme }) => ({}));

export const XTTabContent = (props: XTTabContentProps) => {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value != index}>
      {value === index && <div className="contents">{children}</div>}
    </div>
  );
};

export const XTRowTabs = styled(Tabs)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  minWidth: 0,
  minHeight: 0,
  ".MuiTabs-flexContainer": {
    gap: `${theme.typography.pxToRem(20)};`,
    width: "100%",
    height: "100%",
    minWidth: 0,
    minHeight: 0,

    [theme.breakpoints.down("lg")]: {
      gap: `${theme.typography.pxToRem(15)};`,
    },
  },
  ".MuiTabs-indicator": {
    display: "none",
  },
}));

export const XTRowTab = styled(Tab)(({ theme }) => ({
  width: "100%",
  maxWidth: "calc(50% - 10px) !important",
  flex: 1,
  backgroundColor: `${theme.palette.transparent};`,
  height: `${theme.typography.pxToRem(44)};`,
  maxHeight: "100%",
  borderRadius: "100px",
  color: `${theme.palette.white};`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 400,
  fontSize: `${theme.typography.pxToRem(26)}`,
  lineHeight: 1,
  letterSpacing: "0.52px;",
  textTransform: "uppercase",
  border: `1px solid ${theme.palette.white}`,
  boxSizing: "border-box",
  minWidth: 0,
  minHeight: 0,
  "&.Mui-selected": {
    backgroundColor: `${theme.palette.white};`,
    color: `${theme.palette.black};`,
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "calc(50% - 7.5px) !important",
    height: `${theme.typography.pxToRem(32)};`,
    fontSize: `${theme.typography.pxToRem(16)}`,
    letterSpacing: "0px",
    padding: 0,
  },
}));

export const XTRowTabContent = (props: XTTabContentProps) => {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value != index}>
      {value === index && <div className="contents">{children}</div>}
    </div>
  );
};
