import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import axios from "axios";
import { t } from "i18next";
type fileType = {
  fileTitle: string;
  fileExt: string;
  onClick: () => void;
};

export default function FileDownload({ fileTitle, fileExt, onClick }: fileType) {
  const getFileName = () => {
    if (!fileTitle) return "";
    let strIdx = fileTitle.lastIndexOf(".");
    const result = fileTitle.substr(0, strIdx);
    return result ? result : fileTitle;
  };

  return (
    <div className="download-box" css={style.download}>
      <em dangerouslySetInnerHTML={{ __html: t("post:file") }}></em>
      <Link
        to="/"
        target="_parent"
        onClick={(event) => {
          event.preventDefault();
          onClick();
        }}>
        <span className="file-title">{getFileName()}</span>
        {fileExt && <span className="file-ext">.{fileExt}</span>}
        <i css={globalCSS.icoDownload}></i>
      </Link>
    </div>
  );
}

const style = {
  download: css`
    position: relative;
    display: flex;
    align-items: center;
    padding: ${theme.typography.pxToRem(13.37)} 0 ${theme.typography.pxToRem(16.62)};
    border: 1px solid ${theme.palette.gray};
    border-width: 1px 0;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: column;
      align-items: flex-start;
      padding: ${theme.typography.pxToRem(14.87)} 0 ${theme.typography.pxToRem(19.13)};
    }

    em {
      font-weight: 400;
      font-size: ${theme.typography.pxToRem(20)};
      line-height: 1;
      color: ${theme.palette.white};
      margin-right: ${theme.typography.pxToRem(30)};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-bottom: ${theme.typography.pxToRem(2)};
        margin-right: ${theme.typography.pxToRem(0)};
        font-size: ${theme.typography.pxToRem(16)};
        line-height: ${theme.typography.pxToRem(24)};
      }
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: 100%;
        position: static;
      }
      .file-title {
        font-weight: 400;
        font-size: ${theme.typography.pxToRem(18)};
        line-height: 1;
        color: ${theme.palette.yellow};
        max-width: ${theme.typography.pxToRem(900)};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(16)};
          line-height: ${theme.typography.pxToRem(24)};
        }
      }

      .file-ext {
        font-weight: 400;
        font-size: ${theme.typography.pxToRem(18)};
        line-height: 1;
        color: ${theme.palette.yellow};
        text-transform: uppercase;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(16)};
          line-height: ${theme.typography.pxToRem(24)};
        }
      }

      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
        }
      }
    }
  `,
};
