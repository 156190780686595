import { formatEnYmd } from "@/assets/ts/format";
import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { isDetailPageState, pageYState } from "@/state";
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import FileDownload from "../ui/download";
import OtherContent from "../ui/other-content";
import RoundedButton from "../ui/roundedButton";
import { DetailOverlay } from "./post";
import { DescFormat, DescFormatLink } from "../ui/description";
import { usePostDetailManager } from "@/hooks/use-post";
import { fileDownload, unlockDom } from "@/assets/ts/common";
import { getImagePath } from "@/assets/ts/image";
import { imageTpType } from "@/types/image";
import i18n from "@/i18n";
import FlexsibleLink from "@/components/ui/flexible-link";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import Observer from "@/assets/ts/bauerlab/util/Observer";

interface ScrollObjectType {
  observer: Observer | null;
  scrollObj: ScrollObject | null;
}

function EmployLinkButton(data) {
  // 채용 바로가기 버튼

  return (
    <>
      {data.data.tpDtl === 2 && data.data.prvncUrl !== "" && (
        <div
          className="link-box"
          // ref={linkRef}
          css={globalCSS.flexibleLink}>
          <div className="background"></div>
          <a
            target="_blank"
            href={data.data.prvncUrl}>
            <span>채용 사이트 바로가기</span>
            <i css={globalCSS.icoArrowRight}></i>
          </a>
        </div>
      )}
    </>
  );
}

export default function PostDetail(props: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDetailPage, setIsDetailPage] = useRecoilState(isDetailPageState);
  const innerRef = useRef(null);
  const otherContentRef = useRef(null);
  const { postDetailData, isFetched } = usePostDetailManager();

  useEffect(() => {
    setIsDetailPage(true);

    return () => {
      setIsDetailPage(false);
    };
  }, [isDetailPage]);

  const getDetailImageUrl = () => {
    return postDetailData?.data.data.board?.boardImageList?.filter((el) => el.tp == imageTpType.DETAIL_IMAGE_SEQ)?.[0]
      ?.attachImage;
  };
  const moveListPage = () => {
    const hasSearch = location.search ? location.search : "";
    navigate(`/${location.pathname?.split("/")?.[1]}${hasSearch}`);
  };

  function otherContentPageToTop() {
    const mainWrapper = document.querySelector(".post-detail-wrapper");
    mainWrapper.scrollTo({ top: 0 });
  }

  useEffect(() => {
    console.log(postDetailData?.data.data.board.tp, "postDetailData");
  }, [postDetailData]);
  return (
    <>
      <DetailOverlay
        css={style.detail}
        layoutId={props.seq}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="post-detail-wrapper"
        onWheel={(event) => {
          event.stopPropagation();
        }}>
        {isFetched && (
          <div
            className="inner"
            ref={innerRef}>
            <h2 className="title">{postDetailData?.data.data.board.ttl}</h2>
            <div className="desc-box">
              <DescFormat
                title={i18n.language === "ko" ? "유형" : "Type"}
                desc={postDetailData?.data.data.board.tpDtl === 1 ? "Article" : "Reference"}
              />
              {/* {postDetailData?.data.data.board.tp === 2 && (
                <DescFormat
                  title={i18n.language === "ko" ? "유형" : "Type"}
                  desc={postDetailData?.data.data.board.tpDtl === 1 ? "Article" : "Reference"}
                />
              )} */}
              {postDetailData?.data.data.board.prvncUrl ? (
                <DescFormatLink
                  title={i18n.language === "ko" ? "출처" : "Source"}
                  data={postDetailData?.data.data.board}
                />
              ) : (
                <DescFormat
                  title={i18n.language === "ko" ? "출처" : "Source"}
                  desc={postDetailData?.data.data.board.prvncDtl}
                />
              )}
              {/* {postDetailData?.data.data.board.tpDtl === 2 ? null : postDetailData?.data.data.board.prvncUrl ? (
                <DescFormatLink
                  title={i18n.language === "ko" ? "출처" : "Source"}
                  data={postDetailData?.data.data.board}
                />
              ) : (
                <DescFormat
                  title={i18n.language === "ko" ? "출처" : "Source"}
                  desc={postDetailData?.data.data.board.prvncDtl}
                />
              )} */}
              <DescFormat
                title={i18n.language === "ko" ? "작성일" : "Date"}
                desc={formatEnYmd(`${postDetailData?.data.data.board.rgstYmd}`)}
              />
            </div>
            <div className="cont-box">
              <div className="img-box">
                <img
                  src={getImagePath(getDetailImageUrl())}
                  alt={`${postDetailData?.data.data.board.ttl}-이미지`}
                />
              </div>

              <div className="text-box">{postDetailData?.data.data.board.cont}</div>
              {/* 노티스 롤백으로 인한 채용 바로가기 버튼 삭제 */}
              {/* <EmployLinkButton data={postDetailData?.data.data.board} /> */}
            </div>
            <div className="file-box">
              {postDetailData?.data.data.board.boardFileList.map((file, index) => (
                <FileDownload
                  fileTitle={`${file.attachFile.orgNm}`}
                  fileExt={`${file.attachFile.ext}`}
                  onClick={() => fileDownload(file.attachFile.seq, file.attachFile.orgNm)}
                />
              ))}
            </div>
            {!postDetailData?.data.data.boardNext && !postDetailData?.data.data.boardPrev ? null : (
              <div
                className="article-box"
                ref={otherContentRef}>
                <OtherContent
                  isFetched={isFetched}
                  nextBoard={postDetailData?.data.data.boardNext}
                  prevBoard={postDetailData?.data.data.boardPrev}
                  otherContentPageToTop={otherContentPageToTop}
                  title="Prev・Next article"
                />
              </div>
            )}

            <div className="rounded-btn-box">
              <RoundedButton
                type="list"
                onClick={() => moveListPage()}
              />
            </div>
          </div>
        )}
      </DetailOverlay>
    </>
  );
}

const style = {
  detail: css`
    .inner {
      padding-top: ${theme.typography.pxToRem(240)};
      padding-bottom: ${theme.typography.pxToRem(149.75)};
      width: 100%;
      max-width: ${theme.typography.pxToRem(1140)};
      margin: 0 auto;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        padding: 0 ${theme.typography.pxToRem(20)};
        padding-top: ${theme.typography.pxToRem(116)};
        padding-bottom: ${theme.typography.pxToRem(80)};
      }
    }
    .title {
      font-weight: 600;
      font-size: ${theme.typography.pxToRem(56)};
      line-height: calc(66 / 56);
      color: ${theme.palette.white};
      margin-bottom: ${theme.typography.pxToRem(60)};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        padding: 0 ${theme.typography.pxToRem(17.5)};
        font-size: ${theme.typography.pxToRem(28)};
        line-height: calc(38 / 28);
        margin-bottom: ${theme.typography.pxToRem(40)};
      }
    }

    .desc-box {
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        padding: 0 ${theme.typography.pxToRem(17.5)};
      }
    }

    .cont-box {
      margin: ${theme.typography.pxToRem(150)} 0;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin: ${theme.typography.pxToRem(80)} 0;
      }

      .img-box {
        width: 100%;
        margin-bottom: ${theme.typography.pxToRem(150)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin-bottom: ${theme.typography.pxToRem(80)};
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text-box {
        font-weight: 400;
        ${globalCSS.en};
        font-size: ${theme.typography.pxToRem(20)};
        line-height: ${theme.typography.pxToRem(34)};
        color: ${theme.palette.white};
        white-space: pre-wrap;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(16)};
          line-height: ${theme.typography.pxToRem(24)};
        }
      }

      .link-box {
        border: 1px solid ${theme.palette.white};
        border-radius: 100px;
        margin-top: ${theme.typography.pxToRem(150)};

        width: 100%;
        height: ${theme.typography.pxToRem(80)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          height: ${theme.typography.pxToRem(48)};
        }

        a {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-weight: 700;
            font-size: ${theme.typography.pxToRem(30)};
            line-height: 1;
            letter-spacing: -0.6px;
            color: ${theme.palette.white};
            opacity: 1;

            @media (max-width: ${theme.breakpoints.values.lg}px) {
              font-size: ${theme.typography.pxToRem(16)};
              letter-spacing: -0.32px;
            }
          }

          i {
            margin-left: ${theme.typography.pxToRem(8)};
            z-index: 1;
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              margin-left: ${theme.typography.pxToRem(5)};
              width: ${theme.typography.pxToRem(18)};
              height: ${theme.typography.pxToRem(18)};
            }
          }
        }
      }
    }

    .other-content-box {
      margin: ${theme.typography.pxToRem(150)} 0;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin: ${theme.typography.pxToRem(80)} 0;
      }
    }

    .rounded-btn-box {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  `,
};
