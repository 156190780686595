import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import RoutesData from "./routes/Routes";
import globalCSS from "./assets/ts/global-css";
import Header from "@/components/header";
import Layout from "./components/layout";
import useSmoothScroll from "@/hooks/use-smooth-scroll";
import Lenis from "@studio-freight/lenis";
import { FC, useEffect, useState } from "react";
import useWebGL from "@/hooks/use-webgl";
import BouerlabWebGL from "@/assets/ts/bauerlab/BouerlabWebGL";
import { useLanguage } from "./hooks/use-language";
import { useRecoilState } from "recoil";
import { prevPathState } from "./state";

interface ComponentProps {
  lenis: Lenis | null;
  webGL: BouerlabWebGL | null;
}

function Body() {
  const location = useLocation();
  const navigate = useNavigate();
  const lenis = useSmoothScroll();
  const webGL = useWebGL();
  const lang = useLanguage();

  const pathname = location.pathname;
  useEffect(() => {
    if (webGL) {
      // webGL.removeSyncImgMesh("app");
    }
  }, [pathname]);

  const [prevPath, setPrevPath] = useRecoilState(prevPathState);
  useEffect(() => {
    setPrevPath(location.pathname);
  }, [location]);

  return (
    <>
      <div className={lang === "1" ? "App ko" : "App en"}>
        <Layout lenis={lenis}>
          <div id="webglContainer" css={globalCSS.webglContainer}></div>
          <Routes>
            {RoutesData.map((route) => {
              let Component: FC<ComponentProps> = route.component;
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<Component lenis={lenis} webGL={webGL} />}
                />
              );
            })}
          </Routes>
        </Layout>
      </div>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Body />
    </BrowserRouter>
  );
}

export default App;
