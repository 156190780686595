import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import i18n from "@/i18n";
import { css } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { t } from "i18next";
import { forwardRef, useEffect, useRef } from "react";
import CircleCanvas from "../animation/circle-canvas";
import RoundedButton from "../ui/roundedButton";

/*
dangerouslySetInnerHTML={{
                __html: t("brand:sound-goes.desc_mo"),
              }}

*/
const AboutVisual = forwardRef<HTMLElement, {}>((props, ref) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  return (
    <section className="sc-visual" ref={ref} css={style.visual}>
      <CircleCanvas
        bgColor="transparent"
        color="#fff"
        isMask="true"
        id="circleCanvas"
        scale="2"
        // maskProgress={listProgress}
      />
      <div className="title-box">
        <h2 className="sc-title">
          <img src={!isMobile ? "/images/image/about/img-ttl.svg" : "/images/image/about/img-ttl-mo.svg"} alt="about" />
        </h2>
        <div className="sc-desc-box">
          <p className="sc-desc">
            <span css={{ transform: "translateX(50px)" }}>
              We create a narrative that delves into the genesis of all art,
            </span>
            <span>
              and <em>design that story in a unique manner.</em>
            </span>
            {/* <span>birth to extraordinary creations.</span> */}
          </p>
          <p className="sc-desc">
            <span
              dangerouslySetInnerHTML={{ __html: t("about:visualDesc1") }}
              css={{
                fontSize: i18n.language === "ko" ? "1.25rem" : "1.875rem",
                lineHeight: i18n.language === "ko" ? "1.875rem" : "2.5rem",
                letterSpacing: i18n.language === "ko" ? "0px" : "0.6px",
                transform: i18n.language === "ko" ? "translateX(16px)" : "translateX(-165px)",
              }}></span>
            <span
              dangerouslySetInnerHTML={{ __html: t("about:visualDesc2") }}
              css={{
                fontSize: i18n.language === "ko" ? "1.25rem" : "1.875rem",
                lineHeight: i18n.language === "ko" ? "1.875rem" : "2.5rem",
                letterSpacing: i18n.language === "ko" ? "0px" : "0.6px",
                transform: i18n.language === "ko" ? "translateX(85px)" : "translateX(12px)",
              }}></span>
          </p>
        </div>
      </div>
      <div className="btn-box">
        <RoundedButton type="scroll" />
      </div>
    </section>
  );
});

const style = {
  visual: css`
    position: relative;
    width: 100%;
    min-height: calc(var(--vh) * 100);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    padding: ${theme.typography.pxToRem(180)} 0 ${theme.typography.pxToRem(40)};
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      /* padding-top: calc(238 / 812 * 100vh);
      padding: calc(238 / 812 * 100vh) calc(20 / 375 * 100vw)
        ${theme.typography.pxToRem(30)}; */
      display: block;
      padding: calc(168 / 768 * 100vh) calc(20 / 375 * 100vw) 1.875rem;
      overflow-x: hidden;
    }

    .circle-canvas {
      opacity: 1;
      z-index: 2;
      /* z-index: -1; */
    }

    .title-box {
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .sc-title {
      position: relative;
      z-index: 2;
      width: calc(857 / 1920 * 100%);
      min-width: ${theme.typography.pxToRem(857)};
      aspect-ratio: 857 / 260;

      img {
        width: 100%;
        height: 100%;
      }

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: calc(208 / 375 * 100vw);
        min-width: ${theme.typography.pxToRem(208)};
        aspect-ratio: 208 / 58;
      }
    }

    .sc-desc-box {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      margin-top: ${theme.typography.pxToRem(80)};
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        justify-content: center;
        align-items: center;
        margin-top: ${theme.typography.pxToRem(60)};
      }

      .sc-desc {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: ${theme.typography.pxToRem(30)};
        line-height: ${theme.typography.pxToRem(40)};
        color: ${theme.palette.white};
        letter-spacing: 0.6px;
        ${globalCSS.en};
        transform: translateX(-100px);

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          text-align: center;
          font-size: calc(16 / 375 * 100vw);
          line-height: calc(20 / 16);
          transform: none;
        }
        em {
          color: ${theme.palette.yTxt};
        }

        span:nth-of-type(1) {
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            transform: none;
          }
        }

        + .sc-desc {
          margin-top: ${theme.typography.pxToRem(30)};
          font-size: ${theme.typography.pxToRem(20)};
          line-height: ${theme.typography.pxToRem(30)};
          letter-spacing: 0;
          transform: translateX(275px);
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            transform: none;
            text-align: center;
            span {
              font-size: calc(14 / 375 * 100vw);
              line-height: calc(24 / 14);
            }
          }
          span:nth-of-type(1) {
            /* transform: translateX(60px); */
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              transform: none;
            }
          }
          span:nth-of-type(2) {
            /* transform: translateX(125px); */
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              transform: none;
            }
          }
        }
      }
    }

    .btn-box {
      margin-top: auto;
      position: relative;
      z-index: 1;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        position: absolute;
        bottom: ${theme.typography.pxToRem(30)};
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
      }
    }
  `,

  circle: css``,
};

export default AboutVisual;
