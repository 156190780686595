import useSmoothScroll from "./use-smooth-scroll";

export function useMoveTop() {
  // const lenis = useSmoothScroll();
  function moveTop() {
    // lenis.scrollTo(0, { immediate: true });
  }

  return { moveTop };
}
