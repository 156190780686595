import { atom } from "recoil";
import { getPaginatedPostListParamsType, getPostDetailParamsType, postType } from "@/types/post";
export const postListParamState = atom<getPaginatedPostListParamsType>({
  key: "postListParamState/post",
  default: {
    pg: 1,
    pgSz: 10,
    sordFld: "rgstYmd",
    sordMetd: "DESC",
    schFld: "",
    schTxt: "",
    schTp: "",
    schTpDtl: "",
    schLangTp: "1",
  },
});

export const postDetailParamState = atom<getPostDetailParamsType>({
  key: "postDetailParamState/post",
  default: {
    seq: null,
    schFld: "",
    schTxt: "",
    schTp: "",
    schTpDtl: "",
    schLangTp: "1",
  },
});

export const postItemOpenState = atom({
  key: "isPostItemOpenState",
  default: false,
});

export const postMobileTabsOpenState = atom({
  key: "postMobileTabsOpenState",
  default: false,
});
