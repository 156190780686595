// import Lenis from "@studio-freight/lenis";
// import useSmoothScroll from "@/hooks/use-smooth-scroll";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { format } from "path";
import { cursorInnerHTML } from "@/assets/ts/common";

class CreationInteraction {
  constructor() {}

  format(min: number, start: number, end: number, scroll: number, max: number) {
    const progress = (scroll - start) / (end - start);
    return min + (max - min) * progress;
  }

  tabScale(dom: HTMLElement, trigger: HTMLElement, scroll: number) {
    const start = trigger.clientHeight - 200;
    const end = trigger.clientHeight;
    const min = 0;
    const max = 1;

    if (scroll >= start && scroll <= end) {
      const value = this.format(min, start, end, scroll, max);
      dom.style.transform = `scale(${value}) rotate(-45deg)`;
    } else if (scroll < start) {
      dom.style.transform = `scale(${min}) rotate(-45deg)`;
    } else if (scroll > end) {
      dom.style.transform = `scale(${max}) rotate(-45deg)`;
    }
  }

  tabWidth(dom: HTMLElement, trigger: HTMLElement, scroll: number) {
    const start = trigger.offsetTop;
    const end = trigger.offsetTop + trigger.clientHeight * 0.5;
    const min = 44;
    const max = 300;

    if (scroll >= start && scroll <= end) {
      const value = this.format(min, start, end, scroll, max);

      dom.style.width = `${value}px`;
    }
  }

  tabRotate(dom: HTMLElement, trigger: HTMLElement, scroll: number) {
    const start = trigger.offsetTop + trigger.clientHeight * 0.7;
    const end = trigger.offsetTop + trigger.clientHeight - 200;
    const min = -45;
    const max = 0;

    if (scroll >= start && scroll <= end) {
      const value = this.format(min, start, end, scroll, max);
      dom.style.transform = `scale(1) rotate(${value}deg)`;
    } else if (scroll > end) {
      dom.style.transform = `scale(1) rotate(0deg)`;
    }
  }

  subTabsAppear(
    doms: HTMLElement[],
    trigger: HTMLElement,
    scroll: number,
    direction: number
  ) {
    if (direction === -1) return;
    const start = trigger.offsetTop + trigger.clientHeight - 200;
    if (scroll > start) {
      doms.forEach((dom: HTMLElement, i: number) => {
        requestAnimationFrame(() => {
          dom.style.visibility = "visible";

          requestAnimationFrame(() => {
            dom.style.top = `${55 + 55 * i}px`;
          });
        });
      });
    }
  }

  subTabDisappear(
    doms: HTMLElement[],
    trigger: HTMLElement,
    scroll: number,
    direction: number
  ) {
    // if (direction === 1) return;
    // // doms.forEach((dom: HTMLElement, i: number) => {
    // //   gsap.to(dom, {
    // //     scrollTrigger: {
    // //       trigger: trigger,
    // //       start: "-100px top",
    // //     },
    // //     top: "0px",
    // //     delay: i * 0.2,
    // //     onComplete: () => {
    // //       gsap.set(dom, { visibility: "hidden", delay: 0.3 });
    // //     },
    // //   });
    //   // requestAnimationFrame(() => {
    //   //   dom.style.top = "0px";
    //   //   requestAnimationFrame(() => {
    //   //     // dom.style.visibility = "hidden";
    //   //   });
    //   // });
    // });
  }

  fixedTabs(direction: number) {
    if (direction !== 1) {
      gsap.to(".creation-tab-list", {
        scrollTrigger: {
          trigger: ".sc-list",
          start: "top top",
          end: "center top",
          scrub: 1,
        },
        top: "50%",
      });
    } else {
      gsap.to(".creation-tab-list", {
        scrollTrigger: {
          trigger: ".sc-list",
          start: "top top",
          end: "center top",
          scrub: 1,
        },
        top: "100px",
      });
    }
  }

  handleMouseEnter(dom: any) {
    const cursor = document.getElementById("cursor");
    const title = dom.querySelector("figcaption");

    if (title && cursor) {
      cursor.innerHTML = cursorInnerHTML(title.innerText);
      cursor.classList.add("over");
      cursor.classList.add("creation");
    }
  }

  handleMouseLeave() {
    const cursor = document.getElementById("cursor");
    if (cursor) {
      cursor.innerText = "";
      cursor.classList.remove("over");
      cursor.classList.remove("creation");
    }
  }
}

const creation = new CreationInteraction();

export default creation;
