import { formatRangeHyphenYmd } from "@/assets/ts/format";
import { DetailDesc, DetailOverlay, DetailWrap } from "@/components/posts/post";
import { css } from "@emotion/react";
import XTDatepicker from "../ui/datepicker";
import { DescFormat } from "../ui/description";
import { useRecoilState } from "recoil";
import { isDetailPageState } from "@/state";
import { useEffect, useState } from "react";
import theme from "@/assets/ts/theme";
import { useScheduleDetailManager } from "@/hooks/use-international";
import moment from "moment";
import { useParams } from "react-router-dom";

export default function InternationalDetail() {
  const [isDetailPage, setIsDetailPage] = useRecoilState(isDetailPageState);
  const { seq } = useParams();
  const { scheduleDetailData, isFetched } = useScheduleDetailManager(seq);

  const getDrtrNm = () => {
    const list = scheduleDetailData?.data.data.schedule.scheduleDetailList;

    if (list.length >= 1) {
      const nm = list.map((item) => item.drtrNm);

      return nm.join(", ");
    } else {
      return list?.[0].drtrNm;
    }
  };

  useEffect(() => {
    setIsDetailPage(true);
    return () => {
      setIsDetailPage(false);
    };
  }, [isDetailPage, setIsDetailPage]);

  return (
    <>
      <DetailOverlay
        layoutId={seq}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4 }}
        onWheel={(event) => {
          event.stopPropagation();
        }}>
        {isFetched && (
          <div
            className="inner"
            css={style.inner}>
            <div className="info-box">
              <XTDatepicker
                start={scheduleDetailData?.data.data.schedule.strtYmd}
                end={scheduleDetailData?.data.data.schedule.endYmd}
              />
              <div className="title-box">
                <h2 className="title">{scheduleDetailData?.data.data.schedule.ttl}</h2>
                <div className="desc-box">
                  <DescFormat
                    title="총괄"
                    desc={getDrtrNm()}
                  />
                  <DescFormat
                    title="기간"
                    desc={formatRangeHyphenYmd(
                      scheduleDetailData?.data.data.schedule.strtYmd,
                      scheduleDetailData?.data.data.schedule.endYmd
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="content-box">{scheduleDetailData?.data.data.schedule.cont}</div>
          </div>
        )}
      </DetailOverlay>
    </>
  );
}

const style = {
  inner: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: ${theme.typography.pxToRem(1140)};
    padding: ${theme.typography.pxToRem(240)} 0 ${theme.typography.pxToRem(150)};
    margin: 0 auto;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      max-width: 100%;
      /* padding: ${theme.typography.pxToRem(160)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(86.3)}; */
      padding: 0 ${theme.typography.pxToRem(20)};
      padding-top: ${theme.typography.pxToRem(116)};
      padding-bottom: ${theme.typography.pxToRem(80)};
    }
    .info-box {
      display: flex;
      /* justify-content: space-between; */

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        flex-direction: column-reverse;
      }

      .title-box {
        margin-left: ${theme.typography.pxToRem(60)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin-left: 0;
          padding: 0 ${theme.typography.pxToRem(17.5)};
          margin-bottom: ${theme.typography.pxToRem(80)};
        }
        .title {
          font-weight: 600;
          font-size: ${theme.typography.pxToRem(56)};
          line-height: calc(66 / 56);
          color: ${theme.palette.white};
          margin-bottom: ${theme.typography.pxToRem(60)};

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            font-weight: 700;
            font-size: ${theme.typography.pxToRem(28)};
            line-height: calc(38 / 28);
            margin-bottom: ${theme.typography.pxToRem(43.7)};
          }
        }
      }
    }

    .content-box {
      margin-top: ${theme.typography.pxToRem(150)};
      color: ${theme.palette.white};
      font-weight: 400;
      font-size: ${theme.typography.pxToRem(20)};
      line-height: calc(34 / 20);

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-top: ${theme.typography.pxToRem(80)};
        font-size: ${theme.typography.pxToRem(16)};
        line-height: calc(26 / 16);
      }
    }
  `,
};
