import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./assets/ts/theme";
import "./i18n";
import "./App.scss";
import { CssBaseline } from "@mui/material";
import { ReactQueryDevtools } from "react-query/devtools";
import { useEffect } from "react";

const queryClient = new QueryClient();

// axios.defaults.baseURL = "https://k8s-project.ex-it.co.kr/bauerlab/web-api";
axios.defaults.baseURL = process.env.REACT_APP__BASEURL;

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

axios.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    console.log("error: ==================================== ", error.response);
    console.log(
      "error: ==================================== ",
      error.response.status
    );
    // ************* 임시 hide ****************
    // 에러 페이지 리다이렉트
  }
);

ReactDOM.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        {/* <CssBaseline /> */}
        {/* <Global /> */}
        <App />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </RecoilRoot>,
  document.getElementById("root")
);

reportWebVitals();
