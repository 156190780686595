export const peopleData = [
  {
    imgUrl: "/images/image/about/img-people-hyerim.png",
    nmKo: "박해림",
    nmEn: "PARK HAE RIM",
    rankKo: "이사",
    rankEn: "Director",
    positionKo: "작가",
    positionEn: "Head Writer",
    direction: "bottom",
    delay: Math.random(),
  },
  {
    imgUrl: "/images/image/about/img-people-kyunghyun.png",
    nmKo: "김경현",
    nmEn: "KIM KYUNG HYUN",
    rankKo: "이사",
    rankEn: "Director",
    positionKo: "총괄 프로듀서",
    positionEn: "Head Producer",
    direction: "top",
    delay: Math.random(),
  },
  {
    imgUrl: "/images/image/about/img-people-suhyun.png",
    nmKo: "조수현",
    nmEn: "CHO SU HYUN",
    rankKo: "대표",
    rankEn: "CEO",
    positionKo: "총괄 디렉터",
    positionEn: "Executive Director",
    direction: "bottom",
    delay: Math.random(),
  },
  {
    imgUrl: "/images/image/about/img-people-yoonkeuk.png",
    nmKo: "강윤극",
    nmEn: "KANG YOON KEUK",
    rankKo: "이사",
    rankEn: "CTO",
    positionKo: "최고 기술 책임자",
    positionEn: "Director of Media",
    positionEn2: "Production",
    direction: "top",
    delay: Math.random(),
  },
  {
    imgUrl: "/images/image/about/img-people-changhyun.png",
    nmKo: "김창현",
    nmEn: "KIM CHANG HYUN",
    rankKo: "본부장",
    rankEn: "Art Director",
    positionKo: "아트 디렉터",
    positionEn: "Head of Content Creation",
    direction: "bottom",
    delay: Math.random(),
  },
];
