import { forwardRef, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { lenisScrollState } from "@/state";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import { css } from "@emotion/react";
import theme from "@/assets/ts/theme";

interface ContactMapProps {
  map: string;
  local: string;
}

interface ScrollObjectType {
  observer: Observer | null;
  scrollObj: ScrollObject | null;
}

const ContactMap = ({ map, local }: ContactMapProps) => {
  const mapRef = useRef(null);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const [progress, setProgress] = useState(0);

  const interactionItems = useRef<ScrollObjectType>({
    observer: null,
    scrollObj: null,
  });

  useEffect(() => {
    if (mapRef.current) {
      interactionItems.current.observer = new Observer();
      interactionItems.current.observer.add(mapRef.current);
      interactionItems.current.scrollObj = new ScrollObject(mapRef.current);
    }

    return () => {
      if (mapRef.current) {
        interactionItems.current.observer.remove(mapRef.current);
        interactionItems.current.observer.kill();
        interactionItems.current.scrollObj.kill();
      }
    };
  }, [mapRef]);

  // useEffect(() => {
  //   if (interactionItems.current.scrollObj) {
  //     let scrollObj = interactionItems.current.scrollObj;
  //     scrollObj.update(lenisState.scroll);
  //     let progress = scrollObj.ratio.allY * 2;
  //     progress = clamp(progress);
  //     setProgress((prev) => {
  //       if (typeof progress !== "undefined") {
  //         return prev + (progress - prev) * 0.5;
  //       }

  //       return prev;
  //     });
  //   }
  // }, [lenisState]);

  return (
    <div className="map-wrapper" ref={mapRef} css={[style.map]}>
      <div className="map-box">
        <iframe src={map} title={local}></iframe>
      </div>
    </div>
  );
};

const style = {
  map: css`
    height: 1px;
    overflow: hidden;
    transition: all 1s 0.3s;
    &.in-view-from-bottom {
      height: 100%;
    }
    .map-box {
      width: 100%;
      border: 1px solid ${theme.palette.blue};
      iframe {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
      }
    }
  `,
};

export default ContactMap;
