import { css } from "@emotion/react";
import {
  CreationCardItem,
  CreationItemHorizontal,
  CreationItemVertical,
} from "./creation-item";
import React, { Fragment, useEffect, useRef, useState } from "react";
import theme from "@/assets/ts/theme";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import { Link } from "react-router-dom";
import { useCursor } from "@/hooks/use-cursor";

export default function CreationList({
  data,
  pagingList,
}: {
  data: any;
  pagingList: any;
}) {
  const contentsRef = useRef(null);
  const isCursorHoverActive = useCursor(contentsRef.current);

  useEffect(() => {
    let observer = new Observer();
    let list = [];
    document
      .querySelectorAll("[data-fade-anim],[data-fade-anim-mask]")
      .forEach((el: HTMLElement) => {
        el.style.transitionDelay = Math.random() * 0.4 + "s";
        observer.add(el);
        list.push(el);
      });
    return () => {
      list.forEach((el) => {
        observer.remove(el);
      });
      observer.kill();
    };
  }, [pagingList]);

  const hasData = (item, component) => {
    if (!item) return null;
    return component;
  };

  const firstLayout = (items) => {
    if (!items?.[0]) return null;
    return (
      <>
        {items?.[0] && (
          <ul className="creation-list" css={[style.list, style.line1]}>
            {hasData(items?.[0], <CreationItemHorizontal item={items?.[0]} />)}
            {hasData(items?.[1], <CreationItemVertical item={items?.[1]} />)}
            {hasData(items?.[2], <CreationItemHorizontal item={items?.[2]} />)}
            {hasData(items?.[3], <CreationItemHorizontal item={items?.[3]} />)}
          </ul>
        )}
        {hasData(items?.[4], <CreationCardItem item={items?.[4]} />)}
        {items?.[5] && (
          <ul className="creation-list" css={[style.list, style.line2]}>
            {hasData(items?.[5], <CreationItemHorizontal item={items?.[5]} />)}
            {hasData(items?.[6], <CreationItemHorizontal item={items?.[6]} />)}
            {hasData(items?.[7], <CreationItemVertical item={items?.[7]} />)}
          </ul>
        )}
        {items?.[8] && (
          <ul className="creation-list" css={[style.list, style.line3]}>
            {hasData(items?.[8], <CreationItemHorizontal item={items?.[8]} />)}
            {hasData(items?.[9], <CreationItemHorizontal item={items?.[9]} />)}
            {hasData(
              items?.[10],
              <CreationItemHorizontal item={items?.[10]} />
            )}
          </ul>
        )}
        {items?.[11] && (
          <ul className="creation-list" css={[style.list, style.line4]}>
            {hasData(items?.[11], <CreationItemVertical item={items?.[11]} />)}
            {hasData(items?.[12], <CreationItemVertical item={items?.[12]} />)}
            {hasData(
              items?.[13],
              <CreationItemHorizontal item={items?.[13]} />
            )}
            {hasData(
              items?.[14],
              <CreationItemHorizontal item={items?.[14]} />
            )}
          </ul>
        )}
      </>
    );
  };

  const defaultLayout = (items) => {
    if (!items?.[0]) return null;
    return (
      <>
        {items?.[0] && (
          <ul className="creation-list" css={[style.list, style.line2]}>
            {hasData(items?.[0], <CreationItemHorizontal item={items?.[0]} />)}
            {hasData(items?.[1], <CreationItemHorizontal item={items?.[1]} />)}
            {hasData(items?.[2], <CreationItemVertical item={items?.[2]} />)}
          </ul>
        )}
        {items?.[3] && (
          <ul className="creation-list" css={[style.list, style.line3]}>
            {hasData(items?.[3], <CreationItemHorizontal item={items?.[3]} />)}
            {hasData(items?.[4], <CreationItemHorizontal item={items?.[4]} />)}
            {hasData(items?.[5], <CreationItemHorizontal item={items?.[5]} />)}
          </ul>
        )}
        {items?.[6] && (
          <ul className="creation-list" css={[style.list, style.line4]}>
            {hasData(items?.[6], <CreationItemVertical item={items?.[6]} />)}
            {hasData(items?.[7], <CreationItemVertical item={items?.[7]} />)}
            {hasData(items?.[8], <CreationItemHorizontal item={items?.[8]} />)}
            {hasData(items?.[9], <CreationItemHorizontal item={items?.[9]} />)}
          </ul>
        )}
      </>
    );
  };
  //  세로 = 3, 가로 = 2, 상세 메인 6(pc) or 7(mobile), 상세 내용 이미지 = 5

  return (
    <div ref={contentsRef}>
      {data?.[0] ? firstLayout(data?.[0]?.items) : null}
      {pagingList
        ? pagingList?.map((el, i) => {
            return defaultLayout(el.items);
          })
        : null}
    </div>
  );
}

const style = {
  cardItem: css`
    border: solid 1px #fff;
    height: 100vh;
    width: 100%;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      height: auto;
    }
  `,
  list: css`
    display: grid;
    height: ${theme.typography.pxToRem(548)};
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 50%);
    column-gap: ${theme.typography.pxToRem(4)};

    + ul {
      margin-top: ${theme.typography.pxToRem(437)};
    }

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      display: flex;
      height: auto;
      flex-direction: column;
      gap: 60px;
      li {
        margin: 0 !important;
      }
    }
  `,

  line1: css`
    li {
      &:nth-of-type(1) {
      }
      &:nth-of-type(2) {
        grid-row: span 2;
      }
      &:last-of-type {
        grid-column: span 2;
        grid-row: span 2;
        padding-bottom: ${theme.typography.pxToRem(37)};
      }
    }
  `,
  line2: css`
    li {
      &:first-of-type {
        grid-column: span 2;
        grid-row: span 2;
      }

      &:last-of-type {
        grid-column: span 2;
        grid-row: span 2;
        margin-left: 50%;
      }
    }
  `,
  line3: css`
    li {
      &:first-of-type {
        grid-column: span 2;
        margin-left: 50%;
      }
      &:nth-of-type(2) {
        grid-row: span 2;
        grid-column: span 2;
      }
    }
  `,
  line4: css`
    height: auto;
    li {
      &:nth-of-type(1) {
        grid-row: span 2;
      }

      &:nth-of-type(2) {
        grid-row: span 2;
      }

      &:nth-of-type(3) {
      }
      &:nth-of-type(4) {
        grid-column: span 2;
        margin-left: 50%;
      }
    }
  `,
};

// grid-column: span 2;
