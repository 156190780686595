import { useCreationManager } from "@/hooks/use-creation";
import { Fragment, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import theme from "@/assets/ts/theme";
import RollingImgs from "@/components/work/rolling-imgs";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import CreationList from "@/components/work/creation-list";
import globalCSS from "@/assets/ts/global-css";
import RoundedButton from "@/components/ui/roundedButton";
import CircleCanvas from "@/components/animation/circle-canvas";
import { useRecoilState } from "recoil";
import { lenisScrollState } from "@/state";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import Easing from "@/assets/ts/bauerlab/util/Easing";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";

export default function Creation({ lenis }) {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const { i18n } = useTranslation();
  const { t } = useTranslation("creation");
  const {
    creationList,
    creationTypeList,
    fetchNextPage,
    setCreationListParamsForm,
    creationListParamsForm,
    hasNextPage,
    currentPage,
    setCurrentPage,
    setSearchParams,
    searchParams,
  } = useCreationManager();

  const [listProgress, setListProgress] = useState(0);
  const [crossListProgress, setCrossListProgress] = useState(0);
  const [init, setInit] = useState(false);

  useEffect(() => {
    document.querySelector("html").classList.add("b");

    return () => {
      document.querySelector("html").classList.remove("b");
    };
  }, []);

  /*
    Tab List Control
  */
  const [tabActiveId, setTabActiveId] = useState(searchParams.get("schTp") || "");
  const [tabIsAnimation, setTabIsAnimation] = useState(true);
  const [tabIsOpen, setTabIsOpen] = useState(false);
  const [tabOpenProgress, setTabOpenProgress] = useState(0);
  const tabExtendOffset = useRef({
    progress: 1,
  });

  const tabOpenToggle = (isOpen: boolean, duration: number = 0.7) => {
    if (tabAutoCloseTimer.current) clearTimeout(tabAutoCloseTimer.current);
    setTabIsOpen(isOpen);
    gsap.to(tabExtendOffset.current, {
      progress: isOpen ? 1 : 0,
      duration: duration,
      overwrite: true,
      ease: "Power4.easeInOut",
      onUpdate: () => {
        setTabOpenProgress(tabExtendOffset.current.progress);
      },
    });
  };

  /*
    Tab List Control end
  */
  const scrollObjects = useRef({});
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const circleCanvas = useRef(null);
  const crossListContainerRef = useRef(null);
  const listSectRef = useRef(null);
  const tabsRef = useRef(null);

  const tabAnimScaleRef = useRef(null);
  const tabAnimWidthRef = useRef(null);
  const tabAnimRotRef = useRef(null);
  const tabAnimYRef = useRef(null);
  const tabAnimOpenRef = useRef(null);

  const tabAutoCloseTimer = useRef(null);

  const [tabAnimOffset, setTabAnimOffset] = useState({
    y: 100,
    scale: 0,
    width: 0,
    rotate: 0,
    open: 0,
  });

  const tabListRef = useRef([
    { cd: "0", nm: "ALL", count: "" },
    { cd: "1", nm: "PERFORMANCE", count: "" },
    { cd: "2", nm: "MEDIA", count: "" },
    { cd: "3", nm: "LIVE EVENTS", count: "" },
    { cd: "4", nm: "IMMERSIVE SPACE", count: "" },
  ]);

  useEffect(() => {
    if (init) return;
    setInit(true);
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    }

    circleCanvas.current = document.querySelector(".circle-canvas");
    scrollObjects.current["crossListContainer"] = new ScrollObject(crossListContainerRef.current);
    scrollObjects.current["tabAnimScale"] = new ScrollObject(tabAnimScaleRef.current);
    scrollObjects.current["tabAnimWidth"] = new ScrollObject(tabAnimWidthRef.current);
    scrollObjects.current["tabAnimRot"] = new ScrollObject(tabAnimRotRef.current);
    scrollObjects.current["tabAnimY"] = new ScrollObject(tabAnimYRef.current);
    scrollObjects.current["tabAnimOpen"] = new ScrollObject(tabAnimOpenRef.current);
  }, []);

  useEffect(() => {
    let scroll = lenisState.scroll;
    let scrollObjs = scrollObjects.current;
    if (scrollObjs) {
      for (let key in scrollObjs) {
        let scrollObj: ScrollObject = scrollObjs[key];
        scrollObj.update(scroll);
      }
    }

    /*
      circle animation
    */
    let crossListScrollObj = scrollObjs["crossListContainer"];
    let listIntroY = crossListScrollObj.ratio.yClamp;
    setListProgress(listIntroY);

    // let crossListProgress = clamp(crossListScrollObj.ratio.allY - 0.15);
    // crossListProgress = Easing.easeInOutQuart(crossListProgress);

    /*
      tab animation
    */
    let scaleProgress = scrollObjs["tabAnimScale"].ratio.yClamp;
    let widthProgress = scrollObjs["tabAnimWidth"].ratio.yClamp;
    let rotProgress = scrollObjs["tabAnimRot"].ratio.yClamp;
    let rotProgress2 = scrollObjs["tabAnimRot"].ratio.y;
    let yProgress = scrollObjs["tabAnimY"].ratio.yClamp;
    let yProgress2 = scrollObjs["tabAnimY"].ratio.y;
    let openProgress = scrollObjs["tabAnimOpen"].ratio.yClamp;

    let crossListProgress = clamp(scrollObjs["tabAnimScale"].ratio.y, 0, 2) / 2;
    setCrossListProgress(crossListProgress);

    circleCanvas.current.style.opacity = `${1 - scaleProgress}`;

    scaleProgress = Easing.easeInOutQuint(scaleProgress);
    widthProgress = Easing.easeInOutQuart(widthProgress);
    rotProgress = Easing.easeInOutQuart(rotProgress);
    yProgress = Easing.easeInOutQuad(yProgress);
    openProgress = openProgress; //Easing.easeInOutQuart(openProgress);

    if (rotProgress2 > 1) {
      if (tabIsAnimation) {
        tabOpenToggle(true);
        tabAutoCloseTimer.current = setTimeout(() => {
          tabOpenToggle(false);
        }, 1000);
      }
      setTabIsAnimation(false);
    } else {
      if (!tabIsAnimation) {
        tabOpenToggle(false, 0.7);
      }
      setTabIsAnimation(true);
    }

    let tabAnimOffsetScale = scaleProgress;
    let tabAnimOffsetWidth = 44 + (300 - 44) * widthProgress;
    let tabAnimOffsetRot = 45 - 45 * rotProgress;
    let tabAnimMoveY = window.innerHeight * 0.5 - 44 * 0.5;
    let tabAnimOffsetY = tabAnimMoveY - (tabAnimMoveY - 44) * yProgress;

    if (isMobile) {
      tabAnimOffsetWidth = 44 + (200 - 44) * widthProgress;
      tabAnimMoveY = window.innerHeight * 0.5 - 32 * 0.5;
      tabAnimOffsetY = tabAnimMoveY - (tabAnimMoveY - 22) * yProgress;
    }

    setTabAnimOffset({
      ...tabAnimOffset,
      y: tabAnimOffsetY,
      scale: tabAnimOffsetScale,
      width: tabAnimOffsetWidth,
      rotate: tabAnimOffsetRot,
      open: clamp(openProgress * 2),
    });
  }, [lenisState]);

  const handleMoreClick = () => {
    fetchNextPage();
    setCurrentPage(currentPage + 1);
  };

  const exceptFirstList = creationList?.slice(1);
  const [store, setStore] = useState([]);

  useEffect(() => {
    if (currentPage == -1) {
      return setStore([]);
    }
    const newData = [...store];
    if (exceptFirstList?.[currentPage]) {
      newData.push(exceptFirstList?.[currentPage]);
    }

    setStore(newData);

    return null;
  }, [creationList]);

  console.log("@ store", store);
  console.log("! creationList", creationList);

  /*
    리트스 변경 클릭
  */
  const tabListItemClick = (id) => {
    setTabActiveId(id);
  };

  return (
    <div css={style.container} id="creation">
      <CircleCanvas
        bgColor="#000000"
        isMask="true"
        id="circleCanvas"
        scale="2"
        // maskProgress={listProgress}
      />
      <ul
        className="creation-tab-list"
        ref={tabsRef}
        style={{
          transform: `translate3d(-50%,${tabAnimOffset.y}px,0)`,
        }}>
        {Array.isArray(creationTypeList) &&
          creationTypeList?.map((tab, index) => {
            return (
              <li
                className="creation-tab-item"
                key={tab.cd}
                value={tab.cd}
                onClick={(e) => {
                  if (creationListParamsForm.schTp !== e.currentTarget.getAttribute("value")) {
                    setCreationListParamsForm((prev) => {
                      return {
                        ...prev,
                        schTp: e.currentTarget?.getAttribute("value") ? e.currentTarget?.getAttribute("value") : "",
                      };
                    });
                    setSearchParams({
                      schTp: e.currentTarget?.getAttribute("value") ? e.currentTarget?.getAttribute("value") : "",
                    });
                  }

                  if (tab.cd != tabActiveId) {
                    // console.log("aa");
                    tabListItemClick(tab.cd);
                  }
                  tabOpenToggle(!tabIsOpen);
                }}
                style={{
                  pointerEvents: tabIsAnimation ? "none" : "auto",
                  width: tabAnimOffset.width,
                  transform: `translate3d(0,${
                    isMobile ? tabOpenProgress * (32 + 6) * index : tabOpenProgress * (44 + 10) * index
                  }px,0) scale(${tabAnimOffset.scale}) rotate(${tabAnimOffset.rotate}deg)`,
                  zIndex: tabActiveId === tab.cd ? 1 : 0,
                }}>
                <button
                  type="button"
                  style={
                    tabActiveId === tab.cd
                      ? {
                          backgroundColor: tabActiveId === tab.cd ? "#ffffff" : "#000000",
                        }
                      : {
                          backgroundColor: tabActiveId === tab.cd ? "#ffffff" : "#000000",
                          opacity: `${tabAnimOffset.open}`,
                        }
                  }>
                  <span
                    style={{
                      color: tabActiveId === tab.cd ? "#000000" : "#ffffff",
                      opacity: tabIsAnimation ? 0 : 1,
                      transform: `translate3d(${tabActiveId === tab.cd ? 0 : isMobile ? 11 : 18}px,0,0)`,
                    }}>
                    {tab.nm}
                  </span>
                  <img
                    src="/images/icon/creation-menu-arrow.svg"
                    alt=""
                    style={{
                      opacity: tabActiveId === tab.cd ? (tabIsAnimation ? 0 : 1) : 0,
                    }}
                  />
                </button>
              </li>
            );
          })}
        <li
          className="creation-tab-item creation-list-total"
          style={{
            width: tabAnimOffset.width,
            color: "#ffffff",
            transform: `translate3d(0,${
              isMobile
                ? tabOpenProgress * 37 * tabListRef.current?.length
                : tabOpenProgress * (44 + 10) * tabListRef.current?.length
            }px,0) scale(${tabAnimOffset.scale}) rotate(${tabAnimOffset.rotate}deg)`,
          }}>
          <span style={{ opacity: tabOpenProgress }}>
            {tabActiveId === "" ? creationTypeList[0]?.count : creationTypeList[tabActiveId]?.count}
          </span>
        </li>
      </ul>

      <section className="keyvisual">
        <div className="keyvisual__content">
          <h1>
            <p style={{ display: "none" }}>CREATION</p>
            <img
              src={!isMobile ? "/images/image/creation/img-title.svg" : "/images/image/creation/img-title-mo.svg"}
              alt="바우어랩"
            />
          </h1>
          <div className="desc-right">
            {!isMobile ? (
              <>
                <p>
                  <em>Let yourself</em> dive into the borderless
                </p>
                <p>universe where art and</p>
                <p>technology have merged to create.</p>
              </>
            ) : (
              <>
                <p>
                  <em>Let yourself</em> dive into the borderless
                  <br />
                  universe where art and technology
                </p>
                <p>have merged to create.</p>
              </>
            )}
          </div>
          <div className="desc-left">
            <p
              style={{
                fontSize:
                  i18n.language === "ko"
                    ? !isMobile
                      ? "20px"
                      : "calc(14 / 375 * 100vw)"
                    : !isMobile
                    ? "30px"
                    : "calc(16 / 375 * 100vw)",
                lineHeight:
                  i18n.language === "ko"
                    ? !isMobile
                      ? "30px"
                      : "calc(24 / 14)"
                    : !isMobile
                    ? "40px"
                    : "calc(20 / 14)",
              }}>
              {t(isMobile ? "descMo.a" : "desc.a")}
            </p>
            <p
              style={{
                fontSize:
                  i18n.language === "ko"
                    ? !isMobile
                      ? "20px"
                      : "calc(14 / 375 * 100vw)"
                    : !isMobile
                    ? "30px"
                    : "calc(16 / 375 * 100vw)",
                lineHeight:
                  i18n.language === "ko"
                    ? !isMobile
                      ? "30px"
                      : "calc(24 / 14)"
                    : !isMobile
                    ? "40px"
                    : "calc(20 / 14)",
                paddingLeft: i18n.language === "ko" ? (!isMobile ? "350px" : "0px") : !isMobile ? "150px" : "0px",
              }}>
              {t(isMobile ? "descMo.b" : "desc.b")}
            </p>
            <p
              style={{
                fontSize:
                  i18n.language === "ko"
                    ? !isMobile
                      ? "20px"
                      : "calc(14 / 375 * 100vw)"
                    : !isMobile
                    ? "30px"
                    : "calc(16 / 375 * 100vw)",
                lineHeight:
                  i18n.language === "ko"
                    ? !isMobile
                      ? "30px"
                      : "calc(24 / 14)"
                    : !isMobile
                    ? "40px"
                    : "calc(20 / 14)",
                paddingLeft: i18n.language === "ko" ? (!isMobile ? "50px" : "0px") : !isMobile ? "60px" : "0px",
              }}>
              {t(isMobile ? "descMo.c" : "desc.c")}
            </p>
            <p
              style={{
                fontSize:
                  i18n.language === "ko"
                    ? !isMobile
                      ? "20px"
                      : "calc(14 / 375 * 100vw)"
                    : !isMobile
                    ? "30px"
                    : "calc(16 / 375 * 100vw)",
                lineHeight:
                  i18n.language === "ko"
                    ? !isMobile
                      ? "30px"
                      : "calc(24 / 14)"
                    : !isMobile
                    ? "40px"
                    : "calc(20 / 14)",
                paddingLeft: i18n.language === "ko" ? "0px" : !isMobile ? "210px" : "0px",
              }}>
              {t(isMobile ? "descMo.d" : "desc.d")}
            </p>

            {isMobile ? (
              <p
                style={{
                  fontSize:
                    i18n.language === "ko"
                      ? !isMobile
                        ? "20px"
                        : "calc(14 / 375 * 100vw)"
                      : !isMobile
                      ? "30px"
                      : "calc(16 / 375 * 100vw)",
                  lineHeight:
                    i18n.language === "ko"
                      ? !isMobile
                        ? "30px"
                        : "calc(24 / 14)"
                      : !isMobile
                      ? "40px"
                      : "calc(20 / 14)",
                }}>
                {t("descMo.e")}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="scroll-indication">
          <RoundedButton type={"scroll"} />
        </div>
      </section>
      <section className="cross-list-container" ref={crossListContainerRef}>
        <div className="rolling-img-container">
          <RollingImgs lenis={lenis} progress={crossListProgress} data={creationTypeList} />
        </div>
        <div className="tab-anim tab-anim-scale" ref={tabAnimScaleRef}></div>
        <div className="tab-anim tab-anim-width" ref={tabAnimWidthRef}></div>
        <div className="tab-anim tab-anim-rot" ref={tabAnimRotRef}></div>
        <div className="tab-anim tab-anim-y" ref={tabAnimYRef}></div>
        <div className="tab-anim tab-anim-open" ref={tabAnimOpenRef}></div>
      </section>
      <section className="content-list" ref={listSectRef}>
        <CreationList pagingList={store} data={creationList} />
      </section>
      {/* <MoreButton handleMoreClick={handleMoreClick} />
       */}
      {hasNextPage && (
        <div className="btn-more-wrap">
          <RoundedButton type={"more"} onClick={() => handleMoreClick()} />
        </div>
      )}
      {/* <RoundedButton type={"scroll"} /> */}
    </div>
  );
}

const style = {
  container: css`
    background-color: #000;
    overflow: clip;

    ul,
    ol,
    li {
      list-style: none;
    }

    .sc {
      width: 100%;
      &-list {
        width: 100%;
      }
    }

    .keyvisual {
      position: relative;
      display: flex;
      flex-direction: column;
      position: relative;
      min-width: 1254px;
      width: calc(1254 / 1920 * 100%);
      height: calc(var(--vh) * 100);

      margin: 0 auto;
      margin-top: ${theme.typography.pxToRem(-30)};

      @media (max-width: ${theme.breakpoints.values.xl}px) {
        min-width: auto;
      }
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: 100%;
        text-align: center;
      }

      h1 {
        display: flex;
        /* width: calc(1254 / 1920 * 100%); */
        width: 100%;
        aspect-ratio: 1254 / 260;
        justify-content: center;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: calc(305 / 375 * 100vw);
          aspect-ratio: 305 / 58;
        }

        img {
          width: 100%;
          height: 100%;
        }
        svg {
          width: 100%;
        }
      }

      .keyvisual__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin: auto 0;
        padding-top: 40px;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: 100%;
          padding-top: 0px;
          margin: 0;
          padding: calc(168 / 768 * 100vh) calc(20 / 375 * 100vw) 1.875rem;
        }

        .page-title {
          width: ${theme.typography.pxToRem(277)};
          height: ${theme.typography.pxToRem(75.43)};
          margin-bottom: ${theme.typography.pxToRem(76.57)};
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .sc-title-desc {
          ${globalCSS.en};
          font-weight: 400;
          font-size: ${theme.typography.pxToRem(76)};
          line-height: calc(86 / 76);
          color: rgba(255, 255, 255, 1);

          em {
            color: rgba(255, 250, 0, 1);
          }

          &:nth-of-type(1) {
            /* transform: translateX(-30px); */
          }

          &:nth-of-type(2) {
            transform: translateX(-110px);
          }

          &:nth-of-type(3) {
            transform: translateX(150px);
          }
        }
      }

      p {
        color: ${theme.palette.white};
      }

      .desc-right {
        ${globalCSS.en};
        font-weight: 400;
        margin-top: ${theme.typography.pxToRem(60)};
        font-size: ${theme.typography.pxToRem(30)};
        line-height: ${theme.typography.pxToRem(40)};
        letter-spacing: 0.02em;
        margin-right: 0%; //(415 / 1920 * 100%);
        margin-left: auto;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          text-align: center;
          /* font-size: ${theme.typography.pxToRem(16)}; */
          font-size: calc(16 / 375 * 100vw);
          line-height: calc(20 / 16);
          /* line-height: ${theme.typography.pxToRem(20)}; */
          margin: 0;
          margin-top: ${theme.typography.pxToRem(60)};
          margin-bottom: ${theme.typography.pxToRem(40)};
        }

        p {
          em {
            color: rgba(255, 250, 0, 1);
          }
          &:nth-of-type(1) {
            padding-left: ${theme.typography.pxToRem(40)};
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              padding: 0;
            }
          }
          &:nth-of-type(3) {
            padding-left: ${theme.typography.pxToRem(200)};
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              padding: 0;
            }
          }
        }
      }

      .desc-left {
        margin-top: ${theme.typography.pxToRem(30)};
        font-weight: 400;
        font-size: ${theme.typography.pxToRem(20)};
        line-height: ${theme.typography.pxToRem(30)};
        margin-left: 0%; //(335 / 1920 * 100%);
        margin-right: auto;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          text-align: center;
          margin-top: 0;
          margin: 0 auto;
        }

        p {
          white-space: pre-wrap;

          &:nth-of-type(2) {
            padding-left: ${theme.typography.pxToRem(50)};
          }
          &:nth-of-type(3) {
            padding-left: ${theme.typography.pxToRem(20)};
          }
        }
      }

      .scroll-indication {
        width: 100%;
        margin-bottom: 40px;
        display: flex;
        justify-content: center;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          /* margin-bottom: 80px; */
          margin: 0;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: ${theme.typography.pxToRem(30)};
        }
      }
    }

    .cross-list-container {
      position: relative;
      width: 100vw;
    }

    .rolling-img-container {
      position: sticky;
      top: 0;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    }

    .tab-anim-scale {
      width: 100%;
      height: 100vh;
      margin-top: -50vh;
      border: solid 3px purple;
    }

    .tab-anim-width {
      width: 100%;
      height: 500px;
      margin-top: -250px;
      border: solid 3px white;
    }

    .tab-anim-rot {
      width: 100%;
      height: 300px;
      /* margin-top: -20vh; */
      border: solid 3px blue;
    }

    .tab-anim-y {
      width: 100%;
      height: 100px;
      /* margin-top: -20vh; */
      border: solid 3px yellow;
    }

    .tab-anim-open {
      width: 100%;
      height: 300px;
      /* margin-top: -50vh; */
      /* transform: translate3d(0, -80vh, 0); */
      border: solid 3px green;
    }
    .tab-anim-close {
      width: 100%;
      height: 500px;
      /* margin-top: -50vh; */
      /* transform: translate3d(0, -80vh, 0); */
      border: solid 3px orange;
      display: none;
    }

    .tab-open-reset {
      width: 100%;
      height: 300px;
      border: solid 3px gray;
      display: none;
    }

    .tab-anim {
      border: none;
    }

    .creation-tab-list {
      position: sticky;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0px;
      margin-left: 50vw;
      width: ${theme.typography.pxToRem(300)};
      z-index: 2;
      mix-blend-mode: difference;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-left: 50vw;
      }

      .creation-tab-item {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: ${theme.typography.pxToRem(44)};
        cursor: pointer;
        mix-blend-mode: normal;
        border-radius: 100px;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          height: ${theme.typography.pxToRem(32)};
        }

        + .creation-tab-item {
          position: absolute;
          top: 0;
        }

        &.is-scrolling {
          mix-blend-mode: difference;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          width: 100%;
          height: 100%;
          border-radius: 100px;
          cursor: pointer;
          background-color: ${theme.palette.black};
          border: 2px solid ${theme.palette.white};
          transition: background-color 0.2s ${theme.transitions.easing.inout1};
        }

        span {
          display: block;
          align-items: center;
          white-space: nowrap;
          font-size: ${theme.typography.pxToRem(26)};
          text-transform: uppercase !important;
          line-height: 1;
          color: ${theme.palette.white};
          letter-spacing: 0.52px;

          transition: opacity 0.5s ${theme.transitions.easing.inout1}, color 0.5s ${theme.transitions.easing.inout1},
            transform 0.5s ${theme.transitions.easing.inout3};

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            font-size: ${theme.typography.pxToRem(16)};
          }
        }

        img {
          position: relative;
          width: ${theme.typography.pxToRem(16)};
          height: ${theme.typography.pxToRem(18)};
          transition: opacity 0.4s 0s ${theme.transitions.easing.inout1},
            transform 0.4s 0s ${theme.transitions.easing.inout1};

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: ${theme.typography.pxToRem(12)};
            height: ${theme.typography.pxToRem(12)};
          }
        }

        &.active {
          background-color: ${theme.palette.white};

          span {
            color: ${theme.palette.black};
          }
        }

        &:hover {
          img {
            transform: rotate(90deg);
          }
        }
      }

      .creation-list-total {
        display: flex;
        justify-content: center;
        align-items: center;
        height: ${theme.typography.pxToRem(90)};
        span {
          font-size: ${theme.typography.pxToRem(60)};
          letter-spacing: 0.02em;
          line-height: 0;
          transition: none;
        }
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          height: ${theme.typography.pxToRem(70)};
          font-size: ${theme.typography.pxToRem(40)};
        }
      }
    }

    .creation-list {
      /* border: 2px solid red; */
      height: auto;
    }
    .content-list {
      /* margin-top: -50vh; */
      &:last-of-type {
        padding-bottom: ${theme.typography.pxToRem(150)};
      }
    }

    .btn-more-wrap {
      padding-bottom: ${theme.typography.pxToRem(150)};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `,
};
