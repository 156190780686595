import { peopleData } from "@/assets/mock/people";
import { lsToPx } from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import { createRef, forwardRef, useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { useMediaQuery } from "@mui/material";
import Observer from "@/assets/ts/bauerlab/util/Observer";
import { lenisScrollState } from "@/state";
import { useRecoilState } from "recoil";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import { Color } from "three";
import i18n from "@/i18n";

const AboutPeople = forwardRef<HTMLElement, {}>((props, ref) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const isMobileSm = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  const peopelCardListRef = useRef(null);
  const peopleCardItemRefs = useRef(Array.from({ length: peopleData.length }, () => createRef()));
  const timer = useRef(Array.from({ length: peopleData.length }, () => createRef()));

  useEffect(() => {
    let observer = new Observer();
    if (isMobile) {
      peopleCardItemRefs.current.map((ref, i) => {
        observer.add(ref.current as HTMLElement);
      });
    } else {
      observer.add(peopleViewTriggerRef.current, (isIn, entry) => {
        if (isIn) {
          if (entry.boundingClientRect.top > window.innerHeight * 0.5) {
            peopleCardItemRefs.current.map((ref, i) => {
              let timeTemp = timer.current[i].current as any;
              if (timeTemp) clearTimeout(timeTemp);
              timeTemp = setTimeout(() => {
                (ref.current as HTMLElement).classList.add("active");
              }, i * 100);
            });
          }
        }
      });
      observer.add(peopelCardListRef.current, (isIn, entry) => {
        if (!isIn && entry.boundingClientRect.top > window.innerHeight * 0.5) {
          peopleCardItemRefs.current.map((ref, i) => {
            let timeTemp = timer.current[i].current as any;
            if (timeTemp) clearTimeout(timeTemp);
            (ref.current as HTMLElement).classList.remove("active");
          });
        }
      });
    }

    return () => {
      if (isMobile) {
        // peopleCardItemRefs.current.map((ref, i) => {
        //   observer.remove(ref.current as HTMLElement);
        // });
      } else {
        // observer.remove(peopleViewTriggerRef.current);
      }
      observer.kill();
    };
  }, [isMobile]);

  const titleBoxStickyRef = useRef(null);
  const titleBoxRef = useRef(null);
  const titleBoxInnerRef = useRef(null);
  const titleBoxContentRef = useRef(null);
  const circleRef = useRef(null);
  const titleRef = useRef(null);
  const descSubRef = useRef(null);
  const peopleViewTriggerRef = useRef(null);
  const scrollObjects = useRef({});
  const colorWhite = new Color("#ffffff");
  const colorBlue = new Color("#131CDE");
  const crtColor = new Color("#ffffff");
  useEffect(() => {
    scrollObjects.current["titleBox"] = new ScrollObject(titleBoxRef.current);
    scrollObjects.current["titleBoxSticky"] = new ScrollObject(titleBoxStickyRef.current);
    return () => {};
  }, []);

  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  useEffect(() => {
    let scroll = lenisState.scroll;
    let scrollObjs = scrollObjects.current;
    for (let key in scrollObjs) {
      scrollObjs[key].update(scroll);
    }

    let titleBoxScroll = scrollObjs["titleBox"].ratio;
    let titleBoxStickyScroll = scrollObjs["titleBoxSticky"].ratio;

    if (!isMobile) {
      circleRef.current.style.transform = `translate3d(0,0,0) scale(${0.3 + clamp(titleBoxStickyScroll.y)})`;
    } else {
      circleRef.current.style.transform = `translate3d(0,0,0) scale(${0.5 + clamp(titleBoxStickyScroll.y)})`;
    }

    // circleRef.current.style.transform = `translate3d(0,0,0) scale(${0.3 + clamp(titleBoxStickyScroll.y)})`;

    circleRef.current.style.opacity = `${1 - titleBoxStickyScroll.yClamp}`;

    titleBoxContentRef.current.style.transform = `translate3d(0,0,0) scale(${clamp(
      // 0.4 + titleBoxScroll.inBottom * 0.6
      0.4 + titleBoxStickyScroll.yClamp * 0.6
    )})`;
    titleRef.current.style.transform = `perspective(1000px) translate3d(0,0,0) rotateY(${
      360 * titleBoxStickyScroll.yClamp
    }deg)`;

    // titleBoxRef.color =
    crtColor.lerpColors(colorWhite, colorBlue, titleBoxStickyScroll.yClamp);
    titleBoxRef.current.style.color = `#${crtColor.getHexString()}`;

    descSubRef.current.style.opacity = `${(titleBoxStickyScroll.y - 1) * 4}`;
    // circleRef.current.style.transform = `scale(${0.6 + titleBoxScroll * 0.4})`;
  }, [lenisState]);

  return (
    <section className="sc-people" id="people" ref={ref} css={style.people}>
      <div className="title-box" ref={titleBoxRef}>
        <div className="title-box__inner" ref={titleBoxInnerRef}>
          <div className="circle" ref={circleRef}></div>
          <div className="title-box__content" ref={titleBoxContentRef}>
            <h2 ref={titleRef}>people</h2>
            <p className="sc-desc">
              Bauer Lab is a creative team of professionals with diverse expertise in various fields. We're actively
              involved in numerous significant projects, consistently producing impactful content. We always embrace
              challenges and never cease to progress, continuously pushing the boundaries of innovation.
            </p>
            <p
              className="sc-desc-sub"
              ref={descSubRef}
              dangerouslySetInnerHTML={{
                __html: isMobile ? t("about:peopleDescMobile") : t("about:peopleDesc"),
              }}></p>
          </div>
        </div>
        <div className="title-box-sticky" ref={titleBoxStickyRef}></div>
      </div>
      <div className="people-inner">
        <div className="content-box">
          <ul css={style.peopleCardList} ref={peopelCardListRef}>
            {peopleData.map(
              (
                { imgUrl, nmKo, nmEn, rankKo, rankEn, positionKo, positionEn, positionEn2, direction, delay },
                index
              ) => (
                <li
                  className="card-item"
                  key={index}
                  //@ts-ignore
                  ref={peopleCardItemRefs.current[index]}
                  data-dir={index % 2 === 0 ? "up" : "down"}>
                  <div className="card-img__back">
                    <div
                      className="info-box"
                      style={{
                        height:
                          isMobileSm && i18n.language === "en" ? "calc(260 / 650 * 100%)" : "calc(220 / 650 * 100%)",
                      }}>
                      <div className="info-box__inner">
                        {i18n.language === "ko" && <span className="name">{nmKo}</span>}
                        <span className="name">{nmEn}</span>
                        {i18n.language === "ko" ? (
                          <p className="position">
                            <span>{rankKo}.</span>
                            <span>{positionKo}</span>
                          </p>
                        ) : (
                          <p className="position">
                            <span>
                              {rankEn}.<br />
                              {positionEn}
                              <br />
                              {positionEn2 && <span>{positionEn2}</span>}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-img__front"
                    style={{
                      height:
                        isMobileSm && i18n.language === "en" ? "calc(380 / 650 * 100%)" : "calc(430 / 650 * 100%)",
                    }}>
                    <img
                      // src={`/images/image/about/people${index + 1}.jpg`}
                      src={imgUrl}
                      alt={`${nmKo},${nmEn}, ${rankEn}. ${positionEn}`}
                    />
                  </div>
                </li>
              )
            )}
          </ul>
          <div className="people-view-trigger" ref={peopleViewTriggerRef}></div>
        </div>
      </div>
    </section>
  );
});

const style = {
  people: css`
    position: relative;
    z-index: 2;
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: ${theme.typography.pxToRem(300)};
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      margin-bottom: ${theme.typography.pxToRem(100)};
    }

    .title-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${theme.palette.white};
      margin-bottom: ${theme.typography.pxToRem(200)};
      min-height: 100vh;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        padding: 0 ${theme.typography.pxToRem(15)};
      }

      .title-box__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: sticky;
        top: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        .circle {
          position: absolute;
          width: 100%;
          aspect-ratio: 1/1;
          border: solid 10px #fff;
          border-radius: 50%;
          transform: scale(0.6);
        }
      }

      h2 {
        text-align: center;
        /* color: ${theme.palette.blue}; */
        font-size: ${theme.typography.pxToRem(140)};
        ${globalCSS.en};
        font-weight: 700;
        font-size: calc(140 / 1920 * 100vw);
        text-transform: uppercase;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: calc(60 / 375 * 100vw);
        }
      }

      .title-box__content {
        position: relative;
        transform: scale(0.5);
      }

      .sc-desc {
        ${globalCSS.en};
        font-weight: 400;
        font-size: ${theme.typography.pxToRem(46)};
        line-height: calc(56 / 46);
        ${lsToPx(46, -2)};
        margin: ${theme.typography.pxToRem(60)} 0;
        width: 100%;
        max-width: ${theme.typography.pxToRem(1034)};
        text-align: center;
        transform-origin: center 0;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: calc(16 / 375 * 100vw);
          letter-spacing: calc(20 / 16);
          letter-spacing: 0;
        }
      }

      .sc-desc-sub {
        position: absolute;
        width: 100%;
        top: 100%;
        font-weight: 400;
        font-size: ${theme.typography.pxToRem(20)};
        line-height: calc(30 / 20);
        text-align: center;
        opacity: 0;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: calc(16 / 375 * 100vw);
          letter-spacing: calc(24 / 16);
          letter-spacing: 0;
        }
      }

      .title-box-sticky {
        width: 100%;
        height: 100vh;
      }
    }

    .people-inner {
      width: 100vw;
      overflow: hidden;
      /* margin-top: ${theme.typography.pxToRem(400)}; */

      .content-box {
        position: relative;
        display: flex;
        /* padding-left: calc(100 / 1920 * 100%); */

        .people-view-trigger {
          position: absolute;
          width: 100vw;
          height: 10px;
          top: 50%;
        }
      }
    }
    /* background-color: white; */
  `,

  circle: css`
    width: ${theme.typography.pxToRem(515.4)};
    /* height: ${theme.typography.pxToRem(515.4)}; */
    aspect-ratio: 1 / 1;
    border: 14.07px solid ${theme.palette.white};
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      width: calc(300 / 375 * 100vw);
      border-width: 5.71px;
    }

    h2 {
      font-size: ${theme.typography.pxToRem(80)} !important;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        font-size: calc(40 / 375 * 100vw) !important;
      }
    }

    p {
      color: ${theme.palette.white};
      max-width: ${theme.typography.pxToRem(380)};
      margin-top: ${theme.typography.pxToRem(30)};
      text-align: center;
      ${globalCSS.en};
      font-size: ${theme.typography.pxToRem(17.76)};
      line-height: calc(24.87 / 17.76);
      letter-spacing: 0.355px;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        font-weight: 700;
        font-size: calc(10 / 375 * 100vw);
        /* font-size: ${theme.typography.pxToRem(7.2)}; */
        line-height: calc(10.08 / 7.2);
        letter-spacing: 0.144px;
      }
    }
  `,
  peopleCardList: css`
    width: calc(100% - 40px);
    padding-left: 20px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 55px;

    @media (max-width: ${theme.breakpoints.values.xl}px) {
      gap: 40px;
    }

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
      padding-left: 0px;
      gap: 0;
    }

    .card-item {
      position: relative;
      aspect-ratio: 300 / 600;
      border-radius: 600px;
      overflow: hidden;
      transform: rotate(30deg);

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: calc(214 / 375 * 100%);
        margin: 0 auto;
        transform: rotate(45deg);

        + .card-item {
          margin-top: calc(-300 / 1024 * 100%);
        }
      }

      .card-img__back {
        width: 100%;
        height: 100%;
        background: ${theme.palette.blue};
        border-radius: 600px;
        display: flex;
        justify-content: center;

        .info-box {
          width: 100%;
          height: calc(220 / 650 * 100%);

          padding: 20px 0;
          display: flex;
          justify-content: center;

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            padding: 40px 0;
          }
          @media (max-width: ${theme.breakpoints.values.sm}px) {
            padding: 20px 0;
          }

          .info-box__inner {
            display: flex;
            flex-direction: column;
            height: min-content;
          }

          span {
            font-weight: 700;
            /* font-size: ${theme.typography.pxToRem(18)}; */
            /* line-height: ${theme.typography.pxToRem(21)}; */
            font-size: calc(18 / 1920 * 100vw);
            line-height: calc(21 / 1920 * 100vw);
            letter-spacing: 0;
            color: ${theme.palette.white};

            @media (max-width: ${theme.breakpoints.values.lg}px) {
              font-size: ${theme.typography.pxToRem(14)};
              line-height: ${theme.typography.pxToRem(16)};
            }
            @media (max-width: ${theme.breakpoints.values.sm}px) {
              font-size: ${theme.typography.pxToRem(12)};
              line-height: ${theme.typography.pxToRem(14)};
            }
          }
          .position {
            display: flex;
            margin-top: ${theme.typography.pxToRem(5)};
            gap: ${theme.typography.pxToRem(5)};
            /* flex-direction: column; */
            span {
              font-weight: 400;
              /* font-size: ${theme.typography.pxToRem(18)}; */
              /* margin-top: ${theme.typography.pxToRem(5)}; */

              font-size: calc(17 / 1920 * 100vw);
              /* line-height: calc(21 / 1920 * 100vw); */
              line-height: calc(20 / 16);

              @media (max-width: ${theme.breakpoints.values.lg}px) {
                font-size: ${theme.typography.pxToRem(14)};
                line-height: ${theme.typography.pxToRem(16)};
              }
              @media (max-width: ${theme.breakpoints.values.sm}px) {
                font-size: ${theme.typography.pxToRem(12)};
                line-height: ${theme.typography.pxToRem(14)};
              }
            }
            font-weight: 400;
            /* font-size: ${theme.typography.pxToRem(18)}; */
            margin-top: ${theme.typography.pxToRem(5)};

            font-size: calc(16 / 1920 * 100vw);
            /* line-height: calc(21 / 1920 * 100vw); */
            line-height: calc(20 / 16);

            @media (max-width: ${theme.breakpoints.values.lg}px) {
              font-size: ${theme.typography.pxToRem(14)};
              line-height: ${theme.typography.pxToRem(16)};
            }
            @media (max-width: ${theme.breakpoints.values.sm}px) {
              font-size: ${theme.typography.pxToRem(12)};
              line-height: ${theme.typography.pxToRem(14)};
            }
          }
        }
      }

      .card-img__front {
        position: absolute;
        left: 0;
        width: 100%;
        height: calc(430 / 650 * 100%);
        background: ${theme.palette.white};
        border-radius: 600px;
        overflow: hidden;
        display: flex;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          /* object-position: center; */
          /* transform: translate(-25%, 25%) rotate(-30deg); */
        }
      }

      &[data-dir="up"] {
        .card-img__front {
          top: 0;
          align-items: flex-end;
          transform: translate3d(0, -100%, 0);
        }

        .card-img__back {
          align-items: flex-end;
          transform: translate3d(0, -100%, 0);
        }
      }

      &[data-dir="down"] {
        .card-img__front {
          bottom: 0;
          transform: translate3d(0, 100%, 0);
          img {
            object-fit: contain;
            width: 150%;
            transform: translate(-15%, 0) rotate(-90deg);
            /* height: 120%; */
          }
        }
        .card-img__back {
          transform: translate3d(0, 100%, 0);
          .info-box {
            align-items: flex-end;

            .info-box__inner {
              transform: translate3d(50%, 50%, 0) rotate(-90deg);
              transform-origin: 0 center;
            }
          }
        }
      }

      &.active,
      &.in-view-from-bottom {
        .card-img__front {
          transition: transform 1.2s ${theme.transitions.easing.inout3};
          transform: translate3d(0, 0%, 0);
          transition-delay: 0.3s;
        }
        .card-img__back {
          transition: transform 1.2s ${theme.transitions.easing.inout3};
          transform: translate3d(0, 0%, 0);
        }
      }
    }
  `,
};

export default AboutPeople;
