import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import * as ReactDOMServer from "react-dom/server";

const SwiperCustomPagination = {
  clickable: true,
  renderBullet: function (index: number, className: string) {
    return `
                <button class="${className} custom-pagination-button" >
      
          </button>
    `;
  },
};

export default SwiperCustomPagination;
