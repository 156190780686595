import AboutBusiness from "@/components/about/about-business";
import AboutOffice from "@/components/about/about-office";
import AboutPeople from "@/components/about/about-people";
import AboutVisual from "@/components/about/about-visual";
import AboutBackgroundInteraction from "@/utils/ineraction/about";
import { lenisScrollState } from "@/state";
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";

const colors = [
  { r: 16, g: 17, b: 221 },
  { r: 255, g: 255, b: 255 },
  { r: 217, g: 212, b: 0 },
];

export default function About() {
  const backgroundRef = useRef(null);
  const visualRef = useRef(null);
  const businessRef = useRef(null);
  const peopleRef = useRef(null);
  const officeRef = useRef(null);
  const lenis = useRecoilValue(lenisScrollState);

  const backgroundChangeMotion = new AboutBackgroundInteraction(colors, {
    visualRef,
    businessRef,
    peopleRef,
    officeRef,
  });

  useEffect(() => {
    if (!lenis) return;
    backgroundChangeMotion.updateBackgroundColor(lenis.scroll, backgroundRef.current);
  }, [lenis]);

  return (
    <>
      <div className="background" ref={backgroundRef} css={style.background}></div>
      <AboutVisual ref={visualRef} />
      <AboutBusiness ref={businessRef} />
      <AboutPeople ref={peopleRef} />
      <AboutOffice ref={officeRef} />
    </>
  );
}

const style = {
  background: css`
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: rgba(16, 17, 221, 1);
    z-index: 1;
    overflow-x: hidden;
  `,
};
