import { usePostManager } from "@/hooks/use-post";
import { useEffect, useRef, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import PostDetail from "@/components/posts/post-detail";
import globalCSS from "@/assets/ts/global-css";
import { XTSearchInput } from "@/components/ui/input";
import UndefinedSchResult from "@/components/posts/undefined-sch-result";
import { AnimatePresence } from "framer-motion";
import { List } from "@/components/posts/post";
import PostItem from "@/components/posts/post-item";
import XTPagination from "@/components/ui/pagination";
import { css } from "@emotion/react";
import commonMotion from "@/utils/ineraction/common";
import CircleSm from "@/assets/ts/bauerlab/CircleSm";
import theme from "@/assets/ts/theme";
import i18n from "@/i18n";
import { useRecoilValue } from "recoil";
import { lenisScrollState } from "@/state";
import { useMediaQuery } from "@mui/material";

export default function NewsList({ lenis }) {
  const location = useLocation();
  const detailMatch = useMatch("/news/:seq");
  const subPageTitle = location.pathname.replace("/", "");
  const listRef = useRef(null);
  const [isFirst, setIsFirst] = useState(true);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const lenisState = useRecoilValue(lenisScrollState);

  useEffect(() => {
    document.querySelector("html").classList.add("b");

    return () => {
      document.querySelector("html").classList.remove("b");
    };
  }, []);

  const { postTypeList, postList, setSearchParams, defaultParams, searchParams } = usePostManager();

  const [schForm, setSchForm] = useState({
    pg: defaultParams.pg,
    schTxt: defaultParams.schTxt,
  });

  const convertPostDetailype = (tpDtl: number) => {
    return postTypeList?.BoardDetailType?.[`${tpDtl}`];
  };

  const handleChangePage = (e, value) => {
    setSchForm((prev) => {
      return {
        ...prev,
        pg: value,
      };
    });

    window.dispatchEvent(new Event("lenisTop"));
  };

  const handleSchTxt = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSchForm((prev) => {
      return {
        ...prev,
        schTxt: e.target.value,
        pg: "1",
      };
    });
  };

  useEffect(() => {
    setIsFirst(false);
  }, []);

  useEffect(() => {
    if (!isFirst) {
      setSearchParams({
        ...searchParams,
        ...schForm,
      });
    }
  }, [schForm]);

  const circleRef = useRef(null);
  const circleCanvasRef = useRef(null);
  useEffect(() => {
    if (!circleRef.current) {
      circleRef.current = new CircleSm(circleCanvasRef.current, "transparent");
    } else {
      return;
    }
  }, [circleCanvasRef]);

  useEffect(() => {
    if (isMobile) return;
    if (lenisState.scroll > 0 && lenisState.scroll < 200) {
      circleCanvasRef.current.style.transform = `translateY(${lenisState.scroll}px) scale(1.3)`;
      circleCanvasRef.current.style.opacity = `${lenisState.scroll / -200 + 1}`;
    }
  }, [lenisState, isMobile]);

  return (
    <>
      <div className="main-wrap" css={style.visual}>
        <canvas ref={circleCanvasRef} css={globalCSS.circleSm} className="circle-sub"></canvas>

        <div css={[globalCSS.postContainer, style.container]}>
          <h2 className="sub-page-title">NEWS</h2>
          {/* NEWS는 탭 없음 */}

          <div className="contents-area">
            <div className="inner">
              <XTSearchInput
                value={schForm.schTxt}
                onChange={handleSchTxt}
                placeholder={i18n.language === "ko" ? "제목을 입력해 주세요." : "Please enter a keyword"}
                type="text"
                endAdornment={
                  <div className="input-btn-wrap">
                    {schForm.schTxt?.length > 0 && (
                      <button
                        onClick={() =>
                          setSchForm((prev) => {
                            return {
                              ...prev,
                              schTxt: "",
                            };
                          })
                        }>
                        <i css={globalCSS.icoDelete}></i>
                      </button>
                    )}
                    <button onClick={() => {}}>
                      <i css={globalCSS.icoSearch}></i>
                    </button>
                  </div>
                }
              />

              {/* NEWS는 탭 없음 */}

              <AnimatePresence>
                <List ref={listRef} onMouseLeave={() => commonMotion.hover(-1, listRef.current)}>
                  {defaultParams.schTxt && postList.boardList.list.length == 0 ? (
                    <UndefinedSchResult schTxt={schForm.schTxt} />
                  ) : (
                    postList.boardList.list.map((item, index) => {
                      const itemData = {
                        image: item.boardImageList[0].attachImage.seq,
                        title: item.ttl,
                        cont: item.cont,
                        date: item.formatRgstYmd,
                        prvncDtl: item.prvncDtl,
                        seq: item.seq,
                        category: convertPostDetailype(item.tpDtl),
                        idx: index,
                      };

                      return (
                        <PostItem
                          data={itemData}
                          key={index}
                          lenis={lenis}
                          handleItemHover={() => commonMotion.hover(index, listRef.current)}
                          schTxt={schForm.schTxt}
                        />
                      );
                    })
                  )}
                </List>
              </AnimatePresence>
            </div>
          </div>
          {postList.boardList.list.length > 0 && (
            <div className="pagination-box" css={globalCSS.paginationWrap}>
              <XTPagination
                onChange={handleChangePage}
                page={Number(schForm.pg)}
                count={postList.boardList?.totalPageSize}
              />
            </div>
          )}
        </div>
      </div>
      {detailMatch ? <PostDetail seq={detailMatch.params.seq} /> : null}
    </>
  );
}

const style = {
  visual: css`
    .circle-canvas {
      opacity: 0.7;
    }
  `,
  container: css`
    flex-direction: column;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding-top: ${theme.typography.pxToRem(70)};
    }

    .contents-area {
      justify-content: center;
    }

    .inner {
      max-width: ${theme.typography.pxToRem(1140)} !important;

      ul {
        width: 100%;
      }
    }
  `,
};
