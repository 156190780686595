export default class AboutBackgroundInteraction {
  private colors: any;
  private refs: any;

  constructor(colors, refs) {
    this.colors = colors;
    this.refs = refs;
  }

  calculateBackgroundColors(nextColorIndex, scroll, start, end) {
    const calculateColor = (prev, next) => {
      return this.formatChangeBackground(prev, next, scroll, start, end);
    };

    return {
      r: calculateColor(this.colors[nextColorIndex - 1].r, this.colors[nextColorIndex].r),
      g: calculateColor(this.colors[nextColorIndex - 1].g, this.colors[nextColorIndex].g),
      b: calculateColor(this.colors[nextColorIndex - 1].b, this.colors[nextColorIndex].b),
    };
  }

  formatChangeBackground(prev, next, scroll, start, end) {
    let value = prev - ((prev - next) * (scroll - start)) / (end - start);
    return Math.round(value);
  }

  calculateScrollIntervals() {
    const visualH = this.refs.visualRef.current.clientHeight;
    const businessH = this.refs.businessRef.current.clientHeight;
    const peopleH = this.refs.peopleRef.current.clientHeight;
    const officeH = this.refs.officeRef.current.clientHeight;
    const peopleMarginBottom = Number(getComputedStyle(this.refs.peopleRef.current).marginBottom.replace("px", ""));
    const peopleCircleH = this.refs.peopleRef.current.querySelector(".title-box").clientHeight;

    return {
      whiteBackgroundStart: visualH + businessH + window.innerHeight * 0.5,
      whiteBackgroundEnd: visualH + businessH + window.innerHeight,
      yellowBackgroundStart: visualH + businessH + peopleCircleH,
      yellowBackgroundEnd: visualH + businessH + peopleCircleH + window.innerHeight,
    };
  }

  updateBackgroundColor(scrollPosition, backgroundRef) {
    const { whiteBackgroundStart, whiteBackgroundEnd, yellowBackgroundStart, yellowBackgroundEnd } =
      this.calculateScrollIntervals();
    if (scrollPosition < whiteBackgroundStart) {
      backgroundRef.style.backgroundColor = `rgba(${this.colors[0].r}, ${this.colors[0].g}, ${this.colors[0].b}, 1)`;
      return;
    }

    if (scrollPosition >= whiteBackgroundStart && scrollPosition <= whiteBackgroundEnd) {
      let { r, g, b } = this.calculateBackgroundColors(1, scrollPosition, whiteBackgroundStart, whiteBackgroundEnd);
      backgroundRef.style.backgroundColor = `rgba(${r}, ${g}, ${b}, 1)`;
      return;
    }

    if (scrollPosition >= yellowBackgroundStart && scrollPosition <= yellowBackgroundEnd) {
      let { r, g, b } = this.calculateBackgroundColors(2, scrollPosition, yellowBackgroundStart, yellowBackgroundEnd);
      backgroundRef.style.backgroundColor = `rgba(${r}, ${g}, ${b}, 1)`;
      return;
    }

    if (scrollPosition > yellowBackgroundEnd) {
      backgroundRef.style.backgroundColor = `rgba(${this.colors[2].r}, ${this.colors[2].g}, ${this.colors[2].b}, 1)`;
      return;
    }
  }
}
