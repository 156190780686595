const officeData = [
  {
    nation: "korea",
    local: "seoul",
    email: "office@bauer-lab.com",
    "business-hours": "10:00 am - 06:00 pm",
    phone: "02-515-1315",
    desc: "Headquarters of Bauer Lab, an immersive media company driven by a vision to create a new universe through the seamless fusion of art and technology. Pioneering the performing arts, media, and exhibitions industry, we are dedicated to the continuous creation of groundbreaking creative content.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.933117419366!2d126.91242717644796!3d37.55663952469455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c98d937aa66c9%3A0x5a9e4b8696b7afc9!2z7ISc7Jq47Yq567OE7IucIOuniO2PrOq1rCDrj5nqtZDroZwxN-q4uCA0MQ!5e0!3m2!1sko!2skr!4v1703841590309!5m2!1sko!2skr",
  },
  {
    nation: "usa",
    local: "ca",
    email: "byung.lee@bauer-lab.com",
    "business-hours": "10:00 AM - 06:00 PM (PST)",
    desc: "Bauer Lab's branch in California, USA. Bauer Lab is dedicated to creating global content and fostering collaborations with studios worldwide.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3301.72443944528!2d-118.25724962389795!3d34.153391412382014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c142b429c06d%3A0xc580bf24a935d45d!2zNTAwIE4gQnJhbmQgQmx2ZCwgR2xlbmRhbGUsIENBIDkxMjAzIOuvuOq1rQ!5e0!3m2!1sko!2skr!4v1702352934261!5m2!1sko!2skr",
    location: "500 N Brand Blvd, Glendale, CA 91203",
    locationMobile: "500 N Brand Blvd, Glendale,<br />CA 91203",
  },
  {
    nation: "canada",
    local: "quebec",
    email: "Office.Canada@bauer-lab.com",
    "business-hours": "10:00 am - 06:00 pm (PST)",
    desc: "Bauer Lab's branch in Québec, Canada. Leveraging the technological advances of K-art and spearheading cultural influence, Bauer Lab is dedicated to sharing our vision with audiences around the world.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2730.5510703029418!2d-71.23073932340014!3d46.81314844243133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb89671c3b964b3%3A0x5c0cdfb4b297b597!2zMjI2IFJ1ZSBTYWludC1Kb3NlcGggRSwgUXXDqWJlYywgUUMgRzFLIDNBOSDsupDrgpjri6Q!5e0!3m2!1sko!2skr!4v1702352969158!5m2!1sko!2skr",
    location: "226 Rue Saint-Joseph E, Québec, QC G1K 3A9, Canada",
  },
];

export default officeData;
