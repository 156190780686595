import CircleMask from "@/assets/ts/bauerlab/CircleMask";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import { clamp, translate } from "@/assets/ts/bauerlab/util/utils";
import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import FadeTextSingleLine from "@/components/fadeTextSingleLine";
import FlexsibleLink from "@/components/ui/flexible-link";
import { useCursor } from "@/hooks/use-cursor";
import { useMainManager } from "@/hooks/use-main";
import { lenisScrollState } from "@/state";
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import axios from "axios";
import { useLanguage } from "@/hooks/use-language";
import { useTranslation } from "react-i18next";
import Easing from "@/assets/ts/bauerlab/util/Easing";
import { imageTpType } from "@/types/image";
import creationMotion from "@/utils/ineraction/creation";
import { useNavigate } from "react-router-dom";
import { getImagePath } from "@/assets/ts/image";
import { useMediaQuery } from "@mui/material";

type convertListType = {
  seq: number;
  ttl: string;
  image: {};
};

function SplitText({ text, isDirection }) {
  let dir = clamp(isDirection, -1, 1);
  let y = -120 * dir; //isActive ? 0 : 100;
  let rx = 120 * dir; //isActive ? 0 : 100;
  return text.split("").map((letter, i) => (
    <span
      key={i}
      className="splited-letter"
      style={{
        transitionTimingFunction: `${theme.transitions.easing.inout3}`,
        transitionDuration: `${y == 0 ? 0.8 : 0.5}s`,
        transitionDelay: `${i * 0.02}s`,
        transform: `perspective(1000px) translate(0,${y}%) rotateX(${rx}deg)`,
        height: letter === "\n" ? "1px" : "auto",
        display: letter === "\n" ? "block" : "inline-flex",
      }}
      dangerouslySetInnerHTML={
        letter === " " ? { __html: "&nbsp;" } : { __html: letter }
      }
    ></span>
  ));
}

export default function Creation(props) {
  const { t } = useTranslation("main");
  const navigate = useNavigate();

  // const { list, creationType, fetchFinishAll } = useMainManager();

  const list = props.list;
  const creationType = props.creationType;

  const langTp = useLanguage();

  const isMobile = useMediaQuery(
    `(max-width:${theme.breakpoints.values.lg}px)`
  );

  const convertCreationData = (el) => {
    const result = el?.map((el) => {
      return {
        seq: el?.seq,
        ttl: el?.articleDetailList?.filter((el) => el.langTp == langTp)?.[0]
          ?.ttl,
        image: el?.articleImageList,
      };
    });

    return result;
  };

  const PERFORMANCE_LIST: convertListType = convertCreationData(
    list.PERFORMANCE
  );

  const MEDIA_LIST: convertListType = convertCreationData(list.MEDIA);

  const LIVE_EVENTS_LIST: convertListType = convertCreationData(
    list.LIVE_EVENTS
  );
  const IMMERSIVE_SPACE_LIST: convertListType = convertCreationData(
    list.IMMERSIVE_SPACE
  );

  // const getImageUrl = (item: any, tp: 2 | 3) => {
  //   return {
  //     seq: item?.filter((el) => el.tp == tp)?.[0]?.attachImage?.seq,
  //     ttl: item?.filter((el) => el.tp == tp)?.[0]?.attachImage?.orgNm,
  //   };
  // };
  const getImageUrl = (item: any, tp: 2 | 3) => {
    return item?.filter((el) => el.tp == tp)?.[0]?.attachImage;
  };

  // console.log("IMMERSIVE_SPACE_LIST", IMMERSIVE_SPACE_LIST);

  const [init, setInit] = useState(false);
  const [sectionId, setSectionId] = useState(0);
  const interactionItems = useRef<ScrollObjectType>({
    observer: null,
    scrollObjects: {},
  });

  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const creationListName = [
    "creation__performance",
    "creation__media",
    "creation__live",
    "creation__immersive",
  ];
  const creation = useRef(null);
  const circleMaskContainer = useRef(null);
  const circleMask = useRef(null);
  const sectionScrollObjecs = useRef([]);

  const contentsRef = useRef(null);
  const isCursorHoverActive = useCursor(contentsRef.current);

  const moreButtonTriggerRef = useRef(null);
  const moreButtonRef = useRef(null);
  const [moreButtonProgress, setMoreButtonProgress] = useState(0);

  useEffect(() => {
    if (init) return;
    setInit(true);

    let scrollObjs = interactionItems.current.scrollObjects;
    scrollObjs["creation"] = new ScrollObject(creation.current);
    scrollObjs["creation"].on(ScrollObject.EVENT_INTERSECTION, ({ inview }) => {
      if (inview) {
        window.dispatchEvent(new Event("resiz"));
      }
    });

    scrollObjs["moreBtnTrigger"] = new ScrollObject(
      moreButtonTriggerRef.current
    );

    let contentSections = document.querySelectorAll(
      ".creation__content__section"
    );
    contentSections.forEach((el, i) => {
      scrollObjs["section" + i] = new ScrollObject(el);
      sectionScrollObjecs.current.push(scrollObjs["section" + i]);
    });

    circleMask.current = new CircleMask(circleMaskContainer.current);
    circleMask.current.draw(0);
    circleMask.current.invert = true;

    return () => {
      for (let key in interactionItems.current.current) {
        interactionItems.current[key].kill();
      }
    };
  }, []);

  useEffect(() => {
    for (let key in interactionItems.current.scrollObjects) {
      interactionItems.current.scrollObjects[key].update(lenisState.scroll);
    }

    let currentId = 0;
    let positions = [];
    sectionScrollObjecs.current.forEach((scrollObj, i) => {
      if (scrollObj.inview && scrollObj.ratio.y > 0.5) {
        positions.push({ y: scrollObj.ratio.y, id: i });
      }
    });

    positions.sort((a, b) => a.y - b.y);
    if (positions[0] !== undefined) {
      // console.log(positions[0].id);
      if (sectionId != positions[0].id) {
        setSectionId(positions[0].id);
      }
    } else {
      if (sectionId != -1) {
        setSectionId(-1);
      }
    }

    let creationScrollObj = interactionItems.current.scrollObjects["creation"];

    if (circleMask.current) {
      circleMask.current.draw(clamp(creationScrollObj.ratio.y - 1));
    }

    let moreBtnScrollObj =
      interactionItems.current.scrollObjects["moreBtnTrigger"];

    let inY = clamp(moreBtnScrollObj.ratio.yClamp * 2);
    let outY = clamp((1 - moreBtnScrollObj.ratio.y + 1) * 1.1);
    inY *= inY;
    outY *= outY;
    if (isMobile) {
      if (moreBtnScrollObj.ratio.yClamp > 0.7) {
        outY = clamp(1 - (moreBtnScrollObj.ratio.yClamp + 0.3 - 1) / 0.25);
      }
    }
    let moreP = inY * outY;
    if (moreP > 0 && moreP <= 1) {
      moreButtonRef.current.style.pointerEvents = "auto";
    } else {
      moreButtonRef.current.style.pointerEvents = "none";
    }
    setMoreButtonProgress(moreP);
  }, [lenisState]);

  const moveCreationDetailPage = (seq: number) => {
    navigate(`/creation/${seq}`);
  };

  return (
    <section
      {...props}
      css={style.main}
      className={isMobile ? "-mobile" : ""}
      ref={creation}
    >
      <div className="circleMaskContainer" ref={circleMaskContainer}></div>
      <div className="creation-inner" id="creationContent">
        <div id="creation__navi">
          <ul>
            {creationType?.map((el, i) => {
              return (
                <li className={sectionId === i ? "active" : ""}>
                  <a
                    onClick={() => {
                      props.lenis.scrollTo(`#${creationListName[i]}`, {
                        offset: -250,
                        duration: 2,
                        easing: Easing.easeInOutQuart,
                      });
                      // console.log(`🚀 ~ props.lenis:`, creationListName[i]);
                    }}
                  >
                    {el?.nm}
                  </a>
                </li>
              );
            })}
            {/* <li className={sectionId === 0 ? "active" : ""}>
              <a href="">Performance</a>
            </li>
            <li className={sectionId === 1 ? "active" : ""}>
              <a href="">Media</a>
            </li>
            <li className={sectionId === 2 ? "active" : ""}>
              <a href="">Live Event</a>
            </li>
            <li className={sectionId === 3 ? "active" : ""}>
              <a href="">Virtual Space</a>
            </li> */}
          </ul>
        </div>
        <div id="creation__contents" ref={contentsRef}>
          <h2
            className="creation__content-title"
            // style={{
            //   height: isMobile && sectionId === 3 ? "80px" : "auto",
            // }}
          >
            <p className={sectionId === -1 ? "active" : ""}>
              <SplitText text={"CREATION"} isDirection={sectionId - -1} />
            </p>
            <p className={sectionId === 0 ? "active" : ""}>
              <SplitText text={"PERFORMANCE"} isDirection={sectionId - 0} />
            </p>
            <p className={sectionId === 1 ? "active" : ""}>
              <SplitText text={"MEDIA"} isDirection={sectionId - 1} />
            </p>
            <p className={sectionId === 2 ? "active" : ""}>
              <SplitText text={"LIVE EVENTS"} isDirection={sectionId - 2} />
            </p>
            <p className={sectionId === 3 ? "active" : ""}>
              <SplitText
                text={!isMobile ? "IMMERSIVE SPACE" : "IMMERSIVE"}
                isDirection={sectionId - 3}
              />
            </p>
            {isMobile && (
              <p
                className={
                  sectionId === 3
                    ? "active immersive-sec-line"
                    : "immersive-sec-line"
                }
              >
                <SplitText text={"SPACE"} isDirection={sectionId - 3} />
              </p>
            )}
          </h2>
          <div
            className="creation__content__section"
            id="creation__performance"
          >
            <div className="content-desc">
              <FadeTextSingleLine
                texts={[
                  t("creation.performance.a"),
                  t("creation.performance.b"),
                  t("creation.performance.c"),
                ]}
              />
            </div>
            <div className="content-block-group">
              <figure
                className="creation-item content-block2"
                onMouseEnter={(event) => {
                  creationMotion.handleMouseEnter(event.currentTarget);
                }}
                onMouseLeave={() => creationMotion.handleMouseLeave()}
                onClick={() => {
                  moveCreationDetailPage(PERFORMANCE_LIST?.[0]?.seq);
                  creationMotion.handleMouseLeave();
                }}
              >
                <img
                  data-sync-img-webgl="data-sync-img-webgl"
                  style={{ aspectRatio: "400 / 533" }}
                  src={`${getImagePath(
                    getImageUrl(
                      PERFORMANCE_LIST?.[0]?.image,
                      imageTpType.VERTICAL_IMAGE_SEQ
                    )
                  )}`}
                  alt={PERFORMANCE_LIST?.[0]?.ttl}
                />

                <figcaption>{PERFORMANCE_LIST?.[0]?.ttl}</figcaption>
              </figure>

              <figure
                className="creation-item content-block1"
                onMouseEnter={(event) => {
                  creationMotion.handleMouseEnter(event.currentTarget);
                }}
                onMouseLeave={() => creationMotion.handleMouseLeave()}
                onClick={() => {
                  moveCreationDetailPage(PERFORMANCE_LIST?.[1]?.seq);
                  creationMotion.handleMouseLeave();
                }}
              >
                <img
                  data-sync-img-webgl="data-sync-img-webgl"
                  style={{ aspectRatio: "780 / 1076" }}
                  src={`${getImagePath(
                    getImageUrl(
                      PERFORMANCE_LIST?.[1]?.image,
                      imageTpType.VERTICAL_IMAGE_SEQ
                    )
                  )}`}
                  alt={PERFORMANCE_LIST?.[1]?.ttl}
                />
                <figcaption>{PERFORMANCE_LIST?.[1]?.ttl}</figcaption>
              </figure>
            </div>

            <figure
              className="creation-item content-block3"
              onMouseEnter={(event) => {
                creationMotion.handleMouseEnter(event.currentTarget);
              }}
              onMouseLeave={() => creationMotion.handleMouseLeave()}
              onClick={() => {
                moveCreationDetailPage(PERFORMANCE_LIST?.[2]?.seq);
                creationMotion.handleMouseLeave();
              }}
            >
              <img
                data-sync-img-webgl="data-sync-img-webgl"
                style={{ aspectRatio: "740 / 438" }}
                src={`${getImagePath(
                  getImageUrl(
                    PERFORMANCE_LIST?.[2]?.image,
                    imageTpType.HORIZONTAL_IMAGE_SEQ
                  )
                )}`}
                alt={PERFORMANCE_LIST?.[2]?.ttl}
              />
              <figcaption>{PERFORMANCE_LIST?.[2]?.ttl}</figcaption>
            </figure>
          </div>
          <div className="creation__content__section" id="creation__media">
            <div className="content-block-group">
              <div
                className="content-block1"
                onMouseLeave={() => creationMotion.handleMouseLeave()}
              >
                <figure
                  className="creation-item"
                  onMouseEnter={(event) => {
                    creationMotion.handleMouseEnter(event.currentTarget);
                  }}
                  onMouseLeave={() => creationMotion.handleMouseLeave()}
                  onClick={() => {
                    moveCreationDetailPage(MEDIA_LIST?.[0]?.seq);
                    creationMotion.handleMouseLeave();
                  }}
                >
                  <img
                    data-sync-img-webgl="data-sync-img-webgl"
                    style={{ aspectRatio: "780 / 1040" }}
                    src={`${getImagePath(
                      getImageUrl(
                        MEDIA_LIST?.[0]?.image,
                        imageTpType.VERTICAL_IMAGE_SEQ
                      )
                    )}`}
                    alt={MEDIA_LIST?.[0]?.ttl}
                  />
                  <figcaption>{MEDIA_LIST?.[0]?.ttl}</figcaption>
                </figure>

                <div className="content-desc">
                  <FadeTextSingleLine
                    texts={[t("creation.media.a"), t("creation.media.b")]}
                  />
                </div>
              </div>
              <div
                onClick={() => {
                  moveCreationDetailPage(MEDIA_LIST?.[1]?.seq);
                  creationMotion.handleMouseLeave();
                }}
                className="creation-item content-block content-block-desc content-block2"
              >
                <figure
                  onMouseEnter={(event) => {
                    creationMotion.handleMouseEnter(event.currentTarget);
                  }}
                  onMouseLeave={() => creationMotion.handleMouseLeave()}
                  onClick={() => {
                    moveCreationDetailPage(MEDIA_LIST?.[1]?.seq);
                    creationMotion.handleMouseLeave();
                  }}
                >
                  <img
                    data-sync-img-webgl="data-sync-img-webgl"
                    style={{ aspectRatio: "780 / 475" }}
                    src={`${getImagePath(
                      getImageUrl(
                        MEDIA_LIST?.[1]?.image,
                        imageTpType.HORIZONTAL_IMAGE_SEQ
                      )
                    )}`}
                    alt={MEDIA_LIST?.[1]?.ttl}
                  />
                  <figcaption>{MEDIA_LIST?.[1]?.ttl}</figcaption>
                </figure>
              </div>
            </div>
            <figure
              className="creation-item content-block content-block3"
              onMouseEnter={(event) => {
                creationMotion.handleMouseEnter(event.currentTarget);
              }}
              onClick={() => {
                moveCreationDetailPage(MEDIA_LIST?.[2]?.seq);
                creationMotion.handleMouseLeave();
              }}
              onMouseLeave={() => creationMotion.handleMouseLeave()}
            >
              <img
                data-sync-img-webgl="data-sync-img-webgl"
                style={{ aspectRatio: "1600 / 900" }}
                src={`${getImagePath(
                  getImageUrl(
                    MEDIA_LIST?.[2]?.image,
                    imageTpType.HORIZONTAL_IMAGE_SEQ
                  )
                )}`}
                alt={MEDIA_LIST?.[2]?.ttl}
              />
              <figcaption>{MEDIA_LIST?.[2]?.ttl}</figcaption>
            </figure>
          </div>
          <div className="creation__content__section" id="creation__live">
            {/* <div className="content-block-group"> */}
            <div className="content-block1">
              <div className="content-desc">
                <FadeTextSingleLine
                  texts={[
                    t("creation.live-events.a"),
                    t("creation.live-events.b"),
                    t("creation.live-events.c"),
                  ]}
                />
              </div>
              <figure
                className="creation-item"
                onMouseEnter={(event) => {
                  creationMotion.handleMouseEnter(event.currentTarget);
                }}
                onClick={() => {
                  moveCreationDetailPage(LIVE_EVENTS_LIST?.[0]?.seq);
                  creationMotion.handleMouseLeave();
                }}
                onMouseLeave={() => creationMotion.handleMouseLeave()}
              >
                <img
                  data-sync-img-webgl="data-sync-img-webgl"
                  style={{ aspectRatio: "400 / 533" }}
                  src={`${getImagePath(
                    getImageUrl(
                      LIVE_EVENTS_LIST?.[0]?.image,
                      imageTpType.VERTICAL_IMAGE_SEQ
                    )
                  )}`}
                  alt={LIVE_EVENTS_LIST?.[0]?.ttl}
                />
                <figcaption>{LIVE_EVENTS_LIST?.[0]?.ttl}</figcaption>
              </figure>
            </div>
            <figure
              className="creation-item content-block2"
              onMouseEnter={(event) => {
                creationMotion.handleMouseEnter(event.currentTarget);
              }}
              onClick={() => {
                moveCreationDetailPage(LIVE_EVENTS_LIST?.[1]?.seq);
                creationMotion.handleMouseLeave();
              }}
              onMouseLeave={() => creationMotion.handleMouseLeave()}
            >
              <img
                data-sync-img-webgl="data-sync-img-webgl"
                style={{ aspectRatio: "780/ 1040" }}
                src={`${getImagePath(
                  getImageUrl(
                    LIVE_EVENTS_LIST?.[1]?.image,
                    imageTpType.VERTICAL_IMAGE_SEQ
                  )
                )}`}
                alt={LIVE_EVENTS_LIST?.[1]?.ttl}
              />
              <figcaption>{LIVE_EVENTS_LIST?.[1]?.ttl}</figcaption>
            </figure>
            <figure
              className="creation-item content-block3"
              onMouseEnter={(event) => {
                creationMotion.handleMouseEnter(event.currentTarget);
              }}
              onClick={() => {
                moveCreationDetailPage(LIVE_EVENTS_LIST?.[2]?.seq);
                creationMotion.handleMouseLeave();
              }}
              onMouseLeave={() => creationMotion.handleMouseLeave()}
            >
              <img
                data-sync-img-webgl="data-sync-img-webgl"
                style={{ aspectRatio: "365/ 487" }}
                src={`${getImagePath(
                  getImageUrl(
                    LIVE_EVENTS_LIST?.[2]?.image,
                    imageTpType.VERTICAL_IMAGE_SEQ
                  )
                )}`}
                alt={LIVE_EVENTS_LIST?.[2]?.ttl}
              />
              <figcaption>{LIVE_EVENTS_LIST?.[2]?.ttl}</figcaption>
            </figure>
          </div>
          <div className="creation__content__section" id="creation__immersive">
            <div className="content-block-group">
              <div className="content-block1">
                <figure
                  className="creation-item"
                  onMouseEnter={(event) => {
                    creationMotion.handleMouseEnter(event.currentTarget);
                  }}
                  onClick={() => {
                    moveCreationDetailPage(IMMERSIVE_SPACE_LIST?.[0]?.seq);
                    creationMotion.handleMouseLeave();
                  }}
                  onMouseLeave={() => creationMotion.handleMouseLeave()}
                >
                  <img
                    data-sync-img-webgl="data-sync-img-webgl"
                    style={{ aspectRatio: "780 / 1076" }}
                    src={`${getImagePath(
                      getImageUrl(
                        IMMERSIVE_SPACE_LIST?.[0]?.image,
                        imageTpType.VERTICAL_IMAGE_SEQ
                      )
                    )}`}
                    alt={IMMERSIVE_SPACE_LIST?.[0]?.ttl}
                  />
                  <figcaption>{IMMERSIVE_SPACE_LIST?.[0]?.ttl}</figcaption>
                </figure>
                <div className="content-desc">
                  <FadeTextSingleLine
                    texts={[
                      t("creation.immersive-space.a"),
                      t("creation.immersive-space.b"),
                      t("creation.immersive-space.c"),
                      t("creation.immersive-space.d"),
                      t("creation.immersive-space.e"),
                    ]}
                  />
                </div>
              </div>
              <div className="content-block2">
                <figure
                  className="creation-item content-block3"
                  onClick={() => {
                    moveCreationDetailPage(IMMERSIVE_SPACE_LIST?.[1]?.seq);
                    creationMotion.handleMouseLeave();
                  }}
                  onMouseEnter={(event) => {
                    creationMotion.handleMouseEnter(event.currentTarget);
                  }}
                  onMouseLeave={() => creationMotion.handleMouseLeave()}
                >
                  <img
                    data-sync-img-webgl="data-sync-img-webgl"
                    style={{ aspectRatio: "365 / 487" }}
                    src={`${getImagePath(
                      getImageUrl(
                        IMMERSIVE_SPACE_LIST?.[1]?.image,
                        imageTpType.VERTICAL_IMAGE_SEQ
                      )
                    )}`}
                    alt={IMMERSIVE_SPACE_LIST?.[1]?.ttl}
                  />
                  <figcaption>{IMMERSIVE_SPACE_LIST?.[1]?.ttl}</figcaption>
                </figure>
              </div>
            </div>

            {/* <figure className="creation-item content-block2">
              <img
                src="/images/image/main/creation/img13.jpg"
                data-sync-img-webgl="data-sync-img-webgl"
                alt=""
              />
              <figcaption>Space</figcaption>
            </figure> */}
            <figure
              className="creation-item content-block3"
              onMouseEnter={(event) => {
                creationMotion.handleMouseEnter(event.currentTarget);
              }}
              onClick={() => {
                moveCreationDetailPage(IMMERSIVE_SPACE_LIST?.[2]?.seq);
                creationMotion.handleMouseLeave();
              }}
              onMouseLeave={() => creationMotion.handleMouseLeave()}
            >
              <img
                data-sync-img-webgl="data-sync-img-webgl"
                style={{ aspectRatio: "780 / 438" }}
                src={`${getImagePath(
                  getImageUrl(
                    IMMERSIVE_SPACE_LIST?.[2]?.image,
                    imageTpType.HORIZONTAL_IMAGE_SEQ
                  )
                )}`}
                alt={IMMERSIVE_SPACE_LIST?.[2]?.ttl}
              />
              <figcaption>{IMMERSIVE_SPACE_LIST?.[2]?.ttl}</figcaption>
            </figure>
          </div>
          <div className="more-button-trigger" ref={moreButtonTriggerRef}></div>
          <div ref={moreButtonRef}>
            <FlexsibleLink
              passive
              progress={moreButtonProgress}
              to="/creation/"
            >
              CREATION ALL
            </FlexsibleLink>
          </div>
        </div>
      </div>
    </section>
  );
}

const style = {
  main: css`
    position: relative;
    width: 100%;
    /* padding-top: 355px; */
    padding-bottom: 40px;
    color: #fff;
    min-height: 100vh;
    margin-top: -10px;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      margin-top: -25vh;
      z-index: 0;
    }

    &.-mobile {
      figure {
        img {
          visibility: visible;
        }
      }
    }

    figure {
      img {
        width: 100%;
        height: auto;
        /* opacity: 0; */
        /* 데이터 확인하고 지우기 (정수인) */
        visibility: hidden;
      }
    }

    .more-button-trigger {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50vh;
      pointer-events: none;
    }

    .more-button-wrap {
      position: fixed;
      z-index: 3;
      width: 100%;
      bottom: 40px;
      left: 0;
    }

    .circleMaskContainer {
      position: sticky;
      top: 0;
      width: 100%;
      height: 100vh;
      z-index: 2;
      pointer-events: none;
      overflow: hidden;
    }

    .creation-inner {
      display: flex;
      margin-top: 25vh;
      padding-bottom: 200px;
      z-index: 0;
    }

    .creation__content-title {
      z-index: 1;
      position: sticky;
      top: 65px;
      margin-left: -0.07em;
      margin-bottom: 50vh;
      font-size: ${theme.typography.pxToRem(90)};
      line-height: 90px;
      letter-spacing: 0;
      font-weight: normal;
      mix-blend-mode: difference;

      overflow: hidden;
      /* transition: height 0s 0.3s; */

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        font-size: ${theme.typography.pxToRem(40)};
        margin-left: ${theme.typography.pxToRem(40)};
        height: ${theme.typography.pxToRem(80)};
      }

      p {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        line-height: 1em;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          /* top: 100px; */
        }

        &:first-of-type {
          position: relative;
        }
        white-space: pre;

        span {
          display: inline-flex;
        }

        /* opacity: 0; */
        &.active {
          /* opacity: 1; */
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            /* top: 0; */
          }
        }

        &.immersive-sec-line {
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            top: ${theme.typography.pxToRem(40)} !important;
          }
        }
      }
    }

    .section-title {
      p {
        margin-top: 65px;
        ${globalCSS.en};
      }
    }

    .content-desc {
      margin-top: 0;
      margin-bottom: auto;
    }

    .content-block-desc {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    figure {
      cursor: pointer;
    }

    .creation__content__section {
      display: flex;
      flex-wrap: wrap;
      gap: 164px 0;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        gap: 80px 0;
      }
      /* border: solid 1px; */
      figcaption {
        margin-top: 20px;
        font-size: 16px;
        ${globalCSS.ko}

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(14)};
          margin-left: 10px;
          margin-top: 10px;
        }
      }
    }

    .creation__content__section + .creation__content__section {
      margin-top: 256px;
    }

    #creation__navi {
      ul {
        position: sticky;
        top: calc(100vh);
        width: 280px;
        padding-left: 40px;
        transform: translate(0, -100%);
        padding-bottom: 30px;
        /* display: flex; */
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: none;
        }

        li {
          position: relative;
          display: flex;
          width: max-content;
          gap: 0 10px;
          align-items: center;

          &:after {
            content: "";
            position: relative;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            /* background-color: ${theme.palette.blue}; */
            background: radial-gradient(
              circle,
              ${theme.palette.blue} 40%,
              rgba(0, 0, 0, 1) 120%
            );
            opacity: 0;
            transition: opacity 0.6s ${theme.transitions.easing.inout1};
          }

          a {
            transition: color 0.6s ${theme.transitions.easing.inout1};
            color: ${theme.palette.gray};
          }
          &.active {
            a {
              color: ${theme.palette.white};
            }
            &:after {
              opacity: 1;
            }
          }

          @media (hover: hover) {
            &:hover {
              a {
                color: ${theme.palette.white};
              }
              &:after {
                /* opacity: 1; */
              }
            }
          }
        }
      }
    }

    #creation__contents {
      position: relative;
      /* width: calc(100% - 310px - 40px); */
      width: 77%;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        width: 100%;
      }

      .content-block-group {
        width: 100%;
      }
    }

    #creation__performance {
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        gap: 0;
      }
      .content-block {
        margin-left: 0;
        margin-right: auto;
        width: calc(780 / 1600 * 100%);
      }

      .content-desc {
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin-left: 40px;
        }
      }

      .content-block-group {
        display: flex;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          flex-direction: column-reverse;
          margin-top: 80px;
        }

        .content-block1 {
          width: calc(780 / 1600 * 100%);
          margin-right: 0;
          margin-left: auto;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: calc(300 / 375 * 100%);
          }
        }
        .content-block2 {
          width: calc(400 / 1600 * 100%);
          margin-top: calc(750 / 1600 * (100vw - 320px));
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: calc(180 / 375 * 100%);
            margin-top: 80px;
            margin-left: 20px;
          }
        }
      }

      .content-block3 {
        width: calc(780 / 1600 * 100%);
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin-top: 80px;
          width: calc(300 / 375 * 100%);
        }
      }
    }

    #creation__media {
      .content-block-group {
        display: flex;
        gap: 40px;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          flex-direction: column;
        }

        .content-block1 {
          position: relative;
          width: 50%;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            gap: 80px;
            figure {
              width: calc(300 / 375 * 100%);
            }
          }

          .content-desc {
            position: absolute;
            left: 100%;
            top: 0;
            width: 100%;
            margin-left: 40px;
            /* width: max-width; */
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              position: relative;
              left: auto;
              width: auto;
              margin-left: auto;
              margin-right: 40px;
            }
          }
        }

        .content-block2 {
          display: flex;
          width: 50%;
          align-items: flex-end;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: calc(240 / 375 * 100%);
            margin-right: 0;
            margin-left: auto;
          }

          figure {
            width: 100%;
            margin-top: auto;
          }
        }

        .content-block4 {
          width: calc(780 / 1600 * 100%);
          margin-top: auto;
        }
        .content-block5 {
          width: calc(400 / 1600 * 100%);
          margin-top: auto;
        }
      }
      .content-block3 {
        width: 100%;
        margin-top: auto;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: calc(320 / 375 * 100%);
          margin-left: auto;
          margin-right: 0;
          margin-top: 0;
        }
      }
    }

    #creation__live {
      .content-block {
        margin-left: 0;
        margin-right: auto;
        width: calc(780 / 1600 * 100%);
      }

      .content-desc {
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin-left: 40px;
        }
      }

      .content-block1 {
        width: 100%;
        display: flex;
        gap: 80px;
        figure {
          width: calc(780 / 1600 * 100%);
          margin-right: 0;
          margin-left: auto;

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: calc(300 / 375 * 100%);
          }
        }

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          flex-direction: column;
        }
      }
      .content-block2 {
        width: calc(400 / 1600 * 100%);
        margin-top: calc(-400 / 1600 * 100%);
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin-top: 0;
          width: calc(180 / 375 * 100%);
        }
      }

      .content-block3 {
        width: calc(365 / 1600 * 100%);
        margin-right: 0;
        margin-left: auto;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin-top: calc(200 / 375 * 100vw);
          width: calc(160 / 375 * 100%);
        }
      }

      .content-block-group {
        display: flex;
      }
    }

    #creation__immersive {
      .content-block-group {
        display: flex;

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          flex-direction: column;
          gap: 80px;
        }
      }

      /* .content-block {
        margin-left: 0;
        margin-right: auto;
        width: calc(780 / 1600 * 100%);
      } */

      .content-block1 {
        position: relative;
        width: calc(780 / 1600 * 100%);
        figure {
          width: 100%;
          margin-left: 0;
          margin-right: auto;

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: calc(300 / 375 * 100%);
          }
        }

        .content-desc {
          position: absolute;
          width: 100%;
          left: 100%;
          margin-left: 140px;
          top: 0;

          @media (max-width: ${theme.breakpoints.values.lg}px) {
            position: relative;
            left: auto;
            width: auto;
            margin-right: 40px;
            margin-left: auto;
          }
        }

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          display: flex;
          gap: 80px;
          width: 100%;
          flex-direction: column-reverse;
        }
      }

      .content-block2 {
        display: flex;
        height: 100%;
        width: calc(365 / 679 * 100%);
        margin-left: auto;

        figure {
          margin-top: auto;
          margin-bottom: 0;
          /* margin-top: calc(-400 / 1600 * 100%); */
        }

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: calc(180 / 375 * 100%);
        }
      }

      .content-block3 {
        width: calc(780 / 1600 * 100%);
        margin-right: 0;
        margin-left: auto;
      }

      .content-block2 {
        .content-block3 {
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: 100%;
          }
        }
      }

      > .content-block3 {
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          width: calc(340 / 375 * 100%);
        }
      }
    }
  `,
};
