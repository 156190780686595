import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const XTButton = styled(Button)(({ theme }) => ({}));

export const XTSubmitButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  border: `1px solid ${theme.palette.white};`,
  borderRadius: "100px",
  minHeight: 0,
  minWidth: 0,
  height: `${theme.typography.pxToRem(80)};`,
  fontFamily: ` "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif;`,
  fontWeight: 700,
  fontSize: `${theme.typography.pxToRem(30)};`,
  lineHeight: 1,
  color: `${theme.palette.white};`,
  letterSpacing: "-0.6px;",
  textTransform: "capitalize",
  maxWidth: "100%",

  [theme.breakpoints.down("lg")]: {
    height: `${theme.typography.pxToRem(48)};`,
    fontSize: `${theme.typography.pxToRem(16)};`,
    letterSpacing: "-0.32px;",
  },

  "&::after": {
    content: '""',
    width: `${theme.typography.pxToRem(32)}`,
    height: `${theme.typography.pxToRem(32)}`,
    background: `url("/images/icon/ico-submit.svg") no-repeat center / cover`,
    marginLeft: `${theme.typography.pxToRem(8)};`,

    [theme.breakpoints.down("lg")]: {
      width: `${theme.typography.pxToRem(20)}`,
      height: `${theme.typography.pxToRem(20)}`,
      marginLeft: `${theme.typography.pxToRem(4)};`,
    },
  },
}));
