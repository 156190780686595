import { EventEmitter } from "events";
import Observer from "./util/Observer";

function clamp(value, min = 0, max = 1) {
  return Math.min(Math.max(value, min), max);
}

class ScrollObject extends EventEmitter {
  static EVENT_INTERSECTION: string = "eventIntersection";
  observer: Observer;

  inview: boolean = false;
  name = "";
  dom = null;
  rect = null;
  speed = 1;
  offsetTop = 0;
  offsetLeft = 0;
  scroll = {
    x: 0,
    y: 0,
    oldY: 0,
  };
  x = 0;
  y = 0;
  z = 0;
  _x = 0;
  _y = 0;
  _z = 0;
  unit = {
    x: "px",
    y: "px",
    z: "px",
  };
  scale = 1;
  ratioX = 0;
  ratioY = 0;
  ratio = {
    x: 0,
    y: 0,
    yClamp: 0,
    allYClamp: 0,
    allX: 0,
    allY: 0,
    inBottom: 0,

    r: 0,
    inR: 0,
    inRIn: 0,
  };

  counting = 0;

  constructor(dom: HTMLElement | any) {
    super();
    this.dom = dom;
    this.observer = new Observer();
    this.observer.add(dom, (inview) => {
      this.inview = inview;
      this.resize();
      this.emit(ScrollObject.EVENT_INTERSECTION, {
        inview: inview,
        rect: dom.getBoundingClientRect(),
      });
    });
    this.onResize = this.onResize.bind(this);
    window.addEventListener("resize", this.onResize);
    this.resize();
  }

  kill() {
    this.inview = false;
    window.removeEventListener("resize", this.onResize);
    this.observer.remove(this.dom);
    this.observer.kill();
  }
  update(scrollTop = 0, scrollLeft = 0) {
    if (!this.rect) return;

    this.counting++;
    if (this.counting % 100 == 0) {
      this.resize();
    }
    // let rect = this.dom.getBoundingClientRect();
    this.scroll.x = this.offsetLeft - scrollLeft;
    this.scroll.y = scrollTop - this.offsetTop;

    this.ratio.x = this.scroll.x / window.innerWidth;
    this.ratio.y = (this.scroll.y + window.innerHeight) / window.innerHeight;

    this.ratio.allY =
      (this.scroll.y + window.innerHeight) /
      (this.rect.height + window.innerHeight);
    this.ratio.inBottom =
      (this.scroll.y + window.innerHeight) / this.rect.height;

    this.ratio.allYClamp = clamp(this.ratio.allY);
    this.ratio.yClamp = clamp(this.ratio.y);
  }

  resize(scrollTop = window.scrollY, scrollLeft = 0) {
    this.rect = this.dom.getBoundingClientRect();
    // this.offsetLeft = this.rect.right + scrollLeft/ - window.innerWidth;
    this.offsetTop = this.rect.top + scrollTop;
  }

  onResize() {
    this.resize();
  }
}

// function addScrollObject(name, documentElement) {
// 	return Object.assign(ScrollObject(), { name: name, dom: documentElement });
// }
export { ScrollObject };
