export default class Observer {
  config = {
    root: null, // scroll area, null = body
    rootMargin: "0px", // -100~100  px, %
    threshold: [], // 0 ~ 100..n
  };
  instance: IntersectionObserver;
  list: Array<any> = [];
  constructor(option = {}) {
    Object.assign(this.config, option);

    const thresholds = [];
    const numSteps = 0;

    for (let i = 1.0; i <= numSteps; i++) {
      let ratio = i / numSteps;
      thresholds.push(ratio);
    }
    thresholds.push(0);

    this.config.threshold = thresholds;
    this.instance = new IntersectionObserver((entries, observer) => {
      this.onUpdate(entries, observer);
    }, this.config);
  }

  add(element: HTMLElement, toggleFn = (bool: boolean, entry: any) => {}) {
    this.instance.observe(element);
    //@ts-ignore
    element._toggleFn = toggleFn;
  }

  remove(element: HTMLElement) {
    if (this.instance) {
      this.instance.unobserve(element);
    }
  }

  kill() {
    if (this.instance) {
      this.instance.disconnect();
      delete this.instance;
    }
  }

  onUpdate(entries, observer: IntersectionObserver) {
    entries.forEach((entry: IntersectionObserverEntry) => {
      let target: HTMLElement = entry.target as HTMLElement;
      //@ts-ignore
      target._toggleFn(entry.isIntersecting, entry);

      if (entry.isIntersecting) {
        target.classList.add("in-view");
      } else {
        target.classList.remove("in-view");
      }

      if (entry.isIntersecting) {
        target.classList.add("in-view-from-bottom");
      } else {
        if (entry.boundingClientRect.top > window.innerHeight * 0.5) {
          target.classList.remove("in-view-from-bottom");
        }
      }
    });
  }
}
