import { useInfiniteQuery, useQuery } from "react-query";
import {
  getScheduleListAPI,
  getScheduleDetailAPI,
  getScheduleTypeAPI,
} from "@/api/international";
import { useState } from "react";
import {
  getScheduleDataType,
  getScheduleListDataType,
  getScheduleListParamsType,
} from "@/types/international";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import { useParams } from "react-router";

export function useScheduleManager() {
  const defaultMonth = moment().format("MM");
  const defaultYear = moment().format("YYYY");
  const params = useParams();

  const yearList = [
    moment().subtract(1, "y").format("YYYY"),
    defaultYear,
    moment().add(1, "y").format("YYYY"),
  ];

  const [scheduleParams, setScheduleParams] =
    useState<getScheduleListParamsType>({
      pg: 1,
      pgSz: 8,
      sordFld: "rgstYmd",
      sordMetd: "DESC",
      schYyyy: defaultYear,
      schMm: defaultMonth,
    });

  // 스케쥴 타입 호출
  const { data: scheduleType } = useQuery<any>(
    // AxiosResponse<WarehousingListType>,
    // AxiosError,
    // WarehousingListType["data"]
    ["scheduleType", getScheduleTypeAPI],
    {
      onError: (error) => {
        // console.log("scheduleType - error", error);
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !params.seq,
    }
  );

  // 스케쥴 목록 호출
  const {
    data: scheduleData,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery<getScheduleListDataType, AxiosError>(
    ["scheduleListQuery", scheduleParams],
    ({ pageParam = 1 }) =>
      getScheduleListAPI({
        ...scheduleParams,
        pg: pageParam,
      }),

    {
      getNextPageParam: (_lastPage, pages) => {
        const isLastPage = _lastPage.data.data.scheduleList.totalPageSize;

        if (pages.length < isLastPage) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },

      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !params.seq || !!scheduleParams.schMm,
      staleTime: 300,
    }
  );

  return {
    scheduleType,
    scheduleData,
    fetchNextPage,
    scheduleParams,
    setScheduleParams,
    hasNextPage,
    yearList,
  };
}
//////////////////////////////////////////////////////////////////////////////////////////////

export function useScheduleDetailManager(seq: any) {
  const initalData: getScheduleDataType = {
    data: {
      data: {
        schedule: {
          cont: "",
          endYmd: "",
          expsYn: "Y",
          formatModYmd: "",
          formatModYmdHis: "",
          formatRgstYmd: "",
          formatRgstYmdHis: "",
          modHis: "",
          modYmd: "",
          modrManager: "",
          modrSeq: "",
          rgstHis: "",
          rgstYmd: "",
          rgstrIp: "",
          rgstrManager: "",
          rgstrSeq: "",
          scheduleDetailList: [
            {
              seq: 0,
              rgstrSeq: 0,
              rgstrIp: "",
              rgstYmd: "",
              rgstHis: "",
              modrSeq: "",
              modYmd: "",
              modHis: "",
              csSeq: 0,
              drtrNm: "",
              formatRgstYmdHis: "",
              formatRgstYmd: "",
              formatModYmd: "",
              formatModYmdHis: "",
            },
          ],
          scheduleImageList: [
            {
              seq: 0,
              rgstrSeq: "",
              rgstrIp: "",
              rgstYmd: "",
              rgstHis: "",
              modrSeq: "",
              modYmd: "",
              modHis: "",
              csSeq: 0,
              aiSeq: 0,
              tp: 0,
              attachImage: {
                seq: 0,
                rgstrSeq: null,
                rgstrIp: null,
                rgstYmd: "",
                rgstHis: "",
                modrSeq: 0,
                modYmd: "",
                modHis: "",
                orgNm: "",
                saveNm: "",
                path: "",
                url: "",
                ext: "",
                capa: 0,
                formatRgstYmdHis: "",
                formatRgstYmd: "",
                formatModYmd: "",
                formatModYmdHis: "",
              },
              formatRgstYmdHis: "",
              formatRgstYmd: "",
              formatModYmd: "",
              formatModYmdHis: "",
            },
          ],
          seq: 0,
          strtYmd: "",
          ttl: "",
        },
      },
    },
  };

  const { data: scheduleDetailData = initalData, isFetched } = useQuery<
    getScheduleDataType,
    AxiosError
  >(["scheduleDetailData", seq], () => getScheduleDetailAPI(seq), {
    select: (res) => {
      return res;
    },
    enabled: !!seq,
    refetchOnWindowFocus: false,
  });

  return {
    scheduleDetailData,
    isFetched,
  };
}
