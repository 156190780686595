import UAParser from "ua-parser-js";
const _ua = new UAParser();
const UA = {
	initialized: false,
	os: null,
	engine: null,
	device: null,
	browser: null,
	isPC: null,
	isIOS: null,
	isMobile: null,
	isIphone: null,
	isSafari: null,
	isIpad: null,
	isMozilla: false,
	touchable: true,

	init: function () {
		if (this.initialized) return;
		this.initialized = true;

		let agent = _ua.getUA().toLowerCase();

		this.device = _ua.getDevice();
		this.browser = _ua.getBrowser();
		this.engine = _ua.getEngine();
		this.os = _ua.getOS();

		this.isMobile = this.device.type == "mobile";
		this.isIOS = this.device.model == "iPhone";
		this.isSafari = this.browser.name == "Safari";
		this.isIphone = this.device.model == "iPhone";
		this.isIpad =
			this.device.model == "iPad" ||
			agent.indexOf("ipad") > -1 ||
			(agent.indexOf("macintosh") > -1 && "ontouchend" in window);
		this.touchable = "ontouchend" in document;
		this.isPC = this.device.type == undefined ? true : false;

		if (this.isIpad) {
			this.isPC = false;
		}
		if (this.isIOS) {
			this.isPC = false;
		}
	},
};
UA.init();
export default UA;
