import {
  useParams,
  useNavigate,
  Link,
  useLocation,
  Router,
  useRoutes,
  useSearchParams,
} from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import { useCreationDetailManager } from "@/hooks/use-creation";
import { css } from "@emotion/react";
import { Autoplay, EffectFade, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCustomPagination from "@/components/ui/slidePagination";
import CreationDetailCSS from "@/assets/ts/creation-detail";
import globalCSS from "@/assets/ts/global-css";
import RoundedButton from "@/components/ui/roundedButton";
import { clamp, getRandomImage } from "@/assets/ts/bauerlab/util/utils";
import { lenisScrollState } from "@/state";
import { useRecoilState } from "recoil";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import Easing from "@/assets/ts/bauerlab/util/Easing";
import axios from "axios";
import { useLanguage } from "@/hooks/use-language";
import { useMediaQuery } from "@mui/material";
import XTVideo from "@/components/ui/video";
import { XTModal } from "@/components/ui/modal";
import { useMoveTop } from "@/hooks/use-moveTop";
import theme from "@/assets/ts/theme";
import { getImagePath, getVideoPath } from "@/assets/ts/image";
import { imageTpType, videoTpType } from "@/types/image";

export default function CreationDetail({ lenis }) {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery(
    `(max-width:${theme.breakpoints.values.lg}px)`
  );
  const navigate = useNavigate();
  const detailImgsRef = useRef(null);
  const historyBack = () => {
    const schTpValue = searchParams.get("schTp");
    return schTpValue
      ? navigate(`/creation?schTp=${schTpValue}`)
      : navigate("/creation");
  };

  useEffect(() => {
    document.querySelector("html").classList.add("b");

    return () => {
      document.querySelector("html").classList.remove("b");
    };
  }, []);

  const handleMoveTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    handleMoveTop();
  }, [pathname]);

  const visualSwipeRef = useRef(null);
  const videoSwipeRef = useRef(null);

  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const [init, setInit] = useState(false);
  const scrollObjects = useRef({});
  const visualStickySpaceRef = useRef(null);
  const visualImgRef = useRef(null);
  const scVisualRef = useRef(null);
  const imgListWrapperRef = useRef(null);
  const imgListRef = useRef(null);
  const imgListStikcySpaceRef = useRef(null);
  const imgListX = useRef(0);
  const creationTopTtlRef = useRef(null);

  const { creationDetailData, isFetched } = useCreationDetailManager();
  const langTp = useLanguage();

  const visualSlidePC = creationDetailData?.article?.articleImageList?.filter(
    (item, i) => item.tp === imageTpType.PC_IMAGE_SEQ
  );
  const visualSlideMO = creationDetailData?.article?.articleImageList?.filter(
    (item, i) => item.tp === imageTpType.MO_IMAGE_SEQ
  );
  const detailLangData = creationDetailData?.article?.articleDetailList?.filter(
    (item, i) => item.langTp === Number(langTp)
  )?.[0];
  const contentDetailData = creationDetailData?.article;

  console.log("detailLangData", detailLangData);

  const contentImageList = contentDetailData?.articleImageList?.filter(
    (item, i) => item.tp === imageTpType.CONTENT_IMAGE_SEQ
  );

  const videoShortSlide = contentDetailData?.articleVideoList?.filter(
    (item, i) => item.tp === videoTpType.CREATION_SHORT_SEQ
  );
  const videoLongSlide = contentDetailData?.articleVideoList?.filter(
    (item, i) => item.tp === videoTpType.CREATION_LONG_SEQ
  );
  const title = detailLangData?.ttl;

  // next article info

  const nextArticle = {
    seq: creationDetailData?.articleNext?.seq,
    schTp: creationDetailData?.articleNext?.tp,
    langFilterContent:
      creationDetailData?.articleNext?.articleDetailList?.filter(
        (item, i) => item.langTp === Number(langTp)
      )?.[0],

    image: creationDetailData?.articleNext?.articleImageList?.filter(
      (item, i) => item.tp === imageTpType.HORIZONTAL_IMAGE_SEQ
    )?.[0],
  };

  // prev article info

  const prevArticle = {
    seq: creationDetailData?.articlePrev?.seq,
    schTp: creationDetailData?.articlePrev?.tp,
    langFilterContent:
      creationDetailData?.articlePrev?.articleDetailList?.filter(
        (item, i) => item.langTp === Number(langTp)
      )?.[0],

    image: creationDetailData?.articlePrev?.articleImageList?.filter(
      (item, i) => item.tp === imageTpType.HORIZONTAL_IMAGE_SEQ
    )?.[0],
  };

  useEffect(() => {
    if (init) {
      return;
    }
    setInit(true);

    scrollObjects.current["scVisual"] = new ScrollObject(scVisualRef.current);
    scrollObjects.current["visualSticky"] = new ScrollObject(
      visualStickySpaceRef.current
    );
    scrollObjects.current["imgListSticky"] = new ScrollObject(
      imgListStikcySpaceRef.current
    );
  }, []);

  useEffect(() => {
    let scroll = lenisState.scroll;
    let scrollObjs = scrollObjects.current;
    if (scrollObjs) {
      for (let key in scrollObjs) {
        let scrollObj: ScrollObject = scrollObjs[key];
        scrollObj.update(scroll);
      }
    }

    /*
      top visual
    */
    let vProgress = clamp(scrollObjects.current["scVisual"].ratio.y - 1);
    let vStickyProgress = scrollObjects.current["visualSticky"].ratio.yClamp;

    // vProgress = Easing.easeOutQuint(vProgress);
    // vStickyProgress = Easing.easeInOutCubic(vStickyProgress);

    visualImgRef.current.style.setProperty("--y", `${vProgress}`);
    visualImgRef.current.style.setProperty("--scale", `${vStickyProgress}`);

    /*
      img list
    */

    let imgListProgress =
      scrollObjects.current["imgListSticky"].ratio.allYClamp;
    imgListProgress = imgListProgress * 2;
    // imgListProgress = -1;

    let diff =
      imgListRef.current.clientWidth - imgListWrapperRef.current.clientWidth;

    let x = diff * -imgListProgress;

    imgListX.current += (x - imgListX.current) * 0.1;
    if (-imgListProgress > -1.05) {
    }

    // imgListStikcySpaceRef.current.style.height =
    //   Math.max(window.innerHeight, imgListRef.current.clientWidth - window.innerHeight) + "px";
    if (!isMobile) {
      imgListStikcySpaceRef.current.style.height =
        imgListRef.current.clientWidth * 0.13 + "px";
    } else {
      imgListStikcySpaceRef.current.style.height =
        imgListRef.current.clientWidth * 0.5 + "px";
    }

    if (diff < 0) {
      imgListX.current = 0;
      imgListStikcySpaceRef.current.style.height = 0;
    }
    imgListRef.current.style.transform = `translate(${imgListX.current}px,0)`;

    if (lenisState.direction === 1) {
      // 스크롤 하단으로 갈 떄

      if (lenisState.scroll > scVisualRef.current.clientHeight * 0.5) {
        if (!isMobile) {
          // 모바일이 아닐 때
          creationTopTtlRef.current.style.transform = `translateY(0) translateX(-50%)`;
        } else {
          // 모바일일 떄
          creationTopTtlRef.current.style.transform = `translateY(25%) translateX(-50%)`;
        }
      }

      // 푸터 도달
      if (lenisState.scroll >= lenisState.limit - 700) {
        creationTopTtlRef.current.style.transform = `translateY(-200%) translateX(-50%)`;
      }
    } else {
      // 스크롤 상단으로 갈 떄

      if (lenisState.scroll < scVisualRef.current.clientHeight * 0.5) {
        creationTopTtlRef.current.style.transform = `translateY(-200%) translateX(-50%)`;
      } else if (lenisState.scroll <= lenisState.limit - 700) {
        creationTopTtlRef.current.style.transform = `translateY(0) translateX(-50%)`;
      }
    }
  }, [lenisState]);

  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const handleVideoOpen = () => setIsVideoOpen(true);
  const handleVideoClose = () => setIsVideoOpen(false);

  const cursorInnerHTML = (title: string) => {
    return `<div class="rolling1"><div class="item"><span>${title}</span><i></i></div><div class="item"><span>${title}</span><i></i></div></div><div class="rolling2"><div class="item"><span>${title}</span><i></i></div><div class="item"><span>${title}</span><i></i></div></div>`;
  };

  const handleCursorVideoEnter = () => {
    const cursor = document.getElementById("cursor");
    if (cursor) {
      cursor.innerHTML = cursorInnerHTML("PLAY VIDEO");
      cursor.classList.add("over");
      cursor.classList.add("video");
    }
  };

  const handleCursorVideoLeave = () => {
    const cursor = document.getElementById("cursor");
    if (cursor) {
      cursor.innerHTML = "";
      cursor.classList.remove("over");
      cursor.classList.remove("video");
    }
  };

  const { moveTop } = useMoveTop();

  return (
    <>
      {/* {isFetched && ( */}
      <div
        css={[style.container, CreationDetailCSS.container]}
        id="creation-detail"
      >
        <span className="creation-top-ttl" ref={creationTopTtlRef}>
          {detailLangData?.ttl}
        </span>
        <section className="sc-visual" ref={scVisualRef}>
          <div className="visual-heading">
            <h2 className="visual-heading__title">{detailLangData?.ttl}</h2>
            <div className="visual-heading__detail">
              <p className="detail-desc" css={globalCSS.hangulCont2}>
                {detailLangData?.cont}
              </p>
              <div className="detail-info">
                <div className="info-box">
                  {contentDetailData?.opengExpsYn == "Y" &&
                    contentDetailData?.opengCont && (
                      <dl>
                        <dt>date</dt>
                        <dd>{contentDetailData?.opengCont}</dd>
                      </dl>
                    )}
                  <dl>
                    <dt>director</dt>
                    <dd>{detailLangData?.drtrNm}</dd>
                  </dl>
                </div>
                <div className="info-box">
                  {detailLangData?.plcExpsYn == "Y" && (
                    <dl>
                      <dt>place</dt>
                      <dd> {detailLangData?.plcCont}</dd>
                    </dl>
                  )}
                  {detailLangData?.prdnExpsYn == "Y" &&
                    detailLangData?.prdnCont && (
                      <dl>
                        <dt>production</dt>
                        <dd>{detailLangData?.prdnCont}</dd>
                      </dl>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="visual-img" ref={visualImgRef}>
            <Swiper
              className="visual-img-list"
              onSwiper={(swiper) => (visualSwipeRef.current = swiper)}
              modules={[Pagination, Autoplay]}
              loop={true}
              autoplay={{
                delay: 4500,
                disableOnInteraction: false,
              }}
              speed={800}
              pagination={SwiperCustomPagination}
              ref={visualSwipeRef}
            >
              {isMobile
                ? visualSlideMO?.map((slide, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={getImagePath(slide?.attachImage)}
                        alt={slide?.attachImage?.title}
                      />
                    </SwiperSlide>
                  ))
                : visualSlidePC?.map((slide, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={getImagePath(slide?.attachImage)}
                        alt={slide?.attachImage?.title}
                      />
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>

          <div className="visual-sticky-space" ref={visualStickySpaceRef}></div>
        </section>

        <section className="sc-detail">
          <em>{detailLangData?.vcTtl}</em>
          <p>{detailLangData?.vcCont}</p>
        </section>

        {contentDetailData?.articleVideoList.length > 0 && isFetched && (
          <section
            className="sc-video"
            onClick={handleVideoOpen}
            onMouseEnter={handleCursorVideoEnter}
            onMouseLeave={handleCursorVideoLeave}
          >
            <Swiper
              className="visual-img-list creation-video-item"
              onSwiper={(swiper) => (visualSwipeRef.current = swiper)}
              modules={[Pagination]}
              loop={true}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              speed={800}
              pagination={SwiperCustomPagination}
              slideActiveClass={"active"}
              ref={visualSwipeRef}
            >
              {videoShortSlide?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <video
                    width="100%"
                    playsInline // for safari
                    autoPlay
                    muted
                    loop
                    preload="auto"
                    controls={false}
                  >
                    <source src={getVideoPath(slide)} type="video/mp4" />
                  </video>
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
        )}

        <section className="sc-imgs">
          <div className="img-list-wrapper" ref={imgListWrapperRef}>
            <ul className="img-list" ref={imgListRef}>
              {contentImageList?.map((item, index) => (
                <li className="img-list__item" key={index}>
                  <img
                    src={getImagePath(item?.attachImage)}
                    alt={item?.attachImage.title}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div
            className="sc-imgs__sticky-space"
            ref={imgListStikcySpaceRef}
          ></div>
        </section>
        {!nextArticle?.seq && !prevArticle?.seq ? (
          ""
        ) : (
          <section className="sc-related">
            <h3 className="sc-title">Related Projects</h3>
            <ul
              className={
                nextArticle.seq === undefined || prevArticle.seq === undefined
                  ? "related-list single-related-list"
                  : "related-list"
              }
            >
              {nextArticle?.seq && (
                <li className="related-item">
                  <Link
                    to={`/creation/${nextArticle?.seq}?schTp=${nextArticle?.schTp}`}
                    onClick={() => {
                      moveTop();
                    }}
                  >
                    <img
                      src={getImagePath(nextArticle?.image?.attachImage)}
                      alt={nextArticle?.image?.attachImage?.title}
                    />
                    <span className="title">
                      {nextArticle.langFilterContent?.ttl}
                    </span>
                  </Link>
                </li>
              )}
              {prevArticle?.seq && (
                <li className="related-item">
                  <Link
                    to={`/creation/${prevArticle?.seq}?schTp=${prevArticle?.schTp}`}
                    onClick={() => {
                      moveTop();
                    }}
                  >
                    <img
                      src={getImagePath(prevArticle?.image?.attachImage)}
                      alt={prevArticle?.image?.attachImage?.title}
                    />
                    <span className="title">
                      {prevArticle.langFilterContent?.ttl}
                    </span>
                  </Link>
                </li>
              )}
            </ul>
          </section>
        )}

        <div className="list-btn-box">
          <RoundedButton
            type={"list"}
            onClick={() => {
              historyBack();
              moveTop();
            }}
          />
        </div>

        <XTModal
          open={isVideoOpen}
          className="video-modal"
          onClose={handleVideoClose}
          disableScrollLock={true}
        >
          <XTVideo
            video={videoLongSlide}
            title={title}
            onClose={handleVideoClose}
          />
        </XTModal>
      </div>
      {/* // )} */}
    </>
  );
}

const style = {
  container: css`
    h2 {
    }
  `,
};
