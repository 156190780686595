import { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import RoundedLink from "./rounded-link";
import { useRecoilState } from "recoil";
import { headerMenuProgressState, isMenuOpenState, lenisScrollState } from "@/state";
import globalCSS from "@/assets/ts/global-css";
import Menu from "./menu";
import { MenuButton } from "./menuButton";
import theme from "@/assets/ts/theme";
import Easing from "@/assets/ts/bauerlab/util/Easing";

export default function Header({ lenis }) {
  const [menuProgressState, setMenuProgressState] = useRecoilState(headerMenuProgressState);
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(isMenuOpenState);
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);

  const naviMoveDuration = 2;
  const headerRef = useRef(null);
  useEffect(() => {
    let scroll = lenisState.scroll;
    let direction = lenisState.direction;
    let limit = lenisState.limit;
    const mainMarginBottom = Number(getComputedStyle(document.querySelector("main")).marginBottom.replace("px", ""));
    if (mainMarginBottom * 0.5 > limit - scroll) {
      headerRef.current.style.transform = "translateY(-100%)";
    } else {
      headerRef.current.style.transform = "translateY(0%)";
    }
  }, [lenisState]);

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };
  return (
    <>
      <header css={style.header} ref={headerRef}>
        <div className="navi">
          <ul className="navi__home">
            <li>
              <RoundedLink
                to=""
                onClick={() => {
                  lenis.scrollTo("#weare", {
                    offset: window.innerHeight * 0.5,
                    duration: naviMoveDuration,
                    easing: Easing.easeInOutQuart,
                  });
                }}>
                <span className="text">We are</span>
                <span className="over-text" style={{ "--progress": menuProgressState.weare + "%" } as any}>
                  We are
                </span>
              </RoundedLink>
            </li>
            <li>
              <RoundedLink
                to=""
                onClick={() => {
                  lenis.scrollTo("#creationContent", {
                    duration: naviMoveDuration,
                    easing: Easing.easeInOutQuart,
                  });
                }}>
                <span className="text">Creation</span>
                <span className="over-text" style={{ "--progress": menuProgressState.creation + "%" } as any}>
                  Creation
                </span>
              </RoundedLink>
            </li>
            <li>
              <RoundedLink
                to=""
                onClick={() => {
                  lenis.scrollTo("#partnerContent", {
                    offset: window.innerHeight * 1.5,
                    duration: naviMoveDuration,
                    easing: Easing.easeInOutQuart,
                  });
                }}>
                <span className="text">Partners</span>
                <span className="over-text" style={{ "--progress": menuProgressState.partners + "%" } as any}>
                  Partners
                </span>
              </RoundedLink>
            </li>
          </ul>
          <button type="button" aria-label="menu-button" className="navi__toggle">
            <MenuButton />
          </button>
        </div>
      </header>
    </>
  );
}

const style = {
  header: css`
    position: fixed;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    transition: transform 0.4s;

    .navi {
      display: flex;
      margin-top: 40px;
      margin-right: 45px;
      margin-left: auto;
      gap: 0 10px;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        margin-top: ${theme.typography.pxToRem(20)};
        margin-right: ${theme.typography.pxToRem(20)};
        margin-left: auto;
      }
    }
    .navi__home {
      display: flex;
      gap: 0 10px;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        display: none;
      }

      a {
        position: relative;
        overflow: hidden;
        color: #fff;

        span {
          display: inline-flex;
          width: 100%;
          ${globalCSS.en};
        }

        .text {
        }

        .over-text {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          color: blue;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          clip-path: polygon(0% 0, var(--progress) 0%, var(--progress) 100%, 0 100%);
        }
      }
    }

    .navi__toggle {
      height: 100%;
      /* width: 32px;
      height: 32px; */
      /* border-radius: 50%;
      border: solid 1px #fff;
      background-color: transparent; */
      cursor: pointer;
      transform: translateY(-1px);
    }
  `,
  naviToggle: css``,
};
