import { formatRangeHyphenYmd } from "@/assets/ts/format";
import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import styled from "@emotion/styled";
import { AnimateSharedLayout, motion } from "framer-motion";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import ViewMore from "../ui/viewMore";
import { getImagePath } from "@/assets/ts/image";
import { useRecoilState } from "recoil";
import { pageYState } from "@/state";
import { lockDom } from "@/assets/ts/common";

type ItemDataType = {
  endYmd: string;
  strtYmd: string;
  title: string;
  seq: number;
  author: any;
  image: any;
  idx: number;
  // handleItemMouseEnter: any;
};

export default function SchedulerItem({ item, handleItemHover }: { item: ItemDataType; handleItemHover: any }) {
  const navigator = useNavigate();
  // console.log(item, "item");

  const handleItemClick = (seq: string | number) => {
    navigator(`/scheduler/${seq}`);
  };

  const handleNameList = () => {
    if (item.author.length > 1) {
      return `${item.author[0]?.drtrNm} 외 ${item.author.length - 1}인`;
    } else if (item.author.length === 1) {
      return `${item.author[0]?.drtrNm}`;
    } else {
      return "";
    }
  };
  const [pageY, setPageY] = useRecoilState(pageYState);

  function scrollLock() {
    setPageY(window.scrollY);
    const main = document.querySelector("main");
    main.style.top = `-${window.scrollY}px`;
    lockDom("html");
    lockDom("body");
  }

  return (
    <AnimateSharedLayout>
      <Item
        onMouseEnter={handleItemHover}
        layoutId={"1"}
        initial="normal"
        transition={{ type: "tween" }}
        onClick={() => {
          handleItemClick(item.seq);
          scrollLock();
        }}>
        <Link
          to="#"
          onClick={(event) => event.preventDefault()}
          onMouseEnter={(event) => {
            event.stopPropagation();
          }}>
          <div className="img-box">
            <img src={getImagePath(item.image)} alt={item.title} />
            <ViewMore />
          </div>
          <div className="text-box">
            <h3 className="title">{item.title}</h3>
            <span className="date">{formatRangeHyphenYmd(item.strtYmd, item.endYmd)}</span>
            <span className="author">{handleNameList()}</span>
          </div>
        </Link>
      </Item>
    </AnimateSharedLayout>
  );
}

const Item = styled(motion.li)`
  position: relative;

  width: ${theme.typography.pxToRem(350)};
  /* flex: 1; */
  /* height: 100%; */
  transition: opacity 0.2s;

  @media (max-width: ${theme.breakpoints.values.lg}px) {
    width: 100%;
  }
  a {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */

    .img-box {
      position: relative;

      width: 100%;
      height: ${theme.typography.pxToRem(466.66)};
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:hover {
      .article-view-more {
        /* display: flex; */
        transform: translateY(0);
      }
    }

    .text-box {
      display: flex;
      flex-direction: column;
      margin-top: ${theme.typography.pxToRem(20)};

      .title {
        font-weight: 700;
        font-size: ${theme.typography.pxToRem(26)};
        line-height: calc(36 / 26);
        color: ${theme.palette.white};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(24)};
          /* font-size: calc(24 / 375 * 100vw); */
          line-height: calc(30 / 24);
        }
      }

      .date,
      .author {
        ${globalCSS.en};
        font-weight: 400;
        font-size: ${theme.typography.pxToRem(18)};
        line-height: calc(22 / 18);
        color: ${theme.palette.white};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: ${theme.typography.pxToRem(17)};
          line-height: calc(20 / 16);
        }
      }

      .date {
        margin: ${theme.typography.pxToRem(10)} 0;
      }
    }
  }
`;
