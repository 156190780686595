import GUI from "lil-gui";

const gui = new GUI({});
const guiDom = gui.domElement;
gui.domElement.style.zIndex = "10000";
gui.domElement.style.transform = "translateZ(10000px)";
gui.close();

if (window.location.hash.indexOf("gui") == -1) {
    gui.domElement.remove();
    gui.destroy();
}

if (1) {
    document.addEventListener("keydown", (e) => {
        if (e.key == "g") {
            if (guiDom.style.visibility == "hidden") {
                guiDom.style.visibility = "visible";
            } else {
                guiDom.style.visibility = "hidden";
            }
        }
    });
} else {
    // gui.domElement.remove();
}

function gui_vec3(gui, vec3, _option = {}) {
    if (!gui) return;
    let option = Object.assign({ folder: "", range: 1000, threshold: 0.01 }, _option);

    let g = gui;
    if (option.folder) {
        g = gui.addFolder(option.folder);
    }

    g.add(vec3, "x", -option.range, option.range, option.threshold).listen();
    g.add(vec3, "y", -option.range, option.range, option.threshold).listen();
    g.add(vec3, "z", -option.range, option.range, option.threshold).listen();

    return g;
}

function gui_simple(
    gui,
    object,
    option = { folder: null, props: [], range: 1000, threshold: 0.01 }
) {
    if (!gui) return;
    let g = gui;
    if (option.folder) {
        g = gui.addFolder(option.folder);
    }

    option.props.forEach((key) => {
        if (object.hasOwnProperty(key)) {
            g.add(object, key, -option.range, option.range, option.threshold);
        }
    });
    return g;
}

function gui_light(gui, light) {
    if (!gui) return;

    if (light.color) {
        gui.addColor({ color: light.color }, "color").onChange((value) => {
            light.color.r = value.r;
            light.color.g = value.g;
            light.color.b = value.b;
        });
    }

    if (light.groundColor) {
        gui.addColor({ groundColor: light.color }, "groundColor").onChange((value) => {
            light.groundColor.r = value.r;
            light.groundColor.g = value.g;
            light.groundColor.b = value.b;
        });
    }
    if (light.intensity != undefined) {
        gui.add(light, "intensity", 0, 3, 0.01);
    }

    if (light.distance != undefined) {
        gui.add(light, "distance", -5000, 5000, 0.1);
    }

    if (light.power != undefined) {
        gui.add(light, "power", 0, 100, 0.1);
    }

    if (light.angle != undefined) {
        gui.add(light, "angle", -Math.PI, Math.PI, (Math.PI / 180) * 0.1);
    }

    if (light.penumbra != undefined) {
        gui.add(light, "penumbra", 0, 1, 0.001);
    }

    if (light.decay != undefined) {
        gui.add(light, "decay", 0, 50, 0.01);
    }

    if (light.position != undefined) {
        gui.add(light.position, "x", -30000, 30000, 0.1);
        gui.add(light.position, "y", -30000, 30000, 0.1);
        gui.add(light.position, "z", -30000, 30000, 0.1);
    }

    if (light.visible != undefined) {
        gui.add(light, "visible");
    }
    return gui;
}

export { gui, gui_vec3, gui_simple, gui_light };
