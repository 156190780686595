import DatePicker from "react-datepicker";
import { ko } from "date-fns/locale";
import globalCSS from "@/assets/ts/global-css";
import moment from "moment";
// import "react-datepicker/dist/react-datepicker.css";
import "@/utils/styles/datepicker-custom.scss";
import { useEffect, useState } from "react";

type datepickerType = {
  start: string | Date | null | "";
  end: string | Date | null | "";
};

type datepickerHeaderType = {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
};

type datepickerDaysType = {
  date: Date;
  day: string | Date;
};

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: datepickerHeaderType) => {
  function formatMonth(date: Date) {
    moment.locale("en");
    return moment(date).format("MMMM");
  }

  return (
    <div className="datepicker-header">
      <button type="button" className="btn-month prev" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <i css={globalCSS.icoDatePrev}></i>
      </button>
      <span className="current-month">{formatMonth(date)}</span>
      <button type="button" className="btn-month next" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <i css={globalCSS.icoDateNext}></i>
      </button>
    </div>
  );
};

export default function XTDatepicker({ start, end }: datepickerType) {
  const [date, setDate] = useState({ start: start, end: end });
  const format = "YYYYMMDD";

  // useEffect(() => {
  //   if (date.start === "" || date.end === "") {
  //     setDate({ start: moment().format(format), end: moment().format(format) });
  //   } else {
  //     setDate({ start: start, end: end });
  //   }
  // }, [start, end, date]);
  let startDate = moment(date.start, format).toDate();
  let endDate = moment(date.end, format).toDate();

  return (
    <DatePicker
      locale={ko}
      inline
      readOnly
      selectsRange
      startDate={startDate}
      endDate={endDate}
      minDate={startDate}
      maxDate={endDate}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }: datepickerHeaderType) => {
        return (
          <CustomHeader
            date={date}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
          />
        );
      }}
    />
  );
}
