import globalCSS from "@/assets/ts/global-css";
import { isDetailPageState, isMenuOpenState, isScrollHiddenState, lenisScrollState, pageYState } from "@/state";
import { postItemOpenState, postMobileTabsOpenState } from "@/state/post";
import { css } from "@emotion/react";
import { createRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import Menu from "./menu";
import RoundedLink from "./rounded-link";
import theme from "@/assets/ts/theme";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";
import { clamp } from "@/assets/ts/bauerlab/util/utils";
import Easing from "@/assets/ts/bauerlab/util/Easing";
import { lockDom, unlockDom } from "@/assets/ts/common";

const contactSection = [
  { name: "Enquiry", target: "enquiry" },
  { name: "Office Space", target: "office" },
];
const aboutSection = [
  { name: "Business Area", target: "business" },
  { name: "People", target: "people" },
  { name: "Office Space", target: "office" },
];

const SubPageProgressHeader = ({ menus }) => {
  const menuRefs = useRef(menus.map((menu) => createRef()));
  const overTextRefs = useRef(menus.map((menu) => createRef()));
  const menuItems = useRef([]);
  const scrollObjects = useRef({});
  const [lenisState, setLenisState] = useRecoilState(lenisScrollState);
  const lenis = useRecoilValue(lenisScrollState);
  const [isSet, setIsSet] = useState(false);
  const [isOut, setIsOut] = useState(false);
  const setTimer = useRef(null);
  // css는  globalCSS에서 subHeader 로 검색하시면 나옵니다

  useEffect(() => {
    setIsSet(false);
    if (setTimer.current) clearTimeout(setTimer.current);
    setTimer.current = setTimeout(() => {
      menus.forEach((data, i) => {
        let menuItem = document.querySelector(`[data-target=${data.target}]`);
        let targetItem = document.getElementById(data.target);
        menuItems.current.push({
          menuItem: menuItem,
          overText: menuItem.querySelector(".over-text"),
          targetItem: targetItem,
          scrollObject: new ScrollObject(targetItem),
        });
      });

      setIsSet(true);
    }, 100);

    return () => {
      menuItems.current.forEach((data) => {
        data.overText.style.setProperty("--progress", `0%`);
        if (data.scrollObject) data.scrollObject.kill();
      });
      menuItems.current = [];
    };
  }, [menus]);

  useEffect(() => {
    let scroll = lenisState.scroll;

    if (!isSet) return;
    menuItems.current.forEach((data, i) => {
      let scrollObj = data.scrollObject;
      scrollObj.update(scroll);
      data.overText.style.setProperty("--progress", `${clamp(scrollObj.ratio.inBottom) * 100}%`);
    });
  }, [lenisState]);

  return (
    <>
      {menus.map((menu, i: number) => (
        <div key={i} className="anchor-section" data-target={menu.target} css={style.button} ref={menuRefs.current[i]}>
          <RoundedLink
            to={`#${menu.target}`}
            onClick={() => {
              let margin = 0;
              if (menu.margin == "windowHeight") {
                margin = window.innerHeight * 0.5;
              }
              //@ts-ignore
              window.lenis.scrollTo(`#${menu.target}`, {
                offset: margin,
                duration: 2,
                easing: Easing.easeInOutQuart,
              });
            }}>
            <span className="text">{menu.name}</span>
            <span
              className="over-text"
              ref={overTextRefs.current[i]}
              style={
                {
                  "--progress": "0",
                } as any
              }>
              {menu.name}
            </span>
          </RoundedLink>
        </div>
      ))}
    </>
  );
};

const style = {
  button: css`
    position: relative;
    overflow: hidden;
    color: #fff;

    @media (max-width: ${theme.breakpoints.values.lg}px) {
      display: none;
    }

    a {
      position: relative;
      overflow: hidden;
      color: #fff;
    }

    span {
      display: inline-flex;
      width: 100%;
      ${globalCSS.en};
    }

    .over-text {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      color: blue;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      clip-path: polygon(0% 0, var(--progress) 0%, var(--progress) 100%, 0 100%);
    }
  `,
};

export function MenuButton() {
  const [isDetailPage, setIsDetailPage] = useRecoilState(isDetailPageState);
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(isMenuOpenState);
  const [isScrollHidden, setIsScrollHidden] = useRecoilState(isScrollHiddenState);

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const lenis = useRecoilValue(lenisScrollState);
  const menuRef = useRef(null);

  const [pageY, setPageY] = useRecoilState(pageYState);
  const [isTabOpen, setIsTabOpen] = useRecoilState(postMobileTabsOpenState);

  function scrollUnlock() {
    const main = document.querySelector("main");

    unlockDom("html");
    unlockDom("body");
    main.style.top = `0px`;
    window.scrollTo(0, pageY);
    window.setTimeout(() => {
      setPageY(null);
    });
  }

  function scrollLock() {
    setPageY(window.scrollY);
    const main = document.querySelector("main");
    main.style.top = `-${window.scrollY}px`;
    lockDom("html");
    lockDom("body");
  }

  useEffect(() => {
    // if (isDetailPage) return;

    if (lenis.scroll > 50) {
      menuRef.current.classList.remove("appear");
    } else {
      menuRef.current.classList.add("appear");
    }
  }, [lenis, isDetailPage]);

  function handleMenuClick() {
    const hasSearch = location.search ? location.search : "";
    const hasSeq = pathname?.split("/").length === 3 ? true : false; // 상세페이지 확인
    const isCreationPage = location.pathname.includes("creation");

    if (location.hash !== "#menu") {
      // 매뉴가 열리지 않은 상태에서
      if (isCreationPage) {
        // 크리에이션에서는 서브에서 창을 닫지 않고 바로 매뉴로 이동함
        window.dispatchEvent(new Event("lenisStop"));
        navigate(`${location.pathname}${location.search}#menu`);
        setIsScrollHidden(true);
      } else {
        if (isTabOpen) {
          // 탭이 열렸을 때 (스케줄의 캘린더, 노티스의 카테고리...)
          window.dispatchEvent(new Event("lenisStart"));
          setIsTabOpen(false);
          // scrollUnlock();

          return;
        }

        if (hasSeq) {
          // 상세페이지, 상세페이지에서 클릭 시 -> 목록 페이지로 이동할 때
          navigate(`${pathname?.split("/")?.[1]}${hasSearch}`);
          // scrollUnlock();
        } else {
          // 상세 페이지 아닐 때 (인덱스)
          window.dispatchEvent(new Event("lenisStop"));
          navigate(`/${pathname?.split("/")?.[1]}${hasSearch}#menu`);
          setIsScrollHidden(true);
        }
      }
    }

    // if (isCreationPage) {
    //   // 크레이에션 페이지일 경우
    // } else {}

    // 크리에이션페이지는 다른 서브랑 다르게 동작함
    // 서브에서 창을 닫지 않고 메뉴로 이동함
    //     if (isCreationPage) {
    //       if (hasSeq && location.hash) {
    //         return navigate(`${location.pathname}`);
    //       } else {
    //         setIsScrollHidden(true);

    //         navigate(`${location.pathname}${location.search}#menu`);
    //         // setIsScrollHidden(true);
    //         return;
    //       }
    //     }

    //     // 일반 서브페이지
    //     if (location.hash !== "#menu") {
    //       // 매뉴가 열리지 않은 상태

    //       if (location.hash !== "") {
    //         // 스케줄 캘린더 같은 탭이 열렸을 때
    //       } else if (hasSeq) {
    //         // 상세 페이지일 대
    //         navigate(`${pathname?.split("/")?.[1]}${hasSearch}`);
    //       } else {
    //         // 매뉴 열려야 할 때
    //          navigate(`/${pathname?.split("/")?.[1]}${hasSearch}#menu`);
    // setIsScrollHidden(true);
    //       }
    //     } else {
    //       //  매뉴가 열린 상태
    //     }
    //     // if (location.hash !== "") {
    //     //   setIsDetailPage((prev) => !prev);
    //     //   navigate(`/${pathname?.split("/")?.[1]}${hasSearch}`);
    //     // } else {
    //     //   if (hasSeq) {
    //     //     // scrollUnlock();

    //     //     navigate(`${pathname?.split("/")?.[1]}${hasSearch}`);
    //     //   } else {
    //     //     navigate(`/${pathname?.split("/")?.[1]}${hasSearch}#menu`);
    //     //     setIsScrollHidden(true);
    //     //   }
    //     // }
  }

  function subHeaderAppear() {
    if (pathname.includes("contact")) {
      return <SubPageProgressHeader menus={contactSection} />;
    } else if (pathname.includes("about")) {
      return <SubPageProgressHeader menus={aboutSection} />;
    } else {
      return null;
    }
  }

  return (
    <>
      {subHeaderAppear()}
      <button
        css={globalCSS.menuBtn}
        ref={menuRef}
        className="appear"
        data-isdetail={isDetailPage || isTabOpen ? "true" : "fasle"}
        onClick={() => {
          handleMenuClick();
        }}>
        {isDetailPage || isTabOpen ? <span>Close</span> : <span>Menu</span>}
      </button>
    </>
  );
}
