import { getPostDataType } from "./../types/post";
import { useState } from "react";
import { useQuery } from "react-query";
import { getPaginatedPostListAPI, getPostDetailAPI, getPostTypeAPI } from "@/api/post";
import { postType } from "@/types/post";
import { Axios, AxiosError, AxiosResponse } from "axios";

import { useParams, useLocation, useSearchParams } from "react-router-dom";

/**
 * 게시물 (뉴스, 공지사항)
 * Tp "1" 뉴스(NEWS)
 * Tp "2" 공지사항(NOTICE BOARD)
 */
export function usePostManager() {
  const location = useLocation();
  const params = useParams();

  const postTypeInitData: postType = {
    BoardType: {
      // tp
      "1": "NEWS",
      "2": "NOTICE BOARD",
    },
    BoardDetailType: {
      // tpDtl
      "0": "All",
      "1": "ARTICLE",
      "2": "REFERENCE",
      // "2": "CAREER",
    },
    BoardLanguageType: {
      // langTp
      "1": "국문",
      "2": "영문",
    },
  };
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultParams = {
    pg: searchParams.get("pg") || 1,
    pgSz: searchParams.get("pgSz") || 10,
    schTxt: searchParams.get("schTxt") || "",
    schTp: searchParams.get("schTp") || location.pathname.includes("news") ? "1" : "2",
    schTpDtl: searchParams.get("schTpDtl") || "1",
  };

  const [postTypeList, setPostTypeList] = useState(postTypeInitData);

  const [postList, setPostList] = useState({
    boardList: {
      list: [],
    },
  });

  // 게시물 타입 조회
  const { data: postTypeData } = useQuery<any>(
    // AxiosResponse<WarehousingListType>,
    // AxiosError,
    // WarehousingListType["data"]
    ["getPostType"],
    getPostTypeAPI,
    {
      onSuccess: (data) => {
        console.log("postTypeData - data", data);

        setPostTypeList(data.data.data);
      },
      onError: (error) => {
        console.log("postTypeData - error", error);
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  // 게시물 전체 목록 조회
  const { data: postListData } = useQuery<any>(
    ["postListData", location.search, defaultParams],
    () =>
      getPaginatedPostListAPI({
        ...defaultParams,
      }),
    {
      onSuccess: (data) => {
        setPostList(data.data.data);
      },
      onError: (error) => {
        console.log("postListData - error", error);
      },
      staleTime: 300,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !params.seq || !!defaultParams.schTpDtl,
      // enabled: !!defaultParams.pg || !!defaultParams.schTxt,
    }
  );

  return {
    postTypeList,
    postList,
    setSearchParams,
    defaultParams,
    searchParams,
  };
}

// 게시물 상세
export function usePostDetailManager() {
  const initalData: getPostDataType = {
    data: {
      data: {
        board: {
          boardFileList: [
            {
              seq: 0,
              attachFile: {
                seq: 0,
                orgNm: "",
                path: "",
                saveNm: "",
              },
            },
          ],
          boardImageList: [
            {
              tp: 1,
              seq: 0,
              attachFile: {
                seq: 0,
                orgNm: "",
                path: "",
                saveNm: "",
              },
            },
          ],
          cont: "",
          expsYn: "Y",
          prvncDtl: "",
          prvncUrl: "",
          rgstHis: "",
          rgstYmd: "",
          seq: 0,
          tp: 1,
          tpDtl: 1,
          ttl: "",
        },
        boardNext: {
          boardFileList: [
            {
              seq: 0,
              attachFile: {
                seq: 0,
                orgNm: "",
                path: "",
                saveNm: "",
              },
            },
          ],
          boardImageList: [
            {
              tp: 1,
              seq: 0,
              attachFile: {
                seq: 0,
                orgNm: "",
                path: "",
                saveNm: "",
              },
            },
          ],
          cont: "",
          expsYn: "Y",
          prvncDtl: "",
          prvncUrl: "",
          rgstHis: "",
          rgstYmd: "",
          seq: 0,
          tp: 1,
          tpDtl: 1,
          ttl: "",
        },
        boardPrev: {
          boardFileList: [
            {
              seq: 0,
              attachFile: {
                seq: 0,
                orgNm: "",
                path: "",
                saveNm: "",
              },
            },
          ],
          boardImageList: [
            {
              tp: 1,
              seq: 0,
              attachFile: {
                seq: 0,
                orgNm: "",
                path: "",
                saveNm: "",
              },
            },
          ],
          cont: "",
          expsYn: "Y",
          prvncDtl: "",
          prvncUrl: "",
          rgstHis: "",
          rgstYmd: "",
          seq: 0,
          tp: 1,
          tpDtl: 1,
          ttl: "",
        },
      },
    },
  };

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { seq } = useParams();

  const defaultParams = {
    schTxt: searchParams.get("schTxt") || "",
    schTp: searchParams.get("schTp") || location.pathname.includes("news") ? "1" : "2",
    schTpDtl: searchParams.get("schTpDtl") || "",
  };

  // 게시물 상세 조회
  const { data: postDetailData = initalData, isFetched } = useQuery<getPostDataType, AxiosError>(
    ["postDetailData", seq, defaultParams],
    () =>
      getPostDetailAPI(Number(seq), {
        ...defaultParams,
      }),

    {
      select: (res) => {
        return res;
      },

      refetchOnWindowFocus: false,
    }
  );

  return { postDetailData, isFetched };
}
