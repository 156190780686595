import { lsToPx } from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import officeData from "@/assets/ts/mock-data/office";
import theme from "@/assets/ts/theme";
import { css } from "@emotion/react";
import { forwardRef } from "react";
import Clock from "../clock/clock";
import RollingText from "../rolling-text";
import { useMediaQuery } from "@mui/material";

const AboutOffice = forwardRef<HTMLElement, {}>((props, ref) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const getOfficeTime = (local: string) => {
    switch (local) {
      case "seoul":
        return "ko";
      case "ca":
        return "la";
      case "burbank":
        return "la";
      case "quebec":
        return "qb";
      default:
        return "ko";
    }
  };

  return (
    <section className="sc-office" id="office" ref={ref} css={style.office}>
      <div className="title-box">
        <RollingText title="OFFICES" align="right" desc="" />
      </div>

      <ul className="office-list">
        {officeData.map((office, index) => (
          <li className="office-item" key={index} data-location-name={office.nation}>
            <div className="clock-box">
              <div className="info">
                <h2>{office.nation}</h2>
                <span>{office.local}</span>
              </div>
              <div className="clock">
                <Clock local={getOfficeTime(office.local)} />
              </div>
            </div>
            <div className="address-box">
              <div className="address-info-box">
                <dl>
                  <dt>e-mail.</dt>
                  <dd>{office.email}</dd>
                </dl>
                <dl>
                  <dt>business hours.</dt>
                  <dd>{office["business-hours"]}</dd>
                </dl>
                {office.phone && (
                  <dl>
                    <dt>phone.</dt>
                    <dd>{office.phone}</dd>
                  </dl>
                )}
                {office.location && (
                  <dl>
                    <dt>location.</dt>
                    <dd
                      dangerouslySetInnerHTML={{
                        __html: isMobile ? office.locationMobile || office.location : office.location,
                      }}></dd>
                  </dl>
                )}
              </div>
              <p className="address-desc">{office.desc}</p>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
});

const style = {
  office: css`
    position: relative;
    z-index: 2;
    /* background-color: yellow; */
    padding-bottom: ${theme.typography.pxToRem(150)};
    padding-top: ${theme.typography.pxToRem(200)};

    .title-box {
      margin-bottom: ${theme.typography.pxToRem(150)};
      .inner {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      h2 {
        text-align: center;
        color: ${theme.palette.blue};
        font-size: ${theme.typography.pxToRem(140)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          font-size: calc(60 / 375 * 100vw);
        }
      }
    }

    .office-list {
      max-width: ${theme.typography.pxToRem(1317)};
      margin: 0 auto;
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        padding: 0 ${theme.typography.pxToRem(27.5)};
      }

      .office-item {
        display: flex;
        /* justify-content: space-between; */
        justify-content: center;
        align-items: center;
        gap: ${theme.typography.pxToRem(39.7)};
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          flex-direction: column;
          align-items: flex-start;
          gap: ${theme.typography.pxToRem(15)};
        }
        .clock-box {
          /* flex: 1; */
          display: flex;
          align-items: flex-start;
          gap: ${theme.typography.pxToRem(39.7)};
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            align-items: center;
          }

          .info {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            h2 {
              ${globalCSS.en};
              font-weight: 700;
              font-size: ${theme.typography.pxToRem(100)};
              ${lsToPx(100, 4)};
              color: ${theme.palette.blue};
              text-transform: uppercase;
              line-height: 1;
              @media (max-width: ${theme.breakpoints.values.lg}px) {
                font-size: calc(40 / 375 * 100vw);
                line-height: 1;
                letter-spacing: 0;
              }
            }

            span {
              ${globalCSS.en};
              font-weight: 400;
              font-size: ${theme.typography.pxToRem(50)};
              ${lsToPx(50, 4)};
              color: ${theme.palette.blue};
              text-transform: capitalize;
              @media (max-width: ${theme.breakpoints.values.lg}px) {
                font-size: calc(20 / 375 * 100vw);
              }
            }
          }
        }

        .address-box {
          flex: 1;
          width: 100%;
          max-width: ${theme.typography.pxToRem(700)};
          .address-info-box {
            margin-bottom: ${theme.typography.pxToRem(15)};
            dl {
              display: flex;
              /* align-items: center; */
              align-items: flex-start;
              ${globalCSS.en};
              font-size: ${theme.typography.pxToRem(26)};
              line-height: ${theme.typography.pxToRem(32)};
              color: ${theme.palette.blue};
              letter-spacing: -0.52px;
              text-transform: uppercase;
              @media (max-width: ${theme.breakpoints.values.lg}px) {
                flex-direction: column;
                align-items: flex-start;
              }

              + dl {
                margin-top: ${theme.typography.pxToRem(2)};
              }
            }
            dt {
              font-weight: 400;
              margin-right: ${theme.typography.pxToRem(20)};
              @media (max-width: ${theme.breakpoints.values.lg}px) {
                font-size: ${theme.typography.pxToRem(17)};
                line-height: ${theme.typography.pxToRem(22)};
              }
            }
            dd {
              font-weight: 700;
              @media (max-width: ${theme.breakpoints.values.lg}px) {
                font-size: ${theme.typography.pxToRem(17)};
                line-height: ${theme.typography.pxToRem(22)};
              }
            }
          }
          .address-desc {
            ${globalCSS.en};
            font-size: ${theme.typography.pxToRem(18)};
            line-height: ${theme.typography.pxToRem(22)};
            color: ${theme.palette.blue};
            font-weight: 400;
            letter-spacing: 0.36px;
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              font-size: ${theme.typography.pxToRem(12)};
              line-height: ${theme.typography.pxToRem(16)};
            }
          }
        }

        &[data-location-name="usa"] {
          .address-box {
            margin-left: auto;
            @media (max-width: ${theme.breakpoints.values.lg}px) {
              text-align: right;
            }
          }
        }
      }

      .office-item + .office-item {
        position: relative;
        margin-top: ${theme.typography.pxToRem(50)};
        padding-top: ${theme.typography.pxToRem(50)};

        @media (max-width: ${theme.breakpoints.values.lg}px) {
          margin-top: ${theme.typography.pxToRem(40)};
          padding-top: ${theme.typography.pxToRem(40)};
        }

        &::before {
          position: absolute;
          content: "";
          width: 95%;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          background-color: ${theme.palette.blue};
          height: 1px;
          @media (max-width: ${theme.breakpoints.values.lg}px) {
            width: 100%;
          }
        }
      }

      .office-item:nth-of-type(2n) {
        flex-direction: row-reverse;
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          flex-direction: column;

          .clock-box {
            width: 100%;
            justify-content: flex-start;
            flex-direction: row-reverse;
          }

          .address-box {
            dl {
              align-items: flex-end;
            }

            dt {
              margin: 0;
            }
          }
        }
        .info span {
          text-transform: uppercase;
        }

        dl {
          justify-content: flex-end;
        }

        .address-desc {
          text-align: right;
        }
      }
    }
  `,
};

export default AboutOffice;
