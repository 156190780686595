import globalCSS from "@/assets/ts/global-css";
import { isMenuOpenState, pageYState, pointerState } from "@/state";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import Footer from "./footer";
import GuideLayout from "./guide/layout";
import GuideSidebar from "./guide/sidebar";
import Header from "./header";
import Menu from "./menu";
import SubHeader from "./sub-header";
import RoundedButton from "./ui/roundedButton";
import theme from "@/assets/ts/theme";
import { useMediaQuery } from "@mui/material";
import { lockDom, unlockDom } from "@/assets/ts/common";

const Layout = ({ children, lenis }: any) => {
  const location = useLocation();
  const pathname = location.pathname;
  // const [pointer, setPointer] = useState({ x: 0, y: 0 });
  const [pointer, setPointer] = useRecoilState(pointerState);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);

  const [backgroundColor, setBackgroundColor] = useState("");
  const prevPath = useRef(location.pathname);
  useEffect(() => {
    // 브라우저 갱신할때 스크롤 0으로 설정
    window.history.scrollRestoration = "manual";
  }, []);

  const [pageY, setPageY] = useRecoilState(pageYState);

  function scrollUnlock() {
    const main = document.querySelector("main");

    unlockDom("html");
    unlockDom("body");
    main.style.top = `0px`;
    window.scrollTo(0, pageY);
    window.setTimeout(() => {
      setPageY(null);
    });
  }

  function scrollLock() {
    setPageY(window.scrollY);
    const main = document.querySelector("main");
    main.style.top = `-${window.scrollY}px`;
    lockDom("html");
    lockDom("body");
  }

  useEffect(() => {
    if (pathname.includes("about") || pathname === "/") {
      // 백그라운드가 노란색인 페이지
      setBackgroundColor("y");
    } else if (pathname.includes("international") || pathname.includes("contact")) {
      // 백그라운드가 흰색 페이지
      setBackgroundColor("w");
    } else {
      // 그외에는 다 검증색
      setBackgroundColor("");
    }

    if (pathname === "/news" || pathname === "/notice" || pathname === "/scheduler") {
      // 스크롤 풀려야 됨
      scrollUnlock();
      // window.dispatchEvent(new Event("lenisStart"));
    } else if (prevPath.current.match(/news\/\d+/)) {
      // scrollLock();
      // window.dispatchEvent(new Event("lenisStop"));
    } else {
      window.dispatchEvent(new Event("lenisTop"));
    }

    prevPath.current = location.pathname;
  }, [pathname]);

  return (
    <>
      {pathname.startsWith("/guide") ? (
        <GuideLayout>
          <GuideSidebar />
          <div>{children}</div>
        </GuideLayout>
      ) : (
        <div
          className="container"
          css={globalCSS.mainContainer}
          onPointerMove={(event) => {
            !isMobile && setPointer({ x: event.clientX, y: event.clientY });
          }}>
          {pathname === "/" ? <Header lenis={lenis} /> : <SubHeader lenis={lenis} />}
          <main css={[globalCSS.mainWrapper, globalCSS.anim]} className={backgroundColor} id="main">
            {children}
          </main>
          <Footer className={pathname === "/creation" ? "is-black" : ""} />
        </div>
      )}
      <Menu />
      {!isMobile && (
        <div
          id="cursor"
          style={{
            transform: `translate(${pointer.x}px, ${pointer.y}px)`,
            display: isMobile ? "none" : "flex",
          }}
          css={[globalCSS.cursor]}></div>
      )}
    </>
  );
};

export default Layout;
