import { Vector2 } from "three";

const LastScreenShader = {
	uniforms: {
		uTime: { type: "f", value: 0 },
		tDiffuse: { type: "t", value: null },
		tVideo: { type: "t", value: null },
		uOpacity: { type: "f", value: 1.0 },
		uTransition: { type: "f", value: 0.0 },
		uProgress: { type: "f", value: 0.0 },
		uNextProgress: { type: "f", value: 0.0 },
		uNextDirection: { type: "f", value: 1.0 },
		uCenter: { type: "v2", value: new Vector2(0.5, 0.5) },
		uAmp: { type: "f", value: 0 },
		uFreq: { type: "f", value: 0 },
		uScale: { type: "f", value: 1 },
		uResolution: { type: "v2", value: new Vector2(1.0) },
	},

	vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
    `,

	fragmentShader: `
		#define PI 3.1415926535897932384626433832795
		uniform sampler2D tDiffuse;
		uniform sampler2D tVideo;
		uniform vec2 uResolution;
		uniform float uOpacity;
		uniform float uTime;
		varying vec2 vUv;

		void main() {

			float aspect = uResolution.y / uResolution.x;
			vec2 tUv = vUv;

			vec4 tColor = texture2D(tDiffuse,vUv);

			vec4 videoColor = texture2D(tVideo, vUv);
			float alpha = videoColor.r;
			gl_FragColor = vec4(tColor.rgb,tColor.a * alpha);

		}
    `,
};

export { LastScreenShader };
