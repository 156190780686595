import guideCSS from "@/assets/ts/guide-css";
import { css } from "@emotion/react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Link, Router, useLocation, useRoutes } from "react-router-dom";

export default function GuideSidebar() {
  const { pathname } = useLocation();
  return (
    <div className="guide-sidebar" css={guideCSS.guideSidebar}>
      <div className="inner">
        <div className="title-box">
          <h2>UI Guide</h2>
        </div>
        <div className="content-box">
          <Accordion disableGutters>
            <AccordionSummary>01. UI Components</AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>
          <Accordion disableGutters>
            <AccordionSummary>02. Palette</AccordionSummary>
            <AccordionDetails>
              <ul className="depth-01">
                <li>
                  <Link to="/guide/palette" className={pathname === "/guide/palette" ? "active" : ""}>
                    Palette
                  </Link>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters>
            <AccordionSummary>02. Typography</AccordionSummary>
            <AccordionDetails>
              <ul className="depth-01">
                <li>
                  <Link to="/guide/font" className={pathname === "/guide/font" ? "active" : ""}>
                    Typography
                  </Link>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

const style = {
  sidebar: css`
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 300px;
    height: 100vh;
  `,
};
