import {
  LinearFilter,
  Object3D,
  TextureLoader,
  WebGLRenderTarget,
} from "three";
import BaseWebGL, { stageSize } from "./BaseWebGL";
import config from "./config";
import { gui, gui_vec3 } from "./GUI";
import Observer from "./util/Observer";
import SyncImgMesh from "./SyncImgMesh";
import Particle from "@/assets/ts/bauerlab/Particle";
import { ScrollObject } from "@/assets/ts/bauerlab/ScrollObject";

export type ScrollParams = {
  x: number;
  y: number;
  _x: number;
  _y: number;
  oldX: number;
  oldY: number;
  max: number;
  min: number;
  addX: number;
  aboutX: number;
};

export default class BouerlabWebGL extends BaseWebGL {
  container: Object3D;
  renderTarget: WebGLRenderTarget;
  gsap: GSAPAnimation;
  observer: Observer;
  particle: Particle;

  isParticleOn: boolean = false;

  syncImgMeshs: Array<SyncImgMesh> = [];
  syncImgContainer: Object3D;

  curveImgOffsets: any = {
    uCurve: 500,
    uCurveY: -0.08,
  };

  constructor(containerEl, operationMode: boolean = false) {
    super(containerEl, { render_clearColor: 0xffffff });
    /*
      set observer
    */
    this.observer = new Observer();

    /*
      set particle
    */
    this.particle = new Particle();
    // this.scene.add(this.particle.container);

    /*
        set sync image to WebGl
    */
    // this.addSyncImgMesh();
    this.syncImgContainer = new Object3D();
    this.scene.add(this.syncImgContainer);

    this.setGui();
    this.resize(true);
    this.addResizeList("main", (e) => {
      this.syncImgMeshs.forEach((item: SyncImgMesh) => {
        item.resize();
        item.updateScroll(0, 0);
      });
    });
  }

  async addSyncImgMesh() {
    if (this.syncImgMeshs.length > 0) {
      this.removeSyncImgMesh();
    }

    // console.log(`🚀🚀🚀🚀🚀 ~ addSyncImgMesh`);
    document
      .querySelectorAll("[data-sync-img-webgl]")
      .forEach((el: HTMLImageElement, i) => {
        this.syncImgMeshs.filter((syncImgMesh: SyncImgMesh) => {
          if (syncImgMesh.syncElement == el) {
            return;
          }
        });
        let syncImgMesh = new SyncImgMesh(el, this.curveImgOffsets);
        this.syncImgMeshs.push(syncImgMesh);
        this.syncImgContainer.add(syncImgMesh.mesh);

        syncImgMesh.applyUniforms({
          uCurve: this.curveImgOffsets.uCurve,
          uCurveY: this.curveImgOffsets.uCurveY,
          uCurveStyle: this.curveImgOffsets.uCurveStyle,
        });
      });

    this.resize(true);
  }

  removeSyncImgMesh(from = "") {
    // console.log(`🚀🚀🚀🚀🚀 ~ removeSyncImgMesh`);
    this.syncImgMeshs.forEach((syncImgMesh: SyncImgMesh, i) => {
      this.syncImgContainer.remove(syncImgMesh.mesh);
      syncImgMesh.kill();
    });
    this.syncImgMeshs = [];
  }

  async load(progress_cb = () => {}) {
    /*
      texture load
    */
    const loaders: Array<Promise<void>> = [];
    const list = [];
    list.forEach((textures) => {
      for (let key in textures) {
        let promise = new Promise<void>((resolve) => {
          let src = textures[key];

          new TextureLoader().load(src, (t) => {
            console.log("src", src);

            t.minFilter = LinearFilter;
            textures[key] = t;
            resolve();
          });
        });
        loaders.push(promise);
      }
    });

    let count = 0;
    return new Promise<void>((resolve) => {
      loaders.forEach((promise) => {
        promise.then(() => {
          count++;
          let p = count / loaders.length;
          progress_cb();
          if (p == 1) {
            resolve();
          }
        });
      });
    });
  }

  async setup() {
    await this.load();
  }

  resize(force?: boolean): void {
    let deviceScale = window.devicePixelRatio;
    // this.renderTarget.setSize(stageSize.width * deviceScale, stageSize.height * deviceScale);
    super.resize(force);
  }

  particleZ(z) {
    if (this.particle) {
      this.particle.container.position.z +=
        (z - this.particle.container.position.z) * 0.1;
    }
  }
  particleRot(x, y, z) {
    this.particle.addRot.set(x, y, z);
  }
  particleOnToggle(isOn) {
    this.isParticleOn = isOn;
    if (this.particle) {
      if (isOn) {
        this.scene.add(this.particle.container);
      } else {
        this.scene.remove(this.particle.container);
      }
    }
  }

  setGui() {
    // gui.open();
    // gui.add(this.stage, "width").listen();
    // gui.add(this.stage, "height").listen();
    // this.setEffGui();

    this.syncImgMeshs.forEach((item: SyncImgMesh) => {
      item.applyUniforms({
        uCurve: this.curveImgOffsets.uCurve,
        uCurveY: this.curveImgOffsets.uCurveY,
        uCurveStyle: this.curveImgOffsets.uCurveStyle,
      });
    });

    gui.add(this.camera, "fov", 0, 180).onChange((value) => {
      this.camera.updateProjectionMatrix();
    });
    gui
      .add(this.curveImgOffsets, "uCurve", -3000, 3000)
      .listen()
      .onChange((value) => {
        this.syncImgMeshs.forEach((item: SyncImgMesh) => {
          item.applyUniforms({ uCurve: this.curveImgOffsets.uCurve });
        });
      });
    gui
      .add(this.curveImgOffsets, "uCurveY", -2, 2, 0.01)
      .listen()
      .onChange((value) => {
        this.syncImgMeshs.forEach((item: SyncImgMesh) => {
          item.applyUniforms({ uCurveY: this.curveImgOffsets.uCurveY });
        });
      });

    gui_vec3(gui, this.camera.position, {
      folder: "📷camera",
      range: 10000,
      threshold: 0.1,
    }).close();
  }

  // render(): void {
  // super.render();
  // console.log(`🚀 ~ this.syncImgMeshs:`, this.syncImgMeshs.length);
  // }

  update(): void {
    if (this.particle && this.isParticleOn) {
      this.particle.update();
    }
  }

  kill() {
    super.kill();
  }

  syncScroll(scrollY, velocity) {
    velocity = Math.min(velocity, 100) / 100;
    this.syncImgMeshs.forEach((item: SyncImgMesh, i) => {
      item.updateScroll(scrollY, velocity);
    });
  }
}
