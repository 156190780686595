import { type } from "os";
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import theme from "./theme";

const guideCSS = {
  guideInner: css`
    background-color: white;
    /* padding: 20px; */
    min-height: 100vh;
  `,

  guideSidebar: css`
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 300px;
    height: 100vh;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px;

    h2 {
      text-align: center;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .MuiPaper-root {
      border: 0;
      border-radius: 0;
      box-shadow: none !important;
      &::before,
      &::after {
        display: none !important;
      }
      .MuiButtonBase-root {
        min-height: 0 !important;
        padding: 10px 0 !important;
        .MuiAccordionSummary-content {
          margin: 0 !important;
          font-weight: 600;
          font-size: 18px;
        }
      }
      .MuiCollapse-root {
        .depth-01 {
          li {
            a {
              display: flex;
              align-items: center;
              color: inherit;
              color: black;
              text-transform: capitalize !important;

              &::before {
                content: "";
                width: 6px;
                height: 2px;
                background-color: rgba(0, 0, 0, 0.2);
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  `,

  guideContainer: css`
    * {
      color: black;
    }
    padding-left: 300px;
    width: 100%;

    .guide-content {
      padding: 20px;

      .guide-section-title {
        text-align: center;
        font-size: 28px;
      }
    }
  `,
};
export default guideCSS;
