import axios from "axios";
import { getScheduleListParamsType } from "@/types/schedule";

/**
 * 스케쥴 전체 목록 조회
 */
export const getScheduleListAPI = async (params: getScheduleListParamsType) => {
  return await axios.get("/api/cs/schedule/page-list", { params });
};

/**
 * 스케쥴 상세 조회
 */
export const getScheduleDetailAPI = async (seq: number) => {
  console.log("getScheduleDetailAPI", seq);
  return await axios.get(`/api/cs/schedule/get/${seq}`);
};

/**
 * 스케쥴 타입 조회
 */
export const getScheduleTypeAPI = async () => {
  return await axios.get("/api/cs/schedule/get-type");
};
