import { MONTH_LIST } from "@/assets/mock/months";

import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import InternationalDetail from "@/components/scheduler/scheduler-detail";
import SchedulerItem from "@/components/scheduler/scheduler-item";
import SchedulerList from "@/components/scheduler/scheduler-list";
import { XTMonthRadio, XTRadioGroupForm } from "@/components/ui/radio";
import RoundedButton from "@/components/ui/roundedButton";
import { useScheduleManager } from "@/hooks/use-international";
import { isDetailPageState, lenisScrollState, pageYState } from "@/state";
import { css } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router";
import SchedulerVisual from "@/components/scheduler/scheduler-visual";
import { MobileSchedulerTabTitle } from "@/components/ui/mobile-tab";
import SchedulerMobileTab from "@/components/scheduler/scheduler-mobile-tab";
import { useRecoilState, useRecoilValue } from "recoil";
import commonMotion from "@/utils/ineraction/common";
import moment from "moment";
import { postMobileTabsOpenState } from "@/state/post";
import { lockDom } from "@/assets/ts/common";

export default function Scheduler() {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const { scheduleData, scheduleParams, setScheduleParams, fetchNextPage, hasNextPage } = useScheduleManager();
  const navigate = useNavigate();
  const lenis = useRecoilValue(lenisScrollState);
  const location = useLocation();
  const detailMatch = useMatch("/scheduler/:seq");
  const [isDetailPage, setIsDetailPage] = useRecoilState(isDetailPageState);

  useEffect(() => {
    document.querySelector("html").classList.add("b");

    return () => {
      document.querySelector("html").classList.remove("b");
    };
  }, []);

  const handleSchTpDtl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleParams((prev) => {
      return {
        ...prev,
        schMm: e.target.value,
        pg: "1",
      };
    });
  };

  const [isMobileMonthOpen, setIsMobileMonthOpen] = useState(false);

  const handleMobileMonthOpen = () => {
    setIsMobileMonthOpen(true);
    setIsDetailPage(true);
  };

  const listRef = useRef(null);

  const handleNextYear = () => {
    setScheduleParams((prev) => {
      return {
        ...prev,
        schMm: "",
        pg: "1",
        schYyyy:
          scheduleParams.schYyyy == moment().subtract(1, "y").format("YYYY")
            ? moment().format("YYYY")
            : moment().add(1, "y").format("YYYY"),
      };
    });
  };
  const handlePrevYear = () => {
    setScheduleParams((prev) => {
      return {
        ...prev,
        schMm: "",
        pg: "1",
        schYyyy:
          scheduleParams.schYyyy == moment().add(1, "y").format("YYYY")
            ? moment().format("YYYY")
            : moment().subtract(1, "y").format("YYYY"),
      };
    });
  };

  const yearRef = useRef(null);
  const [isTabOpen, setIsTabOpen] = useRecoilState(postMobileTabsOpenState);

  useEffect(() => {
    if (location.hash !== "") return;
    const visualSect = document.querySelector(".sc-visual");
    if (lenis.scroll > visualSect.clientHeight * 0.6) {
      yearRef.current.classList.add("active");
    } else {
      yearRef.current.classList.remove("active");
    }

    if (lenis.scroll >= lenis.limit - 500) {
      yearRef.current.classList.remove("active");
    } else if (lenis.scroll > visualSect.clientHeight * 0.6) {
      yearRef.current.classList.add("active");
    }
  }, [lenis, location.hash]);

  const getCurrentYyyymm = (year: string, month: string) => {
    if (month.length === 1) {
      return `${year}.0${month}`;
    } else {
      return `${year}.${month}`;
    }
  };

  const handleFooterHidden = () => {
    const main = document.querySelector("main");
    main.classList.add("hidden-footer");
  };

  const handleFooterActive = () => {
    const main = document.querySelector("main");
    main.classList.remove("hidden-footer");
  };

  const [pageY, setPageY] = useRecoilState(pageYState);

  function scrollLock() {
    setPageY(window.scrollY);
    const main = document.querySelector("main");
    main.style.top = `-${window.scrollY}px`;
    lockDom("html");
    lockDom("body");
  }

  return (
    <>
      {isMobile && location.hash === "#calendar" && isTabOpen ? (
        <SchedulerMobileTab
          scheduleParams={scheduleParams}
          handleSchTpDtl={handleSchTpDtl}
          MONTH_LIST={MONTH_LIST}
          currentYear={scheduleParams.schYyyy}
          currentMonth={scheduleParams.schMm}
          handlePrevYear={handlePrevYear}
          handleNextYear={handleNextYear}
          handleFooterActive={handleFooterActive}
          handleFooterHidden={handleFooterHidden}
        />
      ) : (
        <>
          <SchedulerVisual scheduleParams={scheduleParams} />
          <div className="main-wrap" css={style.container}>
            <div css={[globalCSS.postContainer]}>
              <div className="year-control-box" ref={yearRef}>
                {!isMobile ? (
                  <>
                    <button
                      onClick={() => handlePrevYear()}
                      className={scheduleParams.schYyyy === moment().subtract(1, "y").format("YYYY") ? "disabled" : ""}>
                      <i css={globalCSS.icoDatePrev}></i>
                    </button>
                    <span>{scheduleParams.schYyyy}</span>
                    <button
                      onClick={() => handleNextYear()}
                      className={scheduleParams.schYyyy === moment().add(1, "y").format("YYYY") ? "disabled" : ""}>
                      <i css={globalCSS.icoDateNext}></i>
                    </button>
                  </>
                ) : (
                  !isMobileMonthOpen && (
                    <MobileSchedulerTabTitle
                      onClick={() => {
                        navigate(`/scheduler#calendar`);
                        handleFooterHidden();
                        setIsTabOpen(true);
                      }}>
                      {getCurrentYyyymm(scheduleParams.schYyyy, scheduleParams.schMm)}
                    </MobileSchedulerTabTitle>
                  )
                )}
              </div>

              <div className="scheduler-wrapper">
                <div className="tabs-area" style={{ marginBottom: !isMobile ? "100px" : "0px" }}>
                  <XTRadioGroupForm
                    defaultValue={scheduleParams.schMm}
                    onChange={handleSchTpDtl}
                    value={scheduleParams.schMm}>
                    {MONTH_LIST.map((month, index) => {
                      return <XTMonthRadio key={index} value={month.cd} label={`${month.nm}`} />;
                    })}
                  </XTRadioGroupForm>
                </div>
                <div className="contents-area">
                  <AnimatePresence>
                    <SchedulerList ref={listRef} onMouseLeave={() => commonMotion.hover(-1, listRef.current)}>
                      {scheduleData?.pages.map((page) => {
                        const scheduleList = page.data?.data.scheduleList;

                        return scheduleList?.list?.map((schedule, idx) => {
                          const item = {
                            endYmd: schedule.endYmd,
                            strtYmd: schedule.strtYmd,
                            title: schedule.ttl,
                            seq: schedule.seq,
                            author: schedule.scheduleDetailList,
                            image: schedule.scheduleImageList?.[0]?.attachImage,
                            idx: idx,

                            // handleItemMouseEnter: handleItemMouseEnter(idx),
                          };

                          return (
                            <SchedulerItem
                              item={item}
                              handleItemHover={() => commonMotion.hover(idx, listRef.current)}
                              key={item.seq}
                            />
                          );
                        });
                      })}
                    </SchedulerList>
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </div>
          {hasNextPage && !isMobileMonthOpen && (
            <div className="btn-box" css={style.btnBox}>
              <RoundedButton type="more" onClick={() => fetchNextPage()} />
            </div>
          )}

          {detailMatch ? <InternationalDetail seq={detailMatch.params.seq} /> : null}
        </>
      )}
    </>
  );
}

const style = {
  container: css`
    background-color: ${theme.palette.black};
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      padding-top: ${theme.typography.pxToRem(240)};
      position: relative;
    }
    > div {
      /* padding-top: ${theme.typography.pxToRem(65)};
      margin-top: ${theme.typography.pxToRem(175)}; */
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        padding-top: 0;
        display: block;
      }
    }

    .year-control-box {
      position: fixed;

      top: ${theme.typography.pxToRem(40)};
      height: ${theme.typography.pxToRem(30)};
      left: 50%;
      transform: translateX(-50%) translateY(-300%);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${theme.typography.pxToRem(15)};
      z-index: 10;
      mix-blend-mode: difference;
      transition: transform 0.4s;

      @media (max-width: ${theme.breakpoints.values.lg}px) {
        position: sticky;
        /* left: 0; */
        /* left: 50%; */
        left: 0;
        transform: none;

        /* transform: translateX(-50%); */
        top: ${theme.typography.pxToRem(30)};
      }
      button {
        cursor: pointer;

        &.disabled {
          opacity: 0.3;
          cursor: auto;
        }
      }

      span {
        ${globalCSS.en};
        font-weight: 700;
        font-size: ${theme.typography.pxToRem(26)};
        line-height: 1;
        color: ${theme.palette.white};
      }

      &.active {
        transform: translateX(-50%);
        @media (max-width: ${theme.breakpoints.values.lg}px) {
          transform: none;
        }
      }
    }

    .scheduler-wrapper {
      width: 100%;
      display: flex;
      /* margin-top: ${theme.typography.pxToRem(174)}; */
    }

    .contents-area {
      @media (max-width: ${theme.breakpoints.values.lg}px) {
        padding-top: ${theme.typography.pxToRem(64)};
      }
    }
  `,

  btnBox: css`
    width: 100%;
    background-color: ${theme.palette.black};
    display: flex;
    justify-content: center;
    padding-bottom: ${theme.typography.pxToRem(150.35)};
  `,

  mobileMonthBox: css`
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid red;
  `,
};
