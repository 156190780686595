import { lsToPx } from "@/assets/ts/common";
import globalCSS from "@/assets/ts/global-css";
import theme from "@/assets/ts/theme";
import { Checkbox, FormControlLabel, FormLabel, Input, TextField } from "@mui/material";
import { css, styled } from "@mui/material/styles";
import { url } from "inspector";

export const XTSearchInput = styled(Input)(({ theme }) => ({
  position: "sticky",
  top: `${theme.typography.pxToRem(100)}`,
  // marginTop: `${theme.typography.pxToRem(-138)}`,
  left: 0,
  zIndex: 10,
  width: "100%",
  maxWidth: `${theme.typography.pxToRem(1140)}`,

  "&::before, &::after": {
    display: "none",
  },

  input: {
    padding: 0,
    paddingBottom: `${theme.typography.pxToRem(10)}`,
    borderBottom: `1px solid ${theme.palette.white}`,
    height: "100%",
    boxSizing: "border-box",
    color: `${theme.palette.white}`,
    fontFamily: '"Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif',
    fontSize: `${theme.typography.pxToRem(27)}`,
    lineHeight: 32 / 27,
    mixBlendMode: "difference",
    fontWeight: 400,
    paddingRight: "110px",
    borderRadius: "0px",

    "&.Mui-disabled": {
      // disabled
      // fontFamily: '"Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif',
      // fontSize: `${theme.typography.pxToRem(27)}`,
      // lineHeight: 32 / 27,
      // mixBlendMode: "difference",
      // fontWeight: 400,
      // color: `${theme.palette.gray}`,
      // [theme.breakpoints.down("lg")]: {
      //   fontSize: `${theme.typography.pxToRem(20)}`,
      //   lineHeight: 32 / 20,
      // },
    },

    [theme.breakpoints.down("lg")]: {
      fontSize: `${theme.typography.pxToRem(20)}`,
      lineHeight: 32 / 20,
      padding: `${theme.typography.pxToRem(10)} 0`,
      paddingRight: "110px",
    },

    "&::placeholder": {
      fontFamily: '"Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum, "돋움", arial, sans-serif',
      fontSize: `${theme.typography.pxToRem(27)}`,
      lineHeight: 32 / 27,
      mixBlendMode: "difference",
      fontWeight: 400,
      color: `${theme.palette.gray}`,
      opacity: 1,
      [theme.breakpoints.down("lg")]: {
        fontSize: `${theme.typography.pxToRem(20)}`,
        lineHeight: 32 / 20,
      },
    },

    "&:focus": {
      backgroundColor: `${theme.palette.black}`,
      mixBlendMode: "normal",
    },
  },

  ".input-btn-wrap": {
    position: "absolute",
    top: "50%",
    left: 0,
    transform: "translateY(-50%)",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 10,
    pointerEvents: "none",
    button: {
      pointerEvents: "auto",
      cursor: "pointer",
    },
  },
}));

export const XTInput = styled(Input)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.white}`,
  width: "100%",

  input: {
    width: "100%",

    padding: `${theme.typography.pxToRem(10)} 0 ${theme.typography.pxToRem(16)};`,
    height: "auto",
    color: `${theme.palette.white};`,
    fontWeight: 400,
    fontSize: `${theme.typography.pxToRem(20)};`,
    lineHeight: `${theme.typography.pxToRem(30)};`,

    [theme.breakpoints.down("lg")]: {
      padding: `${theme.typography.pxToRem(14)} 0 ${theme.typography.pxToRem(12)};`,
      fontWeight: 600,
    },

    "&::placeholder": {
      color: `${theme.palette.gray};`,
      fontWeight: 400,
      fontSize: `${theme.typography.pxToRem(20)};`,
      lineHeight: `${theme.typography.pxToRem(30)};`,
      opacity: 1,

      [theme.breakpoints.down("lg")]: {
        fontWeight: 600,
      },
    },

    "&.Mui-disabled": {
      "-webkit-text-fill-color": `${theme.palette.gray};`,
      opacity: "0.3",
    },
  },

  "&::before, &::after": {
    display: "none",
  },
}));
export const XTTextarea = styled(TextField)(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  borderRadius: "0px",
  resize: "none",
  outline: "none",
  fontWeight: 400,
  backgroundColor: `${theme.palette.transparent}`,
  marginTop: `${theme.typography.pxToRem(10)};`,
  height: `${theme.typography.pxToRem(116)}`,
  [theme.breakpoints.down("lg")]: {
    height: `${theme.typography.pxToRem(164)}`,
  },
  "&:focus": {
    outline: "none",
    border: "none",
  },
  ".MuiOutlinedInput-root": {
    overflowY: "scroll",
    overflowX: "hidden",
    height: "100% !important",
    borderRadius: 0,
    borderBottom: "1px solid white",
    color: `${theme.palette.white};`,
    fontWeight: 700,
    fontSize: `${theme.typography.pxToRem(20)};`,
    lineHeight: `${theme.typography.pxToRem(30)};`,
    alignItems: "flex-start",
    padding: 0,
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: " none",
    },
  },

  textarea: {
    // height: "100% !important",
    // overflowY: "scroll !important",
    fontWeight: 400,

    "&::placeholder": {
      color: `${theme.palette.gray};`,
      fontWeight: 400,
      fontSize: `${theme.typography.pxToRem(20)};`,
      lineHeight: `${theme.typography.pxToRem(30)};`,
      opacity: 1,
    },
    "&:focus": {
      outline: "none",
      border: "none",
    },
  },

  input: {
    padding: 0,
    height: "100%",
    "&:focus": {
      outline: "none",
      border: "none",
    },
  },

  fieldset: {
    padding: 0,
    display: "none",
  },
}));
export const XTCheckBox = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  ".MuiCheckbox-root": {
    padding: 0,
    borderRadius: 0,
    width: `${theme.typography.pxToRem(34)}`,
    height: `${theme.typography.pxToRem(34)}`,
    border: `1px solid ${theme.palette.white};`,
    backgroundColor: "transparent",
    background: "url('/images/icon/ico-checkbox.svg') no-repeat center / cover",
    svg: {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {
      width: `${theme.typography.pxToRem(28)}`,
      height: `${theme.typography.pxToRem(28)}`,
    },

    "&.Mui-checked": {
      // backgroundColor: `${theme.palette.white};`,
      backgroundColor: `${theme.palette.white}`,
      background: `url('/images/icon/ico-checkbox-checked.svg') no-repeat center / cover`,
    },
  },
  ".MuiTypography-root": {
    marginLeft: `${theme.typography.pxToRem(20)};`,
    color: `${theme.palette.white};`,
    fontWeight: 400,
    fontSize: `${theme.typography.pxToRem(20)};`,
    lineHeight: `${theme.typography.pxToRem(30)};`,

    [theme.breakpoints.down("lg")]: {
      marginLeft: `${theme.typography.pxToRem(15)};`,

      fontSize: `${theme.typography.pxToRem(16)};`,
      lineHeight: `${theme.typography.pxToRem(24)};`,
    },
  },
}));
