import { usePostManager } from "@/hooks/use-post";
import { useEffect, useRef, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { css } from "@emotion/react";
import XTPagination from "@/components/ui/pagination";
import globalCSS from "@/assets/ts/global-css";
import PostItem from "@/components/posts/post-item";
import { useMediaQuery } from "@mui/material";
import UndefinedSchResult from "@/components/posts/undefined-sch-result";
import { XTSearchInput } from "@/components/ui/input";
import theme from "@/assets/ts/theme";
import { AnimatePresence } from "framer-motion";
import PostDetail from "@/components/posts/post-detail";
import { List } from "@/components/posts/post";
import { XTMonthRadio, XTRadioGroupForm } from "@/components/ui/radio";
import FormControl from "@mui/material/FormControl";
import { MobileTabArea, MobileTabTitle } from "@/components/ui/mobile-tab";
import commonMotion from "@/utils/ineraction/common";
import CircleSm from "@/assets/ts/bauerlab/CircleSm";
import { useRecoilState, useRecoilValue } from "recoil";
import { lenisScrollState, pageYState } from "@/state";
import { postMobileTabsOpenState } from "@/state/post";
import { lockDom, unlockDom } from "@/assets/ts/common";
import i18n from "@/i18n";

export default function NoticeList({ lenis }) {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
  const location = useLocation();

  const listRef = useRef(null);
  const [isFirst, setIsFirst] = useState(true);
  const detailMatch = useMatch("/notice/:seq");
  const subPageTitle = location.pathname.replace("/", "");
  const [isMobileTabsOpen, setIsMobileTabsOpen] = useState(false);
  const lenisOpt = useRecoilValue(lenisScrollState);
  const [isTabOpen, setIsTabOpen] = useRecoilState(postMobileTabsOpenState);
  const [pageY, setPageY] = useRecoilState(pageYState);

  const convertPostDetailype = (tpDtl: number) => {
    return postTypeList?.BoardDetailType?.[`${tpDtl}`];
  };

  useEffect(() => {
    document.querySelector("html").classList.add("b");

    return () => {
      document.querySelector("html").classList.remove("b");
    };
  }, []);

  const { postTypeList, postList, setSearchParams, defaultParams, searchParams } = usePostManager();

  const [schForm, setSchForm] = useState({
    pg: defaultParams.pg,
    schTxt: defaultParams.schTxt,
    schTpDtl: defaultParams.schTpDtl,
  });

  const convertBoardDetailTypeList = () => {
    const list = Object.entries(postTypeList.BoardDetailType);
    const convertList = list.map((el) => {
      return {
        nm: el[1],
        cd: el[0],
      };
    });

    return convertList;
  };

  const handleSchTxt = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSchForm((prev) => {
      return {
        ...prev,
        schTxt: e.target.value,
        pg: "1",
      };
    });
  };

  const handleSchTpDtl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSchForm((prev) => {
      return {
        ...prev,
        schTpDtl: e.target.value,
        pg: "1",
      };
    });

    if (isMobile) {
      setIsMobileTabsOpen(false);
    }
  };

  const handleChangePage = (e, value) => {
    setSchForm((prev) => {
      return {
        ...prev,
        pg: value,
      };
    });

    window.dispatchEvent(new Event("lenisTop"));
  };

  useEffect(() => {
    setIsFirst(false);
  }, []);

  useEffect(() => {
    if (!isFirst) {
      setSearchParams({
        ...searchParams,
        ...schForm,
      });
    }
  }, [schForm]);

  const getSchTpDtlTxt = (schTpDtlTxt: string) => {
    switch (schTpDtlTxt) {
      case "1":
        return "Article";
      case "2":
        return "Career";
      default:
        return "Article";
    }
  };
  const circleRef = useRef(null);
  const circleCanvasRef = useRef(null);
  useEffect(() => {
    if (!circleRef.current) {
      circleRef.current = new CircleSm(circleCanvasRef.current, "transparent");
    } else {
      return;
    }
  }, [circleCanvasRef]);
  const mobileTabTitleRef = useRef(null);

  useEffect(() => {
    if (isMobile) {
      if (lenisOpt.scroll + 200 >= lenisOpt.limit) {
        mobileTabTitleRef.current.style.transform = `translate(-50%, -300%)`;
      } else {
        mobileTabTitleRef.current.style.transform = `translate(-50%, 0%)`;
      }
    } else {
      if (lenisOpt.scroll > 0 && lenisOpt.scroll < 200) {
        circleCanvasRef.current.style.transform = `translateY(${lenisOpt.scroll}px) scale(1.3)`;
        circleCanvasRef.current.style.opacity = `${lenisOpt.scroll / -200 + 1}`;
      }
    }
  }, [lenisOpt, isMobile]);

  function scrollLock() {
    // setPageY(window.scrollY);
    // const main = document.querySelector("main");
    // main.style.top = `-${window.scrollY}px`;
    lockDom("html");
    lockDom("body");
  }

  function scrollUnlock() {
    const main = document.querySelector("main");

    unlockDom("html");
    unlockDom("body");
    // main.style.top = `0px`;
    // window.scrollTo(0, pageY);
    // window.setTimeout(() => {
    //   setPageY(null);
    // });
  }

  useEffect(() => {
    if (isTabOpen) {
      const main = document.querySelector("main");
      main.classList.add("hidden-footer");
      scrollLock();
    } else {
      const main = document.querySelector("main");
      main.classList.remove("hidden-footer");
      scrollUnlock();
    }
  }, [isTabOpen]);

  return (
    <>
      <div
        className="main-wrap"
        css={[
          style.visual,
          {
            overflowX: isMobile && isTabOpen ? "clip" : "clip",
            height: isMobile && isTabOpen ? "calc(var(--vh) * 100)" : "auto",
          },
        ]}>
        <canvas
          ref={circleCanvasRef}
          css={[
            globalCSS.circleSm,
            {
              transform: isMobile && isTabOpen ? "scale(2)" : "scale(1.3)",
              opacity: isMobile && isTabOpen ? "0.5" : "0.7",
            },
          ]}></canvas>

        <div css={[globalCSS.postContainer, style.container]}>
          {!isMobile ? (
            <h2 className="sub-page-title">NOTICE</h2>
          ) : (
            <MobileTabTitle
              ref={mobileTabTitleRef}
              onClick={() => {
                setIsMobileTabsOpen(true);
                setIsTabOpen(true);
              }}
              css={{ display: isTabOpen ? "none" : "flex" }}>
              {getSchTpDtlTxt(schForm.schTpDtl)}
            </MobileTabTitle>
          )}
          <div className="tabs-area">
            <FormControl>
              <XTRadioGroupForm
                onChange={handleSchTpDtl}
                value={schForm.schTpDtl}
                defaultValue="1">
                {convertBoardDetailTypeList()?.map((tab, index) => (
                  <XTMonthRadio
                    label={`${tab.nm}`}
                    key={index}
                    value={`${tab.cd}`}
                  />
                ))}
              </XTRadioGroupForm>
            </FormControl>
          </div>
          <div className="contents-area">
            <XTSearchInput
              value={schForm.schTxt}
              onChange={handleSchTxt}
              placeholder={i18n.language === "ko" ? "제목을 입력해 주세요." : "Please enter a keyword"}
              type="text"
              endAdornment={
                <div className="input-btn-wrap">
                  {schForm.schTxt?.length > 0 && (
                    <button
                      onClick={() =>
                        setSchForm((prev) => {
                          return {
                            ...prev,
                            schTxt: "",
                          };
                        })
                      }>
                      <i css={globalCSS.icoDelete}></i>
                    </button>
                  )}
                  <button
                    onClick={() => {
                      isMobile && setIsTabOpen(false);
                    }}>
                    <i css={globalCSS.icoSearch}></i>
                  </button>
                </div>
              }
            />
            {!isTabOpen ? (
              <AnimatePresence>
                <List
                  onMouseLeave={() => commonMotion.hover(-1, listRef.current)}
                  ref={listRef}>
                  {defaultParams.schTxt && postList.boardList.list.length == 0 ? (
                    <UndefinedSchResult schTxt={schForm.schTxt} />
                  ) : (
                    postList.boardList.list.map((item, index) => {
                      const itemData = {
                        image: item.boardImageList[0].attachImage,
                        title: item.ttl,
                        cont: item.cont,
                        date: item.formatRgstYmd,
                        prvncDtl: item.prvncDtl,
                        seq: item.seq,
                        category: convertPostDetailype(item.tpDtl),
                        idx: index,
                        tpDtl: item.tpDtl,
                      };
                      return (
                        <PostItem
                          data={itemData}
                          handleItemHover={() => commonMotion.hover(index, listRef.current)}
                          key={index}
                          lenis={lenis}
                          schTxt={schForm.schTxt}
                        />
                      );
                    })
                  )}
                </List>
              </AnimatePresence>
            ) : (
              <MobileTabArea>
                <FormControl>
                  <XTRadioGroupForm
                    onChange={handleSchTpDtl}
                    value={schForm.schTpDtl}
                    defaultValue="1">
                    {convertBoardDetailTypeList()?.map((tab, index) => (
                      <XTMonthRadio
                        label={`${tab.nm}`}
                        key={index}
                        value={`${tab.cd}`}
                      />
                    ))}
                  </XTRadioGroupForm>
                </FormControl>
              </MobileTabArea>
            )}
            {/* <div className="inner">
            </div> */}
          </div>
        </div>
        {postList.boardList.list.length > 0 && !isTabOpen && (
          <div
            className="pagination-box"
            css={globalCSS.paginationWrap}>
            <XTPagination
              onChange={handleChangePage}
              page={Number(schForm.pg)}
              count={postList.boardList?.totalPageSize}
            />
          </div>
        )}
      </div>
      {detailMatch ? <PostDetail seq={detailMatch.params.seq} /> : null}
    </>
  );
}

const style = {
  visual: css`
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      position: relative;
    }
  `,

  container: css`
    padding-top: ${theme.typography.pxToRem(138)};
    /*
    display: grid;
    grid-template-columns: 1px 1fr;
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
    }

    .contents-area {
      max-width: ${theme.typography.pxToRem(1140)};
      margin: 0 auto;
    } */

    ul.content-list {
      padding-top: ${theme.typography.pxToRem(60)};
    }

    .pagination-box {
    }
  `,
};
