import { useLanguage } from "@/hooks/use-language";
import { CSSObject } from "@emotion/react";
import { createTheme, PaletteOptions, Palette } from "@mui/material";
import { createBreakpoints } from "@mui/system";
import { string } from "zod";

declare module "@mui/material/styles" {
  export interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }

  export interface Easing {
    inout1: string;
    inout2: string;
    inout3: string;
    inout4: string;
    inout5: string;
  }

  export interface PaletteColor {}

  export interface SimplePaletteColorOptions {}

  export interface Palette {
    default: string;
    black: string;
    yellow: string;
    yTxt: string;
    yg1: string;
    yg2: string;
    yg3: string;
    blue: string;
    white: string;
    gray: string;
    transparent: string;
  }

  export interface PaletteOptions {
    default: string;
    black: string;
    yellow: string;
    yTxt: string;
    yg1: string;
    yg2: string;
    yg3: string;
    blue: string;
    white: string;
    gray: string;
    transparent: string;
  }
}

const customBreakpointValues = {
  values: {
    xxs: 320,
    xs: 375,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
  },
};

const theme = createTheme({
  spacing: 5,
  breakpoints: {
    ...customBreakpointValues,
  },
  palette: {
    default: "#000",
    black: "#000",
    yellow: "#D9D400",
    yTxt: "#FFFA00",
    yg1: "#848701",
    yg2: "#969311",
    yg3: "#A8A516",
    blue: "#131CDE",
    white: "#fff",
    gray: "#5F5F5F",
    transparent: "transparent",
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "'Pretendard','Roboto', 'Noto Sans KR', 'Malgun gothic', '맑은고딕', 'Dotum', '돋움', 'arial'",
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: (themeParams): any =>
        `
        * {
        padding: 0;
        margin: 0;
        box-sizing: border-box !important;
        text-transform: none !important;
        
        :focus-visible {
          outline: none !important;
        }
      }
      
      @font-face {
        font-family: "Pretendard";
        font-weight: 100;
        font-style: normal;
        src: url("/fonts/Pretendard-Thin.ttf") format("truetype"),
          url("/fonts/Pretendard-Thin.woff") format("woff"),
          url("/fonts/Pretendard-Thin.woff2") format("woff2"),
          url("/fonts/Pretendard-Thin.otf") format("opentype");
      }
      
      @font-face {
        font-family: "Pretendard";
        font-weight: 200;
        font-style: normal;
        src: url("/fonts/Pretendard-ExtraLight.ttf") format("truetype"),
          url("/fonts/Pretendard-ExtraLight.woff") format("woff"),
          url("/fonts/Pretendard-ExtraLight.woff2") format("woff2"),
          url("/fonts/Pretendard-ExtraLight.otf") format("opentype");
      }
      
      @font-face {
        font-family: "Pretendard";
        font-weight: 300;
        font-style: normal;
        src: url("/fonts/Pretendard-Light.ttf") format("truetype"),
          url("/fonts/Pretendard-Light.woff") format("woff"),
          url("/fonts/Pretendard-Light.woff2") format("woff2"),
          url("/fonts/Pretendard-Light.otf") format("opentype");
      }
      
      @font-face {
        font-family: 'Pretendard';
        font-weight: 400;
        font-style: normal;
        src: url("/fonts/Pretendard-Regular.ttf") format("truetype"),
          url("/fonts/Pretendard-Regular.woff") format("woff"),
          url("/fonts/Pretendard-Regular.woff2") format("woff2"),
          url("/fonts/Pretendard-Regular.otf") format("opentype");
      }
      
      @font-face {
        font-family: 'Pretendard';
        font-weight: 500;
        font-style: normal;
        src: url("/fonts/Pretendard-Medium.ttf") format("truetype"),
          url("/fonts/Pretendard-Medium.woff") format("woff"),
          url("/fonts/Pretendard-Medium.woff2") format("woff2"),
          url("/fonts/Pretendard-Medium.otf") format("opentype");
      }
      
      @font-face {
        font-family: 'Pretendard';
        font-weight: 600;
        font-style: normal;
        src: url("/fonts/Pretendard-SemiBold.ttf") format("truetype"),
          url("/fonts/Pretendard-SemiBold.woff") format("woff"),
          url("/fonts/Pretendard-SemiBold.woff2") format("woff2"),
          url("/fonts/Pretendard-SemiBold.otf") format("opentype");
      }
      
      @font-face {
        font-family: "Pretendard";
        font-weight: 700;
        font-style: normal;
        src: url("/fonts/Pretendard-Bold.ttf") format("truetype"),
          url("/fonts/Pretendard-Bold.woff") format("woff"),
          url("/fonts/Pretendard-Bold.woff2") format("woff2"),
          url("/fonts/Pretendard-Bold.otf") format("opentype");
      }
      
      @font-face {
        font-family: "Pretendard";
        font-weight: 800;
        font-style: normal;
        src: url("/fonts/Pretendard-ExtraBold.ttf") format("truetype"),
          url("/fonts/Pretendard-ExtraBold.woff") format("woff"),
          url("/fonts/Pretendard-ExtraBold.woff2") format("woff2"),
          url("/fonts/Pretendard-ExtraBold.otf") format("opentype");
      }

      @font-face {
        font-family: "Faktum Test";
        font-weight: 400;
        font-style: normal;
        src: url("/fonts/FaktumTest-regular.otf") format("opentype");
      }
      
      @font-face {
        font-family: "Faktum Test";
        font-weight: 700;
        font-style: normal;
        src: url("/fonts/FaktumTest-bold.otf") format("opentype");
      }


      
    
      
      html,
      body {
        min-height: 100%;
        // font-family:  "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum,
        //   "돋움", arial, sans-serif;
        font-size: 16px;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        counter-reset: section;
        word-spacing: 1px;
        line-height: 1.5;
        color:#000000;

        @media(max-width:${themeParams.breakpoints.values.xxs}px) {
          font-size: 14px;
        } 
        
      }

      #root {
        font-family:  "Faktum Test", "Pretendard", "Malgun gothic", "맑은고딕", Dotum,
          "돋움", arial, sans-serif;
      }
      
      body {
        -webkit-touch-callout: none;
        // height: 100vh;
      }
      
      html,
      body,
      div,
      span,
      applet,
      object,
      iframe,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      pre,
      a,
      abbr,
      acronym,
      address,
      big,
      cite,
      code,
      del,
      dfn,
      em,
      img,
      ins,
      kbd,
      q,
      s,
      samp,
      small,
      strike,
      strong,
      sub,
      sup,
      tt,
      var,
      b,
      u,
      i,
      center,
      dl,
      dt,
      dd,
      ol,
      ul,
      li,
      fieldset,
      form,
      label,
      legend,
      table,
      caption,
      tbody,
      tfoot,
      thead,
      tr,
      th,
      td,
      article,
      aside,
      canvas,
      details,
      embed,
      figure,
      figcaption,
      footer,
      header,
      hgroup,
      menu,
      nav,
      output,
      ruby,
      section,
      summary,
      time,
      mark,
      audio,
      video {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
      
      /* HTML5 display-role reset for older browsers */
      article,
      aside,
      details,
      figcaption,
      figure,
      footer,
      header,
      hgroup,
      menu,
      nav,
      section {
        display: block;
      }
      
      input,
      button,
      select,
      optgroup,
      textarea {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
      }
      
      em {
        font-style: normal;
      }
      
      ol,
      ul {
        li {
          list-style: none;
        }
      }

      a {
        color: inherit;
      }

      a:-webkit-any-link {
        color: inherit;
      }
      
      blockquote,
      q {
        quotes: none;
      }
      
      a {
        color: inherit;
      }
      
      blockquote:before,
      blockquote:after,
      q:before,
      q:after {
        content: "";
        content: none;
      }
      
      table {
        border-collapse: collapse;
        border-spacing: 0;
      }
      
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 5000s;
        -webkit-text-fill-color: auto !important;
      }
      
      
      button {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        cursor: pointer;
      }
      
      .App-body {
        // padding-top: 87px;
        // display: flex;
        // justify-content: space-between;
      }
      
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input::-ms-reveal, input::-ms-clear {
        display: none !important;
      }
      
      .flex {
        display: flex;
        align-items: center;
      }
      
      .col {
        display: flex;
        flex-direction: column;
      }
      
      .row {
        display: flex;
        flex-direction: row;
      }
      
      .justify-content-between {
        justify-content: space-between;
      }
      
      .justify-content-around {
        justify-content: space-around;
      }
      
      .justify-content-center {
        justify-content: center;
      }
      
      .align-items-center {
        align-items: center;
      }

      
      
      .align-items-start {
        align-items: flex-start;
      }
      
      .align-items-end {
        align-items: flex-end;
      }
      
      .align-self-end {
        align-self: flex-end;
      }

      .align-self-start {
        align-self: flex-start;
      }

      .align-self-center {
        align-self: center;
      }

      .no-gap {
        gap: 0;
      }
      
      .gap-10 {
        gap: 0.625rem;
      }
      
      .gap-20 {
        gap: 1.25rem;
      }
      
      .gap-30 {
        gap: 1.875rem;
      }
      
      .gap-40 {
        gap: 2.5rem;
      }
      
      .gap-50 {
        gap: 3.125rem;
      }
      
      .flex-1 {
        flex: 1;
      }
      
      .ml-10 {
        margin-left: 0.625rem;
      }
      
      .mr-10 {
        margin-right: 0.625rem;
      }
      
      .mt-10 {
        margin-top: 0.625rem;
      }
      
      .mb-10 {
        margin-bottom: 0.625rem;
      }
      
      .pl-10 {
        padding-left: 0.625rem;
      }
      
      .pr-10 {
        padding-right: 0.625rem;
      }
      
      .pb-10 {
        padding-bottom: 0.625rem;
      }
      
      .pt-10 {
        padding-top: 0.625rem;
      }
      
      .w-100 {
        width: 100%;
      }
        `,
    },
  },

  transitions: {
    easing: {
      inout1: "cubic-bezier(0.26, 0.06, 0, 1)",
      inout2: "cubic-bezier(0.18, 0.06, 0.23, 1)",
      inout3: "cubic-bezier(0.43, 0.05, 0.17, 1)",
      inout4: "cubic-bezier(0.47, 0.16, 0.24, 1)",
      inout5: "cubic-bezier(.44,.14,.18,1)",
    },
  },
});

export default theme;
